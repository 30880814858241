import { FC } from 'react';
import { NavLink } from 'react-router-dom';

const NavTextLink: FC<{ children?: string, href: string }> = ({ href, children }) => {
	return (
		<NavLink
			to={href}
			className="cursor-pointer select-none text-base font-semibold text-gunmetal hover:text-gunmetal-100 px-4"
		>
			{children}
		</NavLink>
	);
}

export default NavTextLink;