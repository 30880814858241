import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { RootState } from '../../store';
import { register, refreshAuthError } from '../../store/auth/actions';
import { RegistrationUserInfo } from '../../store/auth/actions/types';
import { AuthenticationStateType } from '../../store/auth/reducers';
import { RegisterFormState } from '../../store/forms/reducers';
import  RegisterForm from '../../components/forms/register';
import { AlertType, SimpleAlert } from '../../components/alert';
import { useTimer } from '../../commons/hooks';


interface ReduxStateProps {
  authState: AuthenticationStateType | null
  formState: RegisterFormState
}

interface ReduxDispatchProps {
  register: (userInfo: RegistrationUserInfo) => void
  resetAuthError: () => void
}

interface OwnProps{
  token?: string 
}

type RegisterProps = ReduxStateProps & ReduxDispatchProps & OwnProps;

const Register: React.FC<RegisterProps> = ({ resetAuthError, ...props }) => {

  const history = useHistory();
  const [showError, setShowError] = useState<string | null>();
  const [conditionAccepted, setConditionAccepted] = useState(false);
  const timer= useTimer();
  
  const handleCheckboxChange = (isChecked: boolean) => {
    setConditionAccepted(isChecked);
  }

  const register = () => {
    //get form state from redux
    const {email, password, confirmPassword} = props.formState
    //call redux action to register
    props.register({
      email, 
      password, 
      confirmPassword, 
      conditionAccepted,
      temporaryToken: props.token
    });
  }

  //componentDidUpdate: if the user login successfully redirect, otherwise show the error
  useEffect(() => {
    if (props.authState?.toVerify) {
      history.push('/verify_email');
    }
    if (props.authState?.error) {
      setShowError(props.authState.error);
      timer.setTimer(() => setShowError(null), 2000);

      //once error message is "consumed", we should reset user to not authenticated
      //without keeping the error
      resetAuthError();
    }
  }, [props.authState, history, resetAuthError, timer])

  return (
    <div className="relative flex flex-col h-full w-full xl:px-20 justify-center items-center">
      <SimpleAlert showText={showError??undefined} type={AlertType.ERROR}/>

      <RegisterForm
        onSubmit={() => register()}
        onCheckboxChange={handleCheckboxChange}
        onLoginClick={() => history.push("/auth/signin")}
      />

    </div>
  );
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return ({
    authState: state.user,
    formState: state.registerForm
  });
}

const mapDispatchToProps = (dispatch: any): ReduxDispatchProps => {
  return ({
    register: (userInfo) => dispatch(register(userInfo)),
    resetAuthError: () => dispatch(refreshAuthError()),
  })
}

export default connect<ReduxStateProps, ReduxDispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(Register);