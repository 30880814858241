import React from 'react';

import LearnLayout from '../../layouts/learn_layout';
import { BlankCard } from '../../components/cards';
import { WithTranslation, withTranslation } from 'react-i18next';

class FeaturesPage extends React.Component<WithTranslation, {}, { active: number }> {
  constructor(props: any) {
    super(props);
    this.activeSectionCallback = this.activeSectionCallback.bind(this);
    this.state = {
      active: 0,
    }
  }

  componentDidMount() {
    window.scrollTo(0, window.scrollY - 1);
  }

  activeSectionCallback(index: number) {
    //COMMENTATO PERCHE' DA ERRORE IN COMPILAZIONE
/*     if (index !== this.state.active) {
      this.setState({ active: index });
    } */
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <LearnLayout>
        <div className="text-gunmetal-400 flex flex-row w-full justify-center py-8">
          <BlankCard wrapContent className="w-2/3 p-8">
            <h1 className="text-4xl font-bold">{t("learn.features_title")}</h1>
            <section className="mt-8">
              <h2 className="text-2xl font-semibold">{t("learn.features_throughput")}</h2>
              <div className="mt-4">
                <p>
                {t("learn.features_throughput_des_1")}
                </p>
                <p>
                {t("learn.features_throughput_des_2")}
                </p>
              </div>
            </section>
            <section className="mt-8">
              <h2 className="text-2xl font-semibold">{t("learn.features_latency")}</h2>
              <div className="mt-4">
                <p>
                {t("learn.features_latency_des_1")}
                </p>
                <p>
                {t("learn.features_latency_des_2")}
                </p>
              </div>
            </section>
            <section className="mt-8">
              <h2 className="text-2xl font-semibold">{t("learn.features_memory")}</h2>
              <div className="mt-4">
                <p>
                {t("learn.features_memory_des")}
                </p>
              </div>
            </section>
            <section className="mt-8">
              <h2 className="text-2xl font-semibold">{t("learn.features_distribution")}</h2>
              <div className="mt-4">
                <p>
                {t("learn.features_distribution_des_1")}
                </p>
                <ul className="list-disc list-outside ml-8 space-y-2 py-4">
                  <li>{t("learn.features_distribution_des_1_table_storage")}</li>
                  <li>{t("learn.features_distribution_des_1_replication")}</li>
                  <li>{t("learn.features_distribution_des_1_consistency")}</li>
                </ul>
                <p>
                {t("learn.features_distribution_des_2")}
                </p>
                <p>
                {t("learn.features_distribution_des_3")}
                </p>
              </div>
            </section>
            <section className="mt-8">
              <h2 className="text-2xl font-semibold">{t("learn.features_replication")}</h2>
              <div className="mt-4">
                <p>
                {t("learn.features_replication_des")}
                </p>
              </div>
            </section>
            <section className="mt-8">
              <h2 className="text-2xl font-semibold">{t("learn.features_parallelization")}</h2>
              <div className="mt-4">
                <p>
                {t("learn.features_parallelization_des_1")}
                </p>
                <p>
                {t("learn.features_parallelization_des_2")}
                </p>
              </div>
            </section>
            <section className="mt-8">
              <h2 className="text-2xl font-semibold">{t("learn.features_resilience")}</h2>
              <div className="mt-4">
                <p>
                {t("learn.features_resilience_des")}
                </p>
              </div>
            </section>
            <section className="mt-8">
              <h2 className="text-2xl font-semibold">{t("learn.features_sql")}</h2>
              <div className="mt-4">
                <p>
                {t("learn.features_sql_des")}
                </p>
              </div>
            </section>
          </BlankCard>
        </div>
      </LearnLayout>
    );
  }
}

export default withTranslation()(FeaturesPage);
