export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const UPDATE_PASSWORD ="UPDATE_PASSWORD";
export const UPDATE_CONFIRM_PASSWORD ="UPDATE_CONFIRM_PASSWORD";
export const RESET_FORM="RESET_FORM";

export interface UpdateEmailAction {
  type: typeof UPDATE_EMAIL
  payload: string
}

export interface UpdatePasswordAction {
  type: typeof UPDATE_PASSWORD
  payload: string
}

export interface UpdateConfirmPasswordAction {
  type: typeof UPDATE_CONFIRM_PASSWORD
  payload: string
}

export interface ResetFormAction {
  type: typeof RESET_FORM
}


export type FormAction =
  UpdateEmailAction |
  UpdatePasswordAction |
  UpdateConfirmPasswordAction |
  ResetFormAction; 