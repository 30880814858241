import { FC, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMousePosition } from '../../commons/hooks';
import { FlatButton } from '../buttons';
import { BlankCard } from '../cards';

const NavUser: FC<{ fullName: string, email: string }> = ({ fullName, email, ...props }) => {

  const [open, setOpen] = useState(false);
  const detailsCardRef = useRef<HTMLDivElement>(undefined!);

  const mousePosition = useMousePosition();
  const history = useHistory();

  function handleSettingsClick() {
    history.push('/settings');
    setOpen(false);
  }

  function handleNavClick() {
    if (open) setOpen(false);
    else setOpen(true);
  }

  useEffect(() => {
    function handleClickEverywhere() {
      if (detailsCardRef && detailsCardRef.current !== null) {
        //check if the click is inside the card (horizontal condition)
        const horizontalRelativePosition = mousePosition.x - detailsCardRef.current.getBoundingClientRect().left;
        const isHorizontallyInsideTheCard = horizontalRelativePosition >= 0 && horizontalRelativePosition <= detailsCardRef.current.clientWidth
        //check if the click is inside the card (vertical condition)
        const vericalRelativePosition = mousePosition.y - detailsCardRef.current.getBoundingClientRect().top
        const isVerticallyInsideTheCard = vericalRelativePosition >= 0 && vericalRelativePosition <= detailsCardRef.current.clientHeight
        //if the click is outside the card (not inside)
        if (!(isHorizontallyInsideTheCard && isVerticallyInsideTheCard)) {
          setOpen(false);
        }
      }
    }

    if (open) {
      window.addEventListener("click", handleClickEverywhere);
      return () => window.removeEventListener("click", handleClickEverywhere);
    }
  }, [mousePosition, open])

  return (
    <>
      <button onClick={handleNavClick} className={`bg-cherry-red-400 rounded-full h-10 w-10 sm:h-11 sm:w-11 hover:bg-cherry-red-300 transition-colors duration-200 cursor-pointer flex flex-col items-center justify-center focus:outline-none ${open && `ring-1 ring-inset ring-white ring-offset-cherry-red-200 ring-offset-2`}`}>
        <p className="text-xl text-white font-bold">{email.charAt(0).toUpperCase()}</p>
      </button>
      {open && <div ref={detailsCardRef} className={`absolute right-4 top-16 mt-1.5 ${open ? 'animate-fade-in-rapid' : 'animate-fade-out-rapid'}`}>
        <BlankCard>
          <div className="w-64 sm:w-72">
            <div className="flex flex-row space-x-4 items-center">
              <div className="bg-cherry-red-400 rounded-full h-13 w-13 sm:h-16 sm:w-16 flex flex-col items-center justify-center">
                <p className="text-3xl text-white font-semibold">{email.charAt(0).toUpperCase()}</p>
              </div>
              <div className="h-full">
                <p className="font-bold text-gunmetal">{fullName}</p>
                <p className="text-gray-400 text-sm">{email}</p>
              </div>
            </div>
            <div className="w-full mt-4 h-px bg-gray-200" />
            <div className="flex flex-col space-y-2 pt-2">
              <FlatButton
                className="flex flex-row p-3 space-x-3 rounded-lg cursor-pointer items-center hover:bg-gray-100"
                onClick={() => handleSettingsClick()}
              >
                <i className="fas fa-cog text-gray-400 text-md sm:text-lg"></i>
                <p className="text-sm sm:text-base font-semibold text-gunmetal-200">Settings</p>
              </FlatButton>
            </div>
            <div className="flex flex-col space-y-2 pt-2">
              <FlatButton
                className="flex flex-row p-3 space-x-3 rounded-lg cursor-pointer items-center hover:bg-gray-100"
                onClick={() => history.push('/logout')}
              >
                <i className="fas fa-sign-out-alt text-gray-400  text-md sm:text-lg"></i>
                <p className="text-sm sm:text-base font-semibold text-gunmetal-200">Logout</p>
              </FlatButton>
            </div>
          </div>
        </BlankCard>
      </div>}
    </>
  );
}


export default NavUser