import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMousePosition } from '../../commons/hooks';
import { CountryType as Country, countries } from '../../commons/countries';

interface PhoneInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  countryID?: string
  value?: string
  onCountryChange?: (countryID: string) => void
  onNumberValidityChange?: (state: PhoneNumFieldState) => void
}

interface PasswordInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  withInitialPlaceholder?: boolean
  onShowChange?: (show: boolean) => void
  show?: boolean
}

export enum PhoneNumFieldState {
  EMPTY,
  ERROR,
  OK,
}

export const SimpleInput: React.FC<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>> = (props) => {
  return (
    <input {...props} className={`h-12 w-full outline-none border-2 border-gray-200 focus:border-cherry-red-200 disabled:border-gray-400 bg-gray-50 focus:bg-cherry-red-50 disabled:bg-gray-200 rounded-lg p-4 text-gunmetal block disabled:cursor-not-allowed ${props.className}`} />
  )
}

export const PasswordInput: React.FC<PasswordInputProps> = ({ show, value, withInitialPlaceholder, onShowChange, ...props }) => {

  const [localShow, setLocalShow] = useState(false);
  const [localValue, setLocalValue] = useState<string | number | readonly string[]>('');
  const [firstClick, setFirstClick] = useState<boolean | null>(null);

  function handleContainerClick() {
    if (firstClick) {
      setLocalValue('');
      setFirstClick(false);
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    //If there isn't a value coming from the father, use (and update)
    //the local value
    if (!value) {
      setLocalValue(e.currentTarget.value)
    }
    //Notify parent if it has set the onChange listener
    if (props.onChange) {
      props.onChange(e);
    }
  }

  function handleSetShow() {
    const newShowState: boolean = !localShow
    //Update show state
    setLocalShow(newShowState);
    //If present show callback
    if (onShowChange) {
      onShowChange(newShowState);
    }
  }

  useEffect(() => {
    if (value !== undefined && value !== localValue && firstClick !== true) {
      setLocalValue(value)
    }
  }, [value, localValue, firstClick])

  useEffect(() => {
    if (show !== undefined && show !== localShow) {
      setLocalShow(show)
    }
  }, [show, localShow])

  useEffect(() => {
    if (withInitialPlaceholder && (value === undefined || value === '') && firstClick === null) {
      setFirstClick(true);
      setLocalValue('*******');
    }
  }, [withInitialPlaceholder, value, firstClick])

  return (
    <div onClick={handleContainerClick} className="relative w-full">
      <SimpleInput {...props} value={localValue} type={localShow ? "text" : "password"} onChange={handleChange} className={`${props.className} pr-12 block`} />
      <i onClick={handleSetShow} className={`fa ${localShow ? 'fa-eye' : 'fa-eye-slash'} text-gray-400 absolute right-4 top-4 cursor-pointer hover:text-gray-500`}></i>
    </div>
  );
}

export const PhoneInput: React.FC<PhoneInputProps> = ({ value, countryID, onCountryChange, onNumberValidityChange, ...props }) => {

  const inputBoxRef = useRef<HTMLDivElement>(undefined!);
  const inputRef = useRef<HTMLInputElement>(undefined!);
  const dropDownRef = useRef<HTMLDivElement>(undefined!);
  const [show, setShow] = useState(false);
  const [countryScrolled, setCountryScrolled] = useState(false);
  const [country, setCountry] = useState<Country | undefined>(undefined); //FIXME: change default
  const [number, setNumber] = useState('');
  const [fieldState, setFieldState] = useState<PhoneNumFieldState>(PhoneNumFieldState.EMPTY);
  const [manualCountryChange, setManualCountryChange] = useState(false);
  const [tooltipWidth, setTooltipWidth] = useState(0);
  const [tooltipHeight, setTooltipHeight] = useState(0);
  const [tooltipArrowWidth, setTooltipArrowWidth] = useState(0);
  const [tooltipArrowHeight, setTooltipArrowHeight] = useState(0);
  const [errorHover, setErrorHover] = useState(false);

  const mousePosition = useMousePosition();

  //Ref callback to retrieve tooltip height and width
  const tooltipRefCallback = useCallback((node: HTMLParagraphElement | null) => {
    if (node) {
      setTooltipWidth(node.clientWidth);
      setTooltipHeight(node.clientHeight);
    }
  }, [])


  //Ref callback to retrieve tooltip's arrow height and width
  const arrowRefCallback = useCallback((node: SVGSVGElement | null) => {
    if (node) {
      setTooltipArrowWidth(node.clientWidth);
      setTooltipArrowHeight(node.clientHeight);
    }
  }, [])

  //Ref callback to handle the scrolling process to current country
  const countryItemRefCallback = useCallback((node: HTMLDivElement | null) => {

    function handleRefRendered(currentRefObject: HTMLDivElement | null) {
      //If the object match the "active" country,
      //scroll the dropdown to its position to show it
      if (show === true && countryScrolled === false && currentRefObject && currentRefObject.id === country?.id) {
        currentRefObject.scrollIntoView();
        setCountryScrolled(true);
      }
    }

    //This function is triggered when country list is rendered and the current country is focused
    handleRefRendered(node);

  }, [show, countryScrolled, country])

  //This function is triggered when the country box is clicked
  function handleCountryBoxClick() {
    //Hide or show the selection dropdown according to current state
    setShow(!show);
  }

  //This function is triggered when the input is focused
  function handleInputFocus() {
    //Hide country selection dropdown
    if (show) setShow(false);
  }

  //This function is triggered when a country is selected from the dropdown
  function handleCountrySelected(newCountryID: string) {
    //Update country only if it's necessary
    if (newCountryID !== country?.id) {
      setManualCountryChange(true);
      //NOTIFY PARENT about country change
      if (onCountryChange) {
        onCountryChange(newCountryID);
      }

      //Update country if country value is not forwarded by parent
      if (countryID === undefined) {
        setCountry(countries.get(newCountryID))
      }

    }
    //Hide dropdown (even if country is the same)
    setShow(false);
  }

  //This function is triggered when the user hover the error tooltip
  function errorHovered() {
    setErrorHover(true);
  }

  //This function is triggered when the user click on the error tooltip
  //Active for mobile devices (that doesn't behave well with hover)
  function errorClicked() {
    setErrorHover(!errorHover);
  }

  //This function is triggered when the user stops hovering the error tooltip
  function errorHoverOut() {
    setErrorHover(false);
  }

  //This function is triggered every time the content of the input changes
  //It is also triggered by a useEffect hook "synthetically" when the country is changed
  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    //Remove eventual \t to get the actual phone number
    let phoneNumber = e.currentTarget.value.replace('\t', '');

    //If parents define value, the useEffect hook will care about updating local number
    if (value === undefined) {
      const isANumber = /^\d*$/.test(phoneNumber ?? '') //Check tempNumber is a number
      //Indent number
      if (isANumber) {
        if (country?.patterns && country.patterns.length > 0 && phoneNumber !== undefined) {
          //Retrieve pattern for formatting the number
          const pattern = country.patterns.find(pattern => pattern.regexp.test(phoneNumber ?? ''))
          //Check the number strictly matches a full pattern
          const strictPattern = country.patterns.find(pattern => pattern.regexpStrict.test(phoneNumber ?? ''))
          //Format the number
          if (pattern) phoneNumber = pattern.formatter(phoneNumber);
          //Set field state
          if (phoneNumber.length === 0) setFieldState(PhoneNumFieldState.EMPTY)
          else if (strictPattern === undefined && phoneNumber.length > 0) setFieldState(PhoneNumFieldState.ERROR);
          else setFieldState(PhoneNumFieldState.OK);
        }
      }
    }

    //NOTIFY PARENT about phone number change
    if (props.onChange) {
      //Store local formatted value
      const prova = e.currentTarget.value;
      //Set change event value with complete phone number 
      e.currentTarget.value = `+${country?.prefix}${phoneNumber}`
      //Notify parent with real phone number
      props.onChange(e);
      //Then re-set the number to local formatted value
      e.currentTarget.value = prova;
    }
  }

  //Parse the prefix from the number 
  //returns an error if number isn' affected by the change (=> prefix doesn't match)
  function removeCountryPrefix(number: string, prefix: number): string {
    const noPrefixNumber = number.replace(`+${prefix}`, '');
    if (noPrefixNumber === number) throw new Error("No matching prefix");
    return noPrefixNumber;
  }

  //This hook format updates to phone number coming from the parent
  useEffect(() => {
    let tempNumber: string | undefined;
    if (value !== undefined) {
      //Remove prefix according to country
      try {
        if (country) {
          tempNumber = removeCountryPrefix(value, country.prefix)
        }
        const isANumber = /^\d*$/.test(tempNumber ?? '') //Check tempNumber is a number
        //Indent number
        if (country?.patterns && country.patterns.length > 0 && tempNumber !== undefined) {
          //Retrieve pattern for formatting the number
          const pattern = country.patterns.find(pattern => pattern.regexp.test(tempNumber ?? ''))
          //Check the number strictly matches a full pattern
          const strictPattern = country.patterns.find(pattern => pattern.regexpStrict.test(tempNumber ?? ''))
          //Format the number
          if (pattern) tempNumber = pattern.formatter(tempNumber);
          //Set field state
          if (tempNumber.length === 0) setFieldState(PhoneNumFieldState.EMPTY)
          else if (strictPattern === undefined && tempNumber.length > 0) setFieldState(PhoneNumFieldState.ERROR);
          else setFieldState(PhoneNumFieldState.OK);
        }
        //Update the number if it is necessary
        if (tempNumber !== undefined && tempNumber !== number && isANumber) {
          setNumber(tempNumber)
        }
      } catch (e) {
        //Do nothing, parent still has to update the phone number with new prefix
      }
    }
  }, [value, country, number])

  //This hook update local country when an update is forwarded from parent
  useEffect(() => {
    if (countryID && country?.id !== countryID) {
      const tempCountry = countries.get(countryID ?? '');
      if (tempCountry) {
        setCountry(tempCountry);
      }
    }

  }, [countryID, country])



  //This hook triggers input field OnChange every time the country is updated
  useEffect(() => {
    //NOTIFY PARENT that the phone number changed (country prefix)
    //This update is forwarded on manual change (manualCountryChange === true) and
    //when both parent and child are aligned on country (countryID===country?.id) [implies->after local state updated]
    //don't consider country when parent doesn't provide it (countryID===undefined||...)
    if (inputRef && inputRef.current && manualCountryChange && (countryID === undefined || countryID === country?.id)) {
      const currentNumber = inputRef.current.value; //Save current content of the input field
      var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set;
      inputRef.current.value = 'sadjnsajudsa'; //Set the input field to a random value, otherwise onChange isn't triggered
      nativeInputValueSetter?.call(inputRef.current, currentNumber); //re-set the input field to its correct value
      inputRef.current.dispatchEvent(new Event('input', { bubbles: true })) //dispatch the change event
      setManualCountryChange(false);
    }
  }, [manualCountryChange, country, inputRef, countryID])


  //This hook hide the dropdown when there's a click outside it's area
  useEffect(() => {
    function hideDropdownIfClickIsOutside() {
      if (dropDownRef && dropDownRef.current) {
        const dropdownLeft = dropDownRef.current.getBoundingClientRect().left;
        const dropdownTop = dropDownRef.current.getBoundingClientRect().top;
        const dropdownWidth = dropDownRef.current.clientWidth
        const dropdownHeight = dropDownRef.current.clientHeight
        //Case click outside horizontally
        if (mousePosition.x - dropdownLeft < 0 || mousePosition.x - dropdownLeft > dropdownWidth) {
          setShow(false);
        }
        //Or click outside vertically
        else if (mousePosition.y - dropdownTop < 0 || mousePosition.y - dropdownTop > dropdownHeight) {
          setShow(false);
        }
      }
    }

    //Once current country is "focused" (scrolled) add extenal click listener
    if (countryScrolled === true) {
      window.addEventListener('click', hideDropdownIfClickIsOutside)
      return () => window.removeEventListener('click', hideDropdownIfClickIsOutside)
    }
  }, [dropDownRef, mousePosition, countryScrolled])


  //Reset scroll to country flag when the dropdown is set to hidden
  useEffect(() => {
    if (show === false && countryScrolled === true) {
      setCountryScrolled(false);
    }
  }, [show, countryScrolled])

  //Hook to set a default country if user doesn't define one
  useEffect(() => {
    if ((countryID === undefined || countryID === '') && country === undefined) {
      const defaultCountry = 'it' //FIXME: change default country if needed
      setCountry(countries.get(defaultCountry));
      if (onCountryChange) {
        onCountryChange(defaultCountry);
      }
    }
  }, [countryID, country, onCountryChange])

  //Hook to notify parent about invalid number
  useEffect(() => {
    if (onNumberValidityChange) {
      onNumberValidityChange(fieldState);
    }
  }, [fieldState, onNumberValidityChange])

  return (
    <div ref={inputBoxRef} className="relative flex">
      <div onClick={handleCountryBoxClick} className="flex-1 flex justify-center items-center space-x-2 h-12 bg-gray-100 hover:bg-gray-200 border-2 border-gray-300 rounded-l-lg cursor-pointer">
        <i className={`flag ${country?.id} block`}></i>
        <i className="fas fa-caret-down text-gray-400"></i>
      </div>
      {
        show && <div ref={dropDownRef} className="animate-fade-in-rapid overflow-y-scroll h-40 w-full z-20 bg-white rounded-lg absolute border-2 border-gray-200" style={{ top: `${((inputBoxRef?.current?.clientHeight) ?? 0) + 4}px` }}>
          {Array.from(countries.values()).map(country => {
            return (
              <div ref={countryItemRefCallback} id={country.id} onClick={() => handleCountrySelected(country.id)} key={country.id} className="flex cursor-pointer select-none h-10 w-full items-center hover:bg-gray-50 px-4 border-b border-gray-100">
                <i className={`flag ${country.id} block`}></i>
                <p className="text-gunmetal-100 text-sm font-semibold ml-2">{`${country.name}: (+${country.prefix})`}</p>
              </div>
            );
          })}
        </div>
      }
      <div className="w-full relative flex-3">
        <input ref={inputRef} {...props} value={number} onChange={handleInputChange} onFocus={handleInputFocus} className={`h-12 w-full p-4 ${fieldState === PhoneNumFieldState.ERROR ? 'pr-12' : ''} outline-none border-2 border-gray-200 focus:border-cherry-red-200 disabled:border-gray-400 bg-gray-50 focus:bg-cherry-red-50 disabled:bg-gray-200 rounded-lg text-gunmetal block disabled:cursor-not-allowed rounded-l-none border-l-0`} />
        {
          fieldState === PhoneNumFieldState.ERROR && <div className="absolute top-4 right-4">
            <div className="relative">
              <i
                onMouseOver={() => errorHovered()}
                onMouseOut={() => errorHoverOut()}
                onClick={() => errorClicked()}
                className="absolute top-0 right-0 transform translate-y-px fas fa-exclamation-circle text-xs text-red-600 cursor-pointer">
              </i>
              <div
                className={`absolute top-0 right-0 ${errorHover ? 'opacity-100' : 'opacity-0'} transition-opacity duration-200 select-none`}
                style={{ transform: `translate(${tooltipWidth / 2}px,-${tooltipHeight + tooltipArrowHeight}px)` }}
              >
                <p ref={tooltipRefCallback} className="text-xs text-white font-semibold px-2 py-1 bg-gunmetal-400 rounded whitespace-nowrap">Please insert a valid number</p>
                <svg ref={arrowRefCallback}
                  className="relative text-gunmetal-700 opacity-90 h-1.5" x="0px" y="0px" viewBox="0 0 255 127"
                  style={{ left: `${((tooltipWidth / 2) - (tooltipArrowWidth))}px` }}
                >
                  <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
                </svg>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}