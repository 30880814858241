import React, { useState, useEffect } from 'react';
import AutosizeInput from 'react-input-autosize';


const EditableParam: React.FC<{ value: string, onChange: (value: string) => void, textBefore?: string, textAfter?: string }> = ({ value, onChange, ...props }) => {

  const [update, setUpdate] = useState(false);
  let inputRef: HTMLInputElement | null = null;


  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setUpdate(false)
    }
  }

  const handleClosePress = () => {
    setUpdate(false);
  }

  const handlePencilPress = () => {
    setUpdate(true);
  }

  useEffect(() => {
    if (update && inputRef) {
      inputRef.select()
    }
  }, [update, inputRef])

  return (
    <div className="inline-flex flex-row items-center">
      {update
        ? (
          <div className="relative flex flex-row bg-white items-center rounded mx-1 overflow-hidden px-2 space-x-2">
            <AutosizeInput inputRef={node => inputRef = node} onKeyDown={e => handleKeyDown(e)} className="autosize text-red-crayola" value={value} onChange={e => onChange(e.currentTarget.value)} />
            <i onClick={handleClosePress} className="fas fa-times text-code text-gunmetal hover:text-gunmetal-200 cursor-pointer"></i>
          </div>
        )
        : (<><p className="text-red-crayola font-semibold px-0.5">{props.textBefore}{value}{props.textAfter}</p><i onClick={handlePencilPress} className="fas fa-pencil-alt text-code text-red-crayola cursor-pointer ml-1"></i></>)}
    </div>
  );
}

export default EditableParam