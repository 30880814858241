import React, { useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { RootState } from '../../store';
import { AuthenticationStateType } from '../../store/auth/reducers';
import { login, refreshAuthError } from '../../store/auth/actions';

import CustomSetup from './custom_setup';


interface SetupInformation {
  throughput: number
  diskSpace: number
  avgRecordSize: number
  replication: number
}

interface SetupContext {
  setupInformation: SetupInformation
  setSetupInformation: (info: SetupInformation)=>void;
}

interface ReduxDispatchProps {
  signin: (email: string, password: string) => void
  resetAuthError: () => void;
}

interface ReduxStateProps {
  authState: AuthenticationStateType | null
}

type SignInProps = ReduxStateProps & ReduxDispatchProps;

export const CustomFormContext = React.createContext<SetupContext>(undefined!);

const StartPlanPage: React.FC<SignInProps> = (props) => {

  const [setupInformation, setSetupInformation]= useState<SetupInformation>(undefined!);

  // const handleSubmit = () => {
  //   //TODO
  // }

  return (
    <div className="pt-12 w-full h-screen flex flex-col justify-center items-center">
      {/* USE SIMPLE ALERT COMPONENT TO SHOW ERROR */}
      <div className="relative overflow-hidden p-4">
        <div className="relative transition-all">
          <CustomFormContext.Provider value={{setupInformation,setSetupInformation}}>
            <CustomSetup/>
          </CustomFormContext.Provider>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return ({
    authState: state.user
  })
}

const mapDispatchToProps = (dispatch: any): ReduxDispatchProps => {
  return ({
    signin: (email, password) => dispatch(login(email, password)),
    resetAuthError: () => dispatch(refreshAuthError())
  })
}

export default connect<ReduxStateProps, ReduxDispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(StartPlanPage);



// const [email, setEmail] = useState('');
// const [password, setPassword] = useState('');

// const handleFormChange = (field: Fields,event: React.FormEvent<HTMLInputElement>) => {
//   if (event.currentTarget.name === emailFieldName) {
//     setEmail(event.currentTarget.value);
//   } else if (event.currentTarget.name === passwordFieldName) {
//     setPassword(event.currentTarget.value);
//   }
// }

// useEffect(() => {
//   return () => {
//     if (timer) clearTimeout(timer)
//   }
// }, [timer])

// useEffect(() => {
//   if (props.authState?.logged) {
//     history.push('/console');
//   }
//   if (props.authState?.error) {
//     setShowError(props.authState.error);
//     setTimer(setTimeout(() => setShowError(null), 2000));
//   }
// }, [props.authState, history])