import { useEffect, useState } from "react";

const useOnScreen: (ref:Element|null, options?: IntersectionObserverInit)=>boolean|null = (ref,options)=>{
    const [isOnScreen, setIsOnScreen] = useState<boolean|null>(null);

    useEffect(()=>{
        if(ref){
            const observer = new IntersectionObserver(([entry])=>{
                setIsOnScreen(entry.isIntersecting)
            },options)

            observer.observe(ref)

            return ()=>{
                observer.unobserve(ref)
            }
        }
    },[ref,options])

    return isOnScreen;
}

export default useOnScreen;