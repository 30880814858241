import React, { useState, useEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import useCherryserviceAPI from '../../api/cherryservice/v2';
import { useDebugLogger, useMounted } from '../../commons/hooks';

import { BlankCard } from '../../components/cards';
import { InteractiveModal, MessageModal } from '../../components/modals';
import { Spinner } from '../../components/spinner';

//import CherryService from '../api/cherryservice';

interface CherryTable {
  id: number,
  name: string,
  type: string,
  max_data_size: number,
  throughput: number,
  installation: string,
  provider: string,
  duration: number,
}

// const InfoIcon: React.FC<{}> = () => {
//   const [hover, setHover] = useState(false);

//   return (
//     <div className="ml-1 relative h-4 flex flex-col justify-start">
//       <i onMouseLeave={() => setHover(false)} onMouseOver={() => setHover(true)} className={`far fa-question-circle text-xs ${hover ? 'text-cherry-red-300' : 'text-gunmetal-200'}`}></i>
//       <p className={`${!hover && 'hidden'} absolute text-xs text-white font-semibold w-24 px-1 py-1 -top-7 rounded-md bg-cherry-red-400`}>Record size 1Kb</p>
//       <svg className={`${!hover && 'hidden'} absolute text-cherry-red-400 opacity-90 h-4 left-0.5 -top-2`} width="12px" viewBox="0 0 255 255"><polygon className="fill-current" points="0,0 127.5,127.5 255,0" /></svg>
//     </div>
//   );
// }

const installationsMap = new Map<string, string>([["S", "Single server"]]);

const providerMap = new Map<string, string>([["H", "Hetzner"]]);

const CherryTableCard: React.FC<{ cherryTable: CherryTable; onClickCallback: (prodID: number) => void }> = ({ cherryTable, ...props }) => {
  return (
    <div className="col-span-2">
      <BlankCard>
        <div className="h-80 flex flex-col items-center justify-between">
          <div className="w-full flex flex-col items-center">
            <h1 className="text-gunmetal-400 font-bold text-2xl font-header">{cherryTable.name}</h1>
            <div className="flex flex-row justify-between w-full mt-6">
              <div className="flex flex-row">
                <svg className="w-8" fill="#74797E" height="26" viewBox="0 0 488.6 488.6" ><path d="M188.5,270.3c-24.4,28.1-23.2,71.7,2.6,98.6c14.4,15.1,33.7,22.6,52.9,22.6c18.8,0,37.5-7.2,51.8-21.5	c6.5-6.5,11.6-14,15.1-21.9l0,0l94.5-183.2c2.5-5.2-2.9-10.6-8.1-8.1l-183.2,94.5l0,0C204.6,255.5,195.9,261.9,188.5,270.3zM221.9,296.1c6.1-6.1,14.1-9.2,22.1-9.2s16,3.1,22.2,9.2c12.2,12.2,12.2,32.1,0,44.3c-6.1,6.1-14.1,9.2-22.2,9.2c-8,0-16-3.1-22.1-9.2C209.6,328.1,209.6,308.3,221.9,296.1z M440.2,341.4c0-34.6-9.1-68.6-26.4-98.3c-6.7-11.6-2.8-26.4,8.8-33.1c11.6-6.7,26.4-2.8,33.1,8.8c21.5,37.1,32.9,79.5,32.9,122.6c0,13.4-10.8,24.2-24.2,24.2C451.1,365.6,440.2,354.8,440.2,341.4zM0,341.4C0,206.7,109.6,97.1,244.3,97.1c31.3,0,61.8,5.8,90.6,17.4c12.4,5,18.4,19,13.5,31.4c-5,12.4-19,18.4-31.4,13.5c-23.1-9.2-47.6-13.9-72.7-13.9c-108,0-195.9,87.9-195.9,195.9c0,13.4-10.8,24.2-24.2,24.2C10.8,365.6,0,354.8,0,341.4z" /></svg>
                <p className="ml-2 text-gunmetal-200 font-header font-semibold">Throughput</p>
              </div>
              <p className="ml-2 text-gunmetal-200 font-header font-semibold">{`${cherryTable.throughput} op/s`}</p>
            </div>
            <div className="flex flex-row justify-between w-full mt-6">
              <div className="flex flex-row">
                <svg className="w-8" fill="#74797E" xmlns="http://www.w3.org/2000/svg" width="14pt" height="14pt" viewBox="0 0 95.103 95.103"><g><g id="Layer_1_14_"><g><g><g><path d="M47.561,0C25.928,0,8.39,6.393,8.39,14.283v11.72c0,7.891,17.538,14.282,39.171,14.282c21.632,0,39.17-6.392,39.17-14.282v-11.72C86.731,6.393,69.193,0,47.561,0z" /></g></g><g><g><path d="M47.561,47.115c-20.654,0-37.682-5.832-39.171-13.227c-0.071,0.353,0,19.355,0,19.355c0,7.892,17.538,14.283,39.171,14.283c21.632,0,39.17-6.393,39.17-14.283c0,0,0.044-19.003-0.026-19.355C85.214,41.284,68.214,47.115,47.561,47.115z" /></g></g><path d="M86.694,61.464c-1.488,7.391-18.479,13.226-39.133,13.226S9.875,68.854,8.386,61.464L8.39,80.82c0,7.891,17.538,14.282,39.171,14.282c21.632,0,39.17-6.393,39.17-14.282L86.694,61.464z" /></g></g></g></svg>
                <p className="ml-2 text-gunmetal-200 font-header font-semibold">Storage space</p>
              </div>
              <p className="ml-2 text-gunmetal-200 font-header font-semibold">{`${cherryTable.max_data_size}GB`}</p>
            </div>
            <div className="flex flex-row justify-between w-full mt-6">
              <div className="flex flex-row">
                <div className="w-8 flex flex-row justify-center"><i className="fas fa-compact-disc text-xl text-gunmetal-200"></i></div>
                <p className="ml-2 text-gunmetal-200 font-header font-semibold">Installation</p>
              </div>
              <p className="ml-2 text-gunmetal-200 font-header font-semibold">{`${cherryTable.installation}`}</p>
            </div>
            <div className="flex flex-row justify-between w-full mt-6">
              <div className="flex flex-row">
                <div className="w-8 flex flex-row justify-center"><i className="relative -top-0.5 fas fa-calendar-alt text-lg text-gunmetal-200"></i></div>
                <p className="ml-2 text-gunmetal-200 font-header font-semibold">Duration</p>
              </div>
              <p className="ml-2 text-gunmetal-200 font-header font-semibold">{`${cherryTable.duration} days`}</p>
            </div>
            <div className="flex flex-row justify-between w-full mt-6">
              <div className="flex flex-row">
                <div className="w-8 flex flex-row justify-center"><i className="relative -top-0.5 fas fa-server text-lg text-gunmetal-200"></i></div>
                <p className="ml-2 text-gunmetal-200 font-header font-semibold">Provider</p>
              </div>
              <p className="ml-2 text-gunmetal-200 font-header font-semibold">{`${cherryTable.provider}`}</p>
            </div>
          </div>
          <button onClick={() => props.onClickCallback(cherryTable.id)} className="py-1 px-2.5 bg-cherry-red-400 hover:bg-cherry-red-300 focus:outline-none text-white rounded-lg font-semibold">Activate</button>
        </div>
      </BlankCard>
    </div>
  );
}

// const CherryMonitorCard: React.FC<{ name: string, id: number }> = ({ name, id }) => {
//   return (
//     <div className="col-span-2">
//       <BlankCard>
//         <div className="h-48 flex flex-col items-center justify-between">
//           <div className="w-full flex flex-col items-center">
//             <h1 className="text-gunmetal-400 font-bold text-2xl font-header">{name}</h1>
//           </div>
//           <button className="py-1 px-2.5 bg-cherry-red-400 hover:bg-cherry-red-300 focus:outline-none text-white rounded-lg font-semibold">Activate</button>
//         </div>
//       </BlankCard>
//     </div>
//   );
// }

const NewSubscriptionPage: React.FC<{}> = (props) => {
  const history = useHistory();
  const location = useLocation<{ projectID: number }>();
  const mounted = useMounted();
  const cherryserviceAPI = useCherryserviceAPI();
  const logger = useDebugLogger();

  const [subscription, setSubscription] = useState<{ projectID: number, productID: number } | null>(null);
  const [redirect, setRedirect] = useState<string | null>(null);
  const [cherryTable, setCherryTable] = useState<CherryTable[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  function handleCherryTableClick(productID: number) {
    const projectID = location.state.projectID;
    setSubscription({
      productID: productID,
      projectID: projectID,
    })
  }

  //TODO: Retrieve products

  function handleCreateClick(subscriptionName: string) {
    if (subscription !== null) {
      cherryserviceAPI?.newSubscription(subscription.projectID, subscription.productID, subscriptionName)
        .then(res => {
          setRedirect(`/console/projects/project/${subscription.projectID}/subscription/${res.data.id}`)
        }).catch(err => {
          if (err.response) {
            //hide new project modal
            //FIXME: change setSubscription to modalIsActive when refactoring this page
            if (mounted) setSubscription(null)
            //show error message
            if (err.response.data[0] === "Subscription limit reached for user") {
              if (mounted) setErrorMessage("Subscription limit reached for this account")
            }
          }
        });
    }
  }

  function enableSubmit(subscriptionName: string): string | null {
    //TODO: show error when name match another sub name
    if (subscriptionName === '') {
      return '';
    }
    return null
  }

  function handleErrorOKClick() {
    setErrorMessage(null);
  }

  useEffect(() => {
    if (mounted) {
      cherryserviceAPI?.getProducts().then(res => {
        const productsData: CherryTable[] = [];
        res.data.filter(prod => prod.type === "C").forEach(prod => {
          productsData.push({
            id: prod.id,
            name: prod.name,
            type: prod.type,
            max_data_size: prod.cherrytable.max_data_size_1kb_records_byte / 1000000000 ?? 0,
            throughput: prod.cherrytable.max_throughput,
            installation: installationsMap.get(prod.cherrytable.installation_type) ?? '',
            duration: prod.duration,
            provider: providerMap.get(prod.server_type.cloud_provider) ?? ''
          })
        })
          if (mounted) setCherryTable(productsData);
        }).catch(err => err.response && logger.log(err.response));
      }
  }, [mounted, cherryserviceAPI, logger]);

  if (redirect) {
    //Redirect instead of history.push since history must not track the
    //current page (will cause problems with the back click)
    return <Redirect to={redirect} />
  }

  if (cherryTable === null) {
    return (
      <div className="w-full h-full pt-8 px-8 flex flex-col items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className={`w-full h-full ${subscription === null && 'overflow-hidden'}`}>
      <InteractiveModal
        windowClassName={`absolute w-full h-full z-40`}
        title="New subscription"
        inputLabel="Name"
        mainButtonLabel="Create"
        active={subscription !== null}
        enableSubmitCallback={enableSubmit}
        backCallback={() => setSubscription(null)}
        submitCallback={(input) => handleCreateClick(input)}
      />
      <MessageModal
        title="Error"
        active={errorMessage !== null}
        mainButtonLabel="OK"
        submitCallback={handleErrorOKClick}
      >
        <p className="pt-2">{errorMessage}</p>
      </MessageModal>

      <div className="p-8">
        <div className="flex flex-row items-center space-x-4">
          <i onClick={() => history.goBack()} className="fas fa-chevron-left text-5xl text-gunmetal-500 cursor-pointer hover:text-gunmetal-300"></i>
          <h1 className="text-6xl font-bold font-header text-gunmetal-500">New subscription</h1>
        </div>
        <div className="mt-8 grid grid-cols-6 gap-8">
          {
            cherryTable.map(product =>
              <CherryTableCard
                key={product.id}
                cherryTable={product}
                onClickCallback={handleCherryTableClick}
              />
            )
          }
        </div>
      </div>
    </div>
  );
}

export default NewSubscriptionPage;
