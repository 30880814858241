import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import routes from '../../routes';
import { LinkData } from '../../commons/types';
import { useTranslation } from 'react-i18next';

enum NavState {
  STATIC,
  FIXED_HIDDEN,
  FIXED_SHOWN,
  RELATIVE_SHOWN,
  FIXED_TOP
}

const LearnLayout: React.FC<{ children?: React.ReactNode }> = ({ children, ...props }) => {

  const location = useLocation();
  const history = useHistory();
  const navbarCSSHeight = "h-28";
  const [navState, setNavState] = useState<NavState>(NavState.STATIC);
  const [fixed, setFixed] = useState(false);
  const [previousY, setPreviousY] = useState(0);
  const navRef = useRef<HTMLElement>(null);

  const getLearnLinks = (): LinkData[] => {
    let links: LinkData[] = [];
    routes.filter(route => route.path.match('learn/'))
      .forEach(route => {
        if (route.secondaryLabel) {
          links.push({
            href: route.layout + route.path,
            title: route.secondaryLabel,
          });
        }
      });
    return links;
  }

  useEffect(() => {
    if (!fixed && (navState === NavState.FIXED_TOP || navState === NavState.FIXED_SHOWN || navState === NavState.FIXED_HIDDEN)) {
      setFixed(true);
    } else if (fixed && !(navState === NavState.FIXED_TOP || navState === NavState.FIXED_SHOWN || navState === NavState.FIXED_HIDDEN)) {
      setFixed(false);
    }
  }, [navState, fixed])

  useEffect(() => {
    function trackWindowY() {
      //Update previous scroll position
      if ((window.scrollY === 0) || Math.abs(window.scrollY - previousY) > 5) {
        if (navRef && navRef.current) {
          const navbarHeight = navRef.current.clientHeight;
          //FIXME: Update me if the height of the navbar changes
          const mainNavLimit = 64;

          if (navRef.current.getBoundingClientRect().top === mainNavLimit) {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }

          if (window.scrollY === 0) {
            setNavState(NavState.STATIC);
          } else if (window.scrollY > previousY) {
            if (window.scrollY > navbarHeight) {
              setNavState(NavState.FIXED_HIDDEN);
            }
          } else if (navState !== NavState.STATIC && window.scrollY < previousY) {
            if (window.scrollY > navbarHeight) {
              setNavState(NavState.FIXED_SHOWN);
            } else if (window.scrollY > mainNavLimit) {
              setNavState(NavState.RELATIVE_SHOWN)
            } else {
              setNavState(NavState.FIXED_TOP);
            }
          }
        }

        //Update previous pos
        setPreviousY(window.scrollY);
      }
    }

    window.addEventListener('scroll', trackWindowY)
    return () => window.removeEventListener('scroll', trackWindowY)
  }, [previousY, navState])

  const { t, i18n } = useTranslation();

  return (
    <>
      <section className="w-full">
        <div className={`${fixed ? 'block' : 'hidden'} ${navbarCSSHeight}`} />
        <nav aria-labelledby="learn-navigation" ref={navRef} className={`z-20 ${fixed ? `fixed ${navState === NavState.FIXED_SHOWN ? '-top-12 transform translate-y-14' : navState === NavState.FIXED_TOP ? 'top-16' : '-top-12'} transition-transform duration-300` : `relative ${navState === NavState.RELATIVE_SHOWN ? 'top-12' : ''}`} ${navbarCSSHeight} w-full bg-cherry-red-400 px-4 shadow-md`}>
          <div className="relative w-full h-full">
            <h2 id="learn-navigation" className="absolute top-1/2 transform -translate-y-full text-2xl text-white font-semibold ml-2">{t("learn.navbar_label")}</h2>
            <div className="relative top-1/2 h-1/2 flex flex-row items-end space-x-2">
              {
                getLearnLinks().map(link => {
                  return (
                    <button key={link.href} onClick={() => history.push(link.href)} className={`${location.pathname.match(link.href) ? 'cursor-default' : 'cursor-pointer'} group select-none focus:outline-none`}>
                      <p className={`text-white ${location.pathname.match(link.href) ? '' : 'group-hover:text-gray-300'} font-semibold px-2 py-3 transition-colors`}>{t(link.title)}</p>
                      <div className={`${location.pathname.match(link.href) ? 'bg-white' : 'group-hover:bg-gray-300'} rounded-t-md mx-2 h-3px transition-colors`}></div>
                    </button>
                  );
                })
              }
            </div>
          </div>
        </nav>
      </section>
      {children}
    </>
  );
}



export default LearnLayout;