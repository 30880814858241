import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import CherryServiceAPI from "./api";


const useCherryserviceAPI = () => {
    const token = useSelector((state: RootState) => state.authToken);
    const [cherryserviceAPI, setCherryServiceAPI] = useState<CherryServiceAPI | undefined>()

    useEffect(() => {
        const api = new CherryServiceAPI(token)
        setCherryServiceAPI(api);
        return () => {
            api._cancelToken.cancel("Component unmounted")
        }
    }, [token])

    return cherryserviceAPI
}

export default useCherryserviceAPI;