import React from 'react';

export enum SemaphoreColor {
  RED = 'bg-red-500',
  YELLOW = 'bg-yellow-400',
  GREEN = 'bg-green-500',
  GRAY = 'bg-gray-400'
}

export const Semaphore: React.FC<{ color: SemaphoreColor, tooltip?: string }> = ({ color, tooltip }) => {

  return (
    <div className={`group relative rounded-full h-4 w-4 m-1 ${color} cursor-pointer`}>
      <div
        className={`absolute hidden group-hover:flex justify-start left-full top-1/2 transform translate-x-3px animate-fade-in-rapid`}
      >
        <svg
          className="relative transform -translate-y-1/2 text-gunmetal-200 max-h-2.5 w-2"
          x="0px"
          y="0px"
          viewBox="0 0 255 295"
        >
          <polygon className="fill-current" points="255,295 0,147.5 255,0" />
        </svg>
        <p
          className="relative transform -translate-y-1/2 py-1 px-2 bg-gunmetal-200 text-sm rounded-md text-white"
        >
          {tooltip}
        </p>
      </div>
    </div>
  );
}