import React from 'react'
import { NavLink, useHistory } from 'react-router-dom';
import { AnimatedButton } from '../../components/buttons'

const TermsAndConditionPage: React.FC<{}> = (props) => {

  const history = useHistory();

  return (
    <>
      <section className="pt-24 pb-24 px-8">
        <h2 className="font-bold text-2xl pb-12"> Terms & Conditions</h2>
        <h3 className="font-semibold text-lg pb-4"> FREE AND PAID SERVICES AGREEMENT</h3>
        <p>
          This Agreement will govern Cherrydata srl's ("Cherrydata's") SaaS platform of AyraDB ("Services").
          By pressing the Accept button below, you ("Reader") or the entity (company, institution, etc.) you  represent
          ("Customer") agree that you have read and understood the terms and conditions of this Agreement and
          agree to act in accordance with the terms and conditions of this agreement.
          By pressing the Accept button below, the Reader represents and warrants that the Reader is authorized to bind
          the Customer to this Agreement.
          <br />
          Please, read these Terms & Conditions carefully before using the Services, and check them periodically for changes.
          Your use of the Services now and following the posting of any revised Terms & Conditions shall be deemed acceptance.
          If you disagree with the Terms & Conditions of the Services at any time, your only remedy is to terminate your use of the Services.
 	      </p>
        <h4 className="pt-6 pb-2 font-semibold">1. SERVICES and SUPPORT</h4>
        <p> 1.1 Subject to this Agreement, Cherrydata will provide the Customer with access to the
        Services through the Internet during the Timeframe (as defined below). The Services can be modified
        at Cherrydata's sole discretion, for any purpose considered appropriate by Cherrydata. Cherrydata will use reasonable
        efforts to make the Customer aware of any such modification ahead of time. <br />
        1.2 This Agreement will start upon the Customer pressing the Accept button below and, if not terminated
        in accordance with this Agreement,
        will continue for fourteen (14) days (the “Free Service Timeframe”). If the Customer completes the application
        to obtain the Services under the Paid Services, this Agreement will continue until either party terminates
        this Agreement as described below (the “Paid Service Timeframe”) (when applicable, the Free Service
        Timeframe or the Paid Service Timeframe may be referred to as the “Timeframe”). <br />
        1.3 With a few exceptions described in this Agreement, Cherrydata will use commercially reasonable efforts to fulfil
        Cherrydata’s Service Level Agreement (SLA) which can be found at
        <NavLink to="/service_level_agreement">https://www.ayradb.com/service_level_agreement</NavLink>.
        Cherrydata reserves the right to suspend the Customer’s access to the Services: (i) for
        maintenance reasons, or (ii) if the Customer is in breach of this Agreement, including, without limitation, failure
        to pay any amounts due to Cherrydata or chargebacks of any amounts due to Cherrydata. <br />
        1.4 The Customer agrees that, during the Free Service Timeframe, the Customer’s access to and
        use of the Services may be limited and the Services are made available WITHOUT WARRANTIES
        (EXPRESS OR IMPLIED) OF ANY KIND, AND MAY BE DISCONTINUED OR MODIFIED AT ANY TIME. CHERRYDATA will HAVE NO LIABILITY
        FOR ANY HARM OR DAMAGE ARISING OUT OF OR IN CONNECTION WITH THE SERVICES DURING THE FREE SERVICE TERM. <br />
        1.5 From time to time, Cherrydata may provide upgrades, enhancements, or fixes for the Services
        without additional charge ("Updates"), and such Updates will become part of the Services
        and subject to this Agreement. Cherrydata will have no obligation under this Agreement or
        otherwise to provide any such Updates. The Customer understands that Cherrydata may cease supporting old versions
        of the Services at any time in its sole discretion; Cherrydata will use commercially
        reasonable efforts to notify the Customer ahead of time of any major modifications to the Services.
        </p>
        <h4 className="pt-6 pb-2 font-semibold">2. RESPONSIBILITIES</h4>
        <p>
          2.1 The Customer will not, and will not permit any third party to: (i) reverse engineer, decompile, disassemble
          or otherwise attempt to discover or obtain the source code, object code or underlying structure, ideas or
          algorithms of the Services or any software, documentation or data related to the Services (“Software”)
          (unless prohibiting reverse engineering is contrary to the
          applicable law); (ii) modify or translate the Services;
          (iii) use the Services for any purpose other
          than its own internal use and benefit, and for the benefit of End Users; (iv) use the
          Services in any infringing, defamatory, harmful, fraudulent, illegal, deceptive, threatening,
          harassing, or obscene way; or (v) use the Services other than in accordance with this Agreement
          and with all applicable laws, regulations and rights, including (but not limited to) privacy.<br />
          2.2 The Customer must use the Services in compliance with the documentation posted by Cherrydata
          and updated by Cherrydata periodically at <NavLink to="/">https://www.ayradb.com</NavLink><br />
          2.3 The Customer will cooperate with Cherrydata in connection with the performance of this Agreement by making
          available personnel and information and taking the actions as
          Cherrydata may reasonably request. The Customer will also make sure that only designated employees
          of the Customer have access to any administrative functions of the Services.<br />
          2.4 Cherrydata has no obligation to monitor the Content (as defined below) provided by the Customer or
          the Customer’s use of the Services, but may do so and may remove any Content from the Services or
          suspend any use of the Services at any time, for any or no reason, in particular if Cherrydata receives any notice that
          the Content and/or the Customer's use of the Services may infringe or violate rights of a third party.<br />
          2.5 The Customer will be responsible for maintaining the security of the Customer account and
          for all uses of the Customer account with or without the Customer’s knowledge or consent.<br />
          2.6 The Customer agrees to and is bound by the Privacy Policy on AyraDB's website published
          at <NavLink to="/privacy_policy">https://www.ayradb.com/privacy_policy</NavLink>. <br />
          2.7 The Customer agrees that the Services operate using other services operated or provided
          by third parties (“Third Party Services”). Cherrydata is not
          responsible the availability or operation of the Services to
          the extent such availability and operation is dependent upon Third Party Services. Cherrydata does not make any
          representations or warranties with respect to Third Party Services. Any interaction between the Customer
          and Third Party Services is solely between the Customer and such
          Third Party Services and is ruled by such Third Party Services’s terms and conditions.<br />
        </p>
        <h4 className="pt-6 pb-2 font-semibold">3. CONFIDENTIALITY</h4>
        <p>
          3.1 To the extent approved by the regulation, the parties understand that they may disclose to each other information
          relating to their technology or business (referred
          to as “Confidential Information” transferred to the "Receiving Party" by the "Disclosing Party").<br />
          3.2 The Receiving Party agrees: (i) not to divulge to any third party any such Confidential Information,
          (ii) to give access to such Confidential Information solely to the employees who need to have access
          Confidential Information for purposes of this Agreement, and (iii) to apply reasonable precautions to
          such Confidential Information. The Disclosing Party agrees that confidentiality will not apply with respect
          to any information that the Receiving Party can document (a) is available to the public without any action by, or
          involvement of, the Receiving Party, or (b) was in its possession prior to
          receipt from the Disclosing Party, or (c) was rightfully disclosed to it without restriction by a third party,
          or (d) was independently developed without use of any Confidential Information of the Disclosing Party. This Agreement
          will not prevent the Receiving Party from disclosing the Confidential Information pursuant to any
          judicial or governmental order, provided that the Receiving Party gives the Disclosing Party reasonable prior
          notice to allow the Disclosing Party to contest such order. The Customer acknowledges and agrees that Cherrydata
          may use Aggregated Anonymous Data as described in Section 4. <br />
          3.3 In the event and to the extent that Customer is a controller of Personal Data
          that is subject to the General Data Protection Regulation (Regulation (EU) 2016/679) (the “GDPR”), the EU
          Data Processing Annex located at <NavLink to="/eu_data_processing_annex">https://www.ayradb.com/eu_data_processing_annex</NavLink>
          (the “EU-DPA”) is included and incorporated into this Agreement.
 	      </p>
        <h4 className="pt-6 pb-2 font-semibold">4. INTELLECTUAL PROPERTY RIGHTS (IPR)</h4>
        <p>
          4.1 Cherrydata will retain all
          intellectual property rights relating to the Services, including Aggregated Anonymous Data
          (defined below), or any suggestions, requests, or other
          information provided by the Customer or any third party relating to the Services.
          his Agreement does not convey to the Customer any rights of ownership or IPR related to the Services. <br />
          4.2 Cherrydata will obtain and process data provided by or on behalf of the Customer (“Content”) to
          perform its obligations under this Agreement. The Customer and its licensors will (and the Customer hereby represents and
          warrants that they do) retain all rights of all Content distributed through the Services.
          The Customer acknowledges and agrees that Cherrydata may use and modify the
          Content for (A) providing the Services, (B) testing, improving and operating the Services, and
          (C) generating Aggregated Anonymous Data (defined below) which Cherrydata may use for Cherrydata’s business
          purposes (including without limitation, for
          purposes of improving, testing, operating, promoting and marketing Cherrydata’s products and services). “Aggregated
          Anonymous Data” means Content in aggregate, anonymized form which cannot be linked specifically to the Customer.
        </p>
        <h4 className="pt-6 pb-2 font-semibold">5. INDEMNIFICATION</h4>
        <p>
          5.1 The Customer will indemnify, defend, and hold harmless Cherrydata, its employees, affiliates, contractors agents or
          other representatives (each, a “Cherrydata Representative”) from and against any damages, losses, liabilities, settlements
          and expenses (including without limitation costs and reasonable attorneys’ fees) payable by any Cherrydata Representative
          to any third party in investigating, preparing, or defending against any litigation, commenced or threatened
          by a third party, or any other claim, demand or proceeding of a third party (collectively, “Claims”), based on,
          resulting from, arising out of or in connection with an alleged violation
          of Section 2 of this Agreement or from Content or Customer’s use of the Services.
        </p>
        <h4 className="pt-6 pb-2 font-semibold">6. PAYMENT</h4>
        <p>
          6.1 If the Customer subscribes to the Paid Services, the Customer will pay Cherrydata the fees
          (the “Fees”) calculated based on the Customer’s usage of the Services and set forth on AyraDB's
          site <NavLink to="/">https://www.ayradb.com</NavLink>. The Customer agrees to make all payments by credit card
          and that payments will be processed by a third-party payment processor. The Customer acknowledges and agrees that
          unpaid Fees are subject to a finance charge of one percent (1.0%)
          per month (or the maximum permitted by law if lower) plus all expenses of collection.<br />
          6.2 Fees under this Agreement are exclusive of all taxes, if any.
          The Customer agrees to pay such taxes (excluding Italian taxes
          based on Cherrydata's net income).
        </p>
        <h4 className="pt-6 pb-2 font-semibold">7. TERMINATION OF THE AGREEMENT</h4>
        <p>
          7.1 During the Free Service Timeframe, either party may terminate, without notice, this Agreement for
          any reason.<br />
          7.2 During the Paid Service Timeframe, the Customer may terminate this Agreement by terminating all Services.
          Cherrydata may terminate this Agreement for any or no reason upon thirty (30) days’ prior notice.
          Cherrydata may also immediately terminate this Agreement or immediately suspend the
          Customer’s access to the Services, if (i) Cherrydata discontinues any of the Services;
          (ii) the Customer is late in payment or in breach of this Agreement; (iii) Cherrydata determines that
          the Customer’s use of the Services may be unlawful; or (iv) the Customer
          has become the subject of any bankruptcy, reorganization, liquidation, dissolution or similar
          proceeding.<br />
          7.3 The terms & conditions under Sections 2, 3, 4, 5, 6, 7.3, 8, 9, 10, 11 and 12 will survive any
          termination or expiration of this Agreement. Upon termination or expiration of this Agreement, Cherrydata will
          terminate the Customer’s access to the Services, with no obligation to continue to store any Content.
        </p>
        <h4 className="pt-6 pb-2 font-semibold">8. WARRANTY </h4>
        <p>
          8.1 The Services are provided without any warranties of any kind. Cherrydata (and its agents, affiliates,
          licensors and suppliers, collectively "Cherrydata Representatives") hereby disclaim all warranties, express or implied.
          The Services are provided on an “as is”
          and “as available” basis. Cherrydata Representatives EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS OR
          IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. Cherrydata Representatives MAKE NO WARRANTY
          THAT THE SERVICES WILL MEET YOUR REQUIREMENTS, OR WILL BE UNINTERRUPTED,
          TIMELY, SECURE, OR ERROR, BUG OR VIRUS FREE; NOR DO they WARRANT OR MAKE ANY REPRESENTATIONS REGARDING
          THE USE OR THE RESULTS OF THE SERVICES IN TERMS OF CORRECTNESS, COMPLETENESS, AVAILABILITY,
          ACCURACY, RELIABILITY OR OTHERWISE.YOUR USE OF THIS SITE AND CONTENT IS AT the Customer's OWN
          DISCRETION AND RISK AND the Customer is SOLELY RESPONSIBLE FOR ANY RESULTING CONSEQUENCES.
        </p>
        <h4 className="pt-6 pb-2 font-semibold">9. LIABILITY</h4>
        <p>
          9.1 In no event will Cherrydata (or any of its agents, affiliates, licensors or suppliers) be liable for any
          indirect, punitive, incidental, special or consequential damages, ot cost of procurement of substitute goods,
          services or technology, arising out of or in any way connected with a) the use of or access to the Services or
          anything provided in connection with this Agreement, b) the delay or inability to use or access the services or
          anything provided in connection with this Agreement, c) unauthorised access to, alteration of, or deletion,
          destruction, loss or failure to store any of the Customer's content or other data, d) or otherwise arising from
          this Agreement, including without limitation, loss of revenue or anticipated profits, lost business,
          lost sales or lost data, whether based in contract, tort (including negligence), strict liability, or otherwise,
          even if Cherrydata has been advised of the possibility of damages. <br />
          9.2 The total liability of Cherrydata, whether based in contract, tort (including negligence or strict liability),
          or otherwise, will not exceed, in the aggregate, the fees paid to Cherrydata hereunder in the six month period ending
          on the date that a claim or demand is first asserted.
        </p>
        <h4 className="pt-6 pb-2 font-semibold">10. JUDICIAL ACTION AGREEMENT</h4>
        <p>
          10.1 Both parties acknowledge and agree that for the purposes of any dispute arising out of or relating to the subject
          matter of this Agreement, Cherrydata’s officers, directors, employees and independent contractors (“Personnel”) are
          third-party beneficiaries of this Agreement, and that upon the Customer’s acceptance of this Agreement, the Personnel
          will have the right (and will be deemed to have accepted the right) to enforce the terms and conditions of this
          Agreement against the Customer as the third-party beneficiary hereof.<br />
          10.2 The parties will use their best efforts to settle
          any dispute, claim, question, or disagreement arising out of or relating to the subject matter of this Agreement
          directly through good-faith negotiations, which will be a precondition to either party initiating any judicial action.
          If such negotiations do not resolve the dispute, it will be finally settled by binding judicial actions in the Court of
          Milano, Italy. Judicial actions will proceed in the Italian language.<br />
          10.3 This Agreement will be governed by the laws of Italy. The Italian law will govern payment of judicial action fees. <br />
          10.4 Waiver of Class or Consolidated Actions. The Customer waives the Customer's right to participate
          in class action or class-wide litigation
            or arbitration or mediation. All claims and disputes within the scope of this judicial action
          agreement must be litigated on an individual basis. <br />
          10.5 This judicial action agreement will survive the termination of Customer’s relationship
          with Cherrydata.
        </p>
        <h4 className="pt-6 pb-2 font-semibold">11. ADDITIONAL SPECIFICATIONS</h4>
        <p>
          This Agreement is not assignable, transferable or sublicensable by the Customer except with
          Cherrydata’s prior written consent. Cherrydata may transfer and assign any of its rights and obligations under this
          Agreement with notice to the Customer. Both parties agree that this Agreement is the complete and exclusive statement
          of the mutual understanding of the parties and supersedes and cancels all previous written and oral agreements,
          communications and other understandings relating to the subject matter of this Agreement, and that all waivers
          and modifications must be in a writing signed by both parties, except as otherwise provided in this Agreement. No agency,
          partnership, joint venture, or employment is created as a result of this Agreement and the Customer does not have
          any authority of any kind to bind Cherrydata in any respect whatsoever.
          All notices under this Agreement will be in writing and will be deemed to have been duly given when received,
          if personally delivered; when receipt is electronically confirmed, if transmitted by facsimile or e-mail;
          and upon receipt, if sent by certified or registered mail (return receipt requested), postage prepaid.
          The Customer agrees to participate in press announcements,
          case studies, trade shows, or other forms reasonably requested by Cherrydata. Cherrydata is permitted to disclose
          that the Customer is one of its customers to any third-party at Cherrydata's sole discretion.
        </p>
        <AnimatedButton
          onClick={() => history.goBack()}
          className="bg-cherry-red-400 py-2 px-4 rounded text-white mt-8 font-semibold"
        >
          Back
        </AnimatedButton>
      </section>
    </>
  );
}

export default TermsAndConditionPage;