import React, { useState } from 'react';

interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {

}

export enum ButtonColors {
  WHITE = 'text-white hover:bg-white disabled:text-gray-200'
}

interface TextButtonProps extends ButtonProps {
  color: ButtonColors
}

export const AnimatedButton: React.FC<ButtonProps> = ({ className, children, ...props }) => {
  return (
    <FlatButton {...props} className={`raised-backgrounded-button ${className}`}>
      {children}
    </FlatButton>
  );
}

export const AnimatedTextButton: React.FC<ButtonProps> = ({ className, children, ...props }) => {
  return (
    <FlatButton {...props} className={`raised-text-button ${className}`}>
      {children}
    </FlatButton>
  );
}

export const FlatButton: React.FC<ButtonProps> = ({ className, children, ...props }) => {
  const [clicked, setClicked] = useState(false);

  const handleMouseDown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setClicked(true);
    if (props.onMouseDown) {
      props.onMouseDown(e);
    }
  }

  const handleMouseUp = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setClicked(false);
    if (props.onMouseUp) {
      props.onMouseUp(e);
    }
  }

  const handleMouseLeave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setClicked(false);
    if (props.onMouseLeave) {
      props.onMouseLeave(e);
    }
  }

  const handleDragEnd = (e: React.DragEvent<HTMLButtonElement>) => {
    setClicked(false);
    if (props.onDragEnd) {
      props.onDragEnd(e)
    }
  }

  return (
    <button {...props} onMouseLeave={handleMouseLeave} onMouseUp={handleMouseUp} onMouseDown={handleMouseDown} onDragEnd={handleDragEnd} className={`cherry-button ${clicked && 'pressed'} ${className}`}>
      {children}
    </button>
  );
}

export const PrimaryButton: React.FC<ButtonProps> = ({ className, children, ...props }) => {
  return (
    <AnimatedButton
      {...props}
      className={`${className} text-sm sm:text-base text-white font-bold bg-cherry-red-500 hover:bg-cherry-red-300 disabled:bg-gray-300 rounded`}
    >
      {children}
    </AnimatedButton>
  );
}

export const SecondaryButtonColored: React.FC<ButtonProps> = ({ className, children, ...props }) => {
  return (
    <AnimatedTextButton
      {...props}
      className={`${className} text-sm sm:text-base text-cherry-red-400 font-bold hover:bg-cherry-red-75 rounded`}
    >
      {children}
    </AnimatedTextButton>
  );
}

export const SecondaryButtonWhite: React.FC<ButtonProps> = ({ className, children, ...props }) => {
  return (
    <AnimatedButton
      {...props}
      className={`${className} text-sm sm:text-base text-gunmetal font-bold bg-white hover:bg-gray-200 rounded`}
    >
      {children}
    </AnimatedButton>
  );
}

export const OutlinedButton: React.FC<ButtonProps> = ({ className, children, ...props }) => {
  return (
    <AnimatedTextButton
      {...props}
      className={`${className} text-sm sm:text-base text-gunmetal-400 font-bold hover:bg-gray-200 border border-gray-300 rounded`}
    >
      {children}
    </AnimatedTextButton>
  );
}

export const PrimaryFlatButton: React.FC<ButtonProps> = ({ className, children, ...props }) => {
  return (
    <FlatButton
      {...props}
      className={`${className} text-sm sm:text-base text-white font-bold bg-cherry-red-500 hover:bg-cherry-red-300 rounded disabled:bg-gray-300`}
    >
      {children}
    </FlatButton>
  );
}

export const TextButton: React.FC<TextButtonProps> = ({ color, className, children, ...props }) => {
  return (
    <FlatButton
      {...props}
      className={`${className} ${color} font-bold rounded hover:bg-opacity-30 disabled:bg-transparent`}
    >
      {children}
    </FlatButton>
  );
}