import React from 'react';

import {
  SmallCreditCard, 
  SmallMetricCard, 
  GridCard,
  GridCardColumn
} from '../../components/cards';

const BillingPage: React.FC<{}> = (props) => {
  return (
    <div className="w-full px-8">
      <div className="h-48 pt-8  grid grid-cols-3 gap-8 ">
        <SmallMetricCard
          title="Expenses"
          value="$0"
          icon={<svg width="40" height="40" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"><g><g><polygon points="320,0 320,128 448,128" fill="#ffffff"/></g></g> <g><g>
           <path d="M320,160c-17.632,0-32-14.368-32-32V0H96C78.368,0,64,14.368,64,32v448c0,17.664,14.368,32,32,32h320
             c17.664,0,32-14.336,32-32V160H320z M176,97.984V80c0-8.832,7.168-16,16-16c8.832,0,16,7.168,16,16v18.016
             c10.016,2.272,19.36,6.496,27.072,12.768c6.88,5.568,7.904,15.648,2.304,22.496c-5.6,6.88-15.68,7.872-22.496,2.304
             C208.832,130.688,200.704,128,192,128c-17.344,0-32,10.976-32,24s14.656,24,32,24c35.296,0,64,25.12,64,56
             c0,26.016-20.48,47.744-48,54.016V304c0,8.832-7.168,16-16,16c-8.832,0-16-7.168-16-16v-18.016
             c-10.016-2.272-19.36-6.496-27.072-12.768c-6.88-5.6-7.904-15.68-2.304-22.528c5.6-6.912,15.648-7.872,22.496-2.304
             C175.168,253.312,183.296,256,192,256c17.344,0,32-10.976,32-24s-14.656-24-32-24c-35.296,0-64-25.12-64-56
             C128,125.984,148.48,104.256,176,97.984z M368,448H144c-8.832,0-16-7.168-16-16c0-8.832,7.168-16,16-16h224
             c8.832,0,16,7.168,16,16C384,440.832,376.832,448,368,448z M368,384H144c-8.832,0-16-7.168-16-16c0-8.832,7.168-16,16-16h224
             c8.832,0,16,7.168,16,16C384,376.832,376.832,384,368,384z M368,320h-64c-8.832,0-16-7.168-16-16c0-8.832,7.168-16,16-16h64
             c8.832,0,16,7.168,16,16C384,312.832,376.832,320,368,320z M368,256h-64c-8.832,0-16-7.168-16-16c0-8.832,7.168-16,16-16h64
             c8.832,0,16,7.136,16,16C384,248.832,376.832,256,368,256z" fill="#ffffff"/></g></g>
          </svg>}
          gradient="from-pink-500 to-yellow-500"
          comment="-"
        />
        <SmallMetricCard
          title="Next bill"
          value="$0"
          icon={<i className="fas fa-credit-card absolute text-white text-4xl"></i>}
          gradient="from-blue-500 to-spring-green"
          comment="Due date: -"
        />
        <SmallCreditCard
          //last4={2131}
          brand="visa"
        />
      </div>
      <div className="w-full mt-8">
        <GridCard
          header="Bills"
          cols={8}
          data={[]}
        >
          <GridCardColumn
            colSpan={4}
            label="Subscription"
            key="Subscription"
            id={true}
          />
          <GridCardColumn
            colSpan={4}
            label="Link"
            key="Link"
          />

        </GridCard>
      </div>
    </div>
  );
}

export default BillingPage;