import React from 'react';
import { NavLink } from 'react-router-dom';

const DocumentCard: React.FC<{ document: string, href: string }> = ({ document, href }) => {
  return (
    <NavLink to={href}>
      <div className="transition select-none duration-500 ease-in-out transform w-full h-full rounded-xl bg-white hover:bg-gray-50 hover:-translate-y-1 hover:scale-105 shadow-md hover:shadow-lg p-4">
        <div className="relative overflow-hidden h-32 w-full border-2 border-gray-300 rounded-lg mt-2">
          <i className="far fa-file-alt text-9xl absolute top-4 left-1/2 text-gray-300"></i>
        </div>
        <h2 className="text-base font-header text-cherry-red font-semibold mt-2 -mb-1">{document}</h2>
      </div>
    </NavLink>
  );
}

export default DocumentCard;