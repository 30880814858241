import {
  UpdateEmailAction,
  UpdatePasswordAction,
  UpdateConfirmPasswordAction,
  UPDATE_EMAIL,
  UPDATE_PASSWORD,
  UPDATE_CONFIRM_PASSWORD,
  ResetFormAction,
  RESET_FORM
} from "./types";


export const updateEmail = (email: string): UpdateEmailAction => {
  return {
    type: UPDATE_EMAIL,
    payload: email
  }
}

export const updatePassword = (password: string): UpdatePasswordAction => {
  return {
    type: UPDATE_PASSWORD,
    payload: password
  }
}

export const updateConfirmPassword = (confirmPassword: string): UpdateConfirmPasswordAction => {
  return {
    type: UPDATE_CONFIRM_PASSWORD,
    payload: confirmPassword
  }
}

export const resetFormState = ():ResetFormAction=> {
  return {
    type: RESET_FORM,
  }
}
