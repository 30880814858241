import { useRef, useState, useEffect } from 'react';

const useTimer = (): { setTimer: (callback: ((...args: any[]) => void), ms: number) => void, clearTimer: () => void } => {
  const [timer, setTimerObject] = useState<NodeJS.Timeout | null>(null);
  const functions = useRef({
    //Function to set a new timer. If a timer is already present it is
    //canceled and substituted (useEffect)
    setTimer: (callback: (...args: any[]) => void, ms: number) => {
      setTimerObject(setTimeout(callback, ms))
    },
    //Function to clear the timer if present:
    //Since I set timer to null, useEffect is called and it clears the timer
    clearTimer: () => {
      setTimerObject(null)
    }
  });

  //shouldComponentUpdate: delete previous timer if a new timer is issued
  //work also as componentWillUnmount
  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [timer])

  return functions.current;
}

export default useTimer;