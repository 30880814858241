import React from 'react';

import { BlankCard } from '../../components/cards';
import { WithTranslation, withTranslation } from 'react-i18next';

class CloudMigrationPage extends React.Component<WithTranslation, {}, { active: number }> {

  render() {
    const { t, i18n } = this.props;
    return (
      <>
        <div className="relative py-8">
          <BlankCard className="tw-docs-card">
            <h1>{t("cloud_migration.title")}</h1>
            <section>
              <h2>{t("cloud_migration.introduction")}</h2>
              <div>
                <p>
                {t("cloud_migration.introduction_des")}
                </p>
              </div>
            </section>
            <section>
              <h2>{t("cloud_migration.setup")}</h2>
              <div>
                <p>
                {t("cloud_migration.setup_des")}
                </p>
              </div>
            </section>
            <section>
              <h2>{t("cloud_migration.transfer")}</h2>
              <div>
                <p>
                {t("cloud_migration.transfer_des")}
                </p>
              </div>
            </section>
          </BlankCard>
        </div>
      </>
    );
  }
}

export default withTranslation()(CloudMigrationPage);