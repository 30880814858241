import {
  FC,
  createContext,
  useState,
  useCallback,
  DetailedHTMLProps,
  HTMLAttributes,
} from 'react';

interface NavBarProps extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  className?: string
  primaryNav?: boolean
  children: React.ReactElement | React.ReactElement[]
  shouldNavButtonBeVisible?: boolean
  onSidebarButtonClick?: () => void
}

interface CollapseContextType {
  isCollapsed: boolean
  setIsCollapsed: (breakPointClass: string, shouldCollapse: boolean) => void
}

export const CollapseContext = createContext<CollapseContextType>(undefined!);

/**
 * Allows to create a Navbar.
 * @param className allows to apply additional classes. By default are applied classes to make the nav a FLEX-BOX.
 * @param children contains the navigation elements. Children are inserted in a FLEX-BOX with items aligned at the centre
 * If they are wrapped in a {@link Collapse}, the navbar tracks a collapse event (when {@link Collapse} 
 * is hidden), to show a button that can be used to open collapsed navigation.
 * @param onSidebarButtonClick is triggered when the button for collapsed navigation is triggered
 * @returns a Navbar 
 */
const Navbar: FC<NavBarProps> = ({ className, primaryNav, ...props }) => {

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [collapsingBreakPoint, setCollapsingBreakPoint] = useState<string | null>(null);

  /**
   * The biggest collapsing breakpoint is the one that controls collapsing. By saving the breakpoint class at the first
   * collapsing request we know who is the "biggest" one
   */
  const setNavbarAsCollapsed = useCallback((breakPointClass: string, shouldCollapse: boolean) => {
    if (collapsingBreakPoint === null || collapsingBreakPoint === breakPointClass) {
      setIsCollapsed(shouldCollapse)
      setCollapsingBreakPoint(shouldCollapse ? breakPointClass : null)
    }
  }, [collapsingBreakPoint])


  function handleMobileNavButtonClick() {
    if (props.onSidebarButtonClick) {
      props.onSidebarButtonClick()
    }
  }

  return (
    <nav
      aria-label={`${props['aria-labelledby'] ? '' : props['aria-label'] ? props['aria-label'] : primaryNav ? "primary-navigation" : ''}`}
      className={`flex flex-row items-center ${className ? className : ''}`}>
      {
        primaryNav &&
        <button
          onClick={handleMobileNavButtonClick}
          className={`select-none mr-2 focus:outline-none rounded-lg hover:bg-gray-100 ${(isCollapsed || props.shouldNavButtonBeVisible) ? '' : 'hidden'}`}
        >
          <i className="text-sm sm:text-lg focus:outline-none text-gray-400 py-1 px-2 sm:px-3 fas fa-bars"></i>
        </button>
      }
      {/* In case a lot of states are added, wrap this value in a useMemo before passing it down */}
      <CollapseContext.Provider value={{ isCollapsed, setIsCollapsed: setNavbarAsCollapsed }}>
        {props.children}
      </CollapseContext.Provider>
    </nav>
  );
}

export default Navbar