import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTimer } from '../../commons/hooks';
import { BlankCard } from './blank_card';

interface ContextProps {
  activeFeature: number,
  setActiveFeature: (feature: number) => void
}

interface Feature {
  title: string
  description: string
  icon: React.ReactElement
}

const FeatureContext = React.createContext<ContextProps>(undefined!);

const FeatureDot: React.FC<{ title: string, index: number }> = ({ title, index }) => {

  const { activeFeature, setActiveFeature } = useContext(FeatureContext);

  const handleClick = () => {
    if (activeFeature !== index) {
      setActiveFeature(index);
    }
  }

  return (
    <button onClick={handleClick} className={`${activeFeature === index ? 'bg-white' : 'bg-gray-300 bg-opacity-20 hover:bg-gray-100 hover:bg-opacity-50'} focus:outline-none h-1.5 w-1.5 rounded-full select-none`}></button>
  );
}

const FeatureButton: React.FC<{ title: string, icon: React.ReactElement, index: number }> = ({ title, icon, index }) => {

  const { activeFeature, setActiveFeature } = useContext(FeatureContext);

  const handleClick = () => {
    if (activeFeature !== index) {
      setActiveFeature(index);
    }
  }

  return (
    <button onClick={handleClick} className={`${activeFeature === index ? 'bg-cherry-red-400 bg-opacity-20' : 'hover:bg-gray-100'} focus:outline-none p-2 w-full flex flex-row items-center rounded-xl`}>
      <div className={`${activeFeature === index ? 'bg-cherry-red-400' : 'bg-gray-400'} h-9 sm:h-10 w-9 sm:w-10 rounded-full flex justify-center items-center transition-colors duration-300`}>{icon}</div>
      <p className={`${activeFeature === index ? 'text-cherry-red-400' : 'text-gray-400'} text-sm sm:text-base font-semibold pl-4 transition-colors duration-300 text-left`}>{title}</p>
    </button>
  );
}

const FeatureDescription: React.FC<{ title: string, description: string }> = ({ title, description }) => {

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(false);
  }, [title])

  useEffect(() => {
    if (!show) {
      setTimeout(() => setShow(true), 100);
    }
  }, [show])

  return (
    <>
      <h1 className={`text-3xl sm:text-4xl font-bold text-white pb-4 transform ${show ? 'translate-y-0 transition-all duration-500' : 'opacity-0 translate-y-8'} ease-out`}>{title}</h1>
      <p className={`text-sm sm:text-base text-gray-300 font-docs-element text-justify transform ${show ? 'translate-y-0 delay-100 transition-all duration-500' : 'opacity-0 translate-y-8'} ease-out`}>{description}</p>
    </>
  );
}

export const FeaturesCard: React.FC<{ features: Feature[] }> = ({ features }) => {

  const [activeFeature, setActiveFeature] = useState(0);
  const previousTickFeature = useRef(-1); //Track feature active at previous timer tick
  const [paused, setPaused] = useState(false);
  const [temp, setTemp] = useState(20);
  const radius = 4;
  const timer = useTimer();

  //Hook that manages timer increase when NOT paused
  useEffect(() => {
    if (!paused && temp < 100) {
      timer.setTimer(() => setTemp(temp + 0.5), 50);
    }
  }, [paused, temp, timer])

  //Hook that clears the timer when pauesed
  useEffect(() => {
    if (paused) {
      timer.clearTimer();
    }
  }, [paused, timer])

  //Hook to manage change of feature when the timer is completed
  useEffect(() => {
    if (temp === 100) {
      timer.setTimer(() => {
        setTemp(0);
        setActiveFeature((activeFeature + 1) % features.length);
      }, 50)
    }
  }, [temp, activeFeature, features, timer])

  //Hook that manages tracking the feature active at previous timer tick
  useEffect(() => {
    return () => {
      //Since this item uses active feature, it always save the "previous value" when active feature changes
      //So it isn't necessary to do checks on active feature before saving its value.
      previousTickFeature.current = activeFeature;
    }
  }, [temp, activeFeature, features])

  //Hook to manages timer update on button click
  useEffect(() => {
    //temp!==0 -> feature was not updated because timer reached 100
    //previousTickFeature.current!==activeFeature -> in the previous tick there was a different active feature
    //These condition implies that the active feature changed
    if (temp !== 0 && previousTickFeature.current !== activeFeature) {
      setTemp(0);
    }
  }, [temp, activeFeature, features])

  return (
    <FeatureContext.Provider value={{ activeFeature, setActiveFeature }}>
      <BlankCard
        className="bg-gunmetal-500 bg-opacity-95"
      >
        <div className="h-full">
          <div className="relative flex flex-col xs:flex-row h-full -mx-4">
            <div className="hidden xs:block w-2/5 2xl:w-1/3 px-4 bg-white rounded-xl -my-5 py-4">
              <div className="space-y-4">
                {features.map((feature, index) => {
                  return (
                    <FeatureButton
                      key={feature.title}
                      title={feature.title}
                      icon={feature.icon}
                      index={index}
                    />
                  );
                })}
              </div>
            </div>
            <div className="w-full min-h-80 xs:min-h-0 xs:w-3/5 2xl:w-2/3 px-8">
              <FeatureDescription
                title={features[activeFeature].title}
                description={features[activeFeature].description}
              />

            </div>
            <div className="xs:hidden h-20 relative flex space-x-2 items-end pb-4 justify-center">

              {features.map((feature, index) => {
                return (
                  <FeatureDot
                    key={feature.title}
                    title={feature.title}
                    index={index}
                  />
                );
              })}

            </div>
            {/* PLAY - PAUSE BUTTON */}
            <div className="absolute bottom-2 right-6 pr-1 select-none">
              <div className="flex flex-row space-x-4 items-center">
                {
                  paused
                    ? <i onClick={() => setPaused(false)} className="fas fa-play text-white hover:text-gray-300 text-base"></i>
                    : <i onClick={() => setPaused(true)} className="fas fa-pause text-white hover:text-gray-300 text-base"></i>
                }
                <svg className="h-6 w-6 text-white" viewBox="0 0 10 10" fill="none">
                  <circle className="opacity-25" cx="5" cy="5" r={radius} stroke="currentColor" strokeWidth="1"></circle>
                  <circle className={`opacity-75 ${(temp !== 100 && temp !== 0) && 'transition-all duration-100'}`} transform="rotate(-90 5 5)" cx="5" cy="5" r={radius} stroke="currentColor" strokeWidth="1" strokeDashoffset="0" strokeDasharray={Math.PI * (radius * 2)} style={{ strokeDashoffset: `${(100 - temp) / 100 * Math.PI * (radius * 2)}px` }}></circle>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </BlankCard>
    </FeatureContext.Provider>
  );
}