import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { InteractiveModal, MessageModal } from '../../components/modals';
import { ProjectCard } from '../../components/cards';
import useCherryserviceAPI from '../../api/cherryservice/v2';
import { Spinner } from '../../components/spinner';
import { useDebugLogger, useMousePosition } from '../../commons/hooks';
import { ProductType } from '../../api/cherryservice/v2/types';

type Project = {
  id: number,
  name: string,
  subscriptions: Subscription[]
  monitor?: Subscription
}

type Subscription = {
  id: number,
}

interface NewProjectCardProps {
  projectsToLimit: number
  projectsNumber: number
  onClickCallback: () => void
}

//Card to add a new project
const NewProjectCard: React.FC<NewProjectCardProps> = ({ projectsToLimit, ...props }) => {

  const [showTooltip, setShowTooltip] = useState(false);
  const [active, setActive] = useState(false);
  const mousePosition = useMousePosition();
  const boxRef = useRef<HTMLDivElement>(undefined!);
  const tooltipRef = useRef<HTMLDivElement>(undefined!);
  const arrowRef = useRef<SVGSVGElement>(undefined!);
  const [boxX, setBoxX] = useState<number>(0);
  const [boxY, setBoxY] = useState<number>(0);
  const [arrowX, setArrowX] = useState<number>(0);
  const [arrowY, setArrowY] = useState<number>(0);

  //Function called when the new project card is clicked
  function handleClick() {
    if (active) {
      props.onClickCallback();
    }
  }

  //Function called when the card is hovered with the mouse
  function handleHover() {
    if (!active) {
      setShowTooltip(true);
    }
  }

  //Function called when the mouse leave the card
  function handleMouseLeave() {
    if (showTooltip) {
      setShowTooltip(false);
    }
  }

  //Hook to check if the card should be active or ot
  useEffect(() => {
    if (projectsToLimit > 0) {
      //Case user can create [projectsToLimit] new projects
      setActive(true);
    } else {
      //Case user has 0 projects available
      setActive(false);
    }
  }, [projectsToLimit])

  //Hook updating the position of the tooltip according to mouse position
  useEffect(() => {
    if (boxRef && boxRef.current && tooltipRef && tooltipRef.current && arrowRef && arrowRef.current) {
      const containerX = boxRef.current.getBoundingClientRect().left;
      const containerY = boxRef.current.getBoundingClientRect().top;
      const halfWidthBox = tooltipRef.current.clientWidth / 2; //Half width of the tooltip box
      const boxHeight = tooltipRef.current.clientHeight; //Full height of the tooltip box
      const halfWidthArrow = arrowRef.current.clientWidth / 2; //Half width of tooltip arrow
      const arrowHeight = arrowRef.current.clientHeight; //Full height of the tooltip arrow
      //Mouse should point in the middle of the tooltip's box ad arrow (X axis)
      //The box can overflow horizontally for 20px both left (Math.max(___,___)) and right (Math.min(___,___))
      const maxLeft = -20;
      const maxRight = boxRef.current.clientWidth - (halfWidthBox * 2) + 20; //Container width + tooltip box width + 20
      setBoxX(Math.min(Math.max(mousePosition.x - containerX - halfWidthBox, maxLeft), maxRight));
      setArrowX(mousePosition.x - containerX - halfWidthArrow);
      //Box position should be over (top) the height of tooltip's arrow + box
      setBoxY(mousePosition.y - containerY - boxHeight - arrowHeight);
      //Arrow should stay on top of mouse position (Y Axis)
      setArrowY(mousePosition.y - containerY - arrowHeight);
    }
  }, [mousePosition, boxRef])

  return (
    <div onClick={handleClick} className={`col-span-1 transition duration-500 ease-in-out z-1 transform ${active ? 'cursor-pointer hover:-translate-y-1 hover:scale-105' : 'cursor-not-allowed'}`}>
      <div ref={boxRef} onMouseOver={handleHover} onMouseLeave={handleMouseLeave}
        className={`whitespace-nowrap w-full ${props.projectsNumber ? 'h-full' : 'h-52'} rounded-xl ${active ? 'bg-white hover:bg-gray-50 hover:shadow-lg' : 'bg-gray-50'} shadow-md`}>
        <div className="flex flex-col justify-center items-center space-y-4 h-full">
          <i className={`fas fa-plus text-4xl ${active ? 'text-gunmetal-300' : 'text-gray-300'}`}></i>
          <p className={`text-xl font-header ${active ? 'text-gunmetal-300' : 'text-gray-300'} font-bold`}>New project</p>
        </div>
        {
          !active && showTooltip &&
          <div className={`${!active && showTooltip ? 'animate-fade-in-rapid' : 'animate-fade-out-rapid'}`}>
            <div ref={tooltipRef} className="absolute bg-gunmetal-400 rounded-md mt-0" style={{ top: `${boxY}px`, left: `${boxX}px` }}>
              <p className="px-2 py-1 text-white text-sm font-semibold">Projects limit reached</p>
            </div>
            <svg ref={arrowRef} className="absolute m-0 text-gunmetal-700 opacity-90 h-3" x="0px" y="0px" viewBox="0 0 255 255" style={{ top: `${arrowY}px`, left: `${arrowX}px` }}><polygon className="fill-current" points="0,0 127.5,127.5 255,0" /></svg>
          </div>
        }
      </div>
    </div>
  );
}

//Page component showing all projects
const ProjectsPage: React.FC<{}> = (props) => {

  const [projectsToLimit, setProjectsToLimit] = useState<number | null>(null);
  const [projects, setProjects] = useState<Project[] | null>(null);
  const [modalIsActive, setModalIsActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const logger = useDebugLogger();
  const cherryserviceAPI = useCherryserviceAPI();

  const history = useHistory();

  function handleCreateClick(projectName: string) {
    cherryserviceAPI?.newProject(projectName)
      .then(res => {
        const projectID = res.data.id;
        history.push(`/console/projects/project/${projectID}`);
      }).catch(err => {
        if (err.response) {
          //hide new project modal
          setModalIsActive(false);
          //show error
          if (err.response.data[0] === "Project limit reached for user") {
            setErrorMessage("Project limit reached for this account")
          }
        }
      });
    //TODO: add error display (name can be set in parallel and throw errors)
  }

  //function to enable the submit button in the modal form
  function enableSubmit(projectName: string): string | null {
    if (projectName === '') {
      return '';
    } else if (projects?.map(project => project.name).includes(projectName)) {
      return 'A project with this name already exists'
    }
    return null
  }

  //function triggered when the 'ok' button is clicked
  //when the error modal appears
  function handleErrorOKClick() {
    //hide error
    setErrorMessage(null);
  }

  useEffect(() => {

    //Function to get the limit of project available for 
    function getLimits() {
      cherryserviceAPI?.getMyLimits()
        .then(res => {
          setProjectsToLimit(res.data.number_of_available_projects)
        }).catch(err => err.response && logger.log(err.response))
    }

    //fuction to retrieve all projects
    async function getProjectsData() {
      if (cherryserviceAPI) {
        //get all projects
        const productTypesMap = new Map<number, string>();
        try {
          const prodRes = await cherryserviceAPI.getProducts()
          prodRes.data.forEach(prod => productTypesMap.set(prod.id, prod.type))
          const projectsRes = await cherryserviceAPI.getAllProjects()
          let projectsData: Project[] = []
          let promises: Promise<void>[] = [];
          //for each project
          projectsRes.data.forEach(project => {
            //map response structure to local structure
            let projectData: Project = {
              id: project.id,
              name: project.name,
              subscriptions: []
            }
            //add project to projects lists
            projectsData.push(projectData);
            //retrieve project's subscription
            promises.push(cherryserviceAPI.getSubscriptionsByProject(project.id).then(res2 => {
              //map response structure to local structure
              res2.data.filter(sub => productTypesMap.get(sub.product) === ProductType.CHERRY_TABLE).forEach(sub => { //FIXME: get products and filters all products of type monitor
                projectData.subscriptions.push({
                  id: sub.id
                })
              })
              //EXTRACT THE MONITOR
              //FIXME: get products and filter first monitor
              const monitor = res2.data.filter(sub => productTypesMap.get(sub.product) === ProductType.MONITOR)[0];
              if (monitor) {
                projectData.monitor = {
                  id: monitor.id,
                }
              }
            }));
          });
          //wait all subscriptions to be retrieved
          Promise.all(promises).then(() => {
            setProjects(projectsData);
          }).catch(err => err.response && logger.log(err.response))
        } catch (err: any) {
          if (err.response) logger.log(err.response)
        }
      }
    }

    getProjectsData();
    getLimits();
  }, [cherryserviceAPI, logger])

  if (projects === null || projectsToLimit === null) {
    return (
      <div className="w-full h-full pt-8 px-8 flex flex-col items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className={`w-full h-full ${modalIsActive && 'overflow-hidden'}`}>
      <InteractiveModal
        windowClassName={`absolute w-full h-full z-40`}
        title="New project"
        inputLabel="Name"
        mainButtonLabel="Create"
        active={modalIsActive}
        enableSubmitCallback={enableSubmit}
        backCallback={() => setModalIsActive(false)}
        submitCallback={(input) => handleCreateClick(input)}
      />
      <MessageModal
        title="Error"
        active={errorMessage !== null}
        mainButtonLabel="OK"
        submitCallback={handleErrorOKClick}
      >
        <p className="pt-2">{errorMessage}</p>
      </MessageModal>
      <div className="p-8">
        <h1 className="text-6xl font-bold font-header text-gunmetal-500">Projects</h1>
        <div className="mt-8 pb-8">
          <div className="relative w-full grid grid-cols-3 gap-8">
            <NewProjectCard
              projectsToLimit={projectsToLimit}
              projectsNumber={projects.length}
              onClickCallback={() => setModalIsActive(true)}
            />
            {projects.map(project => {
              return (
                <div key={project.id} className="h-52 col-span-1 row-span-1">
                  <ProjectCard
                    name={project.name}
                    id={project.id}
                    activeSubs={project.subscriptions.length}
                    dashBoardActive={project.monitor !== undefined}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectsPage;