import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory, useLocation, Redirect } from 'react-router-dom';
import useCherryserviceAPI from '../api/cherryservice/v2';
import { useMounted } from '../commons/hooks';
import { AnimatedButton } from '../components/buttons';
import { FullPageSpinner } from '../components/spinner';
import Register from '../pages/auth_register';

import SignIn from '../pages/auth_signin';

const FeatureDisabledScreen: React.FC<{}> = (props) => {

  const history = useHistory();

  return (
    <div className="p-8">
      <h1 className="text-4xl xs:text-5xl sm:text-6xl font-bold text-cherry-red-400 font-intro">Request your credentials</h1>
      <h2 className="text-base xs:text-lg sm:text-xl">Please, send us your request. Our sales personnel will get in touch shortly.</h2>
      {props.children}
      <AnimatedButton
        onClick={() => history.push('/contacts')}
        className="text-sm sm:text-base font-semibold px-4 py-2 bg-cherry-red-400 hover:border-cherry-red-250 text-white rounded mt-8"
      >
        Contact us
      </AnimatedButton>
    </div>
  );
}

//FIXME: Remove this when register will be enable
const RegisterNotEnabled: React.FC<{ children?: React.ReactElement }> = (props) => {

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token');
  const [validToken, setValidToken] = useState<boolean | null>(null);
  const mounted = useMounted();
  const cherryserviceAPI = useCherryserviceAPI()

  useEffect(() => {
    if (token !== null) {
      cherryserviceAPI?.getToken(token).then(res => {
        if(mounted) setValidToken(true)
      }).catch(err=>{
        if(mounted) setValidToken(false)
      })
    }
  }, [token,mounted,cherryserviceAPI])

  //Case token not provided
  if (token === null) {
    return <FeatureDisabledScreen />
  }

  //Case token provided but still under evaluation
  if (validToken === null) {
    return <FullPageSpinner />
  }

  //Case token valid (create new route and )
  if (validToken) {
    return <Register token={token} />
  }

  //Case token invalid or used
  return <FeatureDisabledScreen />
}

class AuthLayout extends React.Component<{}> {
  render() {
    return (
      <div className="h-screen bg-silver-gray">
        <Switch>
          <Route path="/auth/signin" component={SignIn} />
          {/* <Route path="/auth/signup" component={Register} /> */}
          <Route path="/auth/signup" component={RegisterNotEnabled} /> {/* FIXME: Remove this when register will be enable */}
          <Redirect from="/auth/*" to="/not_found" />
        </Switch>
      </div>
    );
  }
}

export default AuthLayout;