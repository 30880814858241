import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';

import {logout as disconnect} from '../../store/auth/actions';

interface ReduxDispatchProps {
  logout: ()=>void
}

type LogoutPageProps = ReduxDispatchProps;

const LogoutPage: React.FC<LogoutPageProps> = ({logout, ...props}) => {

  useEffect(() => {
    logout();
  }, [logout])

  return (
    <Redirect to="/" />
  );
}

const mapDispatchToProps =(dispatch:any):ReduxDispatchProps =>{
  return({
    logout: ()=>dispatch(disconnect())
  });
}

export default connect<null,ReduxDispatchProps,{}>(null,mapDispatchToProps)(LogoutPage);