import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";

import CustomerService from '../../assets/img/contacts.png';
import useCherryserviceAPI from '../../api/cherryservice/v2';
import { BlankCard } from '../../components/cards';
import { RootState } from '../../store';
import { AuthenticationStateType } from '../../store/auth/reducers';
import { useDebugLogger } from '../../commons/hooks';

import { useTranslation } from "react-i18next";

import { MessageModal } from '../../components/modals';

import ReactPixel from 'react-facebook-pixel';

interface ReduxStateProps {
  authState: AuthenticationStateType | null
}
interface OwnProps {

}

type ContactFormProps = ReduxStateProps & OwnProps;


const ContactForm: React.FC<ContactFormProps> = (props) => {
  const minChars = 10;
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [text, setText] = useState('');
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const cherryserviceAPI = useCherryserviceAPI()
  const logger = useDebugLogger();
  const [submitted, setSubmitted] = useState(false)
  const [ticket_id, setTicketId] = useState('')

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const autofill = searchParams.get("autofill")

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (props.authState?.logged && props.authState.user?.email) {
      setEmail(props.authState.user.email);
    }
    if(!!autofill && autofill == "try") {
      setText(t("contact_us.message_content_autofill_try"))
    }
  }, [props.authState, autofill, t])

  useEffect(() => {
    if (!submitEnabled && text.length >= minChars) {
      setSubmitEnabled(true)
    } else if (submitEnabled && text.length < minChars) {
      setSubmitEnabled(false);
    }
  }, [text, email, submitEnabled])

  const handleFormSubmit = () => {
    if (submitEnabled) {
      ReactPixel.trackCustom('submission_contact_us', undefined);
      cherryserviceAPI?.newTicket(email, name, text, i18n.language)
        .then(res => { setTicketId(res.data['ticket_id']);
                       setSubmitted(true); })
        .catch(err => { logger.log(err.response); 
                        setTicketId('');
                        setSubmitted(true); });
    }
  }

  return (
    <div>
       <MessageModal
          title={t("contact_us.message_sent_title")}
          active={submitted && ticket_id!==""}
          mainButtonLabel={t("contact_us.close")}
          submitCallback={() => setSubmitted(false)}
        >
        <p className="pt-2">{t("contact_us.message_sent_content_1")} {ticket_id}</p>
        <p className="pt-2">{t("contact_us.message_sent_content_2")}</p>
        <p className="pt-2">{t("contact_us.message_sent_content_3")}</p>
        </MessageModal> 
        <MessageModal
          title={t("contact_us.message_not_sent_title")}
          active={submitted && ticket_id===""}
          mainButtonLabel={t("contact_us.close")}
          submitCallback={() => setSubmitted(false)}
        >
        <p className="pt-2">{t("contact_us.message_not_sent_content")}</p>
        </MessageModal> 
      <form>
        <div className="flex flex-row items-center space-x-4 w-full">
          <div className="flex-2">
            <label className="block mb-2 text-sm lg:text-base text-gunmetal-300 font-semibold">{t("contact_us.name")}</label>
            <input value={name} onChange={e => setName(e.target.value)} type="text" className="block w-full p-3 border-gray-300 focus:border-cherry-red-400 border-2 rounded-lg focus:outline-none text-sm lg:text-base" />
          </div>
          <div className="flex-3">
            <label className="block mb-2 text-sm lg:text-base text-gunmetal-300 font-semibold">{t("contact_us.email")}</label>
            <input value={email} onChange={e => setEmail(e.target.value)} type="email" className="block w-full p-3 border-gray-300 focus:border-cherry-red-400 border-2 rounded-lg focus:outline-none text-sm lg:text-base" />
          </div>
        </div>
        <label className="block mt-4 mb-2 text-sm lg:text-base text-gunmetal-300 font-semibold">{t("contact_us.message")}</label>
        <textarea
          onChange={e => setText(e.target.value)}
          value={text}
          rows={6}
          className="block w-full p-3 border-gray-300 focus:border-cherry-red-400 border-2 rounded-lg focus:outline-none text-sm lg:text-base"
        />
      </form>
      <div className="flex flex-row items-center space-x-4 mt-6 lg:mt-8">
        <button id="submit-button-id" disabled={!submitEnabled} onClick={handleFormSubmit} className={`px-6 py-3 text-white font-bold text-sm lg:text-base xl:text-lg ${submitEnabled ? 'bg-cherry-red hover:bg-cherry-red-300 cursor-pointer' : 'bg-gray-400 cursor-not-allowed'} rounded-xl focus:outline-none`}>{t("contact_us.submit")}</button>
        {!submitEnabled && <p className="text-sm text-gray-400">{`${minChars - text.length} ${t("contact_us.chars_to_go")}`}</p>}
      </div>
    </div>
  );
}

function mapStateToProps(state: RootState): ReduxStateProps {
  return ({
    authState: state.user
  });
}

const ConnectedContactForm: React.FC<OwnProps> = connect<ReduxStateProps, {}, OwnProps, RootState>(mapStateToProps)(ContactForm);

const ContactsPage: React.FC<{}> = (props) => {

  

  const [clientHeight, setClientHeight] = useState<number>(0);
  const [loaded, setLoaded] = useState(false);
  const imageRef= useRef<HTMLImageElement|null>(null);

  const updateSectionHeight =()=>{
    if(imageRef.current){
      setClientHeight(imageRef.current.clientHeight);
    }
  };

  useEffect(()=>{
    //set client height for the first time
    if(imageRef.current)setClientHeight(imageRef.current.clientHeight);
    //detect client height changes
    window.addEventListener('resize', updateSectionHeight);
    //on unmount remove listener
    return ()=>window.removeEventListener('resize',updateSectionHeight);
  },[loaded])

  const { t, i18n } = useTranslation();
  
  return (
    <section className="relative px-10 pt-24 w-screen"> 
      <div style={clientHeight!==0?{height:clientHeight}:{height:'100%'}}>
        <img ref={imageRef} onLoad={()=>setLoaded(true)} className="hidden bg-fixed sm:block w-9/12 xl:w-7/12 absolute" src={CustomerService} alt="Background"></img>
        <div className="w-full h-full mx-auto flex flex-row items-center justify-end">
          <div className="relative z-10 w-full sm:w-7/12 md:w-1/2 xl:w-5/12">
            <BlankCard wrapContent>
              <div className="p-4">
                <h1 className="tracking-tighter pb-8 text-5xl xl:text-6xl 3xl:text-7xl font-intro text-cherry-red-500">{t("contact_us.title")}</h1>
                <ConnectedContactForm />
              </div>
            </BlankCard>
          </div>
          {/* <h2 className="text-gunmetal pt-8 font-docs-element sm:text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl"></h2> */}
        </div>

      </div>
    </section>
  );
}

export default ContactsPage;