import { useEffect, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import ScrollIntoView from './commons/scroll_resetter';
import ProductLayout from './layouts/product_layout';
import ConsoleLayout from './layouts/console_layout';
import AuthLayout from './layouts/auth_layout';
import PrivacyPolicyPage from './pages/z_privacy_policy';
import NotFoundPage from './layouts/not_found';
import SettingsLayout from './layouts/settings_layout';
import AdminLayout from './layouts/admin_layout';
import useLoadAuthenticatedUser from './commons/hooks/use_load_user';
import { FullPageSpinner } from './components/spinner';
import { useSizeObserver } from './commons/hooks';

import "./i18n";
import LanguageSelector from './components/language_selector';
import i18n from './i18n';

const App: React.FC = () => {

  const isUserLoaded = useLoadAuthenticatedUser();
  const { y } = useSizeObserver(document.documentElement);

  useEffect(() => {
    function updateHScreen(height: number) {
      let vh = height * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    if (y) {
      updateHScreen(y)
    }
  }, [y])

  if (!isUserLoaded) {
    return (<div className="h-screen"><FullPageSpinner /></div>);
  }

  // aggiungere <LanguageSelector /> sotto Suspense per avere il selettore del linguaggio sopra la navbar

  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <ScrollIntoView>
          <Switch>
            <Route path="/console" component={ConsoleLayout} />
            <Route path="/auth" component={AuthLayout}></Route>
            <Route path="/settings" component={SettingsLayout} />
            <Route path="/privacy_policy" component={PrivacyPolicyPage} />
            <Route path="/admin" component={AdminLayout} />
            <Route path="/not_found" component={NotFoundPage} />
            {/* LEAVE THIS AS THE LAST */}
            <Route path="/" component={ProductLayout} />
          </Switch>
        </ScrollIntoView>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
