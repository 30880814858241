import React from 'react';
import { NavLink } from 'react-router-dom';

type MetricCardProps = {
  title: string,
  value: string | number,
  icon: React.ReactNode;
  comment?: string,
  gradient: string;
}

export const SmallMetricCard: React.FC<MetricCardProps> = (props) => {
  return (
    <div className="relative bg-white shadow-md rounded-xl p-4">
      <div className="h-full flex flex-col justify-between">
        <div>
          <h2 className="text-xl font-header text-gunmetal-200 font-bold">{props.title}</h2>
          <h1 className="text-5xl font-header text-gunmetal-500 font-bold pt-2">{props.value}</h1>
        </div>
        <p className={`${!props.comment && 'invisible'} pt-2 text-gunmetal-100`}>{props.comment ?? 'hidden'}</p>
      </div>
      <div className={` absolute right-4 top-4 w-20 h-20 rounded-full bg-gradient-to-tr ${props.gradient}`}>
        <div className="absolute top-5 left-5">
          {props.icon}
        </div>
      </div>
    </div>
  );
}

export const ElementCard: React.FC<{ icon: React.ReactElement, bgColor: string, iconBgColor: string, children: React.ReactElement, fillHeight: boolean }> = ({ children, ...props }) => {
  return (
    <div className={`relative ${props.bgColor} ${props.fillHeight && 'h-full'} shadow-md rounded-xl p-3 2xs:p-4`}>
      <div className="h-full">
        {children}
      </div>
      <div className={`absolute right-3 2xs:right-4 top-3 2xs:top-4 w-16 h-16 2xs:w-18 2xs:h-18 xl:w-20 xl:h-20 rounded-full ${props.iconBgColor}`}>
        <div className="w-full h-full flex flex-row justify-center items-center">
          {props.icon}
        </div>
      </div>
    </div>
  );
}

export const SmallCreditCard: React.FC<{ last4?: number, brand: string }> = (props) => {
  return (
    <div className="relative bg-gradient-to-tl from-safety-orange-blaze via-red-crayola to-dark-magenta shadow-md rounded-xl p-4">
      <div className="h-full flex flex-col justify-between">
        <div>
          <h2 className="text-xl font-header text-white font-bold">Payment method</h2>
          <h1 className="pt-4 text-2xl font-header text-white font-bold">{`•••• •••• •••• ${props.last4 ?? '••••'}`}</h1>
        </div>
        <svg className="absolute bottom-3 left-3 -mb-2" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 291.791 291.791" width="48">
          <g>
            <path fill="#E2574C" d="M182.298,145.895c0,50.366-40.801,91.176-91.149,91.176S0,196.252,0,145.895
		          s40.811-91.176,91.149-91.176S182.298,95.538,182.298,145.895z"/>
            <path fill="#F4B459" d="M200.616,54.719c-20.442,0-39.261,6.811-54.469,18.181l0.073,0.009
              c2.991,2.89,6.291,4.924,8.835,8.251l-18.965,0.301c-2.972,3-5.68,6.264-8.233,9.656H161.3c2.544,3.054,4.896,5.708,7.03,9.081
              h-46.536c-1.705,2.936-3.282,5.954-4.659,9.09h56.493c1.477,3.127,2.799,5.489,3.921,8.799h-63.76
              c-1.012,3.146-1.878,6.364-2.535,9.646h68.966c0.675,3.155,1.194,6.072,1.55,9.045h-71.884c-0.301,3-0.456,6.045-0.456,9.118
              h72.859c0,3.228-0.228,6.218-0.556,9.118h-71.847c0.31,3.091,0.766,6.127,1.368,9.118h68.856c-0.711,2.954-1.532,5.926-2.562,9.008
              h-63.969c0.966,3.118,2.143,6.145,3.428,9.099h56.621c-1.568,3.319-3.346,5.972-5.306,9.081h-46.691
              c1.842,3.191,3.875,6.236,6.081,9.154l33.589,0.501c-2.863,3.437-6.537,5.507-9.884,8.516c0.182,0.146-5.352-0.018-16.248-0.191
              c16.576,17.105,39.744,27.772,65.446,27.772c50.357,0,91.176-40.82,91.176-91.176S250.981,54.719,200.616,54.719z"
            />
          </g>
        </svg>

      </div>
      <p className="cursor-pointer absolute bottom-4 right-4 font-semibold text-white hover:text-blue-100">UPDATE</p>
    </div>
  );
}

const idIcon = <svg className="-ml-1 -mr-1.5" width="21.5pt" height="18pt" viewBox="0 0 18 18" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M10.557 11.99l-1.71-2.966 1.71-3.015h3.42l1.71 3.01-1.71 2.964h-3.42zM4.023 16l-1.71-2.966 1.71-3.015h3.42l1.71 3.01L7.443 16h-3.42zm0-8.016l-1.71-2.966 1.71-3.015h3.42l1.71 3.015-1.71 2.966h-3.42z" fill="#74797E" ></path></svg>;

const dashboardIcon = <svg height="14pt" viewBox="0 0 512 512" width="14pt" fill="#74797E" xmlns="http://www.w3.org/2000/svg"><path d="m197.332031 0h-160c-20.585937 0-37.332031 16.746094-37.332031 37.332031v96c0 20.589844 16.746094 37.335938 37.332031 37.335938h160c20.589844 0 37.335938-16.746094 37.335938-37.335938v-96c0-20.585937-16.746094-37.332031-37.335938-37.332031zm0 0" /><path d="m197.332031 213.332031h-160c-20.585937 0-37.332031 16.746094-37.332031 37.335938v224c0 20.585937 16.746094 37.332031 37.332031 37.332031h160c20.589844 0 37.335938-16.746094 37.335938-37.332031v-224c0-20.589844-16.746094-37.335938-37.335938-37.335938zm0 0" /><path d="m474.667969 341.332031h-160c-20.589844 0-37.335938 16.746094-37.335938 37.335938v96c0 20.585937 16.746094 37.332031 37.335938 37.332031h160c20.585937 0 37.332031-16.746094 37.332031-37.332031v-96c0-20.589844-16.746094-37.335938-37.332031-37.335938zm0 0" /><path d="m474.667969 0h-160c-20.589844 0-37.335938 16.746094-37.335938 37.332031v224c0 20.589844 16.746094 37.335938 37.335938 37.335938h160c20.585937 0 37.332031-16.746094 37.332031-37.335938v-224c0-20.585937-16.746094-37.332031-37.332031-37.332031zm0 0" /></svg>;

const databaseIcon = <svg fill="#74797E" xmlns="http://www.w3.org/2000/svg" width="14pt" height="14pt" viewBox="0 0 95.103 95.103"><g><g id="Layer_1_14_"><g><g><g><path d="M47.561,0C25.928,0,8.39,6.393,8.39,14.283v11.72c0,7.891,17.538,14.282,39.171,14.282c21.632,0,39.17-6.392,39.17-14.282v-11.72C86.731,6.393,69.193,0,47.561,0z" /></g></g><g><g><path d="M47.561,47.115c-20.654,0-37.682-5.832-39.171-13.227c-0.071,0.353,0,19.355,0,19.355c0,7.892,17.538,14.283,39.171,14.283c21.632,0,39.17-6.393,39.17-14.283c0,0,0.044-19.003-0.026-19.355C85.214,41.284,68.214,47.115,47.561,47.115z" /></g></g><path d="M86.694,61.464c-1.488,7.391-18.479,13.226-39.133,13.226S9.875,68.854,8.386,61.464L8.39,80.82c0,7.891,17.538,14.282,39.171,14.282c21.632,0,39.17-6.393,39.17-14.282L86.694,61.464z" /></g></g></g></svg>

const ProjectCardItem: React.FC<{ label: string, value: string | number, icon: React.ReactElement }> = ({ label, value, icon }) => {
  return (
    <div className="pt-1 flex flex-row items-center justify-between">
      <div className="relative flex flex-row items-center">
        <div className="absolute" style={{ top: '-1px' }}>
          {icon}
        </div>
        <p className="pl-7 text-base text-gunmetal-300 font-bold font-sub-header ">{label}</p>
      </div>
      <p className="text-base text-gunmetal-300 font-bold font-sub-header ">{value}</p>
    </div>
  );
}

export const ProjectCard: React.FC<{ name: string, id: number | string, activeSubs: number, dashBoardActive: boolean }> = ({ name, id, activeSubs: activeNodes, dashBoardActive }) => {
  return (
    <NavLink to={`projects/project/${id}`}>
      <div className="transition duration-500 ease-in-out transform w-full h-full rounded-xl bg-white hover:bg-gray-50 hover:-translate-y-1 hover:scale-105 shadow-md hover:shadow-lg p-4">
        <h2 className="text-xl font-header text-gunmetal-300 font-bold">{name}</h2>
        <div className="space-y-5 py-4">
          <ProjectCardItem
            label="Project ID"
            value={id}
            icon={idIcon}
          />
          <ProjectCardItem
            label="Active subscriptions"
            value={activeNodes}
            icon={databaseIcon}
          />
          <ProjectCardItem
            label="Dashboard"
            value={dashBoardActive ? 'Active' : 'Inactive'}
            icon={dashboardIcon}
          />
        </div>
      </div>
    </NavLink>
  );
}


// const newsIcon = <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" height="22" width="22"
//   viewBox="0 0 395.569 395.569" fill="#74797e">
//   <g>
//     <path d="M365.11,81.124c-2.3-29.794-27.261-53.339-57.635-53.339H57.826C25.941,27.785,0,53.726,0,85.61v224.35
// c0,31.884,25.941,57.825,57.826,57.825h279.918c31.885,0,57.826-25.941,57.826-57.825V132.03
// C395.569,110.043,383.225,90.899,365.11,81.124z M333.99,309.96c0,14.619-11.894,26.514-26.514,26.514H57.826
// c-14.62,0-26.514-11.895-26.514-26.514V85.61c0-14.619,11.894-26.514,26.514-26.514h249.65c14.62,0,26.514,11.895,26.514,26.514
// V309.96z"/>
//     <path d="M62.901,145.138h0.652c4.505,0,8.156-3.651,8.156-8.157v-28.756l24.358,33.548c1.536,2.113,3.988,3.365,6.601,3.365h0.791
// c0.03,0,0.06-0.006,0.088-0.006c0.041,0,0.081,0.006,0.123,0.006h0.652c4.504,0,8.156-3.651,8.156-8.157V83.399
// c0-4.505-3.652-8.157-8.156-8.157h-0.652c-4.506,0-8.157,3.652-8.157,8.157v28.755L71.156,78.606
// c-1.535-2.113-3.989-3.364-6.6-3.364h-0.792c-0.03,0-0.058,0.006-0.088,0.006c-0.042,0-0.082-0.006-0.123-0.006h-0.652
// c-4.505,0-8.156,3.652-8.156,8.157v53.582C54.745,141.487,58.395,145.138,62.901,145.138z"/>
//     <path d="M162.419,128.824h-25.441v-10.971h20.764c4.504,0,8.156-3.651,8.156-8.155v-0.653c0-4.506-3.652-8.157-8.156-8.157h-20.764
// v-8.681h24.349c4.505,0,8.157-3.651,8.157-8.156v-0.652c0-4.505-3.651-8.157-8.157-8.157h-33.158c-4.505,0-8.157,3.652-8.157,8.157
// v53.582c0,4.506,3.652,8.157,8.157,8.157h34.251c4.504,0,8.157-3.651,8.157-8.157C170.576,132.477,166.924,128.824,162.419,128.824
// z"/>
//     <path d="M198.958,145.138h0.501c0.011,0,0.021-0.002,0.032-0.002c0.01,0,0.02,0.002,0.032,0.002h0.421
// c3.502,0,6.612-2.235,7.731-5.553l10.196-30.274l10.148,30.265c1.114,3.324,4.227,5.563,7.733,5.563h0.501
// c0.011,0,0.02-0.002,0.032-0.002s0.022,0.002,0.033,0.002h0.421c3.495,0,6.602-2.227,7.724-5.537l18.168-53.583
// c0.845-2.489,0.437-5.235-1.095-7.371c-1.531-2.137-4-3.405-6.628-3.405h-0.835c-3.516,0-6.636,2.253-7.742,5.59L236.268,111.2
// l-10.11-30.376c-1.109-3.333-4.227-5.581-7.739-5.581h-1.14c-3.517,0-6.636,2.253-7.742,5.59L199.474,111.2l-10.109-30.376
// c-1.109-3.333-4.227-5.581-7.74-5.581h-0.834c-2.629,0-5.097,1.268-6.63,3.405c-1.532,2.136-1.94,4.882-1.095,7.371l18.168,53.583
// C192.355,142.911,195.462,145.138,198.958,145.138z"/>
//     <path d="M289.496,145.138c18.084,0,26.178-10.525,26.177-20.893c0.125-16.176-13.955-20.431-22.368-22.973
// c-9.335-2.822-10.215-3.955-10.215-6.244c0-1.362,3.264-2.82,8.12-2.82c3.25,0,7.099,0.954,9.36,2.318
// c3.816,2.306,8.773,1.12,11.135-2.661l0.299-0.479c1.155-1.848,1.521-4.082,1.018-6.202c-0.501-2.121-1.832-3.952-3.693-5.085
// c-4.992-3.041-11.765-4.857-18.119-4.857c-17.33,0-25.087,9.937-25.087,19.786c0,15.75,13.332,19.788,22.153,22.459
// c9.605,2.909,10.453,4.065,10.432,6.699c0,2.739-4.776,3.986-9.212,3.986c-4.31,0-9.038-1.84-11.766-4.579
// c-1.529-1.536-3.605-2.399-5.77-2.402c-0.003,0-0.005,0-0.008,0c-2.162,0-4.239,0.86-5.768,2.39l-0.445,0.446
// c-1.543,1.543-2.404,3.64-2.389,5.822c0.015,2.183,0.904,4.269,2.467,5.791C271.838,141.499,280.911,145.138,289.496,145.138z"/>
//     <path d="M147.338,168.909H69.755c-8.646,0-15.656,7.009-15.656,15.656c0,8.646,7.009,15.656,15.656,15.656h77.582
// c8.646,0,15.656-7.01,15.656-15.656C162.994,175.918,155.984,168.909,147.338,168.909z"/>
//     <path d="M147.338,221.094H69.755c-8.646,0-15.656,7.01-15.656,15.656c0,8.646,7.009,15.656,15.656,15.656h77.582
// c8.646,0,15.656-7.009,15.656-15.656C162.994,228.104,155.984,221.094,147.338,221.094z"/>
//     <path d="M147.338,273.281H69.755c-8.646,0-15.656,7.009-15.656,15.656c0,8.646,7.009,15.656,15.656,15.656h77.582
// c8.646,0,15.656-7.01,15.656-15.656C162.994,280.29,155.984,273.281,147.338,273.281z"/>
//     <path d="M306.61,166.698H186.967c-5.005,0-9.064,4.059-9.064,9.063V297.74c0,5.005,4.059,9.063,9.064,9.063H306.61
// c5.006,0,9.064-4.059,9.064-9.063V175.761C315.674,170.756,311.616,166.698,306.61,166.698z"/>
//   </g>
// </svg>;