import { FC } from 'react';
import { useHistory } from "react-router-dom";
import { PrimaryButton } from "../buttons";

interface NavPrimaryLinkProps {
  children?: React.ReactNode
  href: string
  className?: string
}

const NavPrimaryLink: FC<NavPrimaryLinkProps> = ({children, className, ...props}) => {

  const history = useHistory();

  return (
    <PrimaryButton onClick={() => history.push(props.href)} className={`px-3 sm:px-4 py-1.5 sm:py-2 ${className?className:''}`}>
      {children}
    </PrimaryButton>
  );
}

export default NavPrimaryLink