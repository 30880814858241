import { useCallback, useContext, useEffect, useState } from "react";
import useCherryserviceAPI from "../../../api/cherryservice/v2";
import { useDebugLogger, useMounted } from "../../../commons/hooks";
import { FlatButton } from "../../../components/buttons";
import { TableCard, TableColumns, TableContent, TableHeader, TableRows, Column, Row, Element } from "../../../components/cards"
import { CustomSpinner } from "../../../components/spinner";
import { MessageModalContext } from "../../../layouts/console_layout";
import { FirewallRuleType as FirewallRuleApiType } from '../../../api/cherryservice/v2/types'
import {
  Subscription,
  SubscriptionStatus,
  AddRuleState,
  FirewallRule,
  FirewallRuleType,
  ruleTypesMap
} from '../types';


const RULE_NAME_FIELD = "NAME"
const RULE_SUBNET_FIELD = "SUBNET"
const NAME_MAX_LENGHT = 26
const SUBNET_REGEXP = /^(((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3})(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])(\/(3[0-2]|[1-2][0-9]|[0-9])){0,1}$/;

interface FragmentProps {
  subscription: Subscription,
}

const SecurityFragment: React.FC<FragmentProps> = ({ subscription }) => {

  const [addRuleState, setAddRuleState] = useState<AddRuleState>(AddRuleState.NOT_ADDING);
  const [rules, setRules] = useState<FirewallRule[] | undefined>(undefined);
  const [ruleToDelete, setRuleToDelete] = useState<FirewallRule | undefined>(undefined);
  //States for add rule form
  const [name, setName] = useState("");
  const [subnet, setSubnet] = useState("");
  const [type, setType] = useState<FirewallRuleType>(FirewallRuleType.ALLOW);
  const [nameErr, setNameErr] = useState(false);
  const [subnetErr, setSubnetErr] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const cherryserviceAPI = useCherryserviceAPI();
  const mounted = useMounted();
  const logger = useDebugLogger();
  const modalContext = useContext(MessageModalContext);

  const loadAllRules = useCallback(async () => {
    if (subscription && cherryserviceAPI) {
      try {
        const res = await cherryserviceAPI
          .getSubscriptionFirewallRules(subscription.id)
        //Set rules state
        if (mounted) {
          setRules(res.data.map(rule => ({
            id: rule.id,
            name: rule.name,
            type: ruleTypesMap.get(rule.type) ?? FirewallRuleType.ALLOW,
            subnet: rule.subnet.ip_address
          })))
        }
        //Reset add rule flag
        setAddRuleState(AddRuleState.NOT_ADDING);
      } catch (error: any) {
        //TODO manage errors
        if(error.response){
          logger.log(error.response)
        }
      }
    }
    return
  }, [subscription, cherryserviceAPI, mounted, logger])

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    switch (event.target.name) {
      case RULE_NAME_FIELD:
        const nameValue = event.target.value
        setName(nameValue);
        if (nameValue.length > NAME_MAX_LENGHT) {
          if (!nameErr) setNameErr(true);
        } else {
          if (nameErr) setNameErr(false)
        }
        break;
      case RULE_SUBNET_FIELD:
        const subnetValue = event.target.value;
        setSubnet(subnetValue)
        if (subnetValue !== '' && !SUBNET_REGEXP.test(subnetValue)) {
          if (!subnetErr) setSubnetErr(true)
        } else {
          if (subnetErr) setSubnetErr(false)
        }
        break;
    }
  }

  function handleRuleTypeSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
    if (event.target.value === FirewallRuleType.ALLOW) {
      setType(FirewallRuleType.ALLOW)
    } else if (event.target.value === FirewallRuleType.DENY) {
      setType(FirewallRuleType.DENY)
    }
    logger.log(event.target.value); //FIXME
  }

  function showDeleteRuleModal(rule: FirewallRule) {
    if (modalContext) {
      setRuleToDelete(rule);
    }
  }

  //Hook to load all rules at page first load
  useEffect(() => {
    loadAllRules()
  }, [loadAllRules])

  function submitNewRule() {
    if (allowSubmit && subscription) {
      const apiType = type === FirewallRuleType.DENY ? FirewallRuleApiType.DENY : FirewallRuleApiType.ALLOW
      setAddRuleState(AddRuleState.UPLOADING_TO_SERVER)
      cherryserviceAPI?.addSubscriptionFirewallRule(subscription.id, name, subnet, apiType)
        .then(() => {
          loadAllRules()
        }).catch(err => err.response && logger.log(err.response))
    }
  }

  //Hook to reset add rules when "X" button is clicked
  useEffect(() => {
    if (!addRuleState) {
      setName("");
      setSubnet("");
      setType(FirewallRuleType.ALLOW)
      setSubnetErr(false);
    }
  }, [addRuleState])

  //Hook to enable submit of a new rule
  useEffect(() => {
    if (name !== '' && !nameErr && subnet !== '' && !subnetErr) {
      if (!allowSubmit) setAllowSubmit(true)
    } else {
      if (allowSubmit) setAllowSubmit(false)
    }
  }, [name, nameErr, subnet, subnetErr, allowSubmit])

  //This hook is triggered every time a rule is set to be deleted
  //It uses modalContext to show a modal that enables a rule's deletion
  useEffect(() => {
    //Function triggered after a click on a rule's trash bin
    async function handleDeleteRule() {
      if (modalContext && ruleToDelete) {
        modalContext.actions.setLoading(true);
        if (subscription) {
          try {
            //Delete rule
            await cherryserviceAPI?.removeSubscriptionFirewallRule(subscription.id, ruleToDelete.id)
            //Reload rules
            await loadAllRules().then(() => setRuleToDelete(undefined))
          } catch (error: any) {
            //todo handle errors
            if(error.response){
              logger.log(error.response)
            }
          }
        }
      }
    }

    if (modalContext !== null && ruleToDelete) {
      //Set modal
      modalContext.actions.setModalContent({
        title: "Attention",
        mainButtonLabel: "Confirm",
        children: <p className="pt-2 text-gunmetal">Are you sure to delete the rule?</p>,
        submitCallback: () => handleDeleteRule(),
        closeCallback: () => setRuleToDelete(undefined)
      });
      //Show modal
      modalContext.actions.setVisibility(true);
      return () => {
        modalContext.actions.setModalContent(undefined)
      }
    }
  }, [modalContext, ruleToDelete, cherryserviceAPI, loadAllRules, subscription, logger])


  return (
    <div className="py-8">
      <TableCard className="w-2/3">
        <TableHeader className="flex justify-between items-center">
          <h2 className="text-lg 2xs:text-xl font-header text-gunmetal-300 font-bold">Firewall</h2>
          <FlatButton
            disabled={subscription.status !== SubscriptionStatus.ACTIVE || addRuleState !== AddRuleState.NOT_ADDING}
            onClick={() => setAddRuleState(AddRuleState.ADDING)}
            className={`px-2 py-1 text-sm text-white font-bold bg-cherry-red-500 hover:bg-cherry-red-300 rounded disabled:bg-gray-300`}
          >
            Add rule
          </FlatButton>
        </TableHeader>
        <TableContent
          loadingItems={rules === undefined}
        >
          <TableColumns>
            <Column className="text-gunmetal-350 font-semibold text-sm 2xs:text-base text-left">Rule name</Column>
            <Column className="text-gunmetal-350 font-semibold text-sm 2xs:text-base text-left">Subnet</Column>
            <Column className="text-gunmetal-350 font-semibold text-sm 2xs:text-base text-left">Type</Column>
            <Column className="text-gunmetal-350 font-semibold text-sm 2xs:text-base text-left">{''}</Column>
          </TableColumns>
          <TableRows>
            {
              (rules !== undefined ? rules.map(rule => (
                <Row key={rule.id} className="h-12">
                  <Element className="text-gunmetal-200 text-sm 2xs:text-base text-left">{rule.name}</Element>
                  <Element className="text-gunmetal-200 text-sm 2xs:text-base text-left">{rule.subnet}</Element>
                  <Element className="text-gunmetal-200 text-sm 2xs:text-base text-left">{rule.type}</Element>
                  <Element>
                    <div className="flex justify-center">
                      <i onClick={() => showDeleteRuleModal(rule)} className="far fa-trash-alt text-sm 2xs:text-base cursor-pointer text-gunmetal-200 hover:text-cherry-red-400"></i>
                    </div>
                  </Element>
                </Row>)
              ) : [])
                //Add rule option//Add rule option
                .concat(
                  [
                    addRuleState !== AddRuleState.NOT_ADDING ? <Row key={"ADD_RULE"} className="h-14">
                      <Element className="text-gunmetal-200 text-sm 2xs:text-base text-left pb-3 relative">
                        <input name={RULE_NAME_FIELD} value={name} onChange={handleInputChange} className="px-2 py-0.5 w-full bg-gray-100 outline-none rounded" />
                        <p className={`${nameErr ? 'animate-fade-in-rapid' : 'hidden'} transition-colors duration-300 text-2xs font-semibold absolute text-cherry-red-400`}>Error: Rule name can be at maximim 30 characters</p>
                      </Element>
                      <Element className="text-gunmetal-200 text-sm 2xs:text-base text-left pb-3 relative">
                        <input name={RULE_SUBNET_FIELD} value={subnet} onChange={handleInputChange} className={`px-2 py-0.5 w-full transition-colors duration-300 bg-gray-100 outline-none rounded ${subnetErr ? 'border border-cherry-red-400' : ''}`} />
                        <p className={`${subnetErr ? 'animate-fade-in-rapid' : 'hidden'} transition-colors duration-300 text-2xs font-semibold absolute text-cherry-red-400`}>Error: insert an IP or a subnet in CIDR notation</p>
                      </Element>
                      <Element className="text-gunmetal-200 text-sm 2xs:text-base text-left pb-3 relative">
                        <select value={type} onChange={handleRuleTypeSelectChange} className={`px-2 py-0.5 w-full bg-gray-100 outline-none rounded`}>
                          <option value={FirewallRuleType.ALLOW}>Allow</option>
                          <option value={FirewallRuleType.DENY}>Deny</option>
                        </select>
                      </Element>
                      <Element className="pb-3">
                        <div className="flex space-x-2 justify-center">
                          {
                            addRuleState === AddRuleState.UPLOADING_TO_SERVER
                              ? (
                                <div className="flex items-center justify-center">
                                  <CustomSpinner className="h-4 w-4 text-cherry-red-400" />
                                </div>
                              ) : (
                                <>
                                  <i onClick={submitNewRule} className={`fas fa-check text-sm 2xs:text-base text-gunmetal-200 ${allowSubmit ? 'cursor-pointer hover:text-cherry-red-400' : 'cursor-not-allowed'}`}></i>
                                  <i onClick={() => setAddRuleState(AddRuleState.NOT_ADDING)} className="fas fa-times text-sm 2xs:text-base cursor-pointer text-gunmetal-200 hover:text-cherry-red-400"></i>
                                </>
                              )
                          }
                        </div>
                      </Element>
                    </Row> :
                      <Row></Row>
                  ].filter(() => addRuleState !== AddRuleState.NOT_ADDING)
                  //IF nothing is being added the appended array should be empty
                )
            }
          </TableRows>
        </TableContent>
      </TableCard>
    </div>
  )
}

export default SecurityFragment;