import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useOnScreen } from '../../commons/hooks';
import { CollapseContext } from './navbar';

interface CollapseProps {
  children: React.ReactNode
  className?: string
  breakPoint: string | CollapseBreakPoint
  onVisibilityChange?: (isCollapsed: boolean)=>void
}

/**
 * Allow to set the first breakpoint that shows the collapsed elements
 */
export enum CollapseBreakPoint {
  XS = "hidden xs:contents",
  SM = "hidden sm:contents",
  MD = "hidden md:contents",
  LG = "hidden lg:contents",
  XL = "hidden xl:contents",
  XXL = "hidden 2xl:contents",
}

/**
 * @param className allows to apply classes to the collapse component. By default collapse is
 * a flex-box (flex-direction: row) with items centered in the middle of the cross-axis
 * @param breakPoint must contain either a {@link CollapseBreakPoint} or a class that
 * set a breakpoint to hide the collapse component. If the navbar elements never collapses
 * don't use this component
 * @param onVisibilityChange is triggered every time the visibility of elements inside this component
 * changes. It passes a parameter telling whether children are collapsed or not
 * @returns 
 */
const Collapse: React.FC<CollapseProps> = ({ className, breakPoint, onVisibilityChange, ...props }) => {

  const { setIsCollapsed } = useContext(CollapseContext)
  const [collapseElement, setCollapseElement] = useState<HTMLDivElement | null>(null)
  const collapseRefCallback = useCallback((el: HTMLDivElement | null) => {
    if (el) {
      setCollapseElement(el)
    }
  }, [])

  const isVisible = useOnScreen(collapseElement)

  /**
   * This hook updates every time the visibility observer notices a change
   * in the visibility of <{@link Collapse}> content, which means that reaching the
   * defined breakpoint triggered a change in visibility.
   */
  useEffect(() => {
    if (isVisible !== null) {
      //Visibility observer is defined
      setIsCollapsed(breakPoint, !isVisible)
      if(onVisibilityChange){
        //Callback provided
        onVisibilityChange(!isVisible)
      }
    }
  }, [isVisible, breakPoint, setIsCollapsed, onVisibilityChange])


  return (
    <div className={`flex flex-row items-center select-none ${className ? className : ''}`}>
      <div className={`${breakPoint}`}>
        <div ref={collapseRefCallback}></div>
        {props.children}
      </div>
    </div>
  );
}

export default Collapse;