import { FC } from 'react';
import { NavLink } from 'react-router-dom';

const NavDropItem: FC<{ children: string, href: string }> = (props) => {
  return (
    <NavLink to={props.href} className={`py-4 px-4 block text-white select-none hover:bg-gunmetal-300 hover:bg-opacity-90`}>
      <p>{props.children}</p>
    </NavLink>
  );
}

export default NavDropItem