import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import useCherryserviceAPI from '../../api/cherryservice/v2';
import { InstallationType, ProductType, Subscription as SubscriptionApiObj } from '../../api/cherryservice/v2/types';
import { InteractiveModal, MessageModal } from '../../components/modals';

import { useDebugLogger, useMounted, useOnScreen, useTimer } from '../../commons/hooks';
import { AxiosResponse } from 'axios';
import { SUBSCRIPTION_DAYS_BEFORE_ALERT } from '../../commons/constants';

import { ScreenRefContext } from '../../layouts/console_layout';
import {
  Subscription,
  SubscriptionStatus,
  serverStatusMap,
  ServerStatus,
  subscriptionStatusMap,
  notInstallingStatuses,
} from './types';
import { DashboardFragment } from './fragments/dashboard';
import SecurityFragment from './fragments/security';
import { Spinner } from '../../components/spinner';

const DASHBOARD_FRAG_PATH = "/dashboard"
const SECURITY_FRAG_PATH = "/security"

const InternalNavbarLink: React.FC<{ title: string, path: string }> = ({ title, path }) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div onClick={() => history.replace(path)} className={`${location.pathname.match(path) ? '' : 'cursor-pointer'} group select-none`}>
      <p className={`${location.pathname.match(path) ? 'text-gunmetal-500' : 'text-gunmetal-200 group-hover:text-gray-400'} font-semibold py-3 transition-colors`}>{title}</p>
      <div className={`${location.pathname.match(path) ? 'bg-gunmetal-400' : 'group-hover:bg-gray-300'} rounded-t-md h-3px transition-colors`}></div>
    </div>
  );
}

const SubscriptionPage: React.FC<{}> = () => {

  const fittingScreenContext = useContext(ScreenRefContext);
  const [modalIsActive, setModalIsActive] = useState(false);
  const [showRenewModal, setShowRenewModal] = useState(false);
  const [renewing, setRenewing] = useState(false);
  const [renewed, setRenewed] = useState(false);
  const [isInstalling, setIsInstalling] = useState(false);
  const [callReload, setCallReload] = useState(false);
  const [connSubIsInstalling, setConnSubIsInstalling] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const mounted = useMounted();
  const subscriptionID = useRef<number>(parseInt(location.pathname.split('/subscription/')[1]));
  const pagePath = useRef<string>(location.pathname.split(`/subscription/${subscriptionID.current}`)[1]);
  const subscriptionPath = useRef<string>(location.pathname.split(pagePath.current)[0]);
  const timer = useTimer();
  const logger = useDebugLogger();
  const cherryserviceAPI = useCherryserviceAPI();

  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [navbarHTMLElement, setNavbarHTMLElement] = useState<HTMLDivElement | null>(null);
  const navbarRefCallback = useCallback((element: HTMLDivElement) => {
    setNavbarHTMLElement(element);
  }, [])
  //Hook returning relative positioning and a "visible/out-of-screen" flag
  const isNavbarVisible = useOnScreen(navbarHTMLElement, { root: fittingScreenContext, threshold: 1 })

  function enableSubmit(subscriptionName: string) {
    if (subscriptionName === subscription?.name) {
      return null;
    }
    return '';
  }

  function handleTerminate() {
    cherryserviceAPI?.deleteSubscription(subscriptionID.current)
      .then(res => {
        history.goBack();
      }).catch(err => err.response && logger.log(err.response));
  }

  function handleRenewRequest() {
    setRenewing(true)
    cherryserviceAPI?.renewSubscription(subscriptionID.current)
      .then(res => {
        setRenewed(true);
        setRenewing(false);
      }).catch(err => err.response && logger.log(err.response))
  }


  const manageGetSubscriptionResponse = useCallback(async (res: AxiosResponse<SubscriptionApiObj>) => {

    function isSubscriptionChanged(prev: Subscription, next: Subscription) {
      const isStatusChanged = prev.status !== next.status;
      const isNumberOfServersChanged = prev.servers.length !== next.servers.length;
      let isOneServerStatusChanged = false;
      if (!isNumberOfServersChanged) {
        isOneServerStatusChanged = prev.servers
          .map((server, idx) => server.status !== next.servers[idx].status)
          .reduce((previousServersChanged, current) => previousServersChanged || current, false)
      }
      return isStatusChanged || isNumberOfServersChanged || isOneServerStatusChanged
    }

    if (cherryserviceAPI) {
      try {
        const relatedSubsPromise = cherryserviceAPI.getConnectedSubscription(subscriptionID.current);
        const relatedProduct = await cherryserviceAPI.getProduct(res.data.product);
        const serversAssociated = res.data.servers.map(server => {
          if (serverStatusMap.get(server.status) === undefined) logger.log("Remote status received from back-end untracked");
          return ({
            ...server,
            status: serverStatusMap.get(server.status) ?? ServerStatus.PROVISIONING
          });
        });
        serversAssociated.sort((server1, server2) => (server1.name > server2.name ? 1 : -1))
        const retrievedSubscription: Subscription = {
          id: res.data.id,
          name: res.data.name,
          status: subscriptionStatusMap.get(res.data.status) ?? SubscriptionStatus.LOADING,
          dateCreated: new Date(res.data.date_created),
          dateStart: new Date(res.data.date_start),
          dateEnd: new Date(res.data.date_end),
          autorenew: res.data.autorenew,
          isDemo: relatedProduct.data.demo,
          isCluster: relatedProduct.data.type === ProductType.CHERRY_TABLE
            && relatedProduct.data.cherrytable.installation_type === InstallationType.CLUSTER, //FIXME
          duration: relatedProduct.data.duration,
          servers: serversAssociated
        }
        let relatedSubs;
        // FIXME: Remove this after updating the back-end 
        try {
          relatedSubs = await relatedSubsPromise
        } catch (error: any) {
          if(error.response){
            logger.log(error.response)
          }
        }

        //Finally set subscription-related data in local state
        if (mounted) {
          if ((subscription === null || isSubscriptionChanged(subscription, retrievedSubscription))) {
            setSubscription(retrievedSubscription);
          }
          //Get all related subscriptions and filters the ones undergoing installation
          if (relatedSubs && relatedSubs.data.find(sub => notInstallingStatuses.indexOf(subscriptionStatusMap.get(sub.status) ?? SubscriptionStatus.BOOTING) === -1) !== undefined) {
            setConnSubIsInstalling(true);
          } else {
            setConnSubIsInstalling(false);
          }
        }
      } catch (error: any) {
        if(error.response){
          logger.log(error.response)
        }
      }
    }
  }, [mounted, subscriptionID, cherryserviceAPI, subscription, logger])


  //Hook that starts the reloading process of the page
  //if the sub is installing or a connected sub is installing
  useEffect(() => {
    if (isInstalling || connSubIsInstalling) {
      setCallReload(true);
    }
  }, [isInstalling, connSubIsInstalling])

  //Hook to check if subscription is installing
  //(status != Active or Error)
  useEffect(() => {
    if (subscription) {
      if (notInstallingStatuses.indexOf(subscription.status) !== -1) {
        if (isInstalling) {
          setIsInstalling(false);
        }
      } else {
        if (!isInstalling) {
          setIsInstalling(true);
        }
      }
    }
  }, [subscription, isInstalling])

  //Hooks to show the modal to renew the subscription if necessary
  useEffect(() => {
    if (subscription) {
      const today = new Date()
      const dateToAlertUser = new Date(new Date(subscription.dateEnd).setDate(subscription.dateEnd.getDate() - SUBSCRIPTION_DAYS_BEFORE_ALERT))
      //When the subscription is demo and the date to alert the user passed but the sub is not expired. (And modal not shown)
      if (subscription.isDemo && dateToAlertUser < today && subscription.dateEnd > today) {
        //Only if it's the first renew (date_created==date_start)
        if (subscription.dateCreated.toLocaleDateString() === subscription.dateStart.toLocaleDateString()) {
          setShowRenewModal(true)
        }
      }
    }
  }, [subscription])

  //Hook to retrieve the subscription the first time
  useEffect(() => {
    cherryserviceAPI?.getSubscription(subscriptionID.current)
      .then(subRes => {
        manageGetSubscriptionResponse(subRes)
      }).catch(err => err.response && logger.log(err.response));
  }, [manageGetSubscriptionResponse, cherryserviceAPI, logger])

  //Hook to retrieve the subscription after renewal
  useEffect(() => {
    if (renewed) {
      cherryserviceAPI?.getSubscription(subscriptionID.current)
        .then(subRes => {
          manageGetSubscriptionResponse(subRes)
          setRenewed(false);
          setShowRenewModal(false);
        }).catch(err => err.response && logger.log(err.response));
    }
  }, [mounted, renewed, manageGetSubscriptionResponse, cherryserviceAPI, logger])

  //Hook to continuously retrieve and update the subscription during installation
  useEffect(() => {
    function reloadSubscription() {
      cherryserviceAPI?.getSubscription(subscriptionID.current)
        .then(subRes => {
          manageGetSubscriptionResponse(subRes).then(() => {
            setCallReload(true); //Issue a new request
          });
        }).catch(err => err.response && logger.log(err.response));
    }

    if (callReload) {
      //Accept the request only during installation, 
      //otherwise discard (do nothing)
      if (connSubIsInstalling || isInstalling) {
        timer.setTimer(() => reloadSubscription(), 2000);
      }
      setCallReload(false); //Consume the reload request
    }
  }, [callReload, connSubIsInstalling, isInstalling, timer, manageGetSubscriptionResponse, cherryserviceAPI, logger])

  //Redirect hook: redirects to dashboard fragment when a frag. is not selected
  useEffect(() => {
    if (pagePath.current === '') {
      history.replace(location.pathname.toString() + DASHBOARD_FRAG_PATH)
    }
  }, [history, location])

  if (subscription === null) {
    return <div className="w-full h-full pt-8 px-8 flex flex-col items-center justify-center">
      <Spinner/>
    </div>
  }

  return (
    <div className={`w-full h-full ${modalIsActive && 'overflow-hidden'}`}>
      <MessageModal
        title={"Subscription renewal"}
        active={showRenewModal}
        mainButtonLabel="Confirm"
        submitCallback={() => handleRenewRequest()}
        closeCallback={() => setShowRenewModal(false)}
        loading={renewing}
      >
        <p className="pt-2 text-gunmetal">Click on the confirm button to renew your subscription until {subscription.dateEnd.toLocaleDateString()}.</p>
      </MessageModal>
      <InteractiveModal
        windowClassName={`absolute w-full h-full z-40`}
        title="End subscription"
        inputLabel="Insert subscription name to delete"
        mainButtonLabel="Terminate"
        active={modalIsActive}
        enableSubmitCallback={(input) => enableSubmit(input)}
        backCallback={() => setModalIsActive(false)}
        submitCallback={() => handleTerminate()} //FIXME
      />
      <div className="px-8 pt-8 temp">
        <div onClick={() => history.goBack()} className="flex flex-row items-center space-x-2 group cursor-pointer select-none ml-3px">
          <i className="fas fa-arrow-left text-sm text-gray-300 group-hover:text-gunmetal-300"></i>
          <h3 className="text-base font-bold font-header text-gray-300 group-hover:text-gunmetal-300">Subscriptions</h3>
        </div>
        <h1 className="mt-0.5 text-5xl font-bold font-header text-gunmetal-500">{subscription.name}</h1>
      </div>
      <div ref={navbarRefCallback} className="w-full h-20 flex items-end">
        <div className={`w-full transition-colors ${isNavbarVisible === false ? 'fixed top-12 z-20 shadow-sm bg-white duration-300' : 'static bg-ghost-white duration-150'} `}>
          <div className={`flex px-8 pt-4 space-x-4`}>
            <InternalNavbarLink title="Dashboard" path={subscriptionPath.current + DASHBOARD_FRAG_PATH} />
            <InternalNavbarLink title="Security" path={subscriptionPath.current + SECURITY_FRAG_PATH} />
          </div>
          <div className={`h-px w-full bg-silver-gray-600`} />
        </div>
      </div>
      <div className="px-8 pb-8">
        <Switch>
          <Route
            path={subscriptionPath.current + DASHBOARD_FRAG_PATH}
            render={
              () =>
                <DashboardFragment
                  subscription={subscription}
                  isLocalSubInstalling={isInstalling}
                  isAConnectedSubInstalling={connSubIsInstalling}
                  setModalIsActive={setModalIsActive}
                  manageGetSubscriptionResponse={manageGetSubscriptionResponse}
                />
            }
          />
          <Route
            path={subscriptionPath.current + SECURITY_FRAG_PATH}
            render={
              () =>
                <SecurityFragment
                  subscription={subscription}
                />
            }
          />
        </Switch>
      </div>
    </div>
  );
}

export default SubscriptionPage;