import React, { useEffect, useState } from 'react';
import { Switch, Route, NavLink, Redirect, useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Collapse,
  Navbar,
  NavLogo,
  NavTextLink,
  NavPrimaryLink,
  NavSecondaryLink,
  NavDropDown,
  NavDropItem,
  NavUser,
  CollapseBreakPoint,
} from '../../components/navbar';
import routes from '../../routes';
import { RootState } from '../../store';
import { AuthenticationStateType } from '../../store/auth/reducers';
import { getHomeLinks } from './home_links';
import { LinkData, LinkStyle } from '../../commons/types';
import { MessageModal } from '../../components/modals';
import usePrivacyPolicy from '../../commons/hooks/use_privacy_policy';
import { MobileSidabarLogo, MobileSidebar, SidebarDropdown, SidebarDropdownItem, SidebarItem } from '../../components/sidebar';
import { useTranslation } from 'react-i18next';

enum ItemType {
  DROP_DOWN,
  LINK
}

interface LinkItem {
  label: string
  link: LinkData
  type: ItemType.LINK
}

interface DropDownItem {
  label: string
  items: LinkData[]
  type: ItemType.DROP_DOWN
}

type NavigationItem = LinkItem | DropDownItem

const RoutesSwitcher: React.FC<{}> = (props) => {
  return (
    <Switch>
      {routes.filter(route => (route.layout === '/')).map(route => {
        return (<Route key={route.layout + route.path} exact path={route.layout + route.path} component={route.page} />);
      })}
      <Redirect from="*" to="/not_found" />
    </Switch>
  );
}

interface ReduxStateProps {
  authState: AuthenticationStateType | null
}

type ProductLayoutProps = ReduxStateProps;

const ProductLayout: React.FC<ProductLayoutProps> = ({ authState }) => {

  const privacyPolicy = usePrivacyPolicy();
  const [navigationItems, setNavigationItems] = useState<NavigationItem[] | undefined>(undefined);
  const [showMobileSidebar, setShowMobileSidebar] = useState<boolean>(false);
  const [isAdminCollapsed, setIsAdminCollapsed] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();

  //Function to handle clicks on the button to open the sidebar
  const handleSidebarButtonClick = () => setShowMobileSidebar(true)

  //Function to handle clicks on aimed to close the sidebar
  const handleCloseRequest = () => setShowMobileSidebar(false)

  //Function to handle clicks on Mobile sidebar logo
  const handleSidebarLogoClick = () => {
    //Close sidebar
    handleCloseRequest()
    if (location.pathname !== '/') {
      history.push("/")
    }
  }

  //Function to handle admin button collapsing state changes
  const handleOnAdminCollapse = (isCollapsed: boolean) => setIsAdminCollapsed(isCollapsed)

  //Function that force mobile sidebar closure when all items are visible again
  const handleNavbarNotCollapsed = (areItemsCollapsed: boolean) => !areItemsCollapsed && setShowMobileSidebar(false);

  //This hook loads links for navbar
  useEffect(() => {
    //Function retrieving links
    const getFormattedSimpleLinks = () => {
      //simple links
      let simpleLinks: LinkData[] = getHomeLinks().filter(link => link.style === LinkStyle.TEXT)
        .filter(link => !link.parentLabel);
      let labelsMap: Map<string, LinkData[]> = new Map();
      //dropdowns 
      getHomeLinks().filter(link => link.style === LinkStyle.TEXT)
        .filter(link => link.parentLabel).forEach(link => {
          if (link.parentLabel) {
            let labels = labelsMap.get(link.parentLabel);
            if (labels) { // parent label already present,
              //add current link and update the map
              labels.push(link);
              labelsMap.set(link.parentLabel, labels);
            } else {
              //set the new label
              labelsMap.set(link.parentLabel, [link]);
            }
          }
        });
      let dropdownItems: NavigationItem[] = [];
      for (let [key, value] of labelsMap.entries()) {
        dropdownItems.push({
          label: key,
          items: value,
          type: ItemType.DROP_DOWN
        })
      }
      let links: NavigationItem[] = simpleLinks.map(link =>
      ({
        label: link.title,
        type: ItemType.LINK,
        link: link
      })
      );
      //Possible ordering can be applied here
      return dropdownItems.concat(links);
    }

    // Load navbar links
    setNavigationItems(getFormattedSimpleLinks());
  }, [])

  const { t, i18n } = useTranslation();

  return (
    <>
      <div className={`w-screen ${!privacyPolicy.isAccepted ? 'overflow-hidden h-screen-adaptive' : ''} ${showMobileSidebar ? 'overflow-hidden h-screen' : ''}`}>
        <MessageModal
          title="Privacy Policy"
          active={!privacyPolicy.isAccepted}
          mainButtonLabel="I agree"
          submitCallback={() => privacyPolicy.accept()}
        >
          <p className="pt-2">I've read and I agree with the privacy policy <NavLink className="text-cherry-red-400 hover:text-cherry-red-200" to='/privacy_policy'>Privacy Policy</NavLink></p>
        </MessageModal>
        {/* Placeholder when the navbar takes fixed position */}
        <div className={`${showMobileSidebar?'h-16 w-full':''}`}/>
        <header className={`${showMobileSidebar ? 'fixed': 'sticky'} top-0 w-screen px-3 md:px-3 bg-white border-b z-40`}>
          <Navbar primaryNav className="w-full h-16" onSidebarButtonClick={handleSidebarButtonClick}>
            <NavLogo />
            <Collapse
              onVisibilityChange={handleNavbarNotCollapsed}
              className={`h-full flex-auto`}
              breakPoint={CollapseBreakPoint.MD}
            >
              {navigationItems?.map(navItem => (
                navItem.type === ItemType.DROP_DOWN
                  ? (
                    <NavDropDown key={navItem.label} title={t(navItem.label)}>
                      {navItem.items.map(dropdownLink =>
                        <NavDropItem href={dropdownLink.href} key={dropdownLink.title}>{t(dropdownLink.title)}</NavDropItem>)
                      }
                    </NavDropDown>
                  ) : (
                    <NavTextLink key={navItem.label} href={navItem.link.href}>{t(navItem.label)}</NavTextLink>
                  )
              ))}
            </Collapse>
            <div className="flex flex-row h-full items-center">
              {
                authState?.logged && authState?.user !== undefined
                  ? <>
                    <NavSecondaryLink className="mr-2 sm:mr-4" href="/console">Console</NavSecondaryLink>
                    {
                      authState.user.isStaff &&
                      <Collapse
                        breakPoint={CollapseBreakPoint.SM}
                        className={`h-full flex-auto`}
                        onVisibilityChange={handleOnAdminCollapse}
                      >
                        <NavPrimaryLink className="mr-2 sm:mr-4" href="/admin">Admin</NavPrimaryLink>
                      </Collapse>
                    }
                    <NavUser
                      fullName={`
                            ${(authState.user.name === undefined ||
                          authState.user.surname === undefined)
                          ? `User${authState.user.id}`
                          : `${authState.user.name} ${authState.user.surname}`}
                            `} //FIXME
                      email={authState.user.email}
                    />
                  </>
                  : <>
                    <NavSecondaryLink className="mr-2 sm:mr-4" href="/auth/signin">{t("sign_in.title")}</NavSecondaryLink>
                    <NavPrimaryLink className="mr-2 sm:mr-4" href="/auth/signup">{t("sign_up.title")}</NavPrimaryLink>
                  </>
              }
            </div>
          </Navbar>
        </header>
        <MobileSidebar
          show={showMobileSidebar}
          onCloseRequest={handleCloseRequest}
        >
          <MobileSidabarLogo onLogoClick={handleSidebarLogoClick} />
          <div className="mt-4">
            <SidebarItem key={"Home"} linkData={{ title: "Home", href: "/" }} onItemClick={handleCloseRequest}>Home</SidebarItem>
            {
              navigationItems?.map(navItem => (
                navItem.type === ItemType.DROP_DOWN
                  ? (
                    <SidebarDropdown
                      label={navItem.label}
                      key={navItem.label}
                      associatedLinks={navItem.items.map(link => link.href)}
                    >
                      {
                        navItem.items.map(link => (
                          <SidebarDropdownItem key={link.title} linkData={link} onItemClick={handleCloseRequest}>{link.title}</SidebarDropdownItem>
                        ))
                      }
                    </SidebarDropdown>
                  ) : (
                    <SidebarItem key={navItem.label} linkData={navItem.link} onItemClick={handleCloseRequest}>{navItem.label}</SidebarItem>
                  )
              ))
            }
            {
              isAdminCollapsed && <SidebarItem key={"Admin"} linkData={{ title: "Admin", href: "/admin" }} onItemClick={handleCloseRequest}>Home</SidebarItem>
            }
          </div>
        </MobileSidebar>
        <RoutesSwitcher />
      </div>
    </>
  );

}

function mapStateToProps(state: RootState): ReduxStateProps {
  return ({
    authState: state.user
  });
}

export default connect<ReduxStateProps, {}, {}, RootState>(mapStateToProps)(ProductLayout);