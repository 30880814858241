import React, { useState, useEffect, useRef } from 'react';
import { PrimaryFlatButton, FlatButton } from './buttons';

import {
  BlankCard
} from './cards';
import { CustomSpinner, Spinner } from './spinner';

enum ModalState {
  INACTIVE,
  ACTIVE,
  LOADING_ACTION,
}

interface InteractiveModalProps {
  title: string
  comment?: string
  inputLabel: string
  active: boolean
  enableSubmitCallback: (input: string) => string | null
  mainButtonLabel: string
  backCallback: () => void
  submitCallback: (input: string) => void
  windowClassName?: string
}

interface MessageModalProps {
  title: string
  children?: React.ReactElement | React.ReactElement[]
  active: boolean
  mainButtonLabel: string
  loading?: boolean
  closeCallback?: () => void
  submitCallback: () => void
}

export const InteractiveModal: React.FC<InteractiveModalProps> = ({ title, comment, inputLabel, mainButtonLabel, enableSubmitCallback, ...props }) => {
  const [modalState, setModalState] = useState(ModalState.INACTIVE)
  const [inputContent, setInputContent] = useState('');
  const [inputError, setInputError] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(undefined!);

  useEffect(() => {
    if (modalState === ModalState.INACTIVE && props.active) {
      setModalState(ModalState.ACTIVE);
    }
    if (!(modalState === ModalState.INACTIVE) && !props.active) {
      setModalState(ModalState.INACTIVE);
    }
  }, [props.active, modalState])

  useEffect(() => {
    setInputError(enableSubmitCallback(inputContent))
  }, [inputContent, enableSubmitCallback])

  //ComponentDidMount: get focus
  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [modalState])

  function handleInputChange(event: React.FormEvent<HTMLInputElement>) {
    setInputContent(event.currentTarget.value);
  }

  function handleBackClick() {
    props.backCallback();
    setInputContent('');
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (inputError === null) {
        handleSubmitClick();
      }
    }
  }

  function handleSubmitClick() {
    props.submitCallback(inputContent);
    setModalState(ModalState.LOADING_ACTION);
    setInputContent('');
  }

  return (
    //FIXME: put z-index to 50 and update relative views accordingly
    <div className={`transition ${props.windowClassName ? props.windowClassName : ''} ${!(modalState === ModalState.INACTIVE) ? 'flex flex-col ' : 'hidden'} items-center justify-center bg-gunmetal-700 bg-opacity-30`}>
      {
        modalState === ModalState.ACTIVE
          ? (
            <div className="z-20 w-80">
              <BlankCard
                wrapContent
              >
                <div className="w-full h-full p-2">
                  <h1 className="text-2xl font-header text-gunmetal-500 font-bold">{title}</h1>
                  {comment && <p className="font-sub-header text-gunmetal-200 pt-4 text-sm">{comment}</p>}
                  <form className="pt-6 pb-4 relative">
                    <label className="block text-gunmetal-300 font-semibold">{inputLabel}</label>
                    <input ref={inputRef} onKeyPress={e => handleKeyPress(e)} onChange={e => handleInputChange(e)} className={`focus:outline-none text-gunmetal-300 h-12 w-full mt-2 p-2 ${inputError && inputError.length > 0 ? 'border-cherry-red-400' : 'border-gray-200'} border-2 rounded-lg`} type="text" />
                    {<p className="text-sm text-cherry-red-300 ml-2 absolute">{inputError}</p>}
                  </form>
                  <div className="mt-8 flex flex-row space-x-2">
                    <FlatButton onClick={handleBackClick} className="w-full transition-colors hover:bg-cherry-red-75 text-cherry-red-400 font-semibold rounded">Back</FlatButton>
                    <FlatButton disabled={inputError !== null} onClick={() => handleSubmitClick()} className={`w-full ${inputError === null ? 'bg-cherry-red-400 hover:bg-cherry-red-300 ' : 'bg-gray-400 cursor-not-allowed'} text-white font-semibold h-10 rounded`}>{mainButtonLabel}</FlatButton>
                  </div>
                </div>
              </BlankCard>
            </div>
          ) : (
            <div className="w-full h-full pt-8 px-8 flex flex-col items-center justify-center">
              <Spinner />
            </div>
          )
      }
    </div>
  );
}

export const MessageModal: React.FC<MessageModalProps> = ({ title, children, loading, mainButtonLabel, ...props }) => {
  const [modalState, setModalState] = useState(ModalState.INACTIVE)

  useEffect(() => {
    if (modalState === ModalState.INACTIVE && props.active) {
      setModalState(ModalState.ACTIVE);
    }
    if (!(modalState === ModalState.INACTIVE) && !props.active) {
      setModalState(ModalState.INACTIVE);
    }
  }, [props.active, modalState])


  function handleSubmitClick() {
    props.submitCallback();
  }

  function handleCloseClick() {
    if (props.closeCallback) {
      props.closeCallback();
    } 
  }

  return (
    <div className={`transition absolute top-0 left-0 z-50 h-full w-full ${!(modalState === ModalState.INACTIVE) ? ' ' : 'hidden'} bg-gunmetal-700 bg-opacity-30 backdrop-filter backdrop-blur-md`}>
      <div className="z-50 w-80 relative top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
        {props.closeCallback && <i onClick={() => handleCloseClick()} className="fas fa-times absolute top-4 right-4 cursor-pointer text-cherry-red-400 hover:text-cherry-red-250"></i>}
        <BlankCard
          wrapContent
        >
          <div className="w-full h-full p-2">
            <h1 className="text-2xl font-header text-gunmetal-500 font-bold">{title}</h1>
            {children}
            <div className="mt-8 flex flex-row space-x-2">
              <PrimaryFlatButton disabled={loading} onClick={() => handleSubmitClick()} className={`w-full bg-cherry-red-400 hover:bg-cherry-red-300 text-white font-semibold h-10 rounded`}>
                {
                  loading
                    ? <div className="flex items-center justify-center"><CustomSpinner className="h-4 w-4" /></div>
                    : mainButtonLabel
                }
              </PrimaryFlatButton>
            </div>
          </div>
        </BlankCard>
      </div>
    </div>
  );
}