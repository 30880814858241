import React, { useEffect, useState } from 'react';

export enum AlertType {
  ERROR,
  SUCCESS,
  INFO
}

export const SimpleAlert: React.FC<{ showText?: string, type: AlertType }> = ({ showText, ...props }) => {

  const [type, setType] = useState<AlertType>(undefined!);

  const textColors: Map<AlertType, string> = new Map([
    [AlertType.ERROR, 'text-cherry-red-400'],
    [AlertType.SUCCESS, 'text-green-600'],
    [AlertType.INFO, 'text-blue-600']
  ])

  const bgColors: Map<AlertType, string> = new Map([
    [AlertType.ERROR, 'bg-cherry-red-100'],
    [AlertType.SUCCESS, 'bg-green-200'],
    [AlertType.INFO, 'bg-blue-200']
  ])

  const icons: Map<AlertType, React.ReactElement> = new Map([
    [AlertType.ERROR, <i className="fas fa-times text-cherry-red-400"></i>],
    [AlertType.SUCCESS, <i className="fas fa-check text-green-600"></i>],
    [AlertType.INFO, <i className="fas fa-info text-blue-600"></i>]
  ]);

  useEffect(() => {
    if (showText) {
      setType(props.type);
    }
  }, [showText, props.type])

  return (
    <>
      {/* THIS COMPONENT IS HIDDEN: IT WILL SHOW WHEN SHOW TEXT is !== undefined */}
      <div className={`absolute z-50 w-full -top-12 left-0 duration-300 transition-all transform ${showText && 'translate-y-14'}`}>
        <div className={`w-5/6 mx-auto h-12 rounded-lg ${bgColors.get(type) ?? 'bg-gray-300'} flex flex-row items-center justify-center space-x-2`}>
          {showText && icons.get(type)}
          <p className={`block text-center ${textColors.get(type) ?? 'text-gray-600'} font-semibold`}>{showText}</p>
        </div>
      </div>
    </>
  );
}

export const MessageAlert: React.FC<{ showText?: string, className?:string }> = ({ showText, ...props }) => {

  return (
    <>
      {/* THIS COMPONENT IS HIDDEN: IT WILL SHOW WHEN SHOW TEXT is !== undefined */}
      <div className={`fixed z-50 bg-gunmetal ${props.className} bottom-2 left-2 duration-300 rounded-lg transition-all transform ${!showText && 'translate-y-20'} flex flex-row items-center justify-center`}>
        <p className={`block text-center text-white font-semibold`}>{showText}</p>
      </div>
    </>
  );
}