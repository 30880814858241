import { useState, useEffect } from 'react';

interface Coordinates {
  x: number
  y: number
}

//Mouse Position
const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState<Coordinates>({ x: undefined!, y: undefined! });

  const updateMousePosition = (ev: MouseEvent) => {
    setMousePosition({ x: ev.clientX, y: ev.clientY });
  };

  useEffect(() => {
    window.addEventListener("mousemove", updateMousePosition);

    return () => window.removeEventListener("mousemove", updateMousePosition);
  }, []);

  return mousePosition;
};

export default useMousePosition;