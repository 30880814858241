import {
  USER_LOGGED,
  AuthenticationActions,
  NOT_AUTHENTICATED,
  INVALID_EMAIL,
  GENERIC_ERROR,
  USER_ALREDY_EXISTS,
  TERMS_NOT_ACCEPTED,
  MISSING_PASSWORD,
  WRONG_PASSWORD,
  CREDENTIALS_UPDATED,
  RESET_CREDENTIAL_OPERATION_RESULT,
  USER_TO_VERIFY,
  NOT_VERIFIED,
  UserInfo,
  CREDENTIALS_ERROR,
} from '../actions/types';

export interface AuthenticationStateType {
  logged: boolean
  toVerify: boolean
  user?: UserInfo
  error?: string
}

export interface CredentialsOperationStateType {
  isSuccessful: boolean
  error?: string
}

const tokenStateReducer = (state: string | null = null, action: AuthenticationActions): string | null => {
  switch (action.type) {
    case USER_LOGGED:{
      return action.payload.token
    }
    case NOT_AUTHENTICATED:{
      return null
    }
    default:
      return state
  }
}

const authStateReducer = (state: AuthenticationStateType | null = null, action: AuthenticationActions): AuthenticationStateType | null => {
  switch (action.type) {
    case USER_LOGGED: {
      return {
        ...state,
        logged: true,
        toVerify: false,
        user: {
          id: action.payload.id,
          email: action.payload.email,
          name: action.payload.name,
          surname: action.payload.surname,
          phoneNumber: action.payload.phoneNumber,
          phoneCountry: action.payload.phoneCountry,
          country: action.payload.country,
          company: action.payload.company,
          industry: action.payload.industry,
          jobFunction: action.payload.jobFunction,
          isStaff: action.payload.isStaff
        },
        error: undefined,
      }
    }
    case USER_TO_VERIFY: {
      return {
        ...state,
        logged: false,
        toVerify: true,
        error: undefined,
      }
    }
    case CREDENTIALS_UPDATED: {
      return {
        ...state,
        logged: true,
        user: {
          id: action.payload.id,
          email: action.payload.email,
          name: action.payload.name,
          surname: action.payload.surname,
          phoneNumber: action.payload.phoneNumber,
          phoneCountry: action.payload.phoneCountry,
          country: action.payload.country,
          company: action.payload.company,
          industry: action.payload.industry,
          jobFunction: action.payload.jobFunction,
          isStaff: action.payload.isStaff
        },
        error: undefined,
        toVerify: state !== null ? state.toVerify : false
      }
    }
    case NOT_AUTHENTICATED: {
      return {
        ...state,
        logged: false,
        error: undefined,
        toVerify: state !== null ? state.toVerify : false
      }
    }
    case INVALID_EMAIL: {
      return {
        ...state,
        logged: false,
        error: 'Invalid email provided. Please provide a valid email',
        toVerify: state !== null ? state.toVerify : false
      }
    }
    case MISSING_PASSWORD: {
      return {
        ...state,
        logged: false,
        error: 'Password field can\'t be blank. Please insert a password',
        toVerify: state !== null ? state.toVerify : false,
      }
    }
    case WRONG_PASSWORD: {
      return {
        ...state,
        logged: false,
        error: 'The password inserted is wrong. Please verify your password',
        toVerify: state !== null ? state.toVerify : false
      }
    }
    case USER_ALREDY_EXISTS: {
      return {
        ...state,
        logged: false,
        error: 'A user with this email already exists. Please login or use another email',
        toVerify: state !== null ? state.toVerify : false
      }
    }
    case TERMS_NOT_ACCEPTED: {
      return {
        ...state,
        logged: false,
        error: 'Please accept terms and conditions to continue',
        toVerify: state !== null ? state.toVerify : false
      }
    }
    case NOT_VERIFIED: {
      return {
        ...state,
        logged: false,
        toVerify: true,
        error: 'Before accessing your account you need to verify your email',
      }
    }
    case GENERIC_ERROR: {
      return {
        ...state,
        logged: false,
        error: 'Error during authentication',
        toVerify: state !== null ? state.toVerify : false
      }
    }
    default:
      return state;
  }
}

const credentialsUpdateState = (state: CredentialsOperationStateType | null = null, action: AuthenticationActions) => {
  switch (action.type) {
    case CREDENTIALS_UPDATED: {
      return {
        ...state,
        logged: true,
        isSuccessful: true,
        error: undefined,
      }
    }
    case CREDENTIALS_ERROR: {
      return {
        ...state,
        logged: true,
        isSuccessful: false,
        error: "Couldn't update the credentials"
      }
    }
    case RESET_CREDENTIAL_OPERATION_RESULT: {
      return null;
    }
    default:
      return state;
  }
}

export {
  authStateReducer,
  credentialsUpdateState,
  tokenStateReducer
}
