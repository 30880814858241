import {createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import {authStateReducer, credentialsUpdateState, tokenStateReducer} from './auth/reducers';
import {registerFormReducer} from './forms/reducers';

const rootReducer = combineReducers({
    user: authStateReducer,
    authToken: tokenStateReducer,
    credentialsUpdateOperation: credentialsUpdateState,
    registerForm: registerFormReducer
})

export type RootState = ReturnType<typeof rootReducer>

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;