import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Navbar, NavLogo, NavSecondaryLink, NavUser } from '../../components/navbar';
import { Sidebar } from '../../components/sidebar';
import { RootState } from '../../store';
import { AuthenticationStateType } from '../../store/auth/reducers';

interface ReduxStateProps {
  authState: AuthenticationStateType | null
}

interface OwnProps {
  links: React.ReactElement[]
  children?: React.ReactElement
}

type SidebarLayoutProps = ReduxStateProps & OwnProps;

class SidebarLayout extends Component<SidebarLayoutProps, {}> {

  render() {

    const { id, name, surname, email } = this.props.authState?.user || {}
    return (
      <>
        <header className="fixed w-full px-3 md:px-3 bg-white border-b z-40">
          <Navbar primaryNav className="w-full h-16">
            <NavLogo />
            <div className="flex flex-row h-full items-center justify-end flex-auto space-x-4">
              <NavSecondaryLink href="/console">Console</NavSecondaryLink>
              <NavUser
                fullName={`${(name === undefined || surname === undefined) ? `User${id}` : `${name} ${surname}`}`}
                email={email ?? ''} //Notice: UserLoader ensures that email is not null
              />
            </div>
          </Navbar>
        </header>
        <div className="grid grid-cols-4 lg:grid-cols-5 2xl:grid-cols-10 w-full h-screen pt-16">
          <Sidebar>
            {this.props.links}
          </Sidebar>
          <div className="relative overflow-y-scroll col-span-4 2xl:col-span-8 bg-ghost-white"> {/* THIS WIDTH IS RELATED TO SIDEBAR WIDTH*/}
            {this.props.children}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return ({
    authState: state.user
  })
}

export default connect<ReduxStateProps, {}, OwnProps, RootState>(mapStateToProps)(SidebarLayout);