export type LinkData = {
    href: string;
    title: string;
    style?: LinkStyle;
    icon?: React.ReactNode;
    parentLabel?: string;
}

export enum LinkStyle {
    TEXT,
    OUTLINED,
    BUTTON,
}

export type SubSectionType = {
    title: string,
    content: React.ReactNode,
    ref: React.RefObject<HTMLInputElement>,
  }
  
export type SectionType = {
    title: string,
    content?: React.ReactNode,
    subSections: SubSectionType[],
}

