import { Server } from "./server";

//CONSTS
export enum SubscriptionStatus{
    ACTIVE = "A",
    ERROR = "E",
    INACTIVE = "I",
    BOOTING = "S",
    INSTANTIATING = "D",
    INSTALLING = "P"
}

//OBJECTS
export interface Subscription {
    id: number
    name: string
    date_created: string
    date_start:string
    date_end:string
    autorenew: boolean
    status: SubscriptionStatus
    servers: Server[]
    product: number
}