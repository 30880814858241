import React from 'react';
import { useHistory } from 'react-router-dom';
import { AnimatedButton } from '../components/buttons';

const NotFoundPage: React.FC<{ children?: React.ReactElement }> = (props) => {

  const history = useHistory();

  return (
    <div className="p-8">
      <h1 className="text-6xl font-bold text-cherry-red-400 font-intro">404</h1>
      <h2 className="text-2xl font-semibold">Page not found</h2>
      {props.children}
      <AnimatedButton
        onClick={() => history.goBack()}
        className="px-4 py-2 bg-cherry-red-400 hover:border-cherry-red-250 text-white rounded mt-8"
      >
        Back
      </AnimatedButton>
    </div>
  );
}

export default NotFoundPage;
