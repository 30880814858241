import React, { useContext, useState } from 'react';
import { CustomSpinner } from '../spinner';
import { BlankCard } from './blank_card';

interface TableCardProps {
  className?: string
  children: [React.ReactElement<TableHeaderProps>, React.ReactElement<TableContentProps>]
}

interface TableHeaderProps {
  className?: string
  children: React.ReactElement | (React.ReactElement | undefined)[]
}

interface TableContentProps {
  children: [React.ReactElement<TableColumnsProps>, React.ReactElement<TableRowsProps>]
  className?: string
  loadingItems?: boolean
}

interface TableColumnsProps {
  children: React.ReactElement<ColumnProps> | React.ReactElement<ColumnProps>[]
  className?:string
}

interface ColumnProps {
  children: string
  className?: string
  colClassName?: string
}

interface TableRowsProps {
  children: React.ReactElement<RowProps>[]
  className?: string
}

export interface RowProps {
  className?: string
  children?: React.ReactElement<ElementProps> | React.ReactElement<ElementProps>[]
}

interface ElementProps {
  className?: string
  children: React.ReactNode
}

const ColumnNumberContext = React.createContext<number>(0);
const LoadingItemsContext = React.createContext<boolean>(false);
const EMPTY_TABLE_ROW_KEY = "EMPTY"

/**
 * Allows to create a TableCard (card containing a table)
 */
export const TableCard: React.FC<TableCardProps> = (props) => {
  return (
    <BlankCard wrapContent className={props.className}>
      {props.children}
    </BlankCard>
  );
}

/**
 * Allows to define an header for the table TableCard
 */
export const TableHeader: React.FC<TableHeaderProps> = (props) => {
  return (
    <div className={props.className}>
      {props.children}
    </div>
  );
}

/**
 * Allow to insert the content section (the actual table) of a TableCard
 * 
 * @param children should contain IN ORDER a {@link TableColumns} element and 
 * a {@link TableRows} element
 */
export const TableContent: React.FC<TableContentProps> = (props) => {

  const columns = props.children[0].props.children
  const [columnsNumber,] = useState<number>(Array.isArray(columns) ? columns.length : 1)

  return (
    <ColumnNumberContext.Provider value={columnsNumber}>
      <LoadingItemsContext.Provider value={props.loadingItems !== undefined ? props.loadingItems : false}>
        <table className={`-mx-4 -mb-4 mt-4 ${props.className?props.className:''}`}>
          {props.children}
        </table>
      </LoadingItemsContext.Provider>
    </ColumnNumberContext.Provider>
  );
}

/**
 * Allows to define the columns of the TableCard
 * @param children must contains only {@link Column}s
 */
export const TableColumns: React.FC<TableColumnsProps> = ({children, ...props}) => {

  const columns: React.ReactElement<ColumnProps> | React.ReactElement<ColumnProps>[] = children

  return (
    <>
      <colgroup>
        { 
          Array.isArray(columns) 
          ? columns.map((child, idx)=>(<col key={idx} className={`${child?child.props.colClassName:''}`}></col>))
          : <col className={columns.props.colClassName}></col>
        }
      </colgroup>
      <thead>
        <tr className={`py-1 whitespace-nowrap bg-silver-gray border-gray-200 border-b-2 border-t-2 ${props.className?props.className:''}`}>
          {children}
        </tr>
      </thead>
    </>
  );
}

/**
 * Allows to create a column in a TableCard
 * @param className allows to apply styles (classes) to 
 * the header cell containing column's title
 * @param colClassName allows to apply styles (classes) to the whole column 
 * (uses colgroup and col tags internally)
 * @returns the header cell of the column
 */
export const Column: React.FC<ColumnProps> = ({className, ...props}) => {
  return (
    <th className={`text-left first:pl-4 pr-4 ${className?className:''}`}>{props.children}</th>
  );
}

/**
 * Allows to define the rows of the TableCard
 * @param children must contains only {@link Row}s
 */
export const TableRows: React.FC<TableRowsProps> = (props) => {

  const colSpan = useContext(ColumnNumberContext);
  const isLoadingItems = useContext(LoadingItemsContext);

  return (
    <tbody className={`${props.className?props.className:''}`}>
      {props.children}
      {
        props.children.length === 0 &&
        <tr key={EMPTY_TABLE_ROW_KEY} className="h-12">
          <td colSpan={colSpan}> {/* == NUM OF COLUMNS */}
            {
              isLoadingItems &&
              <div className="flex items-center justify-center">
                <CustomSpinner className="h-4 w-4 text-cherry-red-400" />
              </div>
            }
          </td>
        </tr>
      }
    </tbody>
  );
}

/**
 * Allows to define a row for the TableCard
 * @param children must contains only {@link Row}s
 * @returns a row of the table
 */
export const Row: React.FC<RowProps> = (props) => {
  return (
    <tr className={props.className}>
      {props.children}
    </tr>
  );
}

/**
 * Allows to define an element of the {@link Row}
 * @returns a cell of the Row
 */
export const Element: React.FC<ElementProps> = (props) => {
  return (
    <td className={`first:pl-4 pr-4 ${props.className?props.className:''}`}>{props.children}</td>
  );
}