import { HostingMachine } from './hosting_machine';

export enum ProductType {
    CHERRY_TABLE = "C",
    MONITOR = "M",
    CLIENT = "L"
}

export enum InstallationType {
    SINGLE_SERVER = "S",
    CLUSTER = "C"
}

export interface Product {
    id: number
    name: string
    type: ProductType
    duration: number
    demo: boolean
    server_type: HostingMachine
    cherrytable: CherryTable //FIXME: product can also be a monitor and not only cherrytable
}

export interface CherryTable {
    max_data_size_1kb_records_byte: number
    max_throughput: number
    installation_type: InstallationType
}