import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import LngDetector from 'i18next-browser-languagedetector';

const detectionOptions = {
  order: ['querystring', 'navigator', 'path', 'cookie'],
  lookupQuerystring: 'lng'
}

i18n
.use(Backend)
.use(LngDetector)
.use(initReactI18next)
.init({
    //lng: "en",   //default language
    fallbackLng: "en", //when specified language translations not present then fallbacklang translations loaded.
    debug: true,
    detection: detectionOptions,
    backend: {
      /* translation file path */
      loadPath: "/assets/i18n/{{ns}}/{{lng}}.json"
    },
    ns: ["translations"], /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: false,
      useSuspense: true,
    },
  });

export default i18n;
