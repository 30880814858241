import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { countries } from '../../commons/countries';
import { useTimer } from '../../commons/hooks';
import { AlertType, SimpleAlert } from '../../components/alert';
import { AnimatedButton } from '../../components/buttons';
import { PhoneInput, PhoneNumFieldState } from '../../components/forms';
import { RootState } from '../../store';
import { updateUserInfo, resetCredentialOperationResult } from '../../store/auth/actions';
import { UserInfo } from '../../store/auth/actions/types';
import { AuthenticationStateType, CredentialsOperationStateType } from '../../store/auth/reducers';

interface ReduxStateProps {
  authState: AuthenticationStateType | null
  updateOperation: CredentialsOperationStateType | null
}

interface ReduxDispatchProps {
  updateCredentials: (user: UserInfo) => void
  resetCredentialOperationResult: () => void
}

type AccountProps = ReduxStateProps & ReduxDispatchProps;

const industriesMap = new Map<string, string>([
  ['1', 'Advertising'],
  ['2', 'Education'],
  ['3', 'Energy and utilities'],
  ['4', 'Finance and business services'],
  ['5', 'Gaming'],
  ['6', 'Government'],
  ['7', 'Health care and life sciences'],
  ['8', 'Industrial manufactoring'],
  ['9', 'Media and entertainment'],
  ['10', 'Professional services'],
  ['11', 'Retail'],
  ['12', 'Telco'],
  ['13', 'Technologies'],
  ['14', 'Travel and hospitality'],
  ['15', 'Other'],
])

const jobFunctionsMap = new Map<string, string>([
  ['1', 'IT executive'],
  ['2', 'Architect'],
  ['3', 'DevOps/Technical operations'],
  ['4', 'Business executive'],
  ['5', 'Director/Development manager'],
  ['6', 'Project/Product manager'],
  ['7', 'DBA'],
  ['8', 'Business analyst'],
  ['9', 'Data scientist'],
  ['10', 'Student'],
  ['11', 'Other'],
]);

const AccountPage: React.FC<AccountProps> = ({ authState, updateOperation, resetCredentialOperationResult, ...props }) => {

  //VARIABLES
  const NAME_FIELD: string = "NAME";
  const SURNAME_FIELD: string = "SURNAME";
  const COUNTRY_FIELD: string = "COUNTRY";
  const PHONE_FIELD: string = "PHONE";
  const COMPANY_NAME: string = "COMPANY_NAME";
  const INDUSTRY_FIELD: string = "INDUSTRY";
  const JOB_FUNCTION_FIELD: string = "JOB_FUNCTION";
  const SELECT_DEFAULT_VALUE: string = "Choose one..."; //Default value for select inputs
  const [name, setName] = useState<string>(''); //User's name
  const [surname, setSurname] = useState<string>(''); //User's surname
  const [country, setCountry] = useState<string>(''); //User's country
  const [companyName, setCompanyName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>(''); //User's phone number
  const [phoneCountry, setPhoneCountry] = useState<string>(''); //User's phone number country (for prefix)
  const [industry, setIndustry] = useState<string>(''); //Industry of user's company
  const [jobFunction, setJobFunction] = useState<string>(''); // User's job function
  const [phoneNumState, setPhoneNumErr] = useState(PhoneNumFieldState.EMPTY);
  const timer = useTimer();
  const screenRef = useRef<HTMLDivElement|null>(null);

  //METHODS
  //Handle changes from "normal" INPUT fields in the form for user's account settings.
  const handleFormInputsChange = (event: React.FormEvent<HTMLInputElement>) => {

    const eventValue = event.currentTarget.value;
    switch (event.currentTarget.name) {
      case NAME_FIELD:
        setName(eventValue);
        break;
      case SURNAME_FIELD:
        setSurname(eventValue);
        break;
      case COUNTRY_FIELD:
        setCountry(eventValue);
        break;
      case PHONE_FIELD:
        setPhoneNumber(eventValue);
        break;
      case COMPANY_NAME:
        setCompanyName(eventValue);
        break;
    }
  }

  //Handle changes from SELECT fields in the form for user's account settings.
  const handleFormSelectChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const eventValue = event.currentTarget.value;
    //In case eventValue === SELECT_DEFAULT_VALUE set state to undefined
    switch (event.currentTarget.name) {
      case INDUSTRY_FIELD:
        setIndustry(eventValue !== SELECT_DEFAULT_VALUE ? eventValue : '');
        break;
      case JOB_FUNCTION_FIELD:
        setJobFunction(eventValue !== SELECT_DEFAULT_VALUE ? eventValue : '');
        break;
      case COUNTRY_FIELD:
        setCountry(eventValue !== SELECT_DEFAULT_VALUE ? eventValue : '');
        break;
    }
  }

  const handlePhoneCountryChange = (country: string) => {
    setPhoneCountry(country);
  }

  const onSubmit = () => {
    //scroll to top
    screenRef.current?.scrollIntoView({behavior:'smooth'});
    //update remote
    if (authState?.user) {
      props.updateCredentials({
        id: authState.user.id,
        email: authState.user.email,
        name: name ? name : undefined,
        surname: surname ? surname : undefined,
        country: country ? country : undefined,
        company: companyName ? companyName : undefined,
        phoneNumber: phoneNumState === PhoneNumFieldState.OK ? phoneNumber : undefined, //FIXME: remove condition phone num
        phoneCountry: phoneNumState === PhoneNumFieldState.OK ? phoneCountry : undefined,
        industry: industry ? industry : undefined,
        jobFunction: jobFunction ? jobFunction : undefined,
        isStaff: authState.user.isStaff
      });
    }
  }

  //This hook update local state according to user's state in redux
  useEffect(() => {
    if (authState?.user) {
      setName(authState.user.name ?? '');
      setSurname(authState.user.surname ?? '');
      setCountry(authState.user.country ?? '')
      setCompanyName(authState.user.company ?? '');
      setPhoneNumber(authState.user.phoneNumber ?? '');
      setPhoneCountry(authState.user.phoneCountry ?? '');
      setIndustry(authState.user.industry ?? '');
      setJobFunction(authState.user.jobFunction ?? '');
    }
  }, [authState])

  useEffect(() => {
    if (updateOperation) {
      timer.setTimer(() => resetCredentialOperationResult(), 2000);
    }
  }, [updateOperation, resetCredentialOperationResult, timer])

  useEffect(() => {
    return () => resetCredentialOperationResult();
  }, [resetCredentialOperationResult])

  //COMPONENT
  return (
    <div ref={screenRef} className="p-8 w-116">
      <SimpleAlert showText={updateOperation ? updateOperation.error ?? 'Information updated successfully' : undefined} type={updateOperation?.isSuccessful ? AlertType.SUCCESS : AlertType.ERROR} />
      <div className="h-32 w-32 relative bg-cherry-red-400 rounded-full flex flex-row items-center justify-center">
        <p className="text-white font-semibold text-6xl">{authState?.user?.email.charAt(0).toUpperCase()}</p>
        <div className="absolute h-8 w-8 bottom-1 right-1 border-2 border-silver-gray bg-gunmetal rounded-full"></div>
      </div>
      <form className="w-full mt-8 space-y-4">
        <div className="w-full space-x-3 flex flex-row items-center">
          <div className="flex-1">
            <label className="mb-2 text-gunmetal-350 font-semibold block">Name</label>
            <input value={name} name={NAME_FIELD} onChange={handleFormInputsChange} type="text" className="h-12 w-full outline-none border-2 border-gray-200 focus:border-cherry-red-200 bg-gray-50 focus:bg-cherry-red-50 rounded-lg p-4 text-gunmetal block" />
          </div>
          <div className="flex-1">
            <label className="mb-2 text-gunmetal-350 font-semibold block">Surname</label>
            <input value={surname} name={SURNAME_FIELD} onChange={handleFormInputsChange} type="text" className="h-12 w-full outline-none border-2 border-gray-200 focus:border-cherry-red-200 bg-gray-50 focus:bg-cherry-red-50 rounded-lg p-4 text-gunmetal block" />
          </div>
        </div>
        <div className="w-full space-x-3 flex flex-row items-center">
          <div className="flex-1">
            <label className="mb-2 text-gunmetal-350 font-semibold block">Email</label>
            <input disabled value={authState?.user?.email??''} type="text" className="h-12 w-full outline-none border-2 border-gray-200 focus:border-cherry-red-200 bg-gray-50 focus:bg-cherry-red-50 rounded-lg p-4 text-gunmetal block disabled:bg-gray-100 disabled:cursor-not-allowed"/>
          </div>
          <div className="flex-1">
            <label className="mb-2 text-gunmetal-350 font-semibold block">Company</label>
            <input value={companyName} name={COMPANY_NAME} onChange={handleFormInputsChange} type="text" className="h-12 w-full outline-none border-2 border-gray-200 focus:border-cherry-red-200 bg-gray-50 focus:bg-cherry-red-50 rounded-lg p-4 text-gunmetal block" />
          </div>
        </div>
        <div className="w-full space-x-3 flex flex-row items-center">
          <div className="flex-1">
            <label className="mb-2 text-gunmetal-350 font-semibold block">Country</label>
            <select value={country ?? SELECT_DEFAULT_VALUE} onChange={handleFormSelectChange} name={COUNTRY_FIELD} className="select-input-no-arrow h-12 w-full outline-none border-2 border-gray-200 focus:border-cherry-red-200 bg-gray-50 focus:bg-cherry-red-50 rounded-lg px-4 text-gunmetal block">
              <option>{SELECT_DEFAULT_VALUE}</option>
              {Array.from(countries.entries()).map(country => {
                return (<option value={country[0]} key={country[0]}>{country[1].name}</option>)
              })}
            </select>
          </div>
          <div className="flex-1">
            <label className="mb-2 text-gunmetal-350 font-semibold block">Phone number</label>
            <PhoneInput value={phoneNumber} countryID={phoneCountry} name={PHONE_FIELD} onChange={handleFormInputsChange} onCountryChange={handlePhoneCountryChange} onNumberValidityChange={invalid => setPhoneNumErr(invalid)} />
          </div>
        </div>
        <div className="w-full space-x-3 flex flex-row items-center">
          <div className="flex-1">
            <label className="mb-2 text-gunmetal-350 font-semibold block">Industry</label>
            <select value={industry ?? SELECT_DEFAULT_VALUE} onChange={handleFormSelectChange} name={INDUSTRY_FIELD} className="select-input-no-arrow h-12 w-full outline-none border-2 border-gray-200 focus:border-cherry-red-200 bg-gray-50 focus:bg-cherry-red-50 rounded-lg px-4 text-gunmetal block">
              <option>{SELECT_DEFAULT_VALUE}</option>
              {Array.from(industriesMap.entries()).map(industry => {
                return (<option value={industry[0]} key={industry[0]}>{industry[1]}</option>)
              })}
            </select>
          </div>
          <div className="flex-1">
            <label className="mb-2 text-gunmetal-350 font-semibold block">Job function</label>
            <select value={jobFunction ?? SELECT_DEFAULT_VALUE} onChange={handleFormSelectChange} name={JOB_FUNCTION_FIELD} className="select-input-no-arrow h-12 w-full outline-none border-2 border-gray-200 focus:border-cherry-red-200 bg-gray-50 focus:bg-cherry-red-50 rounded-lg px-4 text-gunmetal block">
              <option>{SELECT_DEFAULT_VALUE}</option>
              {Array.from(jobFunctionsMap.entries()).map((jobFunction) => {
                return (<option value={jobFunction[0]} key={jobFunction[0]}>{jobFunction[1]}</option>)
              })}
            </select>
          </div>
        </div>
      </form>
      <div className="mt-8">
        <AnimatedButton
          className="bg-cherry-red-400 rounded px-4 py-2 text-white font-semibold disabled:bg-gray-400"
          onClick={() => onSubmit()}
          disabled={phoneNumState === PhoneNumFieldState.ERROR}
        >
          Save
        </AnimatedButton>
      </div>
    </div>
  );
}

function mapStateToProps(state: RootState): ReduxStateProps {
  return ({
    authState: state.user,
    updateOperation: state.credentialsUpdateOperation,
  });
}

function mapDispatchToProps(dispatch: any): ReduxDispatchProps {
  return ({
    updateCredentials: (user) => dispatch(updateUserInfo(user)),
    resetCredentialOperationResult: () => dispatch(resetCredentialOperationResult())
  });
}

export default connect<ReduxStateProps, ReduxDispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(AccountPage);