import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import routes from '../../routes'
import { Navbar, NavLogo, NavSecondaryLink, NavUser } from '../../components/navbar';
import { Sidebar, SidebarItem } from '../../components/sidebar';
import { getConsoleSideLinks } from './console_links';
import { RootState } from '../../store';
import { AuthenticationStateType } from '../../store/auth/reducers';

interface ReduxStateProps {
  authState: AuthenticationStateType | null
}

type SettingsLayoutProps = ReduxStateProps;

const RoutesSwitcher: React.FC<{}> = (props) => {
  return (
    <Switch>
      {routes.filter(route => (route.layout === '/settings/')).map(route => {
        return (<Route exact key={route.layout + route.path} path={route.layout + route.path} component={route.page} />);
      })}
      <Redirect exact from="/settings" to="/settings/account" />
      <Redirect from="/settings/*" to="/not_found" />
    </Switch>
  );
}

class SettingsLayout extends Component<SettingsLayoutProps, {}> {



  render() {

    if (this.props.authState?.logged === false) {
      return <Redirect to="/" />
    }

    const { id, name, surname, email } = this.props.authState?.user || {}
    return (
      <>
        <header className="fixed w-full px-3 md:px-3 bg-white border-b z-40">
          <Navbar primaryNav className="w-full h-16">
            <NavLogo />
            <div className="flex flex-row h-full items-center justify-end flex-auto">
              <NavSecondaryLink className="mr-2 sm:mr-4" href="/console">Console</NavSecondaryLink>
              <NavUser
                fullName={`${(name === undefined || surname === undefined) ? `User${id}` : `${name} ${surname}`}`}
                email={email ?? ''} //Notice: UserLoader ensures that email is not null
              />
            </div>
          </Navbar>
        </header>
        <div className="grid grid-cols-4 lg:grid-cols-5 2xl:grid-cols-10 w-full h-screen pt-16">
          <Sidebar>
            {getConsoleSideLinks().map(linkData =>
              <SidebarItem
                key={linkData.href}
                linkData={linkData}
              />
            )}
          </Sidebar>
          <div className="relative overflow-y-scroll col-span-4 2xl:col-span-8 bg-ghost-white"> {/* THIS WIDTH IS RELATED TO SIDEBAR WIDTH*/}
            <RoutesSwitcher />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return ({
    authState: state.user
  })
}

export default connect<ReduxStateProps, {}, {}, RootState>(mapStateToProps)(SettingsLayout);