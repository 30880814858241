import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import useCherryserviceAPI from '../../api/cherryservice/v2';
import { useTimer } from '../../commons/hooks';
import { AlertType, SimpleAlert } from '../../components/alert';
import { AnimatedButton } from '../../components/buttons';
import { BlankCard } from '../../components/cards';
import { MessageModal } from '../../components/modals';
import { RootState } from '../../store';
import { AuthenticationStateType } from '../../store/auth/reducers';

enum ResetState{
  WAITING_USER,
  RESET_OK,
  RESET_ERROR,
}

interface ReduxStateProps{
  authState: AuthenticationStateType | null
}

type ResetPasswordPageProps = ReduxStateProps;

const resetPasswordErrors = new Map<string,string>([

]);

const ResetPasswordPage: React.FC<ResetPasswordPageProps> = (props) => {

  //VARIABLES AND STATES
  const history = useHistory();
  const location = useLocation();
  const NEW_PASSWORD = "NEW_PASSWORD";
  const CONFIRM_PASSWORD = "CONFIRM_PASSWORD";
  const urlParams = new URLSearchParams(location.search);
  const uid = urlParams.get('uid');
  const token = urlParams.get('token');
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [resetState, setResetState] = useState(ResetState.WAITING_USER);
  const [errMessage, setErrMessage] = useState('');
  const timer = useTimer();
  const cherryserviceAPI = useCherryserviceAPI();

  //METHODS

  //This method handles any change registered in form's inputs
  function handleFormChange(e: React.ChangeEvent<HTMLInputElement>) {
    const eventValue = e.currentTarget.value;
    switch (e.currentTarget.name) {
      //case input field for new pass is modified
      case NEW_PASSWORD:
        setNewPass(eventValue);
        break;
      //case input field for confirm pass is modified
      case CONFIRM_PASSWORD:
        setConfirmPass(eventValue);
        break;
    }
  }

  //This method is called when the user submit his/her new password(s)
  function handleResetPasswordSubmit() {
    if (uid && token) {
      cherryserviceAPI?.resetPassword(uid, token, newPass, confirmPass)
        .then(() => {
          setResetState(ResetState.RESET_OK)
        }).catch(err => {
          const errorMess =resetPasswordErrors.get(err.response.data)??'Error: couldn\'t reset the password';
          setErrMessage(errorMess);
          setResetState(ResetState.RESET_ERROR);
          timer.setTimer(()=>setResetState(ResetState.WAITING_USER),2000);
        })
    }
  }

  //This method is called when the user submit the modal that
  //is shown after successfully resetting the password
  function handleSubmitModal(){
    if(props.authState?.logged){
      history.push('/console')
    }else{
      history.push('/auth/signin')
    }
  }

  //COMPONENT
  return (
    <div className="relative h-screen flex justify-center items-center">
      <MessageModal
        title="Success"
        active={resetState===ResetState.RESET_OK}
        mainButtonLabel={props.authState?.logged?"Go to console":"Sign in"}
        submitCallback={handleSubmitModal}
      >
        <p className="mt-4">Password changed successfully</p>
      </MessageModal>
      <SimpleAlert showText={resetState===ResetState.RESET_ERROR?errMessage : undefined} type={AlertType.ERROR} />
      <div className="w-80 sm:w-100 relative">
        <BlankCard wrapContent>
          <div className="mx-4 sm:mx-8 mb-5">
            <h1 onClick={() => history.push("/")} className="text-gunmetal-500 text-3xl mt-3 w-full mx-auto font-header font-bold text-center cursor-pointer">AYRADB</h1>
            <h1 className="text-gunmetal-500 text-2xl font-semibold my-6">Reset password</h1>
            <form className="w-full">
              <div className="w-full space-y-3 mb-12">
                <label className="mb-2 text-gunmetal-350 font-semibold block">New Password</label>
                <input value={newPass} onChange={handleFormChange} name={NEW_PASSWORD} type="password" className="h-12 w-full outline-none border-2 border-gray-200 focus:border-cherry-red-200 bg-gray-50 focus:bg-cherry-red-50 rounded-lg p-4  block" />
                <label className="mb-2 text-gunmetal-350 font-semibold block">Confirm Password</label>
                <input value={confirmPass} onChange={handleFormChange} name={CONFIRM_PASSWORD} type="password" className="h-12 w-full outline-none border-2 border-gray-200 focus:border-cherry-red-200 bg-gray-50 focus:bg-cherry-red-50 rounded-lg p-4  block" />
              </div>
            </form>
            <AnimatedButton onClick={handleResetPasswordSubmit} className="focus:outline-none bg-cherry-red-400 hover:bg-cherry-red-300 rounded-lg font-semibold text-white w-full py-3">Submit</AnimatedButton>
          </div>
        </BlankCard>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState):ReduxStateProps =>{
  return({
    authState: state.user
  });
}

export default connect<ReduxStateProps,{},{},RootState>(mapStateToProps)(ResetPasswordPage);