import routes from '../../routes';

import { LinkData } from '../../commons/types';


export const getConsoleNavLinks = (): LinkData[] => {

  let links: LinkData[] = [];
  routes.filter(link => link).forEach(link => link);
  return links;
}

export const getConsoleSideLinks = (): LinkData[] => {

  let links: LinkData[] = [];
  routes.filter(route => route.layout === '/settings/')
    .forEach(route => {
      if (route.label !== undefined) {
        links.push({
          href: route.layout + route.path,
          title: route.label.title,
          parentLabel: route.label.parentLabel,
          icon: route.label.icon,
        });
      }
    });
  return links;
}
