import React from 'react';

import { BlankCard } from '../../components/cards';
import { WithTranslation, withTranslation } from 'react-i18next';

class CloudNativePage extends React.Component<WithTranslation , {}, { active: number }> {
  
  render() {
    const { t, i18n } = this.props;
    return (
      <>
        <div className="relative py-8">
          <BlankCard className="tw-docs-card">
            <h1>{t("cloud_native.title")}</h1>
            <section>
              <h2>{t("cloud_native.introduction")}</h2>
              <div>
                <p>
                {t("cloud_native.introduction_des_prefix")}
                </p>
                <ul>
                  <li>
                  {t("cloud_native.introduction_des_scalability")}
                  </li>
                  <li>{t("cloud_native.introduction_des_autoscaling")}</li>
                  <li>
                  {t("cloud_native.introduction_des_management")}
                  </li>
                </ul>
                <p>
                {t("cloud_native.introduction_des_suffix")}
                </p>
              </div>
            </section>
            <section>
              <h2>{t("cloud_native.cluster")}</h2>
              <div>
                <p>
                {t("cloud_native.cluster_des")}
                </p>
              </div>
            </section>
          </BlankCard>
        </div>
      </>
    );
  }
}

export default withTranslation()(CloudNativePage);