import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PrimaryFlatButton } from '../buttons';
import { MessageModal } from '../modals';
import { CustomSpinner } from '../spinner';

interface ListItemProps {
  id: string
}

interface NewsItemProps extends ListItemProps {
  title: string
  date: string
}

interface Info {
  id: string
  title: string
  content: string
}

export const ListCardLoadingArticle: React.FC<ListItemProps> = () => {

  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    return () => setFadeOut(true);
  }, [])

  return (
    <>
      <div className={`flex flex-row items-center ${fadeOut ? 'animate-fade-out' : 'animate-pulse'}`}>
        <div className="bg-gray-100 rounded-full w-10 2xs:w-12 h-10 2xs:h-12 flex flex-row items-center justify-center">
        </div>
        <div className="ml-4 h-full">
          <div className="w-64 h-6 rounded bg-gray-100 font-bold font-sub-header " />
          <div className="w-40 h-4 rounded bg-gray-100 font-sub-header mt-1.5" />
        </div>
      </div>
    </>
  );
}

export const ListCardArticle: React.FC<NewsItemProps> = (props) => {
  return (
    <>
      <div className="flex flex-row items-center animate-fade-in">
        <div className="bg-gray-200 rounded-full w-10 2xs:w-12 h-10 2xs:h-12 flex flex-row items-center justify-center">
          {<i className="fas fa-newspaper text-gunmetal-300 text-lg 2xs:text-xl"></i>}
        </div>
        <div className="ml-4 h-full">
          <p className="text-sm 2xs:text-base text-gunmetal-300 font-bold font-sub-header ">{props.title}</p>
          <p className="text-xs 2xs:text-sm text-gray-400 font-sub-header pt-1">{props.date}</p>
        </div>
      </div>
      <div className="hover:bg-gray-50 -mr-2 w-8 2xs:w-10 h-8 2xs:h-10 rounded-full flex items-center justify-center">
        <i className="fas fa-arrow-right text-base 2xs:text-lg text-gunmetal-200"></i>
      </div>
    </>
  );
}

export const ListCardItem: React.FC<{ title: string, value: string | number, icon: React.ReactNode }> = (props) => {

  return (
    <>
      <div className="flex flex-row items-center transition-opacity duration-300">
        <div className="bg-gray-200 rounded-full w-10 h-10 2xs:w-12 2xs:h-12 flex flex-row items-center justify-center">
          {props.icon}
        </div>
        <p className="text-sm 2xs:text-base ml-3 text-gunmetal-300 font-bold font-sub-header pt-1">{props.title}</p>
      </div>
      <p className={`ml-3 text-right text-sm 2xs:text-base text-gunmetal-300 font-sub-header pt-1`}>{props.value}</p>
    </>
  );
}

export const ListCardDateReminder: React.FC<{ title: string, date: Date, daysBeforeAlert?: number, tooltipText: string }> = ({ date, daysBeforeAlert, tooltipText, ...props }) => {

  const [showAlert, setShowAlert] = useState(false);

  //Hook setting date alert if necessary
  useEffect(() => {
    if (daysBeforeAlert !== undefined) {
      const localDate = new Date(date);
      if (localDate.setDate(localDate.getDate() - daysBeforeAlert) <= Date.now()) {
        setShowAlert(true);
      } else {
        setShowAlert(false);
      }
    }
  }, [date, daysBeforeAlert])


  return (
    <>
      <div className="flex flex-row items-center transition-opacity duration-300">
        <div className="relative bg-gray-200 rounded-full w-10 h-10 2xs:w-12 2xs:h-12 flex flex-row items-center justify-center">
          {
            showAlert
              ? <i className="fas fa-calendar-alt text-lg text-gunmetal-200"></i>
              : <i className="fas fa-calendar-alt text-lg text-gunmetal-200"></i>
          }
          {
            showAlert &&
            <>
              <div className="rounded-full h-3 w-3 bg-cherry-red-400 opacity-70 animate-ping absolute top-0.5 right-0.5"></div>
              <div
                className="group rounded-full h-3 w-3 bg-cherry-red-400 absolute top-0.5 right-0.5 flex items-center justify-center cursor-pointer"
              >
                <div className="relative">
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                    width="8px" height="7px" fill="#FFFFFF" viewBox="0 0 401.999 401.998"
                    xmlSpace="preserve">
                    <g>
                      <g>
                        <path d="M237.543,301.499h-73.091c-4.95,0-9.233,1.811-12.851,5.425c-3.615,3.617-5.424,7.897-5.424,12.847v63.953
                          c0,4.948,1.809,9.232,5.424,12.854c3.621,3.61,7.9,5.421,12.851,5.421h73.097c4.942,0,9.227-1.811,12.848-5.421
                          c3.61-3.621,5.42-7.905,5.42-12.854v-63.953c0-4.949-1.813-9.229-5.427-12.847C246.773,303.307,242.488,301.499,237.543,301.499z"
                        />
                        <path d="M259.383,5.424C255.862,1.812,251.628,0,246.676,0h-91.359c-4.948,0-9.18,1.812-12.703,5.424
                          c-3.521,3.617-5.186,7.902-4.996,12.85l7.992,219.265c0.19,4.948,2.139,9.236,5.852,12.847c3.711,3.621,8.041,5.431,12.991,5.431
                          h73.097c4.942,0,9.271-1.81,12.991-5.431c3.71-3.61,5.653-7.898,5.852-12.847l7.987-219.265
                          C264.578,13.326,262.905,9.045,259.383,5.424z"/>
                      </g>
                    </g>
                  </svg>
                  <div className={`absolute hidden group-hover:block group-hover:animate-fade-in-rapid -top-1 left-1/2 transform -translate-y-full`}>
                    <div className="relative bg-gunmetal-400 rounded-md mt-0 w-40 transform -translate-x-4" > {/**/}
                      <p className="px-2 py-1 text-white text-sm font-semibold">{tooltipText}</p>
                    </div>
                    <svg className="relative m-0 text-gunmetal-700 opacity-90 h-3 transform -translate-x-1/2" x="0px" y="0px" viewBox="0 0 255 255"><polygon className="fill-current" points="0,0 127.5,127.5 255,0" /></svg>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
        <p className="text-sm 2xs:text-base ml-3 text-gunmetal-300 font-bold font-sub-header pt-1">{props.title}</p>
      </div>
      <p className={`ml-3 text-right text-sm 2xs:text-base ${showAlert ? 'text-cherry-red-400' : 'text-gunmetal-300'} font-sub-header pt-1`}>{`${date.toLocaleDateString()}`}</p>
    </>
  );
}

export const ListCardNavButton: React.FC<{ title: string, value: string, href: string, disabled?: boolean, loading?: boolean, icon: React.ReactNode, infoModal?: Info, className?: string }> = ({ infoModal, ...props }) => {
  const [clicked, setClicked] = useState(false);
  const [checked, setChecked] = useState(false);
  const [showModal, setShowModal] = useState(true);

  const history = useHistory();

  const handleClick = () => {
    if (!(infoModal && showModal)) {
      redirect();
    }
    setClicked(true);
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    setChecked(event.target.checked);
  }

  function handleCloseModal() {
    setClicked(false);
  }

  const handleContinueClickOnModal = () => {
    redirect();
    setClicked(false);
    if (infoModal && checked) {
      localStorage.setItem(infoModal.id, "true");
    }
  }

  function redirect() {
    if (props.href === '#' || !isExternalURL(props.href)) {
      history.push(props.href);
    } else {
      window.open(props.href, "_blank");
    }
  }

  function isExternalURL(urlString: string) {
    try {
      //Check if the url's host matches ayraDB service's host (External vs Internal url)
      return new URL(urlString).host !== (window.location.host);
    } catch (error) {
      //This case contains partial urls
      //When url is partial (without host) it is an internal url
      return false;
    }
  }

  useEffect(() => {
    if (infoModal && showModal) {
      const show = localStorage.getItem(infoModal.id);
      if (show !== null) {
        setShowModal(false);
      }
    }
  }, [infoModal, clicked, showModal])

  return (
    <>
      <div className="flex flex-row items-center">
        <div className="bg-gray-200 rounded-full w-10 h-10 2xs:w-12 2xs:h-12 flex flex-row items-center justify-center">
          {props.icon}
        </div>
        <p className="text-sm 2xs:text-base ml-3 text-gunmetal-300 font-bold font-sub-header pt-1">{props.title}</p>
      </div>
      <PrimaryFlatButton disabled={props.disabled} onClick={handleClick} className={`py-2 px-3 2xs:px-4 ${props.className}`}>
        {
          !props.loading
            ? props.value
            : <div className="flex space-x-2 justify-center items-center w-12 py-0.5">
              <CustomSpinner className="h-5.5 w-5.5 text-gray-600" />
            </div>
        }
      </PrimaryFlatButton>
      {
        (infoModal) &&
        <MessageModal
          title={infoModal.title}
          active={infoModal && showModal && clicked}
          mainButtonLabel="Continue"
          submitCallback={() => handleContinueClickOnModal()}
          closeCallback={handleCloseModal}
        >
          <p className="pt-2">{infoModal.content}</p>
          <div className="mt-4 flex flex-row items-center">
            <input onChange={handleCheckboxChange} name="terms" type="checkbox" className={`form-checkbox text-cherry-red-400 rounded border  ${checked ? `checked:border-white ring-1 ring-cherry-red-400` : `border-gray-300 focus:border-gray-300`}`} />
            <label className="pl-2">Don't show me again</label>
          </div>
        </MessageModal>
      }
    </>
  );
}

export const ListCardButton: React.FC<{ title: string, value: string, callback?: () => void, icon: React.ReactNode, disabled?: boolean, loading?: boolean, className?: string }> = (props) => {

  function handleClick() {
    if (props.callback) {
      props.callback()
    }
  }

  return (
    <>
      <div className="flex flex-row items-center">
        <div className="bg-gray-200 rounded-full w-10 h-10 2xs:w-12 2xs:h-12 flex flex-row items-center justify-center">
          {props.icon}
        </div>
        <p className="text-sm 2xs:text-base ml-3 text-gunmetal-300 font-bold font-sub-header pt-1">{props.title}</p>
      </div>
      <PrimaryFlatButton disabled={props.disabled} onClick={handleClick} className={`pt-2 pb-2 px-3 2xs:px-4 ${props.className}`}>
        {
          !props.loading
            ? props.value
            : <div className="flex space-x-2 justify-center items-center w-12 py-0.5">
              <CustomSpinner className="h-5.5 w-5.5 text-gray-600" />
            </div>
        }
      </PrimaryFlatButton>
    </>
  );
}

export const InnerListCard: React.FC<{ autoSize: boolean, header: string, children?: React.ReactElement[] }> = (props) => {

  return (
    <div className={`py-4 ${props.autoSize && 'h-full'} flex flex-col bg-white shadow-md rounded-xl`}>
      <div className="w-full">
        <h2 className="text-lg 2xs:text-xl font-header text-gunmetal-300 font-bold px-4 pb-1.5">{props.header}</h2>
      </div>
      <div className={`flex flex-col justify-between px-4 flex-auto mt-3 space-y-2`}>
        {props.children?.map((item, index) =>
          <div key={item.props.title} className={`flex flex-row items-center justify-between`}>
            {item}
          </div>
        )}
      </div>
    </div>
  );
}

export const ScrollableListCard: React.FC<{ header: string, children?: React.ReactElement<ListItemProps>[] }> = (props) => {
  return (
    <div className={`card h-full py-3 2xs:py-4 flex flex-col bg-white shadow-md rounded-xl`}>
      <div className="w-full">
        <h2 className="text-lg 2xs:text-xl font-header text-gunmetal-300 font-bold px-3 2xs:px-4 pb-1.5">{props.header}</h2>
      </div>
      <div className={`overflow-hidden overflow-y-scroll overscroll flex flex-col px-3 2xs:px-4 flex-auto mt-3`}>
        {props.children?.map((item, index) => {
          return (
            <div key={item.props.id} className={`${index > 0 && 'pt-1.5'} ${props.children && index < props.children.length - 1 && 'pb-1.5'} flex flex-row items-center justify-between`}>
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
}