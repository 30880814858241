import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import useCherryserviceAPI from '../../api/cherryservice/v2';
import { RootState } from '../../store';
import { AuthenticationStateType } from '../../store/auth/reducers';
import { InteractiveModal, MessageModal } from '../../components/modals';
import {
  GridCard,
  GridCardColumn,
  ListCardItem,
  InnerListCard,
  ScrollableListCard,
  ListCardArticle,
  ElementCard,
  ListCardNavButton,
} from '../../components/cards';
import { ListCardLoadingArticle } from '../../components/cards/list_cards';
import { Spinner } from '../../components/spinner';
import { ProductType } from '../../api/cherryservice/v2/types';
import { useDebugLogger } from '../../commons/hooks';

interface Project {
  id: number,
  name: string,
  subscriptions: Subscription[]
}

interface News {
  title: string
  subtitle?: string
  published: Date
}

interface Subscription {
  id: number
}

interface ReduxStateProps {
  authState: AuthenticationStateType | null
}

type ConsoleHomeProps = ReduxStateProps;

const NewsCard: React.FC<{}> = () => {

  const [news, setNews] = useState<News[]>(undefined!)
  const cherryserviceAPI = useCherryserviceAPI()
  const logger = useDebugLogger()

  function computeTimeFromPublish(date: Date) {
    const today = new Date();
    if (today.getFullYear() !== date.getFullYear()) {
      const lastedYears = today.getFullYear() - date.getFullYear();
      return (`${lastedYears} ${lastedYears > 1 ? 'years' : 'year'} ago`)
    } else if (today.getMonth() !== date.getMonth()) {
      const lastedMonths = today.getMonth() - date.getMonth();
      return (`${lastedMonths} ${lastedMonths > 1 ? 'months' : 'month'} ago`)
    } else if (today.getDate() !== date.getDate()) {
      const lastedDays = today.getDate() - date.getDate();
      return (`${lastedDays} ${lastedDays > 1 ? 'days' : 'day'} ago`)
    } else if (today.getHours() !== date.getHours()) {
      const lastedHours = today.getHours() - date.getHours();
      return (`${lastedHours} ${lastedHours > 1 ? 'hours' : 'hour'} ago`)
    } else if (today.getMinutes() !== date.getMinutes()) {
      const lastedMinutes = today.getMinutes() - date.getMinutes();
      return (`${lastedMinutes} ${lastedMinutes > 1 ? 'minutes' : 'minute'} ago`)
    } else {
      return "now";
    }
  }

  useEffect(() => {
    cherryserviceAPI?.getNews().then(res => {
      setNews(res.data.map<News>(article => {
        return ({
          title: article.title,
          subtitle: article.subtitle,
          published: new Date(article.date_created)
        });
      }))
    }).catch(err => {
      if (err.response) {
        logger.log(err.response)
      }
    });
  }, [cherryserviceAPI, logger])

  return (
    <ScrollableListCard
      header="Latest releases"
    >
      {
        news
          ? news.map(article => {
            return (
              <ListCardArticle
                key={article.published.toDateString() + article.title}
                id={article.published.toDateString() + article.title}
                title={article.title}
                date={computeTimeFromPublish(article.published)}
              />
            );
          }) : ([
            <ListCardLoadingArticle key={"1"} id={"1"} />,
            <ListCardLoadingArticle key={"2"} id={"2"} />
          ])
      }
    </ScrollableListCard>
  );
}

const ProjectCard: React.FC<{ projects: number; onButtonPressCallback: () => void }> = ({ projects, ...props }) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
  }

  const handleMouseUpOrLeave = () => {
    setClicked(false);
  }

  const handleDragEnd = () => {
    setClicked(false);
  }

  return (
    <ElementCard
      iconBgColor="bg-white"
      bgColor="bg-gradient-to-tr from-safety-orange-blaze via-red-crayola to-dark-magenta"
      icon={<svg className="w-9 2xs:w-11 xl:w-12" viewBox="0 0 18 18" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M10.557 11.99l-1.71-2.966 1.71-3.015h3.42l1.71 3.01-1.71 2.964h-3.42z M4.023 16l-1.71-2.966 1.71-3.015h3.42l1.71 3.01L7.443 16h-3.42zm0-8.016l-1.71-2.966 1.71-3.015h3.42l1.71 3.015-1.71 2.966h-3.42z" fill="#8A2387" ></path><path d="M4.023 16l-1.71-2.966 1.71-3.015h3.42l1.71 3.01L7.443 16h-3.42zm0-8.016l-1.71-2.966" fill="#F27121"></path><path d="M4.023 16l-1.71-2.966 1.71 3.01L7.443 16h-3.42zm0-8.016l-1.71-2.966 1.71-3.015h3.42l1.71 3.015-1.71 2.966h-3.42z" fill="#E94057"></path></svg>}
      fillHeight={true}
    >
      <div>
        <h2 className="text-lg 2xs:text-xl font-header text-gray-50 font-bold">{'Projects'}</h2>
        <h1 className="text-4xl 2xs:text-5xl font-header text-white font-bold pt-2">{projects}</h1>
        <button onClick={props.onButtonPressCallback} onMouseLeave={handleMouseUpOrLeave} onMouseUp={handleMouseUpOrLeave} onMouseDown={handleClick} onDragEnd={handleDragEnd} className={`${clicked && 'pressed'} animated-text-button cursor-pointer absolute px-1.5 2xs:px-2 py-1 right-1.5 2xs:right-2 bottom-1.5 2xs:bottom-2 hover:bg-white hover:bg-opacity-30 focus:outline-none rounded-md 2xs:rounded-lg`}>
          <p className="text-xs 2xs:text-sm text-white font-bold">ADD PROJECT</p>
        </button>
      </div>
    </ElementCard>
  );
}

const StatusCard: React.FC<{}> = (props) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
  }

  const handleMouseUpOrLeave = () => {
    setClicked(false);
  }

  const handleDragEnd = () => {
    setClicked(false);
  }


  return (
    <div className="h-full bg-green-500 rounded-xl overflow-hidden shadow-md">
      <div className="relative bg-white p-3 2xs:p-4 rounded-b-xl" style={{ height: '96%' }}>
        <div>
          <h2 className="text-lg 2xs:text-xl font-header text-gunmetal-200 font-bold">Status</h2>
          <h1 className="text-sm 2xs:text-base font-header text-gunmetal-500 pt-2">All services available</h1>
        </div>
        <NavLink to="#" onMouseLeave={handleMouseUpOrLeave} onMouseUp={handleMouseUpOrLeave} onMouseDown={handleClick} onDragEnd={handleDragEnd} className={`${clicked && 'pressed'} animated-text-button cursor-pointer absolute px-1.5 2xs:px-2 py-1 right-1.5 2xs:right-2 bottom-1.5 2xs:bottom-2 hover:bg-cherry-red-300 hover:bg-opacity-40 rounded-md 2xs:rounded-lg`}>
          <p className="text-xs 2xs:text-sm text-cherry-red-400 font-bold">SEE DETAILS</p>
        </NavLink>
      </div>
    </div>
  );
}

const ConsoleHome: React.FC<ConsoleHomeProps> = (props) => {

  const [projects, setProjects] = useState<Project[] | null>(null);
  const [modalIsActive, setModalIsActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const cherryserviceAPI = useCherryserviceAPI()
  const logger = useDebugLogger()

  const history = useHistory();

  function handleCreateClick(projectName: string) {
    cherryserviceAPI?.newProject(projectName)
      .then(res => {
        const projectID = res.data.id;
        history.push(`/console/projects/project/${projectID}`);
      }).catch(err => {
        if (err.response) {
          //hide new project modal
          setModalIsActive(false);
          //show error message
          if (err.response.data[0] === "Project limit reached for user") {
            setErrorMessage("Project limit reached for this account")
          } else {
            setErrorMessage(err.response.data[0])
          }
        }
      });
    //TODO: add error display (name can be set in parallel and throw errors)
  }

  function handleErrorOKClick() {
    setErrorMessage(null);
  }

  function enableSubmit(projectName: string): string | null {
    if (projectName === '') {
      return '';
    } else if (projects?.map(project => project.name).includes(projectName)) {
      return 'A project with this name already exists'
    }
    return null
  }



  useEffect(() => {
    async function getProjectsData() {
      if (cherryserviceAPI) {
        //get products to understand subscription types
        const productTypesMap = new Map<number, string>();
        try {
          const prodRes = await cherryserviceAPI.getProducts()
          prodRes.data.forEach(prod => productTypesMap.set(prod.id, prod.type))
          //get all projects
          const projectsRes = await cherryserviceAPI.getAllProjects()
          let projectsData: Project[] = []
          let promises: Promise<void>[] = [];
          //for each project
          projectsRes.data.forEach(project => {
            //map response structure to local structure
            let projectData: Project = {
              id: project.id,
              name: project.name,
              subscriptions: []
            }
            //add project to projects lists
            projectsData.push(projectData);
            //retrieve project's subscription
            promises.push(cherryserviceAPI.getSubscriptionsByProject(project.id).then(res2 => {
              //map response structure to local structure
              res2.data.filter(sub => productTypesMap.get(sub.product) === ProductType.CHERRY_TABLE).forEach(sub => { //FIXME: get products and filters all products of type monitor
                projectData.subscriptions.push({
                  id: sub.id
                })
              })
            }))
          });
          //wait all subscriptions to be retrieved
          await Promise.all(promises)
          setProjects(projectsData)

        } catch (error: any) {
          if (error.response) logger.log(error)
        }
      }
    }

    getProjectsData();
  }, [cherryserviceAPI, logger]);

  if (projects === null) {
    return (
      <div className="w-full h-full pt-8 px-8 flex flex-col items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className={`w-full h-full ${(modalIsActive || (errorMessage !== null)) && 'overflow-hidden'}`}>
      <InteractiveModal
        windowClassName={`absolute w-full h-full z-40`}
        title="New project"
        inputLabel="Name"
        mainButtonLabel="Create"
        active={modalIsActive}
        enableSubmitCallback={enableSubmit}
        backCallback={() => setModalIsActive(false)}
        submitCallback={(input) => handleCreateClick(input)}
      />
      <MessageModal
        title="Error"
        active={errorMessage !== null}
        mainButtonLabel="OK"
        submitCallback={handleErrorOKClick}
      >
        <p className="pt-2">{errorMessage}</p>
      </MessageModal>
      <div className="p-4 2xs:p-6 md:p-8">
        <div className="w-full grid grid-cols-2 sm:grid-cols-8 md:grid-cols-10 grid-rows-4 xs:grid-rows-3 sm:grid-rows-2 xl:h-80 gap-4 2xs:gap-6 md:gap-8">
          <div className="col-span-2 sm:col-span-5 md:col-span-6 row-span-2">
            <InnerListCard
              header="Your account"
              autoSize={true}
            >
              <ListCardItem
                title="User"
                value={props.authState?.user?.email ?? ''}
                icon={<i className="fas fa-user text-lg 2xs:text-xl text-gunmetal-200"></i>}
              />
              <ListCardItem
                title="Next bill"
                value="$0"
                icon={<i className="fas fa-file-invoice text-lg 2xs:text-xl text-gunmetal-200"></i>}
              />
              <ListCardItem
                title="Payment date"
                value="-"
                icon={<i className="fas fa-credit-card text-md 2xs:text-lg text-gunmetal-200"></i>}
              />
              <ListCardNavButton
                title="Customer service"
                value="Open"
                href="#"
                icon={<i className="fas fa-headset text-lg 2xs:text-xl text-gunmetal-200"></i>}
              />
            </InnerListCard>
          </div>

          <div className="col-span-2 xs:col-span-1 sm:col-span-3 md:col-span-4 row-span-1">
            <ProjectCard
              projects={projects.length}
              onButtonPressCallback={() => setModalIsActive(true)}
            />
          </div>

          <div className="col-span-2 xs:col-span-1 sm:col-span-3 md:col-span-4 row-span-1">
            <StatusCard />
          </div>

        </div>
        <div className="pt-4 2xs:pt-6 md:pt-8 pb-4 2xs:pb-6 md:pb-8 w-full flex flex-col sm:flex-row space-y-4 2xs:space-y-6 sm:space-y-0 sm:space-x-6">
          <div className="w-full md:w-1/2">
            <GridCard
              cols={4}
              data={
                projects.map(project => {
                  return {
                    project: project.name,
                    'number of subscriptions': project.subscriptions.length
                  }
                })
              }
              header="Projects"
            >
              <GridCardColumn id={true} key="project" label="Project" colSpan={2} />
              <GridCardColumn key="subs" label="Number of subscriptions" colSpan={2} />
            </GridCard>
          </div>
          <div className="w-full h-44 2xs:h-48 md:w-1/2">
            <NewsCard />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return ({
    authState: state.user
  });
}

export default connect<ReduxStateProps, {}, {}, RootState>(mapStateToProps)(ConsoleHome);