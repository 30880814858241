export interface Invitation{
    id: number
    email: string
    status: InvitationStatus
    date_updated: string
    date_created: string
}

export enum InvitationStatus{
    PENDING = 'P',
    REGISTERED = 'R',
    EXPIRED = 'E'
}