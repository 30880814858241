import { FC } from 'react';
import { NavLink } from 'react-router-dom';

const NavLogo: FC<{}> = () => {
  return (
    <div className="pr-8">
      <NavLink to="/" className="text-lg sm:text-2xl font-header tracking-tighter font-bold text-gunmetal-450 select-none">AYRADB</NavLink>
    </div>
  );
}

export default NavLogo