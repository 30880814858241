import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {connect} from 'react-redux';

import { BlankCard } from '../cards';
import { AnimatedButton } from '../buttons';
import {
  updateEmail,
  updatePassword,
  updateConfirmPassword,
  resetFormState
} from '../../store/forms/actions';
import { RegisterFormState } from '../../store/forms/reducers';
import { RootState } from '../../store';
import { PasswordInput } from './inputs';

interface OwnProps {
  onCheckboxChange?: (checked: boolean) => void
  onSubmit: () => void
  onLoginClick: () => void
}

interface ReduxStateProps {
  formState: RegisterFormState
}

interface ReduxDispatchProps {
  updateEmail:(email:string)=>void
  updatePassword:(password: string)=> void
  updateConfirmPassword: (confirmPasswod: string) => void
  resetFormState: ()=>void
}

type SignInFormProps = OwnProps&ReduxDispatchProps&ReduxStateProps

const RegisterForm: React.FC<SignInFormProps> = ({onSubmit: handleSubmit, onLoginClick: handleLoginClick, onCheckboxChange,...props }) => {

  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [show,setShow] = useState(false);
  const emailFieldName = "email";
  const passwordFieldName = "password";
  const confirmPassFieldName = "confirm";

  const handleFormChange = (event: React.FormEvent<HTMLInputElement>) => {
    const eventValue = event.currentTarget.value;
    if (event.currentTarget.name === emailFieldName) {
      props.updateEmail(eventValue);
    } else if (event.currentTarget.name === passwordFieldName) {
      props.updatePassword(eventValue);
    } else if (event.currentTarget.name === confirmPassFieldName) {
      props.updateConfirmPassword(eventValue);
    }
  }

  function handleShowChange(show: boolean){
    setShow(show)
  }

  function handleCloseClick(){
    //Reset form state on close
    props.resetFormState();
    //Bring back the user to the home page
    history.push("/")
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    if (onCheckboxChange) {
      onCheckboxChange(isChecked);
    }
  }

  //This hook checks triggers submit callback if ENTER is pressed
  useEffect(()=>{

    function verifyEnterPressed(ev: KeyboardEvent){
      if(ev.key === 'Enter'){
        handleSubmit()
      }
    }

    window.addEventListener('keypress',verifyEnterPressed);
    return ()=>window.removeEventListener('keypress', verifyEnterPressed);
  },[handleSubmit])

  return (
    <div className="w-80 sm:w-116 relative">
      <button onClick={handleCloseClick} className="absolute w-8 h-8 bg-white hover:bg-cherry-red-50 border border-gray-100 rounded-full -right-4 -top-4 focus:outline-none">
        <i className="fas fa-times text-cherry-red-400"></i>
      </button>
      <BlankCard wrapContent>
        <div className="mx-4 sm:mx-8 mb-5">
          <h1 onClick={() => history.push("/")} className="text-gunmetal-500 text-3xl mt-3 w-full mx-auto font-header font-bold text-center cursor-pointer">AYRADB</h1>
          <h3 className="text-gunmetal-200 font-semibold my-6">Already have an account? <span onClick={() => handleLoginClick()} className="ml-1 cursor-pointer text-cherry-red-500 hover:text-cherry-red-250">Login</span></h3>
          <h1 className="text-gunmetal-500 text-2xl font-semibold mb-8">Register</h1>
          <form className="w-full mb-6">
            <div className="w-full space-y-3 ">
              <label className="mb-2 text-gunmetal-350 font-semibold block">Email</label>
              <input value={props.formState.email} name={emailFieldName} onChange={e => handleFormChange(e)} type="email" className="h-12 w-full outline-none border-2 border-gray-200 focus:border-cherry-red-200 bg-gray-50 focus:bg-cherry-red-50 rounded-lg p-4  block" />
              <div className="flex flex-row space-x-4">
                <div className="flex-1">
                  <label className="mb-2 text-gunmetal-350 font-semibold block">Password</label>
                  <PasswordInput show={show} onShowChange={handleShowChange} value={props.formState.password} name={passwordFieldName} onChange={e => handleFormChange(e)} className="h-12 w-full outline-none border-2 border-gray-200 focus:border-cherry-red-200 bg-gray-50 focus:bg-cherry-red-50 rounded-lg p-4  block" />
                </div>
                <div className="flex-1">
                  <label className="mb-2 text-gunmetal-350 font-semibold block">Confirm Password</label>
                  <PasswordInput show={show} value={props.formState.confirmPassword} name={confirmPassFieldName} onChange={e => handleFormChange(e)} onShowChange={handleShowChange} className="h-12 w-full outline-none border-2 border-gray-200 focus:border-cherry-red-200 bg-gray-50 focus:bg-cherry-red-50 rounded-lg p-4  block" />
                </div>
              </div>
            </div>
            <div className="mt-6 flex flex-row items-center space-x-3">
              <input onChange={handleCheckboxChange} name="terms" type="checkbox" className={`form-checkbox text-cherry-red-400 rounded border  ${checked ? `checked:border-white ring-1 ring-cherry-red-400` : `border-gray-300 focus:border-gray-300`}`} />
              <label>I agree with <span onClick={() => history.push("/terms_and_conditions")} className="text-cherry-red-400 hover:text-cherry-red-200 cursor-pointer">Terms and Conditions</span></label>
            </div>
          </form>

          <AnimatedButton onClick={() => handleSubmit()} className="focus:outline-none bg-cherry-red-400 hover:bg-cherry-red-300 rounded-lg font-semibold text-white w-full py-3">Register</AnimatedButton>
        </div>
      </BlankCard>
    </div>
  );
}


const mapStateToProps = (state: RootState): ReduxStateProps => {
  return ({
    formState: state.registerForm
  });
}

const mapDispatchToProps = (dispatch: any): ReduxDispatchProps => {
  return ({
    updateEmail: (email)=> dispatch(updateEmail(email)),
    updatePassword: (password) => dispatch(updatePassword(password)),
    updateConfirmPassword: (confirmPass)=> dispatch(updateConfirmPassword(confirmPass)),
    resetFormState: ()=> dispatch(resetFormState())
  })
}

export default connect<ReduxStateProps,ReduxDispatchProps,OwnProps,RootState>(mapStateToProps,mapDispatchToProps)(RegisterForm);
