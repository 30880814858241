import React from 'react';

import LearnLayout from '../../layouts/learn_layout';
import { BlankCard } from '../../components/cards';
import { WithTranslation, withTranslation } from 'react-i18next';

class ConfigurationPage extends React.Component<WithTranslation, {}, { active: number }> {
  constructor(props: any) {
    super(props);
    this.activeSectionCallback = this.activeSectionCallback.bind(this);
    this.state = {
      active: 0,
    }
  }

  componentDidMount() {
    window.scrollTo(0, window.scrollY - 1);
  }

  activeSectionCallback(index: number) {
    //COMMENTATO PERCHE' DA PROBLEMI
/*     if (index !== this.state.active) {
      this.setState({ active: index });
    } */
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <LearnLayout>
        <div className="relative py-8">
          <BlankCard className="tw-docs-card">
            <h1>{t("learn.configuration_title")}</h1>
            <section>
              <h2>{t("learn.configuration_introduction")}</h2>
              <div>
                <p>
                {t("learn.configuration_introduction_des_1")}
                </p>
                <ul>
                  <li>{t("learn.configuration_introduction_des_1_step_1")}</li>
                  <li>{t("learn.configuration_introduction_des_1_step_2")}</li>
                  <li>{t("learn.configuration_introduction_des_1_step_3")}</li>
                </ul>
                <p>
                {t("learn.configuration_introduction_des_2")}
                </p>
              </div>
            </section>
            <section>
              <h2>{t("learn.configuration_naming")}</h2>
              <div>
                <p>
                {t("learn.configuration_naming_des_1")}
                </p>
                <ul>
                  <li>{t("learn.configuration_naming_des_1_step_1")}</li>
                  <li>{t("learn.configuration_naming_des_1_step_2")}</li>
                  <li>{t("learn.configuration_naming_des_1_step_3")}</li>
                  <li>{t("learn.configuration_naming_des_1_step_4")}</li>
                  <li>{t("learn.configuration_naming_des_1_step_5")}</li>
                  <li>{t("learn.configuration_naming_des_1_step_6")}</li>
                </ul>
                <p>
                {t("learn.configuration_naming_des_2")}
                </p>
              </div>
            </section>
            <section>
              <h2>{t("learn.configuration_disk")}</h2>
              <div>
                <p>
                  {t("learn.configuration_disk_des")} <br />
                  {'T < (NxDxC)/R'}
                </p>
              </div>
            </section>
            <section>
              <h2>{t("learn.configuration_ram")}</h2>
              <div>
                <p>
                {t("learn.configuration_ram_des_1")} {'NxMx10ˆ9 < 90x(Tx10ˆ9xR)/L'} <br />
                {t("learn.configuration_ram_des_2")} {'T < (NxMxL)/(90xR)'}
                </p>
              </div>
            </section>
            <section>
              <h2>{t("learn.configuration_sizing_rule")}</h2>
              <div>
                <p>
                {t("learn.configuration_sizing_rule_des")} {'T < min{(NxDxC)/R, (NxMxL)/(90xR)}'}
                </p>
              </div>
            </section>
            <section>
              <h2>{t("learn.configuration_sizing")}</h2>
              <div>
                <p>
                {t("learn.configuration_sizing_des")}
                </p>
              </div>
            </section>
          </BlankCard>
        </div>
      </LearnLayout>
    );
  }
}

export default withTranslation()(ConfigurationPage);