import React, { useState } from 'react';
import { CodeFragmentProps } from './code_fragment';

const CodeFragmentsWrapper: React.FC<{ children?: React.ReactElement<CodeFragmentProps> | React.ReactElement<CodeFragmentProps>[] }> = ({ children }) => {

  const [active, setActive] = useState(0);

  if (!children) {
    return (
      <div></div>
    );
  }

  if (!Array.isArray(children)) {
    return (
      <div className="overflow-hidden rounded-xl border border-gray-300">
        {children}
      </div>
    );
  }

  const elements: React.ReactElement<CodeFragmentProps>[] = children as React.ReactElement<CodeFragmentProps>[];

  return (
    <div className="overflow-hidden rounded-xl border border-gray-300">
      <div className="flex flex-row items-end space-x-2 px-2">
        {elements.map((el, i) => {
          return (
            <div onClick={() => setActive(i)} key={new Date().toISOString() + i} className={`${active !== i ? 'cursor-pointer' : ''} group select-none`}>
              <p className={`${active === i ? 'text-cherry-red-400' : 'text-gunmetal-200'} font-semibold px-2 py-3 transition-colors`}>{el.props.label}</p>
              <div className={`${active === i ? 'bg-cherry-red-400' : 'group-hover:bg-gunmetal-200'} rounded-t-md mx-2 h-3px transition-color`}></div>
            </div>
          );
        })}
      </div>
      <div className="h-px bg-gray-300"></div>
      {elements[active]}
    </div>
  );
}

export default CodeFragmentsWrapper