export const USER_LOGGED = 'USER_LOGGED';
export const USER_TO_VERIFY = 'VERIFY_USER_AFTER_REGISTER';
export const NOT_AUTHENTICATED = 'NOT_AUTHENTICATED'; //there isn't an authenticated user since there isn't a locally stored token
export const INVALID_EMAIL = 'INVALID_EMAIL';
export const USER_ALREDY_EXISTS = 'USER_ALREDY_EXISTS';
export const GENERIC_ERROR = 'GENERIC_ERROR';
export const NOT_VERIFIED = 'VERIFY_USER_LOGIN_ERROR';
export const TERMS_NOT_ACCEPTED = 'TERMS_NOT_ACCEPTED';
export const MISSING_PASSWORD = 'MISSING_PASSWORD';
export const WRONG_PASSWORD = 'WRONG_PASSWORD';
export const NAME_MISSING = 'NAME_MISSING';
export const SURNAME_MISSING = 'SURNAME_MISSING';
export const CREDENTIALS_UPDATED = 'CREDENTIALS_UPDATED';
export const CREDENTIALS_ERROR = 'CREDENTIALS_ERROR';
export const RESET_CREDENTIAL_OPERATION_RESULT = 'RESET_CRED_OP_RES';

export type EmailErrors = typeof INVALID_EMAIL | typeof USER_ALREDY_EXISTS
export type PasswordErrors = typeof MISSING_PASSWORD | typeof WRONG_PASSWORD
export type GenericErrors = typeof NOT_VERIFIED;

export interface AuthenticationErrorMessage {
    email: string[]
    password: string[]
    non_field_errors: string[]
}

//UserData
export interface UserInfo {
    id: number
    email: string
    name?: string
    surname?: string
    phoneNumber?: string
    country?: string
    phoneCountry?: string
    company?: string
    industry?: string
    jobFunction?: string
    isStaff?: boolean
}

export interface RegistrationUserInfo {
    email: string
    password: string
    confirmPassword: string
    conditionAccepted: boolean
    temporaryToken?: string
}

interface UserAuthenticatedAction {
    type: typeof USER_LOGGED
    payload: UserInfo & { token: string }
}

interface VerifyUserAction {
    type: typeof USER_TO_VERIFY
}

interface UserNotAuthenticatedAction {
    type: typeof NOT_AUTHENTICATED;
}

interface InvalidEmailProvidedAction {
    type: typeof INVALID_EMAIL
}

interface UserAlredyExistsAction {
    type: typeof USER_ALREDY_EXISTS
}

interface PasswordMissingAction {
    type: typeof MISSING_PASSWORD
}

interface WrongPasswordAction {
    type: typeof WRONG_PASSWORD
}

interface TermsNotAcceptedAction {
    type: typeof TERMS_NOT_ACCEPTED
}

interface UserNotVerifiedAction {
    type: typeof NOT_VERIFIED
}

interface CredentialsUpdatedAction {
    type: typeof CREDENTIALS_UPDATED;
    payload: UserInfo
}

interface CredentialsErrorAction {
    type: typeof CREDENTIALS_ERROR;
}

interface ResetCredentialOperationResult {
    type: typeof RESET_CREDENTIAL_OPERATION_RESULT
}

interface GenericErrorAction {
    type: typeof GENERIC_ERROR
}

export type AuthenticationActions =
    UserAuthenticatedAction |
    VerifyUserAction |
    UserNotAuthenticatedAction |
    InvalidEmailProvidedAction |
    GenericErrorAction |
    UserAlredyExistsAction |
    TermsNotAcceptedAction |
    PasswordMissingAction |
    WrongPasswordAction |
    UserNotVerifiedAction |
    CredentialsUpdatedAction |
    CredentialsErrorAction |
    ResetCredentialOperationResult;

