import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Semaphore, SemaphoreColor } from '../semaphore';


type ColsRange = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type ColSpanRange = ColsRange;

type GridColumnProps = {
  id?: boolean,
  label: string,
  colSpan: ColSpanRange,
  children?: Map<string, any>,
  firstCol?: boolean,
  lastCol?: boolean,
  emptyPlaceHolder?: string
};

type SemaphoreColumnProps = {
  id?: boolean
  label: string
  colSpan: ColSpanRange
  children?: Map<string, any>
  firstCol?: boolean
  lastCol?: boolean
  emptyPlaceHolder?: string
  redTags: string[]
  yellowTags: string[]
  grayTags: string[]
};

const colSpans: Map<ColSpanRange, string> = new Map([
  [1, 'col-span-1'],
  [2, 'col-span-2'],
  [3, 'col-span-3'],
  [4, 'col-span-4'],
  [5, 'col-span-5'],
  [6, 'col-span-6'],
  [7, 'col-span-7'],
  [8, 'col-span-8'],
  [9, 'col-span-9'],
  [10, 'col-span-10'],
  [11, 'col-span-11'],
  [12, 'col-span-12']
]);


const GridCardButton: React.FC<{ href: string, index: number, last: boolean, firstCol?: boolean, lastCol?: boolean }> = ({ href, index, ...props }) => {
  const [hover, setHover] = useState(false);

  return (
    <div className={`py-3 ${props.last && 'border-b-2'} ${props.firstCol && '-ml-3 2xs:-ml-4 pl-3 2xs:pl-4'} ${props.lastCol && '-mr-3 2xs:-mr-4 pr-3 2xs:pr-4'}`}>
      <div className="flex flex-row w-full justify-end">
        <NavLink to={href ?? '#'} onMouseLeave={() => setHover(false)} onMouseEnter={() => setHover(true)} className={`cursor-pointer transition-colors duration-200 w-8 h-8 ${hover && 'bg-gray-100'} rounded-full flex items-center justify-center`}>
          <i className={`transition-colors duration-200 fas fa-arrow-right ${hover ? 'text-gunmetal-400' : 'text-gunmetal-200'}`}></i>
        </NavLink>
      </div>
    </div>
  );
}

export const GridCardSemaphoreColumn: React.FC<SemaphoreColumnProps> = ({ label, colSpan, children, ...props }) => {

  const keys = children && [...children.keys()];

  return <div className={`${colSpans.get(colSpan)} -mb-4`}>
    <div className={`py-1 bg-silver-gray border-gray-200 border-b-2 border-t-2 ${props.firstCol && '-ml-3 2xs:-ml-4 pl-3 2xs:pl-4'} ${props.lastCol && '-mr-3 2xs:-mr-4 pr-3 2xs:pr-4'}`}>
      <p className="font-semibold text-gunmetal-350 ">{label}</p>
    </div>
    {
      children && keys && keys.length > 0
        ? keys.map((key, index) => {
          const child: string = children.get(key);
          return (
            <div key={key + (child !== undefined ? child : '-')} className={`py-4 ${index !== keys.length - 1 && 'border-b-2'} ${props.firstCol && '-ml-4 pl-4'} ${props.lastCol && '-mr-4 pr-4'}`}>
              <Semaphore
                color={props.redTags.includes(child)
                  ? SemaphoreColor.RED
                  : props.yellowTags.includes(child)
                    ? SemaphoreColor.YELLOW
                    : props.grayTags.includes(child)
                      ? SemaphoreColor.GRAY
                      : SemaphoreColor.GREEN
                }
                tooltip={child ?? ''}
              />
            </div>
          );
        }) : (
          <div className={`py-4 ${props.firstCol && '-ml-3 2xs:-ml-4 pl-3 2xs:pl-4'} ${props.lastCol && '-mr-3 2xs:-mr-4 pr-3 2xs:pr-4'}`}>
            <p className={`text-gunmetal-200 invisible`}>empty</p>
          </div>
        )
    }
  </div>
}

export const GridCardButtonColumn: React.FC<GridColumnProps> = ({ label, colSpan, children, ...props }) => {

  const keys = children && [...children.keys()];

  return <div className={`${colSpans.get(colSpan)} -mb-4`}>
    <div className={`py-1 bg-silver-gray border-gray-200 border-b-2 border-t-2 ${props.firstCol && '-ml-3 2xs:-ml-4 pl-3 2xs:pl-4'} ${props.lastCol && '-mr-3 2xs:-mr-4 pr-3 2xs:pr-4'}`}>
      <p className="font-semibold text-gunmetal-350 invisible">-</p>
    </div>
    {
      children && keys && keys.length > 0
        ? keys.map((key, index) => {
          const child: string = children.get(key);
          return (
            <GridCardButton
              key={key + (child !== undefined ? child : '-')}
              href={child}
              index={index}
              last={index !== keys.length - 1}
              firstCol={props.firstCol}
              lastCol={props.lastCol}
            />
          );
        })

        : <div className={`py-4 ${props.firstCol && '-ml-3 2xs:-ml-4 pl-3 2xs:pl-4'} ${props.lastCol && '-mr-3 2xs:-mr-4 pr-3 2xs:pr-4'}`}>
          <p className={`text-gunmetal-200 invisible`}>empty</p>
        </div>
    }
  </div>
}

export const GridCardColumn: React.FC<GridColumnProps> = ({ label, colSpan, children, ...props }) => {

  const keys = children && [...children.keys()];

  return <div className={`${colSpans.get(colSpan)} -mb-4`}>
    <div className={`py-1 whitespace-nowrap bg-silver-gray border-gray-200 border-b-2 border-t-2 ${props.firstCol && '-ml-3 2xs:-ml-4 pl-3 2xs:pl-4'} ${props.lastCol && '-mr-3 2xs:-mr-4 pr-3 2xs:pr-4'}`}>
      <p className="font-semibold text-gunmetal-350 text-sm 2xs:text-base">{label}</p>
    </div>
    {
      children && keys && keys.length > 0
        ? keys.map((key, index) => {
          const child: string = children.get(key);
          return (
            <div key={key + (child !== undefined ? child : '-')} className={`py-4 ${index !== keys.length - 1 && 'border-b-2'} ${props.firstCol && '-ml-3 2xs:-ml-4 pl-3 2xs:pl-4'} ${props.lastCol && '-mr-3 2xs:-mr-4 pr-3 2xs:pr-4'}`}>
              <p className={`text-gunmetal-200 text-sm 2xs:text-base ${child === undefined && !props.emptyPlaceHolder && 'invisible'}`}>{child ?? props.emptyPlaceHolder ?? 'empty'}</p>
            </div>
          );
        }) : (
          <div className={`py-4 ${props.firstCol && '-ml-3 2xs:-ml-4 pl-3 2xs:pl-4'} ${props.lastCol && '-mr-3 2xs:-mr-4 pr-3 2xs:pr-4'}`}>
            <p className={`text-gunmetal-200 text-sm 2xs:text-base invisible`}>empty</p>
          </div>
        )
    }
  </div>
}

export const GridCard: React.FC<{ header: string, cols: ColsRange, data: any[], absoluteComponent?: React.ReactElement, children: React.ReactElement<GridColumnProps>[] }> = ({ header: title, cols, data, children, ...props }) => {

  const gridColsMap: React.Ref<Map<ColsRange, string>> = useRef<Map<ColsRange, string>>(new Map([
    [1, 'grid-cols-1'],
    [2, 'grid-cols-2'],
    [3, 'grid-cols-3'],
    [4, 'grid-cols-4'],
    [5, 'grid-cols-5'],
    [6, 'grid-cols-6'],
    [7, 'grid-cols-7'],
    [8, 'grid-cols-8'],
    [9, 'grid-cols-9'],
    [10, 'grid-cols-10'],
    [11, 'grid-cols-11'],
    [12, 'grid-cols-12']
  ]));

  let updatedChildren: React.ReactElement[] = [];
  const idIndex = children.indexOf(children.filter(child => child.props.id)[0]);

  children.forEach((child, index) => {
    let entries: Map<string, string> = new Map();
    data.forEach((entry, idx) => {
      entries.set(
        entry[idIndex] ?? Date.now().toLocaleString() + ' ' + idx, //if id is null set random key
        entry[child.props.label.toLowerCase()]
      );
    });
    updatedChildren.push(
      React.cloneElement(child, {
        firstCol: index === 0,
        lastCol: index === children.length - 1,
        ...child.props,
      },
        entries
      )
    )
  });

  return (
    <div className={`p-3 2xs:p-4 bg-white shadow-md rounded-xl relative`}>
      <div className="pb-3">
        <p className="text-lg 2xs:text-xl font-header text-gunmetal-300 font-bold">{title}</p>
        {props.absoluteComponent && props.absoluteComponent}
      </div>
      <div className={`grid ${gridColsMap.current?.get(cols)}`}>
        {updatedChildren}
      </div>
    </div>);
}
