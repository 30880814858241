import { FirewallRule, Invitation, Limits, News, Product, Project, Subscription, Token, User, UserData } from "./types";
import axios, { AxiosInstance, AxiosResponse, CancelTokenSource } from "axios";
import { FirewallRuleType } from "./types/firewall";

const websiteDomain = process.env.REACT_APP_WEBSITE_DOMAIN
const baseURL = websiteDomain + ":8443";

class CherryServiceAPI {

    baseEndpoint: AxiosInstance;
    apiEndpoint: AxiosInstance;
    _cancelToken: CancelTokenSource;

    constructor(token: string | null) {
        let headers: any = {
            'Content-Type': 'application/json',
        };
        if (token) {
            headers = {
                ...headers, //append other basic proprieties
                'Authorization': 'Token ' + token
            }
        }

        this.baseEndpoint = axios.create({
            baseURL: `${baseURL}`,
            headers: headers
        });

        this.apiEndpoint = axios.create({
            baseURL: `${baseURL}/cherryservice`,
            headers: headers
        });

        this._cancelToken = axios.CancelToken.source()
    }

    //AUTHENTICATION
    login = async (email: string, password: string) => this.apiEndpoint.post('/auth/login/', {
        email: email,
        password: password,
    }, { cancelToken: this._cancelToken.token });
    register = async (email: string, password: string, confirmPass: string) => this.apiEndpoint.post('/auth/registration/', {
        email: email,
        password1: password,
        password2: confirmPass,
    }, { cancelToken: this._cancelToken.token });
    changePassword = async (oldPassword: string, newPassword: string, confirmPassword: string) => this.apiEndpoint.post(`/auth/password/change/`, {
        old_password: oldPassword,
        new_password1: newPassword,
        new_password2: confirmPassword
    }, { cancelToken: this._cancelToken.token })
    resetPassword = async (uid: string, token: string, newPassword: string, confirmPassword: string) => this.apiEndpoint.post(`/auth/registration/password-reset/confirm/${uid})/${token}/`, {
        uid: uid,
        token: token,
        new_password1: newPassword,
        new_password2: confirmPassword
    }, { cancelToken: this._cancelToken.token })
    sendResetEmail = async (email: string) => this.apiEndpoint.post('/auth/password/reset/', {
        email: email
    }, { cancelToken: this._cancelToken.token });


    //DATA
    getMe = async () => this.apiEndpoint.get<User>(`/user/`, { cancelToken: this._cancelToken.token });
    getMyLimits = async () => this.apiEndpoint.get<Limits>(`/user/?limits`, { cancelToken: this._cancelToken.token });
    updateMe = async (user: UserData) => this.apiEndpoint.put<User>(`/user/`, { ...user }, { cancelToken: this._cancelToken.token });
    getAllProjects = async (): Promise<AxiosResponse<Project[]>> => this.apiEndpoint.get<Project[]>('/projects/', { cancelToken: this._cancelToken.token });
    getProject = async (projectID: number): Promise<AxiosResponse<Project>> => this.apiEndpoint.get<Project>(`/project/${projectID}/`, { cancelToken: this._cancelToken.token });
    deleteProject = async (projectID: number): Promise<AxiosResponse<Project>> => this.apiEndpoint.delete<Project>(`/project/${projectID}/?delete=true`, { cancelToken: this._cancelToken.token });
    newProject = async (name: string) => this.apiEndpoint.post(`/projects/`, {
        name: name
    }, { cancelToken: this._cancelToken.token });
    getSubscriptionsByProject = async (projectID: number) => this.apiEndpoint.get<Subscription[]>(`/project/${projectID}/subscriptions/`, { cancelToken: this._cancelToken.token });
    getSubscription = async (subscriptionID: number) => this.apiEndpoint.get<Subscription>(`/subscription/${subscriptionID}/`, { cancelToken: this._cancelToken.token });
    getConnectedSubscription = async (subscriptionID: number) => this.apiEndpoint.get<Subscription[]>(`/subscription/${subscriptionID}/connected_subscriptions/`, { cancelToken: this._cancelToken.token });
    addNodeToSubscription = async (subscriptionID: number) => this.apiEndpoint.patch<Subscription>(`/subscription/${subscriptionID}/add_node/`, {}, { cancelToken: this._cancelToken.token });
    deleteSubscription = async (subscriptionID: number) => this.apiEndpoint.delete<Subscription>(`/subscription/${subscriptionID}/?delete=true`, { cancelToken: this._cancelToken.token });
    newSubscription = async (projectID: number, productID: number, name: string) => this.apiEndpoint.post('/subscriptions/?create=true', {
        product: productID,
        project: projectID,
        name: name,
    }, { cancelToken: this._cancelToken.token });
    newMonitorSubscription = async (monitorID: number, projectID: number, connectedSubscriptions: number[]) =>
        this.apiEndpoint.post('/subscriptions/?create=true', {
            product: monitorID,
            name: `${projectID}_monitor`,
            project: projectID,
            connected_subscriptions: connectedSubscriptions,
        }, { cancelToken: this._cancelToken.token })
    newClientSubscription = async (clientID: number, projectID: number, connectedSubscriptions: number[]) =>
        this.apiEndpoint.post('/subscriptions/?create=true', {
            product: clientID,
            name: `Client ${projectID}`,
            project: projectID,
            connected_subscriptions: connectedSubscriptions,
        }, { cancelToken: this._cancelToken.token })
    renewSubscription = async (subscriptionID: number) => this.apiEndpoint.patch<Subscription>(`/subscription/${subscriptionID}/renew`, { cancelToken: this._cancelToken.token });
    getSubscriptionFirewallRules = async (subscriptionID: number) => this.apiEndpoint.get<FirewallRule[]>(`/subscription/${subscriptionID}/firewall_rules/`, { cancelToken: this._cancelToken.token });
    addSubscriptionFirewallRule = async (subscriptionID: number, ruleName: string, ruleSubnet: string, ruleType: FirewallRuleType) => this.apiEndpoint.post<FirewallRule>(`/subscription/${subscriptionID}/firewall_rules/`, {
        name: ruleName,
        ip_address: ruleSubnet,
        type: ruleType
    }, { cancelToken: this._cancelToken.token });
    removeSubscriptionFirewallRule = async (subscriptionID: number, ruleID: number) => this.apiEndpoint.delete<FirewallRule>(`/subscription/${subscriptionID}/firewall_rule/${ruleID}/`, { cancelToken: this._cancelToken.token });
    getProducts = async () => this.apiEndpoint.get<Product[]>(`/products/`, { cancelToken: this._cancelToken.token });
    getProduct = async (id: number) => this.apiEndpoint.get<Product>(`/product/${id}/`, { cancelToken: this._cancelToken.token });
    newTicket = async (email: string, name: string, content: string, language: string) => this.apiEndpoint.post('/tickets/?send', {
        email_sender: email,
        name_sender: name,
        content: content,
        language: language
    }, { cancelToken: this._cancelToken.token });
    getNews = async () => this.apiEndpoint.get<News[]>(`/news/`, { cancelToken: this._cancelToken.token })
    newToken = async (clientName: string, clientEmail: string) => this.apiEndpoint.post<Token>(`/tokens/`, {
        name: clientName,
        email: clientEmail
    }, { cancelToken: this._cancelToken.token });
    getToken = async (token: string) => this.apiEndpoint.get<Token>(`/token/${token}/`, { cancelToken: this._cancelToken.token });
    deleteToken = async (token: string) => this.apiEndpoint.delete(`/token/${token}/`, { cancelToken: this._cancelToken.token });
    getInvitations = async () => this.apiEndpoint.get<Invitation[]>(`/invitations/`, { cancelToken: this._cancelToken.token })
}

export default CherryServiceAPI;