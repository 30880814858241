import React, { useEffect } from 'react';
import {useHistory} from 'react-router-dom'

import {BlankCard} from '../cards';
import {AnimatedButton} from '../buttons';
import { PasswordInput } from './inputs';

interface SignInFormProps {
  containerClassName?: string
  emailFieldName: string
  passwordFieldName: string
  onFieldFocus?: ()=>void
  onFormChange: (e: React.ChangeEvent<HTMLInputElement>)=>void
  onSubmit: ()=>void
  onRegisterClick: ()=>void
}

export const SignInForm: React.FC<SignInFormProps> = ({emailFieldName,passwordFieldName, onFieldFocus, onFormChange: handleFormChange, onSubmit : handleSubmit, onRegisterClick: handleRegisterClick , ...props}) => {
  
  const history=useHistory();

  function handleFocus(){
    if(onFieldFocus!==undefined){
      onFieldFocus();
    }
  }

  //This hook checks triggers submit callback if ENTER is pressed
  useEffect(()=>{

    function verifyEnterPressed(ev: KeyboardEvent){
      if(ev.key === 'Enter'){
        handleSubmit()
      }
    }

    window.addEventListener('keypress',verifyEnterPressed);
    return ()=>window.removeEventListener('keypress', verifyEnterPressed);
  },[handleSubmit])
  
  return (
    <div className={`w-80 sm:w-100 relative ${props.containerClassName?props.containerClassName:''}`}>
      <button onClick={()=>history.push("/")} className="absolute w-8 h-8 bg-white hover:bg-cherry-red-50 border border-gray-100 rounded-full -right-4 -top-4 focus:outline-none">
        <i className="fas fa-times text-cherry-red-400"></i>
      </button>
      <BlankCard wrapContent>
        <div className="m-2 sm:m-4">
          <p onClick={()=>history.push("/")} className="text-gunmetal-500 text-2xl sm:text-3xl w-full mx-auto font-header font-bold text-center cursor-pointer">AYRADB</p>
          <p className="text-gunmetal-200 text-sm sm:text-base font-semibold my-4 sm:my-6">Need an account? <span onClick={()=>handleRegisterClick()} className="ml-1 cursor-pointer text-cherry-red-500 hover:text-cherry-red-250">Register</span></p>
          <h1 className="text-gunmetal-500 text-xl sm:text-2xl font-semibold mb-4 sm:mb-8">Login</h1>
          <form className="w-full">
            <div className="w-full text-sm sm:text-base space-y-3 mb-12">
              <label className="mb-2 text-gunmetal-350 font-semibold block">Email</label>
              <input name={emailFieldName} onFocus={() => handleFocus()} onChange={e => handleFormChange(e)} type="email" className="h-12 w-full outline-none border-2 border-gray-200 focus:border-cherry-red-200 bg-gray-50 focus:bg-cherry-red-50 rounded-lg p-4  block" />
              <label className="mb-2 text-gunmetal-350 font-semibold block">Password</label>
              <PasswordInput name={passwordFieldName} onFocus={() => handleFocus()} onChange={e => handleFormChange(e)} type="password" className="h-12 w-full outline-none border-2 border-gray-200 focus:border-cherry-red-200 bg-gray-50 focus:bg-cherry-red-50 rounded-lg p-4  block" />
            </div>
          </form>
          <AnimatedButton onClick={() => handleSubmit()} className="focus:outline-none bg-cherry-red-400 hover:bg-cherry-red-300 rounded-lg font-semibold text-white w-full py-3 text-sm sm:text-base">Sign in</AnimatedButton>
        </div>
      </BlankCard>
    </div>
  );
}
