import React, { useState, useEffect, useContext, useReducer, Reducer, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { LinkData } from '../commons/types';
import { useTranslation } from 'react-i18next';

enum OpenCounterActionType {
  INCREMENT,
  DECREMENT,
  RESET
}

interface OpenCounterState {
  openDropdownsCounter: number
}

interface OpenCounterAction {
  type: OpenCounterActionType
}

interface MobileSidebarProps {
  children?: React.ReactNode
  show?: boolean
  onCloseRequest?: () => void
}

const DropdownOpenContext = React.createContext<{ openDropdowns: number, actions: { increaseOpen: () => void, decreaseOpen: () => void } }>(undefined!)

export const MobileSidebar: React.FC<MobileSidebarProps> = ({ show, onCloseRequest, ...props }) => {

  const handleClose = () => onCloseRequest && onCloseRequest()

  const reducer = (state: OpenCounterState, action: OpenCounterAction): OpenCounterState => {
    switch (action.type) {
      case OpenCounterActionType.INCREMENT:
        return { openDropdownsCounter: state.openDropdownsCounter + 1 }
      case OpenCounterActionType.DECREMENT:
        return { openDropdownsCounter: state.openDropdownsCounter - 1 }
      case OpenCounterActionType.RESET:
        return { openDropdownsCounter: 0 }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer<Reducer<OpenCounterState, OpenCounterAction>>(reducer, { openDropdownsCounter: 0 })

  const actions = useRef({
    increaseOpen: () => dispatch({ type: OpenCounterActionType.INCREMENT }),
    decreaseOpen: () => dispatch({ type: OpenCounterActionType.DECREMENT })
  })


  useEffect(() => {
    return () => {
      if (show) {
        //Case sidebar is open: before closing reset the counter of open dropdowns to zero
        dispatch({ type: OpenCounterActionType.RESET })
      }
    }
  }, [show])

  return (
    <div className={`fixed top-0 left-0 transition-color duration-300 h-screen w-screen overflow-hidden z-40 ${show ? 'bg-gunmetal-700 bg-opacity-30 backdrop-filter backdrop-blur-md' : 'invisible'}`}>
      <div onClick={handleClose} className={`absolute transition-all top-0 left-0 w-full h-full`} />
      <aside className={`duration-300 transform ${show ? '' : '-translate-x-full'} z-10 h-full overflow-y-scroll w-8/12 2xs:w-7/12 xs:w-4/12 bg-cherry-red-600`}>
        <DropdownOpenContext.Provider value={{ openDropdowns: state.openDropdownsCounter, actions: actions.current }}>
          {props.children}
        </DropdownOpenContext.Provider>
      </aside>
    </div>
  );
}

export const MobileSidabarLogo: React.FC<{ onLogoClick?: () => void }> = ({ ...props }) => {

  const handleClick = () => props.onLogoClick && props.onLogoClick()

  return (
    <>
      <div className="p-4">
        <h1 onClick={handleClick} className="text-lg sm:text-2xl font-header tracking-tighter font-bold text-white select-none">AYRADB</h1>
      </div>
      <hr className="bg-white h-px w-full" />
    </>
  );
}

export const Sidebar: React.FC<{ children?: React.ReactNode }> = (props) => {

  const location = useLocation();

  const reducer = (state: OpenCounterState, action: OpenCounterAction): OpenCounterState => {
    switch (action.type) {
      case OpenCounterActionType.INCREMENT:
        return { openDropdownsCounter: state.openDropdownsCounter + 1 }
      case OpenCounterActionType.DECREMENT:
        return { openDropdownsCounter: state.openDropdownsCounter - 1 }
      case OpenCounterActionType.RESET:
        return { openDropdownsCounter: 0 }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer<Reducer<OpenCounterState, OpenCounterAction>>(reducer, { openDropdownsCounter: 0 })

  const actions = useRef({
    increaseOpen: () => dispatch({ type: OpenCounterActionType.INCREMENT }),
    decreaseOpen: () => dispatch({ type: OpenCounterActionType.DECREMENT })
  })

  useEffect(() => {
    dispatch({ type: OpenCounterActionType.RESET })
  }, [location])

  return (
    <>
      <aside className="hidden lg:block col-span-1 2xl:col-span-2 bg-cherry-red-600 pt-8">
        <DropdownOpenContext.Provider value={{ openDropdowns: state.openDropdownsCounter, actions: actions.current }}>
          {props.children}
        </DropdownOpenContext.Provider>
      </aside>
    </>
  );
}

export const SidebarItem: React.FC<{ linkData: LinkData, onItemClick?: (href: string) => void }> = (props) => {

  const location = useLocation();
  const history = useHistory();

  const handleClick = () => {
    const path = props.linkData.href
    //Pass up event if an handler is provided
    props.onItemClick && props.onItemClick(path)
    history.push(path)
  }

  const { t, i18n } = useTranslation();

  return (
    <button onClick={handleClick} className={`h-12 my-2 w-11/12 rounded-r-3xl mr-auto transition-colors hover:bg-cherry-red-200 flex flex-row items-center px-8 ${location.pathname.match(new RegExp(`^${props.linkData.href}(/[\\w,/]*)?$`)) ? 'bg-cherry-red-200' : ''} select-none focus:outline-none`}>
      <div className={`h-full w-1.5 ${location.pathname.match(new RegExp(`^${props.linkData.href}(/[\\w,/]*)?$`)) ? 'bg-cherry-red-100' : ''} -ml-8 mr-6`} />
      {props.linkData.icon}
      <p className={`${props.linkData.icon ? 'ml-5' : ''} text-cherry-red-75 font-docs-element font-medium text-base xs:text-lg`}>
        {
          /* DISPLAYED WITH ONLY THE FIRST LETTER TO CAPS */
          t(props.linkData.title).charAt(0).toUpperCase()
            .concat(t(props.linkData.title).substr(1).toLowerCase())
        }
      </p>
    </button>
  );
}

export const SidebarDropdown: React.FC<{ label: string, associatedLinks: string[], icon?: React.ReactNode, onItemClick?: () => void, children?: React.ReactNode }> = ({ label, associatedLinks, icon, ...props }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [isInternalLinkOpen, setIsInternalLinkOpen] = useState(false);
  const location = useLocation();
  const { openDropdowns, actions } = useContext(DropdownOpenContext);

  const handleClick = () => {
    setIsOpen(wasOpen => !wasOpen)
    isOpen ? actions.decreaseOpen() : actions.increaseOpen()
    props.onItemClick && props.onItemClick()
  }

  useEffect(() => {
    let isMatching = false;
    for (let i = 0; i < associatedLinks.length && !isMatching; i++) {
      isMatching = location.pathname.match(new RegExp(`^${associatedLinks[i]}$`)) ? true : false
    }
    setIsInternalLinkOpen(isMatching)
  }, [associatedLinks, location])

  //Every time the counter is set to 0 all dropdowns must close
  //Most times they are already closed, this triggers a closure only when a new page is selected
  //to close the dropdown if it was opened.
  useEffect(() => {
    if (openDropdowns === 0) {
      setIsOpen(false);
    }
  }, [openDropdowns])

  const { t, i18n } = useTranslation();

  return (
    <div className={`relative rounded-r-3xl w-11/12 my-2 overflow-hidden flex flex-col`}>
      <button onClick={handleClick} className={`relative group z-1 h-12 w-full rounded-r-3xl transition-colors flex flex-row items-center px-8 ${isInternalLinkOpen || isOpen ? 'bg-cherry-red-200 hover:bg-cherry-red-150' : 'bg-cherry-red-600 hover:bg-cherry-red-200'} select-none focus:outline-none`}>
        <div className={`h-full w-1.5 transition-colors -ml-8 mr-6 ${isInternalLinkOpen ? 'bg-cherry-red-100 group-hover:bg-cherry-red-75' : ''}`} />
        {icon}
        <p className={`${icon ? 'ml-5' : ''} text-cherry-red-75 font-docs-element font-medium text-base xs:text-lg`}>
          {
            /* DISPLAYED WITH ONLY THE FIRST LETTER TO CAPS */
            t(label).charAt(0).toUpperCase()
              .concat(t(label).substr(1).toLowerCase())
          }
        </p>
        <i className={`fas fa-chevron-down text-white absolute right-4 transition-transform duration-500 transform ${isOpen ? '-rotate-180' : ''}`}></i>
      </button>
      <div className={`relative duration-500 w-full -top-6 overflow-hidden pb-6 -mb-6`}>
        <div className={`transition-transform-height duration-500 w-full transform ${isOpen ? 'translate-y-0 h-full' : 'h-0 -translate-y-full'} -mb-6 pb-1 bg-cherry-red-300 rounded-br-3xl`}>
          <div className="h-6" />
          {props.children}
        </div>
      </div>
    </div>
  );
}

export const SidebarDropdownItem: React.FC<{ linkData: LinkData, onItemClick?: (href: string) => void }> = (props) => {

  const location = useLocation();
  const history = useHistory();

  const handleClick = () => {
    const path = props.linkData.href
    //Pass up event if an handler is provided
    props.onItemClick && props.onItemClick(path)
    history.push(path)
  }

  const { t, i18n } = useTranslation();

  return (
    <button onClick={handleClick} className={`h-12 my-2 w-11/12 rounded-r-3xl mr-auto transition-colors hover:bg-cherry-red-200 flex flex-row items-center px-8 ${location.pathname.match(new RegExp(`^${props.linkData.href}$`)) ? 'bg-cherry-red-200' : ''} select-none focus:outline-none`}>
      <div className={`h-full w-1.5 -ml-8 mr-6`} />
      {props.linkData.icon}
      <p className={`${props.linkData.icon ? 'ml-5' : ''} text-cherry-red-75 font-docs-element font-medium text-sm 2xs:text-base xs:text-lg`}>
        {
          /* DISPLAYED WITH ONLY THE FIRST LETTER TO CAPS */
          t(props.linkData.title).charAt(0).toUpperCase()
            .concat(t(props.linkData.title).substr(1).toLowerCase())
        }
      </p>
    </button>
  );
}