import React, { useContext, useEffect, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { BlankCard } from '../../components/cards';
import { AnimatedButton, AnimatedTextButton } from '../../components/buttons';
import { CustomFormContext } from './index';
import { useDebugLogger } from '../../commons/hooks';

// const handleFormChange = (field: Fields, event: React.FormEvent<HTMLInputElement>) => {
//   switch (field) {
//     case Fields.THROUGHPUT:

//       break;
//     default:
//       console.error('Untracked field!');
//   }
// }

const MAX_NODES_WITHOUD_ADJ = 2
const ADJUSTMENT_FACTOR = 0.9

export enum Fields {
  THROUGHPUT = 'throughput',
  DISK_SPACE = 'DISK_SPACE',
  REPLICATION = 'REPLICATION',
  AVG_RECORD_SIZE = 'AVG_RECORD_SIZE'
}

interface Server {
  name?:string
  storage: number
  ram: number
  throughput: number
  cost: number
}

interface Solution{
  server: Server,
  nodes: number,
  totalPrice: number,
}

const servers: Server[] = [{
  name: "cpx31",
  storage: 150,
  ram: 8,
  throughput: 26524,
  cost: 64.76
}, {
  name:"cx41",
  storage: 150,
  ram: 16,
  throughput: 38766,
  cost: 68.92
}, {
  name:"cpx41",
  storage: 230,
  ram: 16,
  throughput: 95199,
  cost: 77.25
}, {
  name:"cx51",
  storage: 230,
  ram: 32,
  throughput: 52327,
  cost: 85.58
}, {
  name:"cpx51",
  storage: 350,
  ram: 32,
  throughput: 137364,
  cost: 109.38
}]

const CustomSetup: React.FC<{}> = () => {

  const [customize, setCustomize] = useState(false);
  const [bestServer, setBestServer] = useState<Solution>({
    server:servers[0],
    nodes:1,
    totalPrice: servers[0].cost
  });
  const inputRef = useRef<HTMLInputElement>(undefined!);
  const logger = useDebugLogger();

  const history = useHistory();

  const { setupInformation, setSetupInformation } = useContext(CustomFormContext)

  function handleSubmit() {
    //TODO: Feature not implemented in beta
  }

  //ComponentDidMount: get focus
  useEffect(() => {
    if (inputRef && inputRef.current && customize) {
      inputRef.current.focus();
    }
  }, [customize])


  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    let value;
    if (event.currentTarget.value.length === 0) {
      //put value a 0 if the user delete the whole input
      value = 0;
    } else if (!isNaN(Number(event.currentTarget.value))) {
      let numericString = event.currentTarget.value;
      //remove leading zero if present
      if (event.currentTarget.value.length > 1 && event.currentTarget.value.charAt(0) === '0') {
        numericString = numericString.substr(1);
      }
      value = parseInt(numericString);
    }
    if (value !== undefined) {
      switch (event.currentTarget.name) {
        case Fields.THROUGHPUT:
          setSetupInformation({
            ...setupInformation,
            throughput: value
          })
          break;
        case Fields.DISK_SPACE:
          setSetupInformation({
            ...setupInformation,
            diskSpace: value
          })
          break;
        case Fields.AVG_RECORD_SIZE:
          setSetupInformation({
            ...setupInformation,
            avgRecordSize: value
          })
          break;
        case Fields.REPLICATION:
          setSetupInformation({
            ...setupInformation,
            replication: value
          })
          break;
        default:
          logger.error("Untracked field!");
      }
    }
  }

  function handleDone(){
    if(setupInformation.replication===0) setSetupInformation({
      ...setupInformation,
      replication:1
    })
    if(setupInformation.avgRecordSize===0) setSetupInformation({
      ...setupInformation,
      avgRecordSize:1000
    })
    setCustomize(false)
  }

  useEffect(() => {
    if (setupInformation !== undefined && setupInformation.avgRecordSize!==0) {
      const reqDisk = setupInformation.diskSpace * setupInformation.replication
      const reqThroughput = setupInformation.throughput
      const reqRam = (90 * reqDisk / (2 * setupInformation.avgRecordSize))
      //Array containing all the solutions that respect the criteria
      //which are required throughput, required disk and required ram
      const solutions : Solution[] = []
      for (let i = 0; i < servers.length; i++) {
        const currentServer = servers[i]
        //Compute minimum required nodes to support each requirement
        let reqNodesThroughput = reqThroughput / currentServer.throughput
        const reqNodesDiskSpace = reqDisk / currentServer.storage
        const reqNodesRam = reqRam / currentServer.ram
        const reqNodesReplication = setupInformation.replication

        //If minimum required nodes for throughput are more than 2 
        //(const MAX_NODES_WITHOUD_ADJ) adjust the value.
        if (reqNodesThroughput > MAX_NODES_WITHOUD_ADJ) {
          const a = ADJUSTMENT_FACTOR
          reqNodesThroughput = (reqNodesThroughput + (2 * a + 1)) / a
        }

        //Define the number of nodes that are needed to satisfy every requirement
        //It is equal to the maximum of the values computed before, everyone
        //rounded up (ceil).
        const minimumReqNodes = Math.max(
          Math.ceil(reqNodesThroughput),
          Math.ceil(reqNodesDiskSpace),
          Math.ceil(reqNodesRam),
          reqNodesReplication //Already a rounded integer
        )

        //Add solution to the list of available ones
        solutions.push({
          server: currentServer,
          nodes: minimumReqNodes,
          totalPrice: currentServer.cost*minimumReqNodes
        })

        //Since servers are ordered by price, if one obtains 1 as minimum
        //required nodes, it's not necessary to evaluate the remaining since
        //they will cost more
        if(minimumReqNodes===1) break;
      }

      solutions.sort((sol1,sol2)=>sol1.totalPrice>sol2.totalPrice?1:-1)
      setBestServer(solutions[0]);
    }
  }, [setupInformation])

  useEffect(() => {
    setSetupInformation({
      throughput: 10000,
      diskSpace: 150,
      avgRecordSize: 1000,
      replication: 1
    })
  }, [setSetupInformation])

  return (
    <div className="w-80 sm:w-100">
      <BlankCard wrapContent>
        <div className="mx-4 sm:mx-8">
          <h1 className="text-gunmetal-500 text-3xl mt-3 mb-8 w-full mx-auto font-header font-bold text-center">AYRADB</h1>
          <div className="flex flex-row justify-between items-baseline  mb-8">
            <h1 className="text-gunmetal-500 text-2xl font-semibold">{`${customize ? 'Customize' : 'Confirm'} your plan`}</h1>
            {
              customize
                ? <p onClick={() => handleDone()} className="text-cherry-red-400 hover:text-cherry-red-250 text-base font-bold cursor-pointer select-none">Done</p>
                : <p onClick={() => setCustomize(true)} className="text-cherry-red-400 hover:text-cherry-red-250 text-base font-bold cursor-pointer select-none">Customize</p>
            }
          </div>
          <form className="w-full " onSubmit={e => e.preventDefault()}>
            <div className="w-full space-y-3 mb-12">
              <div className="w-full flex flex-row items-center space-x-1">
                <label className="text-gunmetal-350 font-semibold block whitespace-nowrap">Throughput</label>
                <input ref={inputRef} value={setupInformation?.throughput || 0} name={Fields.THROUGHPUT} onChange={e => handleChange(e)} disabled={!customize} type="text" className={`w-full h-full outline-none block text-right ${customize && 'cursor-pointer'} bg-transparent focus:bg-cherry-red-50 rounded -m-1 p-1`} />
                <p className="block">op/s</p>
              </div>
              <div className="w-full flex flex-row items-center space-x-1">
                <label className="text-gunmetal-350 font-semibold block whitespace-nowrap">Storage size</label>
                <input value={setupInformation?.diskSpace || 0} name={Fields.DISK_SPACE} onChange={e => handleChange(e)} disabled={!customize} type="text" className={`w-full h-full outline-none block text-right ${customize && 'cursor-pointer'} bg-transparent focus:bg-cherry-red-50 rounded -m-1 p-1`} />
                <p className="block">GByte</p>
              </div>
              <div className="w-full flex flex-row items-center space-x-1">
                <label className="text-gunmetal-350 font-semibold block whitespace-nowrap">Avg. record size</label>
                <input value={setupInformation?.avgRecordSize || 0} name={Fields.AVG_RECORD_SIZE} onChange={e => handleChange(e)} disabled={!customize} type="text" className={`w-full h-full outline-none block text-right ${customize && 'cursor-pointer'} bg-transparent focus:bg-cherry-red-50 rounded -m-1 p-1`} />
                <p className="block">Byte</p>
              </div>
              <div className="w-full flex flex-row items-center space-x-1">
                <label className="text-gunmetal-350 font-semibold block whitespace-nowrap">Replication factor</label>
                <input value={setupInformation?.replication || 0} name={Fields.REPLICATION} onChange={e => handleChange(e)} disabled={!customize} type="text" className={`w-full h-full outline-none block text-right ${customize && 'cursor-pointer'} bg-transparent focus:bg-cherry-red-50 rounded -m-1 p-1`} />
                <p className="block"></p>
              </div>
            </div>
          </form>
          <div>
            <p className="w-full text-cherry-red-400 text-center text-2xl mb-6"><span className="text-4xl font-bold">{setupInformation && bestServer !== undefined && `${Math.ceil(bestServer.totalPrice * 100) / 100}€`}</span>/month</p>
          </div>
          <div className="flex flex-row space-x-4 mb-5">
            <AnimatedTextButton onClick={() => history.goBack()/* FIXME: propagate back click to index */} className="w-full focus:outline-none px-4 py-2.5 text-gunmetal font-semibold sm:text-sm md:text-base lg:text-bas rounded-lg hover:bg-gray-200 border border-gray-300">Back</AnimatedTextButton>
            {/* FIXME: re-enable this button and delete the one not commented when the feature will be released */}
            {/* <AnimatedButton onClick={() => handleSubmit()} className="w-full focus:outline-none px-4 py-2.5 text-white font-semibold sm:text-sm md:text-base lg:text-bas rounded-lg bg-cherry-red-500 hover:bg-cherry-red-300 border border-cherry-red-500 hover:border-cherry-red-300">Confirm</AnimatedButton> */}
            <AnimatedButton disabled={true} onClick={() => handleSubmit()} className="w-full focus:outline-none px-4 py-2.5 text-white font-semibold sm:text-sm md:text-base lg:text-bas rounded-lg bg-gray-400">Coming soon...</AnimatedButton>
          </div>
        </div>
      </BlankCard>
    </div>
  );
}

export default CustomSetup;