//CONSTANTS
export enum ServerStatus {
    SETTING_UP_PROVISIONING = 'P',
    SETTING_UP_PRODUCT_INSTALLATION = 'I',
    ACTIVE = 'A',
    ERROR = 'E',
    DECOMMISIONED = 'D'
}

export interface Server {
    id: number
    name: string
    ip_address: string
    status: ServerStatus
    ready: boolean
    product_installed: boolean
}