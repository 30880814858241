import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';

import { RootState } from '../../store';
import { AuthenticationStateType } from '../../store/auth/reducers';
import { login, refreshAuthError } from '../../store/auth/actions';
import { SignInForm } from '../../components/forms';
import { AlertType, SimpleAlert } from '../../components/alert';
import { InteractiveModal } from '../../components/modals';
import useCherryserviceAPI from '../../api/cherryservice/v2';
import { useDebugLogger, useTimer } from '../../commons/hooks';


interface ReduxDispatchProps {
  signin: (email: string, password: string) => void
  resetAuthError: () => void;
}

interface ReduxStateProps {
  authState: AuthenticationStateType | null
}

type SignInProps = ReduxDispatchProps & ReduxStateProps;

const SignIn: React.FC<SignInProps> = ({ resetAuthError, ...props }) => {

  //VARIABLES AND STATES
  const emailFieldName = "email";
  const passwordFieldName = "password";
  const mailSentMessage = 'Mail sent successfully. Check your mail inbox';

  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState<string | null>();
  const [showModal, setShowModal] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const timer = useTimer();
  const cherryserviceAPI = useCherryserviceAPI();
  const logger = useDebugLogger();

  //METHODS
  const signin = () => {
    props.signin(email, password);
  }

  //Called when a user click on the text
  //to reset its password
  const handleResetPassClick = () => {
    setShowModal(true);
  }

  //Called when a user closes the modal to reset
  //the password without clicking "Send"
  const handleCloseModal = () => {
    setShowModal(false);
  }

  //Called when the user clicks on send email to
  //reset the password
  const handleSendEmailClick = (email: string) => {
    cherryserviceAPI?.sendResetEmail(email)
      .then(() => {
        setShowModal(false);
        setMailSent(true);
        timer.setTimer(() => setMailSent(false), 2000);
      }).catch(err => err.response && logger.log(err.response))
  }

  //Method managing changes in the sign in form's inputs
  const handleFormChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.name === emailFieldName) {
      setEmail(event.currentTarget.value);
    } else if (event.currentTarget.name === passwordFieldName) {
      setPassword(event.currentTarget.value);
    }
  }

  useEffect(() => {
    if (props.authState?.logged) {
      history.push('/console');
    }
    if (props.authState?.error) {
      setShowError(props.authState.error);
      timer.setTimer(() => setShowError(null), 2000)
      //once error message is "consumed", we should reset user to not authenticated
      //without keeping the error
      resetAuthError();
    }
  }, [props.authState, history, resetAuthError, timer])

  //COMPONENT
  return (
    <>
      <SimpleAlert showText={mailSent ? mailSentMessage : showError ?? undefined} type={mailSent ? AlertType.INFO : AlertType.ERROR} />
      <InteractiveModal
        windowClassName={`fixed w-screen h-screen z-40`}
        title="Send reset email"
        active={showModal}
        mainButtonLabel="Send"
        inputLabel="Email"
        enableSubmitCallback={() => null} //Always active
        submitCallback={handleSendEmailClick}
        backCallback={handleCloseModal}
      >
        <p className="mt-4">Insert the email used to register to AyraDB. We will send you the link to reset the password.</p>
      </InteractiveModal>
      <div className="relative pt-8 transform top-1/2 -translate-y-1/2">
        <SignInForm
          containerClassName="mx-auto"
          emailFieldName={emailFieldName}
          passwordFieldName={passwordFieldName}
          onFormChange={handleFormChange}
          onRegisterClick={() => history.push('/auth/signup')}
          onSubmit={() => signin()}
        />
        <div className="mt-4">
          <p onClick={handleResetPassClick} className="text-cherry-red-400 hover:text-cherry-red-250 cursor-pointer select-none text-center text-sm sm:text-base">Forgot your password?</p>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return ({
    authState: state.user
  })
}

const mapDispatchToProps = (dispatch: any): ReduxDispatchProps => {
  return ({
    signin: (email, password) => dispatch(login(email, password)),
    resetAuthError: () => dispatch(refreshAuthError())
  })
}

export default connect<ReduxStateProps, ReduxDispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(SignIn);