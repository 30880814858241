import { useState, useEffect } from 'react';

// Hover
const useHover: () => [(ref:HTMLElement|null)=>void, boolean] = () => {
  const [value, setValue] = useState(false);
  const [htmlElement,setHtmlElement] = useState<HTMLElement|null>(null)

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = htmlElement;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);
        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
        };
      }
    },
    [htmlElement] // Recall only if ref changes
  );

  return [setHtmlElement, value];
}

export default useHover;