import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import routes from '../../routes'
import { Navbar, Collapse, CollapseBreakPoint, NavLogo, NavUser, NavPrimaryLink } from '../../components/navbar';
import { Sidebar, SidebarItem } from '../../components/sidebar';
import { getConsoleSideLinks } from './console_links';
import { RootState } from '../../store';
import { AuthenticationStateType } from '../../store/auth/reducers';
import { MessageModal } from '../../components/modals';

interface ReduxStateProps {
  authState: AuthenticationStateType | null
}

interface MessageModalContent {
  title: string
  children: React.ReactElement
  mainButtonLabel: string
  submitCallback?: () => Promise<void>
  closeCallback?: () => void
}

interface MessageModalContextType {
  actions: {
    setModalContent: (content: MessageModalContent | undefined) => void
    setVisibility: (isVisible: boolean) => void
    setLoading: (isLoading: boolean) => void
  }
}

interface OwnState {
  pageRef: HTMLDivElement | null
  isMessageModalVisible: boolean
  isMessageModalLoading: boolean
  messageModalContent?: MessageModalContent
  messageModal: MessageModalContextType
}

type ConsoleLayoutProps = ReduxStateProps;

export const ScreenRefContext = React.createContext<HTMLDivElement | null>(null)
export const MessageModalContext = React.createContext<MessageModalContextType | null>(null)

const RoutesSwitcher: React.FC<{}> = (props) => {
  return (
    <Switch>
      {routes.filter(route => (route.layout === '/console/')).map(route => {
        return (<Route exact key={route.layout + route.path} path={route.layout + route.path} component={route.page} />);
      })}
      <Redirect exact from="/console" to="/console/home" />
      <Redirect from="/console/*" to="/not_found" />
    </Switch>
  );
}

class ConsoleLayout extends Component<ConsoleLayoutProps, OwnState> {

  constructor(props: ConsoleLayoutProps) {
    super(props);
    this.setPageRef = this.setPageRef.bind(this);
    this.setModalContent = this.setModalContent.bind(this);
    this.setVisibility = this.setVisibility.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.submitModal = this.submitModal.bind(this);
    this.state = {
      pageRef: null,
      messageModalContent: undefined,
      isMessageModalLoading: false,
      isMessageModalVisible: false,
      messageModal: {
        actions: {
          setModalContent: this.setModalContent,
          setVisibility: this.setVisibility,
          setLoading: this.setLoading
        }
      }
    }
  }

  setPageRef(pageRef: HTMLDivElement) {
    this.setState({
      pageRef: pageRef
    })
  }

  closeModal() {
    this.setVisibility(false);
    if (this.state.messageModalContent?.closeCallback) {
      this.state.messageModalContent.closeCallback();
    }
  }

  async submitModal() {
    if (this.state.messageModalContent?.submitCallback) {
      this.setLoading(true);
      await this.state.messageModalContent.submitCallback();
    }
    this.setVisibility(false);
    this.setLoading(false);
  }


  setModalContent(content: MessageModalContent | undefined) {
    this.setState({
      messageModalContent: content
    })
  }

  setVisibility(isVisible: boolean) {
    this.setState({
      isMessageModalVisible: isVisible,
    })
  }

  setLoading(isLoading: boolean) {
    this.setState({
      isMessageModalLoading: isLoading
    })
  }

  render() {

    if (this.props.authState !== null && this.props.authState.logged === false) {
      return <Redirect to="/" />
    }

    const { id, name, surname, email } = this.props.authState?.user || {}
    return (
      <>
        <header className="fixed w-full px-3 md:px-3 bg-white border-b z-40">
          <Navbar primaryNav className="w-full h-16">
            <NavLogo />
            <Collapse
              breakPoint={CollapseBreakPoint.MD}
              className={`h-full flex-auto`}
            >
              <div className="flex flex-row h-full items-center justify-end flex-auto space-x-4">
                {
                  this.props.authState?.user?.isStaff && <NavPrimaryLink className="mr-4" href="/admin">Admin</NavPrimaryLink>
                }
              </div>
            </Collapse>
            <NavUser
              fullName={`${(name === undefined || surname === undefined) ? `User${id}` : `${name} ${surname}`}`}
              email={email ?? ''} //Notice: UserLoader ensures that email is not null
            />
          </Navbar>
        </header>
        <div ref={this.setPageRef} className="grid grid-cols-4 lg:grid-cols-5 2xl:grid-cols-10 w-full h-screen pt-16">
          <Sidebar>
            {getConsoleSideLinks().map(linkData =>
              <SidebarItem
                key={linkData.href}
                linkData={linkData}
              />
            )}
          </Sidebar>
          <div className="relative overflow-y-scroll col-span-4 2xl:col-span-8 bg-ghost-white"> {/* THIS WIDTH IS RELATED TO SIDEBAR WIDTH*/}
            {
              this.state.messageModalContent !== undefined &&
              <MessageModal
                title={this.state.messageModalContent.title}
                active={this.state.isMessageModalVisible}
                mainButtonLabel={this.state.messageModalContent.mainButtonLabel}
                submitCallback={() => this.submitModal()}
                closeCallback={() => this.closeModal()}
                loading={this.state.isMessageModalLoading}
              >
                {this.state.messageModalContent.children}
              </MessageModal>
            }
            <ScreenRefContext.Provider value={this.state.pageRef}>
              <MessageModalContext.Provider value={this.state.messageModal}>
                <RoutesSwitcher />
              </MessageModalContext.Provider>
            </ScreenRefContext.Provider>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return ({
    authState: state.user
  })
}

export default connect<ReduxStateProps, {}, {}, RootState>(mapStateToProps)(ConsoleLayout);