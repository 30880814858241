import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import useCherryserviceAPI from '../../api/cherryservice/v2';
import { useDebugLogger, useTimer } from '../../commons/hooks';
import { AlertType, SimpleAlert } from '../../components/alert';
import { AnimatedButton, AnimatedTextButton } from '../../components/buttons';
import { PasswordInput } from '../../components/forms/inputs';
import { MessageModal } from '../../components/modals';
import { RootState } from '../../store';
import { AuthenticationStateType } from '../../store/auth/reducers';

enum UpdatePassStatus {
  NOT_UPDATING,
  UPDATE_OK,
  UPDATE_ERROR,
}

interface ReduxStateProps {
  authState: AuthenticationStateType | null
}

type SecurityPageProps = ReduxStateProps;

const oldPasswordErrors = new Map<string, string>([
  ['Invalid password', 'Old password incorrect. If you don\'t remember it use the reset button'],
  ['This field may not be blank.', 'Old password can\'t be empty'],
]);

const newPasswordErrors = new Map<string, string>([
  ['This field may not be blank.', 'New password can\'t be empty'],
]);

const confirmPasswordErrors = new Map<string, string>([
  ['This field may not be blank.', 'Confirm password can\'t be empty'],
  ["The two password fields didn’t match.", 'The two password don\'t match'],
  ["This password is too short. It must contain at least 8 characters.", "This password is too short. It must contain at least 8 characters"]
]);

const ErrorTooltip: React.FC<{ error?: string }> = ({ error }) => {

  const [tooltipWidth, setTooltipWidth] = useState(0);
  const [tooltipHeight, setTooltipHeight] = useState(0);
  const [tooltipArrowWidth, setTooltipArrowWidth] = useState(0);
  const [tooltipArrowHeight, setTooltipArrowHeight] = useState(0);
  const [errorHover, setErrorHover] = useState(false);

  //Ref callback to retrieve tooltip height and width
  const tooltipRefCallback = useCallback((node: HTMLParagraphElement | null) => {
    if (node && error !== undefined) {
      setTooltipWidth(node.clientWidth);
      setTooltipHeight(node.clientHeight);
    }
  }, [error])


  //Ref callback to retrieve tooltip's arrow height and width
  const arrowRefCallback = useCallback((node: SVGSVGElement | null) => {
    if (node && error !== undefined) {
      setTooltipArrowWidth(node.clientWidth);
      setTooltipArrowHeight(node.clientHeight);
    }
  }, [error])

  //This function is triggered when the user hover the error tooltip
  function errorHovered() {
    setErrorHover(true);
  }

  //This function is triggered when the user click on the error tooltip
  //Active for mobile devices (that doesn't behave well with hover)
  function errorClicked() {
    setErrorHover(!errorHover);
  }

  //This function is triggered when the user stops hovering the error tooltip
  function errorHoverOut() {
    setErrorHover(false);
  }

  return (
    <div className="relative w-4 flex items-center ml-2">
      <div className={`relative transition-opacity duration-200 ${error !== undefined ? 'opacity-100' : 'opacity-0'}`}>
        <i
          onMouseOver={() => errorHovered()}
          onMouseOut={() => errorHoverOut()}
          onClick={() => errorClicked()}
          className="fas fa-exclamation-circle text-xs text-red-600 cursor-pointer block">
        </i>
        <div
          className={`absolute top-0 right-0 ${errorHover ? 'opacity-100' : 'opacity-0'} transition-opacity duration-200 select-none`}
          style={{ transform: `translate(${tooltipWidth / 2}px,-${tooltipHeight + tooltipArrowHeight}px)` }}
        >
          <p ref={tooltipRefCallback} className="text-xs text-white font-semibold px-2 py-1 bg-gunmetal-400 rounded whitespace-nowrap">{error}</p>
          <svg
            ref={arrowRefCallback}
            className="relative text-gunmetal-700 opacity-90 h-1.5" x="0px" y="0px" viewBox="0 0 255 127"
            style={{ left: `${((tooltipWidth / 2) - (tooltipArrowWidth))}px` }}
          >
            <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
          </svg>
        </div>
      </div>
    </div>
  );
}

const SecurityPage: React.FC<SecurityPageProps> = (props) => {

  //VARIABLES
  const OLD_PASSWORD_FIELD: string = "OLD_PASSWORD";
  const NEW_PASSWORD_FIELD: string = "NEW_PASSWORD";
  const CONFIRM_PASSWORD_FIELD: string = "CONFIRM_PASSWORD";
  const MINIMUM_PASS_LENGHT: number = 8;
  const PASS_TOO_SHORT: string = `Password should have at least ${MINIMUM_PASS_LENGHT} characters`;
  const PASS_DOESNT_MATCH: string = `Passwords doesn't match`;
  const MAIL_SENT_MESSAGE = 'Mail sent successfully. Check your mail inbox';
  const PASSWORD_UPDATED_MESS = 'Password updated successfully';
  const GENERIC_ERR_MESS = 'Error while updating password';

  const [oldPass, setOldPass] = useState<string>('');
  const [newPass, setNewPass] = useState<string>('');
  const [confirmPass, setConfirmPass] = useState<string>('');
  const [updatePassStatus, setUpdatePassStatus] = useState<UpdatePassStatus>(UpdatePassStatus.NOT_UPDATING);
  const [showModal, setShowModal] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined)
  const timer = useTimer();
  const cherryserviceAPI = useCherryserviceAPI();
  const logger = useDebugLogger();


  //METHODS
  //Called when a user click on the text
  //to reset its password
  const handleResetPassClick = () => {
    setShowModal(true);
  }

  //Called when a user closes the modal to reset
  //the password without clicking "Send"
  const handleCloseModal = () => {
    setShowModal(false);
  }

  //Called when the user clicks on send email to
  //reset the password
  const handleSendEmailClick = () => {
    if (props.authState && props.authState.user) {
      cherryserviceAPI?.sendResetEmail(props.authState.user.email)
        .then(res => {
          setShowModal(false);
          setMailSent(true);
          timer.setTimer(() => setMailSent(false), 2000);
        })
        .catch(err => err.response && logger.log(err));
    }
  }
  //METHODS
  const handleFormChange = (event: React.FormEvent<HTMLInputElement>) => {
    const eventValue = event.currentTarget.value;
    if (event.currentTarget.name === OLD_PASSWORD_FIELD) {
      setOldPass(eventValue);
    } else if (event.currentTarget.name === NEW_PASSWORD_FIELD) {
      setNewPass(eventValue);
    } else if (event.currentTarget.name === CONFIRM_PASSWORD_FIELD) {
      setConfirmPass(eventValue);
    }
  }

  const onSubmit = () => {
    //Call api to change the password
    cherryserviceAPI?.changePassword(oldPass, newPass, confirmPass)
      .then(res => {
        //Show alert that password is updated successfully
        setUpdatePassStatus(UpdatePassStatus.UPDATE_OK);
        //Set timer to hide alert after a fixed time
        timer.setTimer(() => setUpdatePassStatus(UpdatePassStatus.NOT_UPDATING), 2000);
      })
      .catch((err) => {
        if (err.response) {
          const data = err.response?.data;
          var errorMessage: string | undefined;
          if (data?.old_password) { //has old password error?
            errorMessage = oldPasswordErrors.get(data.old_password[0]);
          } else if (data?.new_password1) { //has new password error?
            errorMessage = newPasswordErrors.get(data.new_password1[0]);
          } else if (data?.new_password2) { //has confirm password error?
            errorMessage = confirmPasswordErrors.get(data.new_password2[0]);
          }
          setError(errorMessage ?? GENERIC_ERR_MESS);
          //Set timer to hide alert after a fixed time
          timer.setTimer(() => setError(undefined), 2000);
        }
      })
  }

  //COMPONENT
  return (
    <div className="p-8 w-116">
      <SimpleAlert showText={updatePassStatus === UpdatePassStatus.UPDATE_OK ? PASSWORD_UPDATED_MESS : mailSent ? MAIL_SENT_MESSAGE : error} type={updatePassStatus === UpdatePassStatus.UPDATE_OK ? AlertType.SUCCESS : mailSent ? AlertType.INFO : AlertType.ERROR} />
      <MessageModal
        title="Send reset email"
        active={showModal}
        mainButtonLabel="Send"
        submitCallback={handleSendEmailClick}
        closeCallback={handleCloseModal}
      >
        <p className="mt-4">You will receive the link to reset the password on the email used for registration</p>
      </MessageModal>
      <h2 className="text-gunmetal font-bold text-xl">Update password</h2>
      <form className="w-full mt-8 space-y-2">
        <div>
          <label className="mb-2 text-gunmetal-350 font-semibold block">Old password</label>
          <div className="flex w-full">
            <PasswordInput value={oldPass} name={OLD_PASSWORD_FIELD} onChange={e => handleFormChange(e)} type="password" className="h-12 w-full outline-none border-2 border-gray-200 focus:border-cherry-red-200 bg-gray-50 focus:bg-cherry-red-50 rounded-lg p-4 text-gunmetal block" />
            <div className="relative w-4 h-12 flex items-center ml-2"></div>
          </div>
        </div>
        <div>
          <label className="mb-2 text-gunmetal-350 font-semibold block">New password</label>
          <div className="flex w-full">
            <PasswordInput value={newPass} name={NEW_PASSWORD_FIELD} onChange={e => handleFormChange(e)} type="password" className="h-12 w-full outline-none border-2 border-gray-200 focus:border-cherry-red-200 bg-gray-50 focus:bg-cherry-red-50 rounded-lg p-4 text-gunmetal block" />
            <ErrorTooltip error={newPass.length > 0 && newPass.length < MINIMUM_PASS_LENGHT ? PASS_TOO_SHORT : undefined} />
          </div>
        </div>
        <div>
          <label className="mb-2 text-gunmetal-350 font-semibold block">Confirm password</label>
          <div className="flex w-full">
            <PasswordInput value={confirmPass} name={CONFIRM_PASSWORD_FIELD} onChange={e => handleFormChange(e)} type="password" className="h-12 w-full outline-none border-2 border-gray-200 focus:border-cherry-red-200 bg-gray-50 focus:bg-cherry-red-50 rounded-lg p-4 text-gunmetal block" />
            <ErrorTooltip error={(newPass.length > 0 || confirmPass.length > 0) && confirmPass !== newPass ? PASS_DOESNT_MATCH : undefined} />
          </div>
        </div>
      </form>
      <div className="mt-8 flex flex-row items-center space-x-4">
        <AnimatedButton
          className="bg-cherry-red-400 rounded px-4 py-2 text-white font-semibold disabled:bg-gray-300"
          disabled={newPass.length < MINIMUM_PASS_LENGHT || confirmPass !== newPass}
          onClick={() => onSubmit()}
        >
          Save
        </AnimatedButton>
        <AnimatedTextButton
          className="rounded px-4 py-2 text-gunmetal hover:bg-gray-200 border border-gray-300 font-semibold"
          onClick={handleResetPassClick}
        >
          Send reset email
        </AnimatedTextButton>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return ({
    authState: state.user
  });
}

export default connect<ReduxStateProps, {}, {}, RootState>(mapStateToProps)(SecurityPage);