import { FormAction, RESET_FORM, UPDATE_CONFIRM_PASSWORD, UPDATE_EMAIL, UPDATE_PASSWORD } from "../actions/types";

export interface RegisterFormState {
  name: string
  surname: string
  email: string
  password: string
  confirmPassword: string
}

const defaultState: RegisterFormState= {
  name:'',
  surname:'',
  email:'',
  password:'',
  confirmPassword:''
}

export const registerFormReducer = (state: RegisterFormState = defaultState, action: FormAction) => {
  switch (action.type) {
    case UPDATE_EMAIL:
      return {
        ...state,
        email: action.payload
      }
    case UPDATE_PASSWORD:
      return {
        ...state,
        password: action.payload
      }
    case UPDATE_CONFIRM_PASSWORD:
      return {
        ...state,
        confirmPassword: action.payload
      }
    case RESET_FORM:
      return {
        ...state,
        ...defaultState
      }
    default:
      return state;
  }
}