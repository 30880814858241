import { FC } from 'react'
import { useHistory } from "react-router-dom";
import { SecondaryButtonColored } from "../buttons";

interface NavSecondaryLinkProps {
  children?: React.ReactNode
  href: string
  className?: string
}

const NavSecondaryLink: FC<NavSecondaryLinkProps> = ({ className, children, ...props }) => {

  const history = useHistory();

  return (
    <SecondaryButtonColored onClick={() => history.push(props.href)} className={`px-3 sm:px-4 py-1.5 sm:py-2 ${className ? className : ''}`}>
      {children}
    </SecondaryButtonColored>
  );
}

export default NavSecondaryLink