import React from 'react';

import { BlankCard } from '../../components/cards';
import { WithTranslation, withTranslation } from 'react-i18next';

class CloudProvidersPage extends React.Component<WithTranslation, {}, { active: number }> {

  render() {
    const { t, i18n } = this.props;
    return (
      <>
        <div className="h-32" />
        <div className="text-gunmetal-400 flex flex-row w-full justify-center py-8">
          <BlankCard className="w-2/3 p-8">
            <h1 className="text-4xl font-bold">{t("cloud_providers.title")}</h1>

            <section className="mt-8">
              <h2 className="text-2xl font-semibold">{t("cloud_providers.aws")}</h2>
              <div className="mt-4">
                <p>
                {t("cloud_providers.aws_des")} {t("cloud_providers.find")}
                </p>
                <ul className="list-disc list-outside ml-8 space-y-2 py-4">
                  <li>{t("cloud_providers.pricing")} <a href={'https://aws.amazon.com/'}>https://aws.amazon.com/</a>;</li>
                  <li>{t("cloud_providers.privacy")} <a href={'https://aws.amazon.com/legal/'}>https://aws.amazon.com/legal/</a>;</li>
                  <li>{t("cloud_providers.terms")} <a href={"https://aws.amazon.com/legal/"}>https://aws.amazon.com/legal/</a>.</li>
                </ul>
              </div>
            </section>

            <section className="mt-8">
              <h2 className="text-2xl font-semibold">{t("cloud_providers.scaleway")}</h2>
              <div className="mt-4">
                <p>
                {t("cloud_providers.scaleway_des")} {t("cloud_providers.find")}
                </p>
                <ul className="list-disc list-outside ml-8 space-y-2 py-4">
                  <li>{t("cloud_providers.pricing")} <a href={'https://www.scaleway.com/en/pricing/?tags=available'}>https://www.scaleway.com/en/pricing/?tags=available</a>;</li>
                  <li>{t("cloud_providers.privacy")} <a href={'https://www.scaleway.com/en/privacy-policy/'}>https://www.scaleway.com/en/privacy-policy/</a>;</li>
                  <li>{t("cloud_providers.terms")} <a href={"https://www.scaleway.com/en/contracts/"}>https://www.scaleway.com/en/contracts/</a>.</li>
                </ul>
              </div>
            </section>

	   <section className="mt-8">
              <h2 className="text-2xl font-semibold">{t("cloud_providers.hetzner")}</h2>
              <div className="mt-4">
                <p>
                {t("cloud_providers.hetzner_des")} {t("cloud_providers.find")}
                </p>
                <ul className="list-disc list-outside ml-8 space-y-2 py-4">
                  <li>{t("cloud_providers.pricing")} <a href={'https://www.hetzner.com/cloud'}>https://www.hetzner.com/cloud</a>;</li>
                  <li>{t("cloud_providers.privacy")} <a href={'https://www.hetzner.com/rechtliches/datenschutz'}>https://www.hetzner.com/rechtliches/datenschutz</a>;</li>
                  <li>{t("cloud_providers.terms")} <a href={"https://www.hetzner.com/rechtliches/agb"}>https://www.hetzner.com/rechtliches/agb</a>.</li>
                </ul>
              </div>
            </section>

  	   <section className="mt-8">
              <h2 className="text-2xl font-semibold">{t("cloud_providers.google")}</h2>
              <div className="mt-4">
                <p>
                {t("cloud_providers.google_des")} {t("cloud_providers.find")}
                </p>
                <ul className="list-disc list-outside ml-8 space-y-2 py-4">
                  <li>{t("cloud_providers.pricing")} <a href={'https://cloud.google.com/compute/'}>https://cloud.google.com/compute/</a>;</li>
                  <li>{t("cloud_providers.privacy")} <a href={'https://policies.google.com/privacy'}>https://policies.google.com/privacy</a>;</li>
                  <li>{t("cloud_providers.terms")} <a href={"https://cloud.google.com/terms/"}>https://cloud.google.com/terms/</a>.</li>
                </ul>
              </div>
            </section>

          </BlankCard>
        </div>
      </>
    );
  }
}

export default withTranslation()(CloudProvidersPage);