import React from 'react';

import LearnLayout from '../../layouts/learn_layout';
import { BlankCard } from '../../components/cards';
import { WithTranslation, withTranslation } from 'react-i18next';

class FAQPage extends React.Component<WithTranslation, {}, { active: number }> {
  constructor(props: any) {
    super(props);
    this.activeSectionCallback = this.activeSectionCallback.bind(this);
    this.state = {
      active: 0,
    }
  }

  componentDidMount() {
    window.scrollTo(0, window.scrollY - 1);
  }

  activeSectionCallback(index: number) {
    //COMMENTATO PERCHE' DA PROBLEMI
/*     if (index !== this.state.active) {
      this.setState({ active: index });
    } */
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <LearnLayout>
        <div className="relative py-8">
          <BlankCard className="tw-docs-card">
            <h1>{t("learn.faq_title")}</h1>
            <section>
              <h2>{t("learn.faq_projects")}</h2>
              <div>
                <p>
                  <strong>{t("learn.faq_question")}#1</strong>: {t("learn.faq_projects_1_q")} <br />
                  <strong>{t("learn.faq_answer")}</strong>: {t("learn.faq_projects_1_a")} <br /><br />
                  <strong>{t("learn.faq_question")}#2</strong>: {t("learn.faq_projects_2_q")} <br />
                  <strong>{t("learn.faq_answer")}</strong>: {t("learn.faq_projects_2_a")}
                </p>
              </div>
            </section>
            {/* <section>
              <h2>{t("learn.faq_pricing")}</h2>
              <div>
              <p>
                <strong>{t("learn.faq_question")}#1</strong>: {t("learn.faq_pricing_1_q")}<br />
                <strong>{t("learn.faq_answer")}</strong>: {t("learn.faq_pricing_1_a")} <br /><br />
                <strong>{t("learn.faq_question")}#2</strong>: {t("learn.faq_pricing_2_q")} <br />
                <strong>{t("learn.faq_answer")}</strong>: {t("learn.faq_pricing_2_a")} 
              </p>
              </div>
            </section> */}
            <section>
              <h2>{t("learn.faq_hardware")}</h2>
              <div>
              <p>
                <strong>{t("learn.faq_question")}#1</strong>: {t("learn.faq_hardware_1_q")}<br />
                <strong>{t("learn.faq_answer")}</strong>: {t("learn.faq_hardware_1_a")} <br /><br />
                <strong>{t("learn.faq_question")}#2</strong>: {t("learn.faq_hardware_2_q")} <br />
                <strong>{t("learn.faq_answer")}</strong>: {t("learn.faq_hardware_2_a")}
              </p>
              </div>
            </section>
            <section>
              <h2>{t("learn.faq_configuration")}</h2>
              <div>
              <p>
                <strong>{t("learn.faq_question")}#1</strong>: {t("learn.faq_configuration_1_q")}<br />
                <strong>{t("learn.faq_answer")}</strong>: {t("learn.faq_configuration_1_a")} <br /><br />
                <strong>{t("learn.faq_question")}#2</strong>: {t("learn.faq_configuration_2_q")} <br />
                <strong>{t("learn.faq_answer")}</strong>: {t("learn.faq_configuration_2_a")}
              </p>
              </div>
            </section>
            <section>
              <h2>{t("learn.faq_maintenance")}</h2>
              <div>
              <p>
                <strong>{t("learn.faq_question")}n#1</strong>: {t("learn.faq_maintenance_1_q")}<br />
                <strong>{t("learn.faq_answer")}</strong>: {t("learn.faq_maintenance_1_a")} <br /><br />
                <strong>{t("learn.faq_question")}#2</strong>: {t("learn.faq_maintenance_2_q")}<br />
                <strong>{t("learn.faq_answer")}</strong>: {t("learn.faq_maintenance_2_a")}<br /><br />
                <strong>{t("learn.faq_question")}#3</strong>: {t("learn.faq_maintenance_3_q")}<br />
                <strong>{t("learn.faq_answer")}</strong>: {t("learn.faq_maintenance_3_a")} <br /><br />
                <strong>{t("learn.faq_question")}#4</strong>: {t("learn.faq_maintenance_4_q")}<br />
                <strong>{t("learn.faq_answer")}</strong>: {t("learn.faq_maintenance_4_a")} 
              </p>
              </div>
            </section> 
          </BlankCard>
        </div>
      </LearnLayout>
    );
  }
}

export default withTranslation()(FAQPage);