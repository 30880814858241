import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Highlight from 'react-highlight';

import { BlankCard } from '../../components/cards';
import DocsLayout from '../../layouts/docs_layout';
import { EditableParam, CodeFragment, CodeFragmentsWrapper } from '../../components/code_fragments';

import { MessageAlert } from '../../components/alert';
import { useTimer } from '../../commons/hooks';

const NodeClientPage: React.FC<{}> = () => {

  const [ip, setIp] = useState('IP_ADDRESS');
  const [copyToClipboard, setCopyToClipboard] = useState(false);

  const timer = useTimer();

  const handleCopyToClipboard = () => {
    setCopyToClipboard(true);
    timer.setTimer(() => setCopyToClipboard(false), 2000);
  }

  return (
    <DocsLayout>
      <MessageAlert
        showText={copyToClipboard ? 'Copied to clipboard' : undefined}
        className="h-14 w-48"
      />
      <div className="relative py-8">
        <BlankCard wrapContent className="tw-docs-card">
          <h1>Get started with the Node.js Client</h1>
          <p>The node.js client wraps AyraDB's native HTTP REST APIs and provides a high-level programming environment that you can use to develop your applications. This page provides a general introduction to the node.js client.</p>
          <section>
            <h2>Prerequisites</h2>
            <div>
              <p>Before starting to use the node.js client you need:</p>
              <ul>
                <li>An AyraDB account</li>
                <li>An active subscription with a valid IP address</li>
              </ul>
            </div>
          </section>
          <section>
            <h2>Add the client to your app</h2>
            <div>
              <p>To use AyraDB the first thing to do is integrating the node.js client into your application. The client is available on <a target="_blank" rel="noreferrer" href="https://www.npmjs.com/package/ayradb" className="text-cherry-red-400 hover:text-cherry-red-700">npm</a> or  <a target="_blank" rel="noreferrer" href="https://pypi.org/project/ayradb/" className="text-cherry-red-400 hover:text-cherry-red-700">yarn</a>.</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Direct link"
                  >
                    <div className="bg-code-black p-4 text-2xs sm:text-code">
                      <p className="text-code-white"><span className="text-white">$</span> npm install ayradb</p>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Connect to the service</h2>
            <div>
              <p className="mb-2">Now you are ready to connect to the service and start using it.</p>
              <p>To create a new connection it is necessary to provide:</p>
              <ul className="list-outside list-disc ml-6 space-y-1 py-1">
                <li className="">A valid <span className="font-semibold text-red-crayola">IP_ADDRESS</span> of one of the server listed inside your subscriptions.</li>
              </ul>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Connect"
                  >
                    <div className="bg-code-black font-code py-2 px-4 relative text-2xs sm:text-code">
                      {/* TODO: Add on copy callback that triggers an alert with "Copied to clipboard" */}
                      <CopyToClipboard onCopy={handleCopyToClipboard} text={`from ayradb import AyraDB\n\ndb: AyraDB = AyraDB(ip="${ip}")`}>
                        <button className="absolute right-2 top-1 focus:outline-none"><i className="far fa-clone text-white hover:text-gray-200"></i></button>
                      </CopyToClipboard>
                      <p className="text-code-white mb-6"><span className="text-code-orange">import</span> &#123; AyraDB &#125; <span className="text-code-orange">from</span> 'ayradb'</p>
                      <div className="text-code-white mt-2 space-y-2">
                        <p>const db: AyraDB = new AyraDB();</p>
                        <p>db.connect(
                          <EditableParam
                            textBefore={`"`}
                            textAfter={`"`}
                            value={ip}
                            onChange={value => setIp(value)}
                          />)</p>
                      </div>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Create a table</h2>
            <div>
              <p>AyraDB manages three types of tables, accommodating for different user requirements:</p>
              <ul>
                <li><span className="font-semibold">fixed length</span>, used when column types are standard SQL and there is a limit for the size of each field;</li>
                <li><span className="font-semibold">padded</span>, used when column types are not standard SQL types, for example, if one or more columns contain a json file;</li>
                <li><span className="font-semibold">noSQL</span> or scheme-less, which are tables without an explicit record structure that can accommodate in each record an arbitrary number of fields, and each field can have an arbitrary label.</li>
              </ul>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Fixed length"
                  >
                    <div className="p-4">
                      <p>For fixed length tables:</p>
                      <ul>
                        <li className="">at least <strong>one field must be provided</strong>;</li>
                        <li className="">maximum <strong>key column size</strong> and <strong>maximum fields</strong> size <strong>must be provided</strong>;</li>
                        <li>new fields can be added at a later time</li>
                      </ul>
                      <p className="mt-4">Here an example on how to create a fixed length table:</p>
                    </div>
                    <div className="text-2xs sm:text-code">
                      <Highlight className="typescript p-4">
                        {`import { AyraDB, Table, CreateTableError } from 'ayradb';

let table: Table = db.create("new_fixed_length_table");
const fields: ColumnData[] = [{
	column_label:  'field1',
	column_max_net_length:  1024
	}, {
	column_label:  'field2',
	column_max_net_length:  1024
}]

const max_key_column_size_byte = 1024 //Required for fixed length tables
table.create(Table.Type.FIXED_LENGTH, fields, { key_max_size:  max_key_column_size_byte })
	.then(resp => {console.log("New fixed length table created")})
	.catch(err => {
		switch(error){
            case CreateTableError.TABLE_ALREADY_EXISTS:
                console.log('ERROR: the table already exists on the database')
                break;
			//Manage remaining errors...
        }
	})`}
                      </Highlight>
                    </div>

                  </CodeFragment>
                  <CodeFragment
                    label="Padded"
                  >
                    <div className="p-4">
                      <p>For padded tables:</p>
                      <ul className="list-outside list-disc ml-6 space-y-1 py-1">
                        <li className="">at least <strong>one field must be provided</strong>;</li>
                        <li>new fields can be added to at a later time</li>
                      </ul>
                      <p className="mt-4">Here an example on how to create a padded table:</p>
                    </div>
                    <div className="text-2xs sm:text-code">
                      <Highlight className="typescript p-4">
                        {`let table: Table = db.create("new_padded_table");
const fields: ColumnData[] = [{
	column_label:  'field1',
	column_max_net_length:  1024
	}, {
	column_label:  'field2',
	column_max_net_length:  1024
}]

table.create(Table.Type.PADDED, fields)
	.then(resp => {console.log("New padded table created")})
	.catch(err => {
		switch(error){
            case CreateTableError.TABLE_ALREADY_EXISTS:
                console.log('ERROR: the table already exists on the database')
                break;
			//Manage remaining errors...
        }
	})`}
                      </Highlight>
                    </div>

                  </CodeFragment>
                  <CodeFragment
                    label="NoSQL"
                  >
                    <div className="p-4">
                      <p>NoSQL tables do not have a fixed structure, so the <strong>fields argument is omitted</strong></p>
                      <p className="mt-4">Here an example on how to create a noSQL table:</p>
                    </div>
                    <div className="text-2xs sm:text-code">
                      <Highlight className="typescript p-4">
                        {`let table : Table = db.create("new_nosql_table");

table.create(Table.Type.NO_SQL)
	.then(resp => {console.log("New no sql table created")})
	.catch(err => {
		switch(error){
            case CreateTableError.TABLE_ALREADY_EXISTS:
                console.log('ERROR: the table already exists on the database')
                break;
			//Manage remaining errors...
        }
	})`}
                      </Highlight>
                    </div>

                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Insert or update a record</h2>
            <div>
              <p>AyraDB provides the functionalities to insert and update records of a table. The <strong>upsert</strong> method allows to add a new record in the table if it does not have already a record with the same key, otherwise the record is updated.</p>
              <p className="mt-2">Insertion can be limited to a subset of fields. Field names and field values must be specified using an object.</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Upsert record"
                  >
                    <div className="text-2xs sm:text-code">
                      <Highlight className="typescript p-4">
                        {`const  key = "key"
const  fields = {
		'field0':  'prova0',
		'field1':  'prova1',
}

table.upsert(key, fields)
	.then(resp => "Record inserted successfully")
	.catch(err => {
		//Use UpsertError enum to understand error codes
	})`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Read a record</h2>
            <div>
              <p>To read a record in AyraDB you have to call the <strong>read</strong> method. Read can be limited to a subset of fields with the fields argument (if set to null, all fields are retrieved).</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Read a record"
                  >
                    <div className="p-4">
                      <p>To read every field of a record you only need to pass its key to the <strong>read</strong> method.</p>
                      <p className="mt-4">Here an example on how to read a record (from the previously created table):</p>
                    </div>
                    <div className="text-2xs sm:text-code">
                      <Highlight className="typescript p-4">
                        {`const key = "key"

try {
	let response = await table.read(key)
	//Suppose that we want to know the value of "field0" of the record inserted before
  	console.log(response.record.field0);
} catch(error){
	//Use ReadError enum to understand error codes
}`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                  <CodeFragment
                    label="Read specific fields"
                  >
                    <div className="p-4">
                      <p>To read only specific fields we have to specify their names in a list and pass the list to the optional argument <strong>fields</strong>:</p>
                    </div>
                    <div className="text-2xs sm:text-code">
                      <Highlight className="typescript p-4">
                        {`const key = "key"
let  fields = ['field0', 'field5']

try {
	let response = await table.read(key, fields)
	//Suppose that we want to know the value of "field0" of the record inserted before
  	console.log(response.record.field0);
} catch(error){
	//Use ReadError enum to understand error codes
}`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Delete a record</h2>
            <div>
              <p>In AyraDB the delete method can be used differently according to the type of table on which is called.</p>
              <p className="mt-2"><strong>Important:</strong> The operation completes successfully even when a record with the provided key does not exists in the table or specific fields doesn't exists in a record.</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Delete record"
                  >
                    <div className="p-4">
                      <p>Every table allows to delete a record completely.</p>
                      <p className="mt-4">Here an example on how to delete a record (from the previously created table):</p>
                    </div>
                    <div className="text-2xs sm:text-code">
                      <Highlight className="typescript p-4">
                        {`const  key = "key"

try {
	await table.delete(key, fields)
	console.log("Record successfully deleted")
}catch(error){
	//Use DeleteError enum to distinguish error codes
}`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                  <CodeFragment
                    label="Delete specific fields"
                  >
                    <div className="p-4">
                      <p><strong>Only NOSQL</strong> tables allow to delete specific fields without deleting the whole record:</p>
                    </div>
                    <div className="text-2xs sm:text-code">
                      <Highlight className="typescript p-4">
                        {`const  key = "key"
const  fields = ['field0', 'field5']

try{
	await table.delete(key, fields)
	console.log("Record successfully deleted")
}catch(error){
	//Use DeleteError enum to distinguish error codes
}`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Get table structure</h2>
            <div>
              <p className="">To retrieve the structure of a table we just need to call the <strong>getStructure</strong> method on a Table object:</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Direct link"
                  >
                    <div className="text-2xs sm:text-code">
                      <Highlight className="typescript p-4">
                        {`try	{
	let resp = await  table.getStructure() 
	response.structure.forEach(column => {
		console.log('Name: '+ column.column_label)
	})
} catch(error) {
	//Use GetTableStructureError to distinguish error codes
}
`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Truncate a table</h2>
            <div>
              <p className="">In AyraDB it is possible to delete all the record of a table with a single api call.</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Direct link"
                  >
                    <div className="text-2xs sm:text-code">
                      <Highlight className="typescript p-4">
                        {`try{
	await table.truncate()
	console.log("Table is now empty!")
} catch(error){
	//Use TruncateTableError to distinguish error codes
}
`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Perform a query</h2>
            <div>
              <p className="">AyraDB provides an API to perform SQL-like queries on a single table.</p>
              <p className="mt-2"><strong>Important:</strong> the semicolon (';') at the end of the query is mandatory.</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Direct link"
                  >
                    <div className="text-2xs sm:text-code">
                      <Highlight className="typescript p-4">
                        {`import { AyraDB } from 'ayradb';
  
  const db = new AyraDB(); //db connected on a previous operation
  
  const query = db.newRawQuery('SELECT * FROM ' + tableName + ' WHERE field = "value";')
  
  let records = []

  try{
    let queryResponse = await query.execute()
      while(!queryResponse.isLast()){
          records.push(...queryResponse.records)
          queryResponse = await queryResponse.next
      }
  } catch(error){
    console.log("An error occurred while executing the query!")
  }
`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Drop a table</h2>
            <div>
              <p>Tables can also be deleted:</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Direct link"
                  >
                    <div className="text-2xs sm:text-code">
                      <Highlight className="typescript p-4">
                        {`try{
	await table.drop()
	console.log("The table was deleted!")
} catch(error){
	//Use DropTableError to distinguish error codes
}
`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Close the connection</h2>
            <div>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Direct link"
                  >
                    <div className="text-2xs sm:text-code">
                      <Highlight className="python p-4">
                        {`db.close_connection();`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
        </BlankCard>
      </div>
    </DocsLayout>
  );
}

export default NodeClientPage;