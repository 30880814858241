
export interface CountryType {
  name: string
  prefix: number
  id: string
  patterns?: Pattern[]
}

interface Pattern {
  regexp: RegExp
  regexpStrict: RegExp
  formatter: (rawNumber:string)=>string
}

export const countries = new Map<string, CountryType>([
    ['af', { 
      id: 'af',
      name: 'Afghanistan', 
      prefix: 93, 
    }],
    ['al', { 
      id: 'al',
      name: 'Albania', 
      prefix: 355, }],
    ['dz', { 
      id: 'dz',
      name: 'Algeria', 
      prefix: 213,  
    }],
    ['ad', { name: 'Andorra', prefix: 376, id: 'ad' }],
    ['ag', { name: 'Antigua and Barbuda', prefix: 1268, id: 'ag' }],
    ['ar', { name: 'Argentina', prefix: 54, id: 'ar' }],
    ['am', { name: 'Armenia', prefix: 374, id: 'am' }],
    ['aw', { name: 'Aruba', prefix: 297, id: 'aw' }],
    ['au', { name: 'Australia', prefix: 61, id: 'au' }],
    ['at', { name: 'Austria', prefix: 43, id: 'at' }],
    ['az', { name: 'Azerbaijan', prefix: 994, id: 'az' }],
    ['bs', { name: 'Bahamas', prefix: 1242, id: 'bs' }],
    ['bh', { name: 'Bahrain', prefix: 994, id: 'bh' }],
    ['bd', { name: 'Bangladesh', prefix: 880, id: 'bd' }],
    ['bb', { name: 'Barbados', prefix: 1246, id: 'bb' }],
    ['by', { name: 'Belarus', prefix: 375, id: 'by' }],
    ['be', { name: 'Belgium', prefix: 994, id: 'be' }],
    ['bz', { name: 'Belize', prefix: 501, id: 'bz' }],
    ['bj', { name: 'Benin', prefix: 229, id: 'bj' }],
    ['bt', { name: 'Bhutan', prefix: 975, id: 'bt' }],
    ['bo', { name: 'Bolivia', prefix: 591, id: 'bo' }],
    ['it', { 
      id: 'it',
      name: 'Italy', 
      prefix: 39,
      patterns: [{
        regexp: /^(32[89]|33[013-9]|34[04-9]|35[01]|36[068]|37[019]|38[089])\d{0,7}$/,
        regexpStrict: /^(32[89]|33[013-9]|34[04-9]|35[01]|36[068]|37[019]|38[089])\d{6,7}$/,
        formatter: (rawNumber)=>{
          if (rawNumber.length>3&&rawNumber.indexOf('\t') === -1) {
            return rawNumber.substring(0, 3) + '\t' + rawNumber.substring(3)
          }
          //Otherwise return the original value
          else {
            return rawNumber
          }
        }
      }]
    }],
  ])