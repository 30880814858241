import React from 'react';

import LandingPage from './pages/landing';
import JavaClientPage from './pages/software_client_java';
import PythonClientPage from './pages/software_client_python';
import CloudNativePage from './pages/product_cloud_native';
import CloudMigrationPage from './pages/product_cloud_migration';
import CloudProvidersPage from './pages/product_cloud_providers'
import ConfigurationPage from './pages/learn_configuration';
import FAQPage from './pages/learn_faq';
import ContactsPage from './pages/product_contacts';
import PricingPage from './pages/product_pricing';
import FeaturesPage from './pages/learn_features';
import BenchmarksPage from './pages/learn_benchmarks';
import ConsoleHome from './pages/console_home';
import ProjectsPage from './pages/console_projects_summary';
import ProjectPage from './pages/console_project';
import NewSubscriptionPage from './pages/console_subscription_new';
import SubscriptionPage from './pages/console_subscription';
import ClientSubscriptionPage from './pages/console_subscription_client';
import BillingPage from './pages/console_billing';
import TermsAndConditionPage from './pages/z_terms_and_conditions';
import LogoutPage from './pages/console_logout';
import StartPlanPage from './pages/product_pricing_custom';
import AccountPage from './pages/settings_account';
import SecurityPage from './pages/settings_security';
import DocumentsPage from './pages/settings_documents';
import VerifyEmailPage from './pages/auth_verify_email';
import ResetPasswordPage from './pages/auth_reset_pass';
import LinksPage from './pages/admin_invitation_sender';
import NodeClientPage from './pages/software_client_node';

//ASSIGN A LABEL ONLY TO ROUTES THAT SHOULD BE AUTOMATICALLY DISPLAYED
//IN NAVBARS OR SIDEBARS

export enum LinkPriority {
    NORMAL,
    HIGHLIGHTED,
    PRIMARY
}

type Label = {
    title: string,
    parentLabel?: string,
    icon?: React.ReactNode;
    priority?: LinkPriority,
}

type Route = {
    label?: Label,
    secondaryLabel?:string,
    layout: `${string}/`, 
    path: string,
    page: React.FunctionComponent | React.ComponentClass;
}

const routes: Route[] = [{
    layout: '/',
    path: '',
    page: LandingPage
},{
    layout: '/',
    path: 'cloud-native',
    page: CloudNativePage,
    label: {
        title: 'cloud_native.navbar_label',
        priority: LinkPriority.NORMAL,
        parentLabel: 'cloud.navbar_label',
    }
},{
    layout: '/',
    path: 'migration',
    page: CloudMigrationPage,
    label: {
        title: 'cloud_migration.navbar_label',
        priority: LinkPriority.NORMAL,
        parentLabel: 'cloud.navbar_label',
    }
},{
    layout: '/',
    path: 'providers',
    page: CloudProvidersPage,
    label: {
        title: 'cloud_providers.navbar_label',
        priority: LinkPriority.NORMAL,
        parentLabel: 'cloud.navbar_label',
    }
},{
    layout: '/',
    path: 'java-client',
    page: JavaClientPage,
    label: {
        title: 'software.java_navbar_label',
        priority: LinkPriority.NORMAL,
        parentLabel: 'software.navbar_label',
    }
},{
    layout: '/',
    path: 'node-client',
    page: NodeClientPage,
    label: {
        title: 'software.js_navbar_label',
        priority: LinkPriority.NORMAL,
        parentLabel: 'software.navbar_label',
    }
},{
    layout: '/',
    path: 'python-client',
    page: PythonClientPage,
    label: {
        title: 'software.python_navbar_label',
        priority: LinkPriority.NORMAL,
        parentLabel: 'software.navbar_label',
    }
},{
    layout: '/',
    path: 'learn/features',
    page: FeaturesPage,
    label: {
        title: 'learn.navbar_label',
        priority: LinkPriority.NORMAL,
    },
    secondaryLabel: 'learn.features_navbar_label'
},
// {
//     layout: '/',
//     path: 'learn/benchmarks',
//     page: BenchmarksPage,
//     secondaryLabel: 'learn.benchmarks_navbar_label'
// },
{
    layout: '/',
    path: 'learn/configuration',
    page: ConfigurationPage,
    secondaryLabel:'learn.configuration_navbar_label'
},{
    layout: '/',
    path: 'learn/faq',
    page: FAQPage,
    secondaryLabel: 'learn.faq_navbar_label'
},
// {
//     layout: '/',
//     path: 'pricing',
//     page: PricingPage,
//     label: {
//         title: 'pricing.navbar_label',
//         priority: LinkPriority.NORMAL,
//     }
// },
{
    layout: '/',
    path: 'verify_email/:status/',
    page: VerifyEmailPage,
},{
    layout: '/',
    path: 'verify_email',
    page: VerifyEmailPage,
},{
    layout: '/',
    path: 'contacts',
    page: ContactsPage,
    label: {
        title: 'contact_us.navbar_label',
        priority: LinkPriority.NORMAL,
    }
},{
    layout: '/',
    path: 'terms_and_conditions',
    page: TermsAndConditionPage,
},{
    layout: '/',
    path: 'password-reset',
    page: ResetPasswordPage,
},{
    layout: '/console/',
    path: 'home',
    page: ConsoleHome,
    label: {
        title: 'Home',
        icon: <svg height="13pt" viewBox="0 0 512 512" width="14pt" xmlns="http://www.w3.org/2000/svg"><path d="m498.195312 222.695312c-.011718-.011718-.023437-.023437-.035156-.035156l-208.855468-208.847656c-8.902344-8.90625-20.738282-13.8125-33.328126-13.8125-12.589843 0-24.425781 4.902344-33.332031 13.808594l-208.746093 208.742187c-.070313.070313-.140626.144531-.210938.214844-18.28125 18.386719-18.25 48.21875.089844 66.558594 8.378906 8.382812 19.445312 13.238281 31.277344 13.746093.480468.046876.964843.070313 1.453124.070313h8.324219v153.699219c0 30.414062 24.746094 55.160156 55.167969 55.160156h81.710938c8.28125 0 15-6.714844 15-15v-120.5c0-13.878906 11.289062-25.167969 25.167968-25.167969h48.195313c13.878906 0 25.167969 11.289063 25.167969 25.167969v120.5c0 8.285156 6.714843 15 15 15h81.710937c30.421875 0 55.167969-24.746094 55.167969-55.160156v-153.699219h7.71875c12.585937 0 24.421875-4.902344 33.332031-13.808594 18.359375-18.371093 18.367187-48.253906.023437-66.636719zm0 0" fill="#EBC6C9" /></svg>,
    }
},{
    layout: '/',
    path: 'start-new-plan',
    page: StartPlanPage,
},{
    layout: '/console/',
    path: 'projects/project/:id/subscription/new',
    page: NewSubscriptionPage,
},{
    layout: '/console/',
    path: 'projects/project/:id/subscription/:id',
    page: SubscriptionPage,
},{
    layout: '/console/',
    path: 'projects/project/:id/subscription/:id/client',
    page: ClientSubscriptionPage,
},{
    layout: '/console/',
    path: 'projects/project/:id/subscription/:id/:fragment',
    page: SubscriptionPage,
},{
    layout: '/console/',
    path: 'projects/project/:id/subscription/:id/client/:fragment',
    page: ClientSubscriptionPage,
},{
    layout: '/console/',
    path: 'projects/project/:id',
    page: ProjectPage,
},{
    layout: '/console/',
    path: 'projects',
    page: ProjectsPage,
    label: {
        title: 'Projects',
        icon: <svg className="-ml-1 -mr-1.5" width="21.5pt" height="18pt" viewBox="0 0 18 18" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M10.557 11.99l-1.71-2.966 1.71-3.015h3.42l1.71 3.01-1.71 2.964h-3.42zM4.023 16l-1.71-2.966 1.71-3.015h3.42l1.71 3.01L7.443 16h-3.42zm0-8.016l-1.71-2.966 1.71-3.015h3.42l1.71 3.015-1.71 2.966h-3.42z" fill="#EBC6C9" ></path></svg>,
    }
},{
    layout: '/console/',
    path: 'billing',
    page: BillingPage,
    label: {
        title: 'Billing',
        icon: <i className="fas fa-credit-card -ml-0.5 -mr-0.5 text-baby-pink text-xl"></i>,
    }
},{
    layout: '/',
    path: 'logout',
    page: LogoutPage,
},{
    layout: '/settings/',
    path: 'account',
    page: AccountPage,
    label: {
        title: 'Account',
        icon: <i className="fas fa-user -ml-0.5 -mr-0.5 text-baby-pink text-xl"></i>,
    }
},{
    layout: '/settings/',
    path: 'security',
    page: SecurityPage,
    label: {
        title: 'Security',
        icon: <i className="fas fa-lock -ml-0.5 -mr-0.5 text-baby-pink text-xl"></i>,
    }
},{
    layout: '/settings/',
    path: 'documents',
    page: DocumentsPage,
    label: {
        title: 'Documents',
        icon: <i className="fas fa-file -ml-0.5 -mr-0.5 text-baby-pink text-xl"></i>,
    }
},{
    layout: '/admin/',
    path: 'links_generator',
    page: LinksPage,
    label: {
        title: 'Account links',
        icon: <svg height="13pt" viewBox="0 0 512 512" width="14pt" xmlns="http://www.w3.org/2000/svg"><path d="m498.195312 222.695312c-.011718-.011718-.023437-.023437-.035156-.035156l-208.855468-208.847656c-8.902344-8.90625-20.738282-13.8125-33.328126-13.8125-12.589843 0-24.425781 4.902344-33.332031 13.808594l-208.746093 208.742187c-.070313.070313-.140626.144531-.210938.214844-18.28125 18.386719-18.25 48.21875.089844 66.558594 8.378906 8.382812 19.445312 13.238281 31.277344 13.746093.480468.046876.964843.070313 1.453124.070313h8.324219v153.699219c0 30.414062 24.746094 55.160156 55.167969 55.160156h81.710938c8.28125 0 15-6.714844 15-15v-120.5c0-13.878906 11.289062-25.167969 25.167968-25.167969h48.195313c13.878906 0 25.167969 11.289063 25.167969 25.167969v120.5c0 8.285156 6.714843 15 15 15h81.710937c30.421875 0 55.167969-24.746094 55.167969-55.160156v-153.699219h7.71875c12.585937 0 24.421875-4.902344 33.332031-13.808594 18.359375-18.371093 18.367187-48.253906.023437-66.636719zm0 0" fill="#EBC6C9" /></svg>,
    }
}];

export default routes;