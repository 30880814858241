import { useState, useEffect } from 'react';

const useMounted = (): boolean => {
  const [mounted, setMounted] = useState(false);

  //Hook to keep track of mount status
  //To avoid memory leaks from fetches.
  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, [])


  return mounted;
}

export default useMounted;