import { useRef } from 'react';

class Logger {

    isDebug: boolean

    constructor(){
        this.isDebug = process.env.NODE_ENV !== 'production';
    }

    log(message?: any, ...optionalParams: any[]) {
        if(this.isDebug) console.log(message, optionalParams)
    }

    error(message?: any, ...optionalParams: any[]){
        if(this.isDebug) console.error(message, optionalParams)
    }
}

const useDebugLogger = () => {
    const ref = useRef<Logger>(new Logger())
    return ref.current
}

export default useDebugLogger