import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { refreshAuthentication } from "../../store/auth/actions";

const useLoadAuthenticatedUser = ()=>{
    const dispatch = useDispatch();
    const authState = useSelector((state:RootState)=>state.user)
    const [userLoaded,setUserLoaded] = useState(false);

    useEffect(()=>{
        dispatch(refreshAuthentication())
    },[dispatch])

    useEffect(()=>{
        if (authState!==null) setUserLoaded(true)
    },[authState])

    return userLoaded
}

export default useLoadAuthenticatedUser;