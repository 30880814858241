import React, { useState, useEffect } from "react";
import CopyToClipboard from 'react-copy-to-clipboard';
import { useHover } from "../../../commons/hooks";

import useTimer from "../../../commons/hooks/use_timer";

interface CopyableIPProps {
  ip: string
  className?: string
}

const CopyableIP: React.FC<CopyableIPProps> = ({ ip, className }) => {

  const [clicked, setClicked] = useState(false);
  //Used to delay the change of text value according to animation duration
  const [changeText, setChangeText] = useState(false);
  const [isTimerRunning, setTimerIsRunning] = useState(false);
  const [hoverRef, isHovered] = useHover()
  const colorTimer = useTimer()
  const textTimer = useTimer()

  function handleCopy() {
    if(!clicked&&!changeText){
      setClicked(true);
      setChangeText(true);
    } 
  }

  useEffect(() => {
    if (clicked) {
      setTimerIsRunning(true);
    }
  }, [clicked, colorTimer])
  
  //Hook checking if the timer to show copied should run or not
  useEffect(()=>{
    if(isTimerRunning){
      if (isHovered){
        //Stop timer while the IP is hovered
        colorTimer.clearTimer()
        textTimer.clearTimer()
      } else {
        //Restart the timer once the ip is not hovered anymore
        colorTimer.setTimer(() => {
          setClicked(false)
          setTimerIsRunning(false);
        }, 1000)
        textTimer.setTimer(()=>{
          setChangeText(false)
        },1200) //colorTimer + animation duration
      }
    }
  },[isTimerRunning, isHovered, colorTimer, textTimer])

  return (
    <CopyToClipboard onCopy={handleCopy} text={ip}>
      <div ref={hoverRef} className={`group select-none flex items-center cursor-pointer`}>
        <p className={`${className ? className : ''}`}>{ip}</p>
        <p className={`transition-all duration-200 ml-2 text-sm rounded-sm px-1 ${clicked ? 'opacity-100 bg-green-200 text-green-700': 'opacity-0 group-hover:opacity-100 bg-gray-100'}`}>{changeText ? 'Copied!' : 'Copy'}</p>
      </div>
    </CopyToClipboard>
  );
}

export default CopyableIP