import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Highlight from 'react-highlight';

import { BlankCard } from '../../components/cards';
import DocsLayout from '../../layouts/docs_layout';
import { EditableParam, CodeFragment, CodeFragmentsWrapper } from '../../components/code_fragments';

import { MessageAlert } from '../../components/alert';
import { useTimer } from '../../commons/hooks';

const JavaClientPage: React.FC<{}> = () => {

  const websiteDomain = process.env.REACT_APP_WEBSITE_DOMAIN
  const baseURL = websiteDomain + ":8443";

  const PORT = 10019;
  const [ip, setIp] = useState('IP_ADDRESS');
  const [debug, setDebug] = useState('DEBUG');
  const [connection, setConnection] = useState('PERSISTENT_CONNECTION_MODE');

  const [copyToClipboard, setCopyToClipboard] = useState(false);
  const timer = useTimer();

  const handleCopyToClipboard = () => {
    setCopyToClipboard(true);
    timer.setTimer(() => setCopyToClipboard(false), 2000);
  }

  return (
    <DocsLayout>
      <MessageAlert
        showText={copyToClipboard ? 'Copied to clipboard' : undefined}
        className="h-14 w-48"
      />
      <div className="relative py-8">
        <BlankCard wrapContent className="tw-docs-card">
          <h1>Get started with the Java Client</h1>
          <p>The Java client wraps AyraDB's native HTTP REST APIs and provides a high-level programming environment that you can use to develop your applications. This page provides a general introduction to the Java client.</p>
          <section>
            <h2>Prerequisites</h2>
            <div>
              <p className="mb-2">Before starting to use the Java Client you need:</p>
              <ul>
                <li>An AyraDB account</li>
                <li>An active subscription with a valid IP address</li>
              </ul>
            </div>
          </section>
          <section>
            <h2>Add the client to your app</h2>
            <div>
              <p className="">To use AyraDB the first thing to do is integrating the Java Client into your application</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Direct link"
                  >
                    <div className="p-4">
                      <p className="block">Download the client using the following link:</p>
                      <a href={baseURL + ":8443/static/ayradb_client_java_latest.jar"} className="text-cherry-red-400 hover:text-cherry-red-200 cursor-pointer " >Java client</a>
                      <p className="block mt-2">The downloaded jar can then be added to your project</p>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Connect to the service</h2>
            <div>
              <p className="mb-2">Now you are ready to connect to the service and start using it.</p>
              <p>To create a new connection it is necessary to provide:</p>
              <ul>
                <li>A valid <span className="font-semibold text-red-crayola">IP_ADDRESS</span> of one of the server listed inside your subscriptions.</li>
                <li>The <span className="font-semibold text-red-crayola">PORT</span>, which should be defaulted to <span className="font-semibold">{PORT}</span>.</li>
                <li><em>(OPTIONAL)</em> The <span className="font-semibold text-red-crayola">DEBUG</span> flag, which can be <span className="font-semibold">true or false</span>.</li>
                <li><em>(OPTIONAL)</em> The <span className="font-semibold text-red-crayola">PERSISTENT_CONNECTION_MODE</span> flag, which is a <span className="font-semibold">boolean</span> value. If true, a single connection is used to perform multiple actions.</li>
              </ul>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Connect"
                  >
                    <div className="bg-code-black font-code py-2 px-4 relative text-2xs sm:text-code">
                      {/* TODO: Add on copy callback that triggers an alert with "Copied to clipboard" */}
                      <CopyToClipboard onCopy={handleCopyToClipboard} text={`CherryClient client = new CherryClient(${ip},${PORT},${debug},${connection})`}>
                        <button className="absolute right-2 top-1 focus:outline-none"><i className="far fa-clone text-white hover:text-gray-200"></i></button>
                      </CopyToClipboard>
                      <p className="text-code-gray">{`//Example: CherryClient client = new CherryClient("192.168.0.1",${PORT},false,true);`}</p>
                      <div className="text-code-white mt-2">
                        CherryClient client = <span className="text-code-orange">new</span> CherryClient("
                        <EditableParam
                          value={ip}
                          onChange={value => setIp(value)}
                        />",
                        <span className="text-code-blue">{PORT}</span>,
                        <EditableParam
                          value={debug}
                          onChange={value => setDebug(value)}
                        />,
                        <EditableParam
                          value={connection}
                          onChange={value => setConnection(value)}
                        />);
                      </div>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Create a table</h2>
            <div>
              <p className="mb-2">AyraDB manages three types of tables, accommodating for different user requirements:</p>
              <ul>
                <li className=""><span className="font-semibold">fixed length</span>, used when column types are standard SQL and there is a limit for the size of each field;</li>
                <li className=""><span className="font-semibold">padded</span>, used when column types are not standard SQL types, for example, if one or more columns contain a json file;</li>
                <li className=""><span className="font-semibold">noSQL</span> or scheme-less, which are tables without an explicit record structure that can accommodate in each record an arbitrary number of fields, and each field can have an arbitrary label.</li>
              </ul>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Fixed length"
                  >
                    <div className="p-4">
                      <p>For fixed length tables:</p>
                      <ul>
                        <li>at least <strong>one field must be provided</strong>;</li>
                        <li>maximum key <strong>column size</strong> and <strong>maximum fields</strong> size <strong>must be provided</strong>;</li>
                        <li>new fields can be added to at a later time</li>
                      </ul>
                      <p className="mt-4">Here an example on how to create a fixed length table:</p>
                    </div>
                    <div className="text-2xs sm:text-code">
                      <Highlight className="java p-4">
                        {`//Select a name for the table
String tableName = "test_table_fixedlength";

//Define the fields
String[] fieldsNames = { "field0", "field1", "field2" };

//Define maximum size of keys in bytes
long maxKeyColumnSizeByte = 1024L;

//Define the maximum size for each field
long[] maxFieldsSizeByte = { 1024L, 256L, 512L };

//Create the table
Response rct = client.createFixedLengthTable(table, fieldsNames, maxKeyColumnSizeByte, maxFieldsSizeByte);

if(rct.isSuccess()) 

    System.out.println("New fixed length table created!");

} else System.out.println(rct.getErrorCode() + " - " + rct.getErrorMessage());`}
                      </Highlight>
                    </div>

                  </CodeFragment>
                  <CodeFragment
                    label="Padded"
                  >
                    <div className="p-4">
                      <p>For padded tables:</p>
                      <ul>
                        <li className="">at least <strong>one field must be provided</strong>;</li>
                        <li>new fields can be added to at a later time</li>
                      </ul>
                      <p className="mt-4">Here an example on how to create a padded table:</p>
                    </div>
                    <div className="text-2xs sm:text-code">
                      <Highlight className="java p-4">
                        {`String table = "test_table_padded";

String[] fieldsNames = { "field0", "field1", "field2" };

Response rct = client.createPaddedTable(table, fieldsNames);

if(rct.isSuccess()) 

    System.out.println("New padded table created!");

} else System.out.println(rct.getErrorCode() + " - " + rct.getErrorMessage());`}
                      </Highlight>
                    </div>

                  </CodeFragment>
                  <CodeFragment
                    label="NoSQL"
                  >
                    <div className="p-4">
                      <p>NoSQL tables do not have a fixed structure, so the <strong>fields argument is omitted</strong></p>
                      <p className="mt-4">Here an example on how to create a noSQL table:</p>
                    </div>
                    <div className="text-2xs sm:text-code">
                      <Highlight className="java p-4">
                        {`String table = "test_table_nosql";

Response rct = client.createNoSqlTable(table);

if(rct.isSuccess()) 

    System.out.println("New nosql table created!");

} else System.out.println(rct.getErrorCode() + " - " + rct.getErrorMessage());`}
                      </Highlight>
                    </div>

                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Insert or update a record</h2>
            <div>
              <p className="">AyraDB provides the functionalities to insert and update records of a table. The <strong>insert</strong> method allows to add a new record in the table if it does not have already a record with the same key, otherwise the record is updated.</p>
              <p className="mt-2">Insertion can be limited to a subset of fields with the fields argument. The fields argument must be specified, and it must contain the same number of items of fieldsValues.</p>
              <p className="mt-2">To insert a new record it is necessary to specify the type of the table we are using. This activity can be done using the <strong>TableFillType</strong> enum value:</p>
              <ul>
                <li className=""><strong>TableFillType.FIXED_LENGHT</strong>, when the table is of type fixed_length</li>
                <li className=""><strong>TableFillType.PADDED</strong>, when the table is of type padded</li>
                <li className=""><strong>TableFillType.NOSQL</strong>, when the table is of type noSQL</li>
              </ul>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Direct link"
                  >
                    <div className="text-2xs sm:text-code">
                      <Highlight className="java p-4">
                        {`//The key of the record
String keyValue = "first_record";

//The fields to insert or update of that record (can be limited to a subset)
String[] fieldsNames = { "field0", "field1", "field2" };

//The values of each field to insert (ordered according to field names)
//It must have the same length of field names
byte[][] fieldsValues = { "value0".getBytes(StandardCharsets.UTF_8),

                          "value1".getBytes(StandardCharsets.UTF_8),

                          "value2".getBytes(StandardCharsets.UTF_8) };

//Call insert: the record will be inserted or updated
//You must specify the TableFillType, representing the type of the table (fixed length, padded, noSQL)
Response ri = client.insert(table, TableFillType.FIXED_LENGTH, keyValue, fieldsNames, fieldsValues);

if(ri.isSuccess()) 

    System.out.println("New record inserted!");

} else System.out.println(ri.getErrorCode() + " - " + ri.getErrorMessage());`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Read a record</h2>
            <div>
              <p className="">To read a record in AyraDB you have to call the read method. Read can be limited to a subset of fields with the fields argument (if set to null, all fields are retrieved).</p>
              <p className="mt-2">The argument <strong>parseResponseFields</strong> controls what kind of output is returned: if set to false, the body of the response is returned as it is, otherwise the body of the response is parsed and the corresponding fields are returned.</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Direct link"
                  >
                    <div className="text-2xs sm:text-code">
                      <Highlight className="java p-4">
                        {`boolean parseResponseFields = true;

Response rr = client.read(tableName, tableFillType, keyValue, fieldsNames, parseResponseFields);

if(rr.isSuccess()) {

      for(int i=0; i<rr.getFieldsNames().length; i++){

            System.out.println("Field name: " + rr.getFieldsNames()[i]);

            System.out.println("Field value: " + new String(rr.getFieldsValues()[i],StandardCharsets.UTF_8));

      }

} else System.out.println(rr.getErrorCode() + " - " + rr.getErrorMessage());`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Delete a record</h2>
            <div>
              <p className="">In AyraDB the delete method can be used differently according to the type of table on which is called. For tables of fill type nosql, deletion can be limited to a subset of fields that must be specified as the last parameter. For other types of tables, or to delete the whole record in a noSQL table, the last parameter must be set to null.</p>
              <p className="mt-2">The operation completes successfully even when a record with the provided key does not exists in the table.</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Direct link"
                  >
                    <div className="text-2xs sm:text-code">
                      <Highlight className="java p-4">
                        {`//Delete a record
Response rd = client.delete(table, TableFillType.FIXED_LENGTH, keyValue, null);

if(rd.isSuccess()) 

    System.out.println("Record successfully deleted!");

} else System.out.println(rd.getErrorCode() + " - " + rd.getErrorMessage());`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Scan a table</h2>
            <div>
              <p>​A convenient API is provided to allow table scans.</p>
              <p className="mt-2">Each table is composed of a certain number of segments: in order to perform a scan, the number of segments of the table must be retrieved.</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Direct link"
                  >
                    <div className="text-2xs sm:text-code">
                      <Highlight className="java p-4">
                        {`//Retrieve the number of segments
Response rSi = client.scanTable(table, fieldsNames, null, null);

int numberOfSegments = rSi.getNumberOfSegments()`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
              <p className="mt-4">Each segment must be scanned independently.</p>
              <p className="mt-2">Each time a record is retrieved, AyraDB returns a lastKeyHash value to be used to retrieve the next record in the segment.</p>
              <p className="mt-2">When the end of a segment is reached (i.e. all records in that segment have been retrieved), an error with code “00002” is returned.</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Direct link"
                  >
                    <div className="text-2xs sm:text-code">
                      <Highlight className="java p-4">
                        {`for(int i=0; i<numberOfSegments && !error; i++){

String lastKeyHash = null;

do {

      Response rS = client.scanTable(table, fieldsNames, i, lastKeyHash);

      if (rS.isSuccess()) {

            String keyValue = rS.getKeyValue();

            byte[][] fieldValues = rS.getFieldValues();

            //DO SOMETHING WITH CURRENT RECORD

            lastKeyHash = rS.getLastKeyHash();

      } else {

            if(rS.getErrorCode().equals("00002")){

                //REACHED END OF SEGMENT

            } else {

                error = true;

                System.out.println(rS.getErrorCode() + " - " + rS.getErrorMessage());

            }

            lastKeyHash = null;

      }

} while (lastKeyHash != null);

}`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Close the connection</h2>
            <div>
              <p>When the <strong>persistentConnectionMode</strong> is set to true, the user must take care of closing the connection to the server using the <strong>disconnect()</strong> method.</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Direct link"
                  >
                    <div className="text-2xs sm:text-code">
                      <Highlight className="java p-4">
                        {`client.disconnect();`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Error handling</h2>
            <div>
              <p className="">When an action <strong>fails</strong>, a Response object is returned to the user.</p>
              <p className="mt-2">It contains a description of the error (getErrorMessage()) and an error code (getErrorCode()). The method <strong>isSuccess()</strong> will return <strong>false</strong> in case of a failure.</p>
              <p className="mt-2">To understand error codes and messages refer to the detailed documentation.</p>
            </div>
          </section>
        </BlankCard>
      </div>
    </DocsLayout>
  );
}

export default JavaClientPage;