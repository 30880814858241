import { useCallback, useEffect, useMemo, useState } from "react";

const LOCAL_STORAGE_KEY = "privacy_policy"

export const savePrivacyAcceptedLocally = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY, 'Accepted in date: '+new Date())
}

const usePrivacyPolicy = ()=>{
    // State of privacy
    const [isAccepted, setAccepted] = useState<boolean>(true);

    // Callback to update the privacy policy
    const accept = useCallback(()=>{
        setAccepted(true);
        savePrivacyAcceptedLocally();
    },[])

    // Load 
    useEffect(()=>{
        const isTokenPresent =localStorage.getItem(LOCAL_STORAGE_KEY)
        if(isTokenPresent == null){
            setAccepted(false);
        } 
    },[])
    
    // To avoid re-renders in case the returned object
    // is inserted as a "whole" in a use effect hook
    const privacyPolicy = useMemo(()=>{
        return({isAccepted, accept})
    },[isAccepted,accept])

    return privacyPolicy;
}

export default usePrivacyPolicy;