export interface FirewallRule{
    id: number
    name: string
    type: FirewallRuleType
    subnet: Subnet
}

interface Subnet {
    ip_address: string
}

export enum FirewallRuleType{
    ALLOW = 'A',
    DENY = 'D',
}