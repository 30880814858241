import React from 'react';

export interface CodeFragmentProps {
  label: string
  children?: React.ReactNode
}

const CodeFragment: React.FC<CodeFragmentProps> = (props) => {
    return (
      <div className="text-gunmetal-350">
        {props.children}
      </div>
    );
  }

export default CodeFragment