import { FC, useState } from 'react';

type NavDropDownProps = {
  children?: React.ReactNode[],
  title: string,
}

const NavDropDown: FC<NavDropDownProps> = (props) => {
  const [dropDownHovered, setDropDownHovered] = useState(false);
  const [boxHovered, setBoxHovered] = useState(false);
  const [triggerOpen, setTriggerOpen] = useState(false);
  return (
    <div>
      <div
        onMouseEnter={() => {
          setDropDownHovered(true)
          setTriggerOpen(true)
        }}
        onMouseLeave={() => setDropDownHovered(false)}
        className="group flex items-center px-4 space-x-2 cursor-pointer select-none"
      >
        <p
          className="text-base font-semibold text-gunmetal group-hover:text-gunmetal-100"
        >
          {props.title}
        </p>
        <svg className="text-gunmetal group-hover:text-gunmetal-100" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 16 13">
          <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
        </svg>
      </div>
      <div
        onMouseEnter={() => {
          if (triggerOpen) {
            setBoxHovered(true)
            setTriggerOpen(false)
          }
        }
        }
        onMouseLeave={() => setBoxHovered(false)}
        className={`${dropDownHovered || boxHovered ? 'visible opacity-100' : 'invisible opacity-0 -translate-y-28'} transition-all transform3D origin-top-center duration-500 bg-gunmetal-700 bg-opacity-90 w-56 absolute top-14 text-base rounded-lg `}
      >
        <svg className={`absolute transition ease-out delay-150 duration-150 transform ${dropDownHovered || boxHovered ? 'text-gunmetal-700 ' : 'translate-y-4 text-transparent'} opacity-90 h-4 left-2 ml-3 -top-4 `} x="0px" y="0px" viewBox="0 0 255 255"><polygon className="fill-current" points="0,255 127.5,127.5 255,255" /></svg>
        <ul className="list-none">
          {
            props.children?.map(
              (listItem, index) => {
                let time = new Date().getTime();
                return (
                  <li key={time.toLocaleString() + index} className={`overflow-hidden ${index === 0 && 'rounded-t-lg'} ${props.children && index === (props.children.length - 1) && 'rounded-b-lg'}`}>
                    {listItem}
                  </li>
                );
              }
            )
          }
        </ul>
      </div>
    </div>
  );
}

export default NavDropDown;