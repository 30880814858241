import React from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { AnimatedButton } from '../../components/buttons';
import { BlankCard } from '../../components/cards';

interface CardProps {
  header: string
  description:string
  buttonText: string
  onButtonClick: ()=>void
}


const VerifyCard: React.FC<CardProps> = (props) => {
  return (
    <BlankCard wrapContent className="w-80">
      <div className="p-2">
        <h1 className="w-full font-bold text-2xl">{props.header}</h1>
        <p className="mt-4">{props.description}</p>
        <AnimatedButton
          className=" w-full text-white font-semibold bg-cherry-red-400 rounded py-2 mt-6"
          onClick={() => props.onButtonClick()}
        >
          {props.buttonText}
        </AnimatedButton>
      </div>
    </BlankCard>
  );
}

const VerifyEmailPage: React.FC<{}> = () => {

  const history = useHistory();
  const location= useLocation();
  const status= location.pathname.split('/verify_email')[1];

  switch(status){
    case "":{
      return (
        <div className="w-full h-full flex flex-row justify-center items-center">
          <VerifyCard
            header="Please verify your email"
            description="Before accessing your AyraDB account you need to verify your email. Please check your email and click on the confirm link"
            buttonText="Sign in"
            onButtonClick={()=>history.push('/auth/signin')}
          />
        </div>
      );
    }
    case "/success/":{
      return (
        <div className="w-full h-full flex flex-row justify-center items-center">
          <VerifyCard
            header="Success"
            description="Your email was verified successfully. Please log in."
            buttonText="Log in"
            onButtonClick={()=>history.push('/auth/signin')}
          />
        </div>
      );
    }
    case "/failure/":{
      return (
        <div className="w-full h-full flex flex-row justify-center items-center">
          <VerifyCard
            header="Failure"
            description="Error" //TODO: explain the error
            buttonText="Send again"
            onButtonClick={()=>{}} //TODO: handle this case
          />
        </div>
      );
    }
    default:
      return(<Redirect to="/not_found"/>)
  }
}

export default VerifyEmailPage;