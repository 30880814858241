import { useEffect, useState, useMemo } from "react";

const useSizeObserver: (ref: Element | null) => { x: number | null, y: number | null } = (ref) => {
    const [x, setX] = useState<number | null>(null);
    const [y, setY] = useState<number | null>(null);

    const size = useMemo(() => ({
        x: x,
        y: y
    }), [x, y])

    useEffect(() => {
        if (ref) {
            const observer = new ResizeObserver(([entry]) => {
                setX(entry.target.clientWidth)
                setY(entry.target.clientHeight)
            })

            observer.observe(ref)

            return () => {
                observer.unobserve(ref)
            }
        }
    }, [ref])

    return size;
}

export default useSizeObserver;