import routes, { LinkPriority } from '../../routes';

import {LinkData, LinkStyle} from '../../commons/types';


const priorityStyleMap: Map<LinkPriority, LinkStyle> = new Map([
    [LinkPriority.NORMAL, LinkStyle.TEXT]
]);

export const getHomeLinks = (): LinkData[] => {
    
    let links: LinkData[] = [];
    routes.filter(route => route.layout === '/')
        .filter(route => route.label !== null)
        .forEach(route => {
            if (route.label !== undefined) {
                links.push({
                    href: route.layout + route.path,
                    title: route.label.title,
                    style: priorityStyleMap.get(route.label.priority??LinkPriority.NORMAL)??LinkStyle.TEXT,
                    parentLabel: route.label.parentLabel,
                })
            }
        });
    return links;
}