import React, { useEffect, useState } from 'react';
import { PrimaryButton } from '../../components/buttons';
import { useDebugLogger, useMounted, useTimer } from '../../commons/hooks';
import useCherryserviceAPI from '../../api/cherryservice/v2';
import { CustomSpinner } from '../../components/spinner';
import { AlertType, SimpleAlert } from '../../components/alert';
import { TableCard, TableColumns, TableContent, TableHeader, TableRows, Column, Row, Element } from '../../components/cards';
import { Semaphore, SemaphoreColor } from '../../components/semaphore';
import { InvitationStatus as InvitationAPIObjStatus } from '../../api/cherryservice/v2/types';

enum InvitationStatus {
  PENDING = "Pending",
  REGISTERED = "Registered",
  EXPIRED = "Expired",
}

interface Invitation {
  id: number
  email: string
  status: InvitationStatus
  lastUpdate: Date
  dateSent: Date
}

const invitationStatusMap = new Map([
  [InvitationAPIObjStatus.PENDING, InvitationStatus.PENDING],
  [InvitationAPIObjStatus.REGISTERED, InvitationStatus.REGISTERED],
  [InvitationAPIObjStatus.EXPIRED, InvitationStatus.EXPIRED]
])

const invitationStatusColorMap = new Map([
  [InvitationStatus.PENDING, SemaphoreColor.YELLOW],
  [InvitationStatus.REGISTERED, SemaphoreColor.GREEN],
  [InvitationStatus.EXPIRED, SemaphoreColor.GRAY]
])


const LinksPage: React.FC<{}> = (props) => {

  const CLIENT_NAME_FIELD = 'CLIENT_NAME';
  const CLIENT_EMAIL_FIELD = 'CLIENT_EMAIL';
  const [loading, setLoading] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [emailSent, setEmailSent] = useState<boolean | null>(null);
  const [invitations, setInvitations] = useState<Invitation[] | undefined>(undefined)
  const mounted = useMounted();
  const timer = useTimer();
  const cherryserviceAPI = useCherryserviceAPI()
  const logger = useDebugLogger();

  function generateLink() {
    setLoading(true);
    cherryserviceAPI?.newToken(clientName, clientEmail).then(res => {
      setEmailSent(true);
    }).catch(() => {
      if(mounted){
        setEmailSent(false);
      }
    }).finally(() => {
      if (mounted) {
        setLoading(false);
        setClientName('');
        setClientEmail('');
        timer.setTimer(() => setEmailSent(null), 2000);
      }
    })
  }

  function handleFormChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    switch (e.target.name) {
      case CLIENT_EMAIL_FIELD:
        setClientEmail(value);
        break;
      case CLIENT_NAME_FIELD:
        setClientName(value);
        break;
      default:
        logger.log("Uncontrolled field input detected")
    }
  }

  useEffect(() => {
    if (cherryserviceAPI) {
      cherryserviceAPI.getInvitations().then(res => {
        setInvitations(
          res.data.map(invitation => ({
            id: invitation.id,
            email: invitation.email,
            lastUpdate: new Date(invitation.date_updated),
            dateSent: new Date(invitation.date_created),
            status: invitationStatusMap.get(invitation.status) ?? InvitationStatus.PENDING,
          }))
        )
      }).catch(err => err.response && logger.log(err.response));
    }
  }, [cherryserviceAPI, logger])

  return (
    <div className="relative p-8">
      <SimpleAlert showText={emailSent === true ? 'Mail sent correctly' : emailSent === false ? 'Couldn\'t send the email' : undefined} type={emailSent ? AlertType.SUCCESS : AlertType.ERROR} />
      <h1 className="text-6xl font-bold font-header text-gunmetal-500">Link generator</h1>
      <div className="my-8">
        <form className="space-y-4 w-2/3" onSubmit={e => e.preventDefault()}>
          <label className="mb-2 text-gunmetal-350 font-semibold block">Client's name</label>
          <input value={clientName} onChange={handleFormChange} name={CLIENT_NAME_FIELD} type="text" className="h-12 w-full outline-none border-2 border-gray-200 focus:border-cherry-red-200 bg-gray-50 focus:bg-cherry-red-50 rounded-lg p-4  block" />
          <label className="mb-2 text-gunmetal-350 font-semibold block">Client's email</label>
          <input value={clientEmail} onChange={handleFormChange} name={CLIENT_EMAIL_FIELD} type="text" className="h-12 w-full outline-none border-2 border-gray-200 focus:border-cherry-red-200 bg-gray-50 focus:bg-cherry-red-50 rounded-lg p-4  block" />
        </form>
        <PrimaryButton
          disabled={clientEmail === '' || loading} //Disable with empty client's email
          onClick={generateLink}
          className="py-2 md:py-3 px-4 mt-8 w-28 flex justify-center">
          {
            loading
              ? <CustomSpinner className="h-4 w-4 text-white" />
              : 'Generate'
          }
        </PrimaryButton>
      </div>
      <TableCard className="w-2/3">
        <TableHeader>
          <h2 className="text-lg 2xs:text-xl font-header text-gunmetal-300 font-bold">Active invitations</h2>
        </TableHeader>
        <TableContent loadingItems={invitations === undefined} className="">
          <TableColumns>
            <Column>Email</Column>
            <Column>Created</Column>
            <Column>Last update</Column>
            <Column>Status</Column>
          </TableColumns>
          <TableRows>
            {
              invitations !== undefined
                ? invitations.map(invitation => (
                  <Row key={invitation.id} className="h-12">
                    <Element className="text-gunmetal-200 text-sm 2xs:text-base text-left relative">{invitation.email}</Element>
                    <Element className="text-gunmetal-200 text-sm 2xs:text-base text-left relative">{invitation.dateSent.toLocaleDateString()}</Element>
                    <Element className="text-gunmetal-200 text-sm 2xs:text-base text-left relative">{invitation.lastUpdate.toLocaleDateString()}</Element>
                    <Element className="">
                      <Semaphore
                        color={invitationStatusColorMap.get(invitation.status) ?? SemaphoreColor.RED}
                        tooltip={invitation.status}
                      />
                    </Element>
                  </Row>
                ))
                : []
            }
          </TableRows>
        </TableContent>
      </TableCard>
    </div>
  );
}

export default LinksPage;