import { Action } from 'redux';
import { RootState } from '../..';

import CherryServiceAPI from "../../../api/cherryservice/v2/api";
import { savePrivacyAcceptedLocally } from '../../../commons/hooks/use_privacy_policy';
import {
  AuthenticationErrorMessage,
  EmailErrors,
  AuthenticationActions,
  RegistrationUserInfo,
  USER_LOGGED,
  NOT_AUTHENTICATED,
  INVALID_EMAIL,
  GENERIC_ERROR,
  USER_ALREDY_EXISTS,
  TERMS_NOT_ACCEPTED,
  PasswordErrors,
  MISSING_PASSWORD,
  WRONG_PASSWORD,
  CREDENTIALS_UPDATED,
  RESET_CREDENTIAL_OPERATION_RESULT,
  USER_TO_VERIFY,
  GenericErrors,
  NOT_VERIFIED,
  UserInfo,
  CREDENTIALS_ERROR,
} from "./types";



export const refreshAuthentication = () => {
  return async (dispatch: (action: AuthenticationActions) => Action<AuthenticationActions>) => {
    const token = localStorage.getItem('key');
    if (token === null) {
      dispatch({
        type: NOT_AUTHENTICATED,
      })
    } else {
      new CherryServiceAPI(token).getMe()
        .then(res => {
          dispatch({
            type: USER_LOGGED,
            payload: {
              email: res.data.email,
              id: res.data.id,
              name: res.data.first_name !== null ? res.data.first_name : undefined,
              surname: res.data.last_name !== null ? res.data.last_name : undefined,
              phoneNumber: res.data.phone_number !== null ? res.data.phone_number : undefined,
              phoneCountry: res.data.phone_country !== null ? res.data.phone_country?.toLowerCase() : undefined, //MUST BE LOWER CASE
              country: res.data.country !== null ? res.data.country?.toLowerCase() : undefined, //MUST BE LOWER CASE
              company: res.data.company_name !== null ? res.data.company_name : undefined,
              industry: res.data.industry !== null ? res.data.industry : undefined,
              jobFunction: res.data.company_role !== null ? res.data.company_role : undefined,
              isStaff: res.data.is_staff,
              token: token
            }
          })
        });
    }
  }
}

export const refreshAuthError = (): AuthenticationActions => {
  return {
    type: NOT_AUTHENTICATED,
  }
}

export const login = (email: string, password: string) => {

  const emailErrorsMap = new Map<string, EmailErrors>([
    ['Enter a valid email address.', INVALID_EMAIL]
  ]);

  const passwordErrorsMap = new Map<string, PasswordErrors>([
    ['This field may not be blank.', MISSING_PASSWORD],
    ['Unable to log in with provided credentials.', WRONG_PASSWORD]
  ])

  const otherErrors = new Map<string, GenericErrors>([
    ["E-mail is not verified.", NOT_VERIFIED]
  ])

  return async (dispatch: (action: AuthenticationActions) => Action<AuthenticationActions>) => {
    new CherryServiceAPI(null).login(email, password).then(res1 => {
      const key = res1.data.key;
      localStorage.setItem('key', key);
      new CherryServiceAPI(key).getMe().then(res2 => {
        dispatch({
          type: USER_LOGGED,
          payload: {
            email: res2.data.email,
            id: res2.data.id,
            name: res2.data.first_name !== null ? res2.data.first_name : undefined,
            surname: res2.data.last_name !== null ? res2.data.last_name : undefined,
            phoneNumber: res2.data.phone_number !== null ? res2.data.phone_number : undefined,
            phoneCountry: res2.data.phone_country !== null ? res2.data.phone_country?.toLowerCase() : undefined, //MUST BE LOWER CASE
            country: res2.data.country !== null ? res2.data.country?.toLowerCase() : undefined, //MUST BE LOWER CASE
            company: res2.data.company_name !== null ? res2.data.company_name : undefined,
            industry: res2.data.industry !== null ? res2.data.industry : undefined,
            jobFunction: res2.data.company_role !== null ? res2.data.company_role : undefined,
            isStaff: res2.data.is_staff,
            token: key
          }
        })
      });
    }).catch(error => {
      const errMessage: AuthenticationErrorMessage = error.response.data;
      if (errMessage.email) {
        dispatch({
          type: emailErrorsMap.get(errMessage.email[0]) ?? GENERIC_ERROR
        })
      } else if (errMessage.password) {
        dispatch({
          type: passwordErrorsMap.get(errMessage.password[0]) ?? GENERIC_ERROR
        })
        //TODO: Check user not verified error
      } else if (errMessage.non_field_errors) {
        if (passwordErrorsMap.get(errMessage.non_field_errors[0])) {
          dispatch({
            type: passwordErrorsMap.get(errMessage.non_field_errors[0]) ?? GENERIC_ERROR
          })
        } else {
          dispatch({
            type: otherErrors.get(errMessage.non_field_errors[0]) ?? GENERIC_ERROR
          })
        }
      } else {
        dispatch({
          type: GENERIC_ERROR
        })
      }
    });
  }
}

const checkNameSurnameAndConditions = (conditionAccepted: boolean): AuthenticationActions | null => {
  if (!conditionAccepted) {
    return {
      type: TERMS_NOT_ACCEPTED,
    }
  }

  return null;

}

export const register = (userInfo: RegistrationUserInfo) => {

  const { email, password, confirmPassword, conditionAccepted, temporaryToken } = userInfo

  const emailErrorsMap = new Map<string, EmailErrors>([
    ['A user is already registered with this e-mail address.', USER_ALREDY_EXISTS]
  ]);

  const actionToDispatch: AuthenticationActions | null = checkNameSurnameAndConditions(conditionAccepted);

  if (actionToDispatch !== null) return actionToDispatch;

  return async (dispatch: (action: AuthenticationActions) => Action<AuthenticationActions>, getState:()=>RootState) => {
    const token = getState().authToken //token is null in this case
    const cherryservice= new CherryServiceAPI(token)
    cherryservice.register(email, password, confirmPassword)
      .then(() => {
        dispatch({
          type: USER_TO_VERIFY
        })
        //Remove eventual temporary token for special registration accesses
        if(temporaryToken){
          cherryservice.deleteToken(temporaryToken)
        }
        //Set privacy policy as accepted
        savePrivacyAcceptedLocally();
      }).catch(err => {
        const errMessage: AuthenticationErrorMessage = err.response.data;
        if (errMessage.email) {
          dispatch({
            type: emailErrorsMap.get(errMessage.email[0]) ?? GENERIC_ERROR
          })
        } else {
          dispatch({
            type: GENERIC_ERROR
          })
        }
      })
  }
}

export const logout = () => {
  localStorage.removeItem('key');
  return {
    type: NOT_AUTHENTICATED
  }
}

export const updateUserInfo = (user: UserInfo) => {
  //JS decomposition: adapt user to api structure
  return async (dispatch: (action: AuthenticationActions) => Action<AuthenticationActions>, getState: ()=>RootState) => {
    const token = getState().authToken
    new CherryServiceAPI(token).updateMe({
      email: user.email,
      first_name: user.name,
      last_name: user.surname,
      phone_number: user.phoneNumber,
      phone_country: user.phoneCountry?.toUpperCase(), //MUST BE UPPERCASE
      country: user.country?.toUpperCase(), //MUST BE UPPERCASE
      company_name: user.company,
      industry: user.industry,
      company_role: user.jobFunction
    }).then(res => {
      dispatch({
        type: CREDENTIALS_UPDATED,
        payload: {
          id: res.data.id,
          email: res.data.email,
          name: res.data.first_name !== null ? res.data.first_name : undefined,
          surname: res.data.last_name !== null ? res.data.last_name : undefined,
          phoneNumber: res.data.phone_number !== null ? res.data.phone_number : undefined,
          phoneCountry: res.data.phone_country !== null ? res.data.phone_country?.toLowerCase() : undefined, //MUST BE LOWER CASE
          country: res.data.country !== null ? res.data.country?.toLowerCase() : undefined, //MUST BE LOWER CASE
          company: res.data.company_name !== null ? res.data.company_name : undefined,
          industry: res.data.industry !== null ? res.data.industry : undefined,
          jobFunction: res.data.company_role !== null ? res.data.company_role : undefined,
          isStaff: res.data.is_staff
        }
      })
    }).catch(err => {
      //FIXME: add more specific errors
      dispatch({
        type: CREDENTIALS_ERROR,
      })
    })
  }
}

export const resetCredentialOperationResult = () => {
  return ({
    type: RESET_CREDENTIAL_OPERATION_RESULT
  })
}