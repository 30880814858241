import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Highlight from 'react-highlight';

import { BlankCard } from '../../components/cards';
import DocsLayout from '../../layouts/docs_layout';
import { EditableParam, CodeFragment, CodeFragmentsWrapper } from '../../components/code_fragments';

import { MessageAlert } from '../../components/alert';
import { useTimer } from '../../commons/hooks';

const PythonClientPage: React.FC<{}> = () => {

  const [ip, setIp] = useState('IP_ADDRESS');
  const [copyToClipboard, setCopyToClipboard] = useState(false);

  const timer = useTimer();

  const handleCopyToClipboard = () => {
    setCopyToClipboard(true);
    timer.setTimer(() => setCopyToClipboard(false), 2000);
  }

  return (
    <DocsLayout>
      <MessageAlert
        showText={copyToClipboard ? 'Copied to clipboard' : undefined}
        className="h-14 w-48"
      />
      <div className="relative py-8">
        <BlankCard wrapContent className="tw-docs-card">
          <h1>Get started with the Python Client</h1>
          <p>The Python client wraps AyraDB's native HTTP REST APIs and provides a high-level programming environment that you can use to develop your applications. This page provides a general introduction to the Python client.</p>
          <section>
            <h2>Prerequisites</h2>
            <div>
              <p>Before starting to use the Python Client you need:</p>
              <ul>
                <li>An AyraDB account</li>
                <li>An active subscription with a valid IP address</li>
              </ul>
            </div>
          </section>
          <section>
            <h2>Add the client to your app</h2>
            <div>
              <p>To use AyraDB the first thing to do is integrating the Python Client into your application. The client is available on <a target="_blank" rel="noreferrer" href="https://pypi.org/project/ayradb/" className="text-cherry-red-400 hover:text-cherry-red-700">PyPi</a>.</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Direct link"
                  >
                    <div className="bg-code-black p-4 text-2xs sm:text-code">
                      <p className="text-code-white"><span className="text-white">$</span> pip install ayradb</p>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Connect to the service</h2>
            <div>
              <p className="mb-2">Now you are ready to connect to the service and start using it.</p>
              <p>To create a new connection it is necessary to provide:</p>
              <ul className="list-outside list-disc ml-6 space-y-1 py-1">
                <li className="">A valid <span className="font-semibold text-red-crayola">IP_ADDRESS</span> of one of the server listed inside your subscriptions.</li>
              </ul>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Connect"
                  >
                    <div className="bg-code-black font-code py-2 px-4 relative text-2xs sm:text-code">
                      {/* TODO: Add on copy callback that triggers an alert with "Copied to clipboard" */}
                      <CopyToClipboard onCopy={handleCopyToClipboard} text={`from ayradb import AyraDB\n\ndb: AyraDB = AyraDB(ip="${ip}")`}>
                        <button className="absolute right-2 top-1 focus:outline-none"><i className="far fa-clone text-white hover:text-gray-200"></i></button>
                      </CopyToClipboard>
                      <p className="text-code-white mb-6"><span className="text-code-orange">from</span> ayradb <span className="text-code-orange">import</span> AyraDB</p>
                      <p className="text-code-gray">{`#Example: db: AyraDB = AyraDB(ip="192.168.1.1")`}</p>
                      <div className="text-code-white mt-2">
                        db: AyraDB = AyraDB(<span className="text-blue-400">ip</span>=
                        <EditableParam
                          textBefore={`"`}
                          textAfter={`"`}
                          value={ip}
                          onChange={value => setIp(value)}
                        />)
                      </div>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Create a table</h2>
            <div>
              <p>AyraDB manages three types of tables, accommodating for different user requirements:</p>
              <ul>
                <li><span className="font-semibold">fixed length</span>, used when column types are standard SQL and there is a limit for the size of each field;</li>
                <li><span className="font-semibold">padded</span>, used when column types are not standard SQL types, for example, if one or more columns contain a json file;</li>
                <li><span className="font-semibold">noSQL</span> or scheme-less, which are tables without an explicit record structure that can accommodate in each record an arbitrary number of fields, and each field can have an arbitrary label.</li>
              </ul>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Fixed length"
                  >
                    <div className="p-4">
                      <p>For fixed length tables:</p>
                      <ul>
                        <li className="">at least <strong>one field must be provided</strong>;</li>
                        <li className="">maximum <strong>key column size</strong> and <strong>maximum fields</strong> size <strong>must be provided</strong>;</li>
                        <li>new fields can be added at a later time</li>
                      </ul>
                      <p className="mt-4">Here an example on how to create a fixed length table:</p>
                    </div>
                    <div className="text-2xs sm:text-code">
                      <Highlight className="python p-4">
                        {`table = db.table("new_fixed_length_table")

fields = [{Column.NAME: "field0", Column.MAX_LENGTH: 1024},
           {Column.NAME: "field1", Column.MAX_LENGTH: 1024},
           {Column.NAME: "field2", Column.MAX_LENGTH: 1024}]

max_key_column_size_byte = 1024

rct = table.create(Table.TYPE_FIXED_LENGTH, columns=fields, key_max_size=max_key_column_size_byte).wait_response()

if rct.success is True:

    print("New fixed length table created!")

else:

    print(f"Error code: {rct.error_code}")`}
                      </Highlight>
                    </div>

                  </CodeFragment>
                  <CodeFragment
                    label="Padded"
                  >
                    <div className="p-4">
                      <p>For padded tables:</p>
                      <ul className="list-outside list-disc ml-6 space-y-1 py-1">
                        <li className="">at least <strong>one field must be provided</strong>;</li>
                        <li>new fields can be added to at a later time</li>
                      </ul>
                      <p className="mt-4">Here an example on how to create a padded table:</p>
                    </div>
                    <div className="text-2xs sm:text-code">
                      <Highlight className="python p-4">
                        {`table = db.table("new_padded_table")

fields = [{Column.NAME: "field0"}, {Column.NAME: "field1"},{Column.NAME: "field2"}]

max_key_column_size_byte = 1024

rct = table.create(Table.TYPE_PADDED, columns=fields, key_max_size=max_key_column_size_byte).wait_response()

if rct.success is True:

    print("New padded table created!")

else:

    print(f"Error code: {rct.error_code}")`}
                      </Highlight>
                    </div>

                  </CodeFragment>
                  <CodeFragment
                    label="NoSQL"
                  >
                    <div className="p-4">
                      <p>NoSQL tables do not have a fixed structure, so the <strong>fields argument is omitted</strong></p>
                      <p className="mt-4">Here an example on how to create a noSQL table:</p>
                    </div>
                    <div className="text-2xs sm:text-code">
                      <Highlight className="python p-4">
                        {`table = db.table("new_nosql_table")

rct = table.create(Table.TYPE_NOSQL).wait_response()

if rct.success is True:

    print("New nosql table created!")

else:

    print(f"Error code: {rct.error_code}")`}
                      </Highlight>
                    </div>

                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Insert or update a record</h2>
            <div>
              <p>AyraDB provides the functionalities to insert and update records of a table. The <strong>upsert_record</strong> method allows to add a new record in the table if it does not have already a record with the same key, otherwise the record is updated.</p>
              <p className="mt-2">Insertion can be limited to a subset of fields. Field names and field values must be specified using a python dict.</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Upsert record"
                  >
                    <div className="text-2xs sm:text-code">
                      <Highlight className="python p-4">
                        {`key_value = 'key_column'

fields={
    "field0": b'value0',
    "field1": b'value1',
    "field2": b'value2',
}

ri = table.upsert_record(key_value, fields).wait_response()

if ri.success is True:

    print("New record inserted!")

else:

    print(f"Error code: {ri.error_code})`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Read a record</h2>
            <div>
              <p>To read a record in AyraDB you have to call the <strong>read_record</strong> method. Read can be limited to a subset of fields with the fields argument (if set to null, all fields are retrieved).</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Read a record"
                  >
                    <div className="p-4">
                      <p>To read every field of a record you only need to pass its key to the <strong>read_record</strong> method.</p>
                      <p className="mt-4">Here an example on how to read a record (from the previously created table):</p>
                    </div>
                    <div className="text-2xs sm:text-code">
                      <Highlight className="python p-4">
                        {`key_value = "key_column"

rr = table.read_record(key_value).wait_response()

if rr.success is True:

    for key, value in rr.content.items():

        print("Field name: " + key)

        print("Field value: " + value.decode())

else:

    print(f"Error code: {rr.error_code}")`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                  <CodeFragment
                    label="Read specific fields"
                  >
                    <div className="p-4">
                      <p>To read only specific fields we have to specify their names in a list and pass the list to the optional argument <strong>fields</strong>:</p>
                    </div>
                    <div className="text-2xs sm:text-code">
                      <Highlight className="python p-4">
                        {`key_value = "key_column"

field_names = ["field0", "field2"]  # Specify the fields you want to read

rr = table.read_record(key_value, fields=field_names).wait_response()

if rr.success is True:

    for key, value in rr.content.items():

        print("Field name: " + key)

        print("Field value: " + value.decode())

else:

    print(f"Error code: {rr.error_code}")`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Delete a record</h2>
            <div>
              <p>In AyraDB the delete method can be used differently according to the type of table on which is called.</p>
              <p className="mt-2"><strong>Important:</strong> The operation completes successfully even when a record with the provided key does not exists in the table or specific fields doesn't exists in a record.</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Delete record"
                  >
                    <div className="p-4">
                      <p>Every table allows to delete a record completely.</p>
                      <p className="mt-4">Here an example on how to delete a record (from the previously created table):</p>
                    </div>
                    <div className="text-2xs sm:text-code">
                      <Highlight className="python p-4">
                        {`key_value = "first_record"

rd = table.delete_record(key_value).wait_response()

if rd.success is True: 

      print("Record successfully deleted!")

else:

    print(f"Error code: {rd.error_code}")`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                  <CodeFragment
                    label="Delete specific fields"
                  >
                    <div className="p-4">
                      <p><strong>Only NOSQL</strong> tables allow to delete specific fields without deleting the whole record:</p>
                    </div>
                    <div className="text-2xs sm:text-code">
                      <Highlight className="python p-4">
                        {`key_value = "first_record"

# Provide the list of fields to be deleted
field_names = ["field0", "field1", "field4"]

rd = table.delete_record(key_value, fields=field_names).wait_response()

if rd.success is True: 

      print("Record successfully deleted!")

else:

    print(f"Error code: {rd.error_code}")`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Get table structure</h2>
            <div>
              <p className="">To retrieve the structure of a table we just need to call the <strong>get_structure</strong> method on a Table object:</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Direct link"
                  >
                    <div className="text-2xs sm:text-code">
                      <Highlight className="python p-4">
                        {`rts = table.get_structure().wait_response()

if rts.success is True:

    for i in range(len(rts.structure)):

        field = rts.structure[i]

        print(f"Position: {i}, Field name: {field.get('column_label')}, Field max length: {int(field.get('column_max_net_length',-1))} bytes")
else:

    print(f"Error code: {rts.error_code}")
`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Scan a table</h2>
            <div>
              <p className="">​A convenient API is provided to allow table scans.</p>
              <p className="mt-2">Each table is composed of a certain number of segments: in order to perform a scan, the number of segments of the table must be retrieved.</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Retrieve segments"
                  >
                    <div className="text-2xs sm:text-code">
                      <Highlight className="python p-4">
                        {`rsi = table.scan_init().wait_response()

if rsi.success is True:

    number_of_segments = rsi.segments

    # Scan segments...

else:

    print (f"Error code: {rsi.error_code}")

`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
              <p className="mt-4">Each segment must be scanned independently.</p>
              <p className="mt-2">Each time a record is retrieved, AyraDB returns a last_hash value that must be used to retrieve the next record in the segment.</p>
              <p className="mt-2">When the end of a segment is reached success become false and we can scan the next segment. Verify that the returned error matches <strong>ScanError.SEGMENT_END</strong></p>
              <p className="mt-2">It is possible to scan only specific fields by passing a list of fields into the <strong>optional parameter "fields"</strong>.</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Scan segment"
                  >
                    <div className="text-2xs sm:text-code">
                      <Highlight className="python p-4">
                        {`# Inside "rsi.success is True" branch

number_of_segments = rsi.segments

# For every segment available
for segment in range(0, number_of_segments):

    # Scan first record of the segment

    rs = table.scan(segment).wait_response()
    
    while rs.success is True:

        # DO SOMETHING WITH CURRENT RECORD...

        # Scan next record
        rs = table.scan(segment, last_hash=rs.last_hash).wait_response()
    
    if rs.error_code != ScanError.SEGMENT_END:
        # Case scan wasn't terminated because of segment end but for an error
        print (f"Error code: {rs.error_code}")
`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Query a table</h2>
            <div>
              <p className="">AyraDB provides an API to perform SQL-like queries on a single table.</p>
              <p className="mt-2"><strong>Important:</strong> the semicolon (';') at the end of the query is mandatory.</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Raw query"
                  >
                    <div className="text-2xs sm:text-code">
                      <Highlight className="python p-4">
                        {`table = "usertable_fixed_length"
field = "field0"

promise = db.new_raw_query('SELECT * FROM %s WHERE %s = "prova";' % (table, field)).execute()
stream = promise.stream
query_result = []
while not stream.is_closed():
    responses = stream.take_when_available()
    for response in responses:
      for record in response.content:
          query_result.append(record)
`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Truncate a table</h2>
            <div>
              <p className="">In AyraDB it is possible to delete all the record of a table with a single api call.</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Direct link"
                  >
                    <div className="text-2xs sm:text-code">
                      <Highlight className="python p-4">
                        {`rt = table.truncate().wait_response()

if rt.success is True:

    print("Table is now empty!")

else:

    print(f"Error code: {rt.error_code}")
`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Drop a table</h2>
            <div>
              <p>Tables can also be deleted:</p>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Direct link"
                  >
                    <div className="text-2xs sm:text-code">
                      <Highlight className="python p-4">
                        {`rd = table.drop().wait_response()

if rd.success is True:

    print("Table destroyed!")

else:

    print(f"Error code: {rd.error_code}")
`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Close the connection</h2>
            <div>
              <div className="mt-4">
                <CodeFragmentsWrapper>
                  <CodeFragment
                    label="Direct link"
                  >
                    <div className="text-2xs sm:text-code">
                      <Highlight className="python p-4">
                        {`db.close_connection();`}
                      </Highlight>
                    </div>
                  </CodeFragment>
                </CodeFragmentsWrapper>
              </div>
            </div>
          </section>
          <section>
            <h2>Error handling</h2>
            <div>
              <p>To see a comprehensive error list check the <a target="_blank" rel="noreferrer" href="https://pypi.org/project/ayradb/" className="text-cherry-red-400 hover:text-cherry-red-700">PyPi</a> page.</p>
            </div>
          </section>
        </BlankCard>
      </div>
    </DocsLayout>
  );
}

export default PythonClientPage;