import React from 'react';

import DocumentCard from './cards';

const DocumentsPage: React.FC<{}> = () => {
  return (
    <section className="p-8">
      <h2 className="text-gunmetal font-bold text-xl">Documents</h2>
      <div className="grid grid-cols-4 mt-4 gap-6">
        <DocumentCard
          document="Privacy policy"
          href="/privacy_policy"
        />
        <DocumentCard
          document="Terms and conditions"
          href="/terms_and_conditions"
        />
      </div>
    </section>
  );
}

export default DocumentsPage;