import {ServerStatus as ServerAPIStatus, SubscriptionStatus as SubscriptionAPIStatus } from '../../api/cherryservice/v2/types';
import { FirewallRuleType as FirewallRuleApiType } from '../../api/cherryservice/v2/types/firewall';

export enum SubscriptionStatus {
  ACTIVE = "Active",
  ERROR = "Error",
  INACTIVE = "Inactive",
  BOOTING = "Booting",
  INSTANTIATING = "Provisioning",
  INSTALLING = "Installing",
  LOADING = "Loading" //Case remote status not tracked/found
}

export const notInstallingStatuses: SubscriptionStatus[] = [
  SubscriptionStatus.ACTIVE,
  SubscriptionStatus.ERROR,
  SubscriptionStatus.INACTIVE
];

export type Subscription = {
  id: number,
  name: string,
  dateCreated: Date,
  dateStart: Date,
  dateEnd: Date,
  duration: number,
  status: SubscriptionStatus,
  isDemo: boolean,
  autorenew: boolean,
  isCluster: boolean
  servers: Server[]
}

export enum ServerStatus {
  ACTIVE = "Active",
  DECOMMISSIONED = "Decommissioned",
  INSTALLING = "Installing product",
  PROVISIONING = "Initializing",
  ERROR = "Error"
}

export interface Server {
  id: number
  name: string,
  ip_address: string,
  status: ServerStatus,
}

export interface FirewallRule {
  id: number,
  type: FirewallRuleType
  name: string,
  subnet: string,
}

export enum FirewallRuleType{
  ALLOW = 'Allow',
  DENY = 'Deny'
}

export enum AddRuleState {
  NOT_ADDING,
  ADDING,
  UPLOADING_TO_SERVER,
}

export const subscriptionStatusMap = new Map<SubscriptionAPIStatus, SubscriptionStatus>([
  [SubscriptionAPIStatus.ACTIVE, SubscriptionStatus.ACTIVE],
  [SubscriptionAPIStatus.ERROR, SubscriptionStatus.ERROR],
  [SubscriptionAPIStatus.INACTIVE, SubscriptionStatus.INACTIVE],
  [SubscriptionAPIStatus.BOOTING, SubscriptionStatus.BOOTING],
  [SubscriptionAPIStatus.INSTANTIATING, SubscriptionStatus.INSTANTIATING],
  [SubscriptionAPIStatus.INSTALLING, SubscriptionStatus.INSTALLING],
]);

export const serverStatusMap = new Map<string, ServerStatus>([
  [ServerAPIStatus.SETTING_UP_PROVISIONING, ServerStatus.PROVISIONING],
  [ServerAPIStatus.SETTING_UP_PRODUCT_INSTALLATION, ServerStatus.INSTALLING],
  [ServerAPIStatus.ERROR, ServerStatus.ERROR],
  [ServerAPIStatus.ACTIVE, ServerStatus.ACTIVE],
  [ServerAPIStatus.DECOMMISIONED, ServerStatus.DECOMMISSIONED]
])

export const ruleTypesMap = new Map<FirewallRuleApiType, FirewallRuleType>([
  [FirewallRuleApiType.ALLOW, FirewallRuleType.ALLOW],
  [FirewallRuleApiType.DENY,FirewallRuleType.DENY]
])