import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SidebarItem } from '../components/sidebar';
import { LinkData } from '../commons/types';
import routes from '../routes';
import SidebarLayout from './sidebar_layout';
import { RootState } from '../store';
import { AuthenticationStateType } from '../store/auth/reducers';
import { connect } from 'react-redux';

interface ReduxStateProps {
  authState: AuthenticationStateType | null
}

type AdminLayoutProps = ReduxStateProps;

const RoutesSwitcher: React.FC<{}> = (props) => {
  return (
    <Switch>
      {routes.filter(route => (route.layout === '/admin/')).map(route => {
        return (<Route exact key={route.layout + route.path} path={route.layout + route.path} component={route.page} />);
      })}
      <Redirect exact from="/admin" to="/admin/links_generator" />
      <Redirect from="/admin/*" to="/not_found" />
    </Switch>
  );
}

export const getConsoleSideLinks = (): LinkData[] => {

  let links: LinkData[] = [];
  routes.filter(route => route.layout === '/admin/')
    .forEach(route => {
      if (route.label !== undefined) {
        links.push({
          href: route.layout + route.path,
          title: route.label.title,
          parentLabel: route.label.parentLabel,
          icon: route.label.icon,
        });
      }
    });
  return links;
}



const AdminLayout: React.FC<AdminLayoutProps> = (props) => {

  //Block access (redirect landing) if user is not authenticated
  if (props.authState!==null&&(props.authState?.logged === false || !props.authState?.user?.isStaff)) {
    return <Redirect to="/" />
  }

  return (
    <SidebarLayout
      links={getConsoleSideLinks().map(linkData =>
        <SidebarItem
          key={linkData.href}
          linkData={linkData}
        />
      )}
    >
      <RoutesSwitcher/>
    </SidebarLayout>
  )
}

const mapStateToProps = (state: RootState): ReduxStateProps => {
  return ({
    authState: state.user
  })
}

export default connect<ReduxStateProps, {}, {}, RootState>(mapStateToProps)(AdminLayout);