import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { AnimatedButton } from '../../components/buttons';

const PrivacyPolicyPage: React.FC<{}> = (props) => {

  const history = useHistory();

  return (
    <>
      <section className="py-16 px-8">
        <h2 className="font-bold text-2xl pb-2"> Privacy Policy</h2>
        <h4 className="italic text-lg pb-12">Last Updated: January 7, 2021</h4>
        <h3 className="font-semibold text-lg pb-4"> Definitions</h3>
        <p>
          <span className="font-bold">Personal Data (or Data)</span> Any information that enables (directly or indirectly) the identification of a natural person.
          <span className="font-bold"> Usage</span> Information collected automatically through this Website (or third party services employed in this Website),
          which can include: the information that the User's browser sends whenever the User visits this Webite or uses the
          Services (“Log Data”, Log Data could include, for example, the computer’s Internet Protocol address, the browser type,
          login information, the web page the User was visiting before comingto this Website and information the User searches
          for on this Website), information collected by the Google Tag Manager and Google Analytics about the User's visit,
          including the full Site (uniform resource locators (URL)) clickstream history to, through and from this Website (including
          date and time), the method utilized to submit the request to the server, the size of the file received in response, the
          numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the
          features of the browser and the operating system utilized by the User, page response times, download errors, length of visits
          to certain pages, page interaction information (such as scrolling, clicks and mouse-overs), and methods used to browse away
          from the page. <span className="font-bold">User</span> The individual using this Website who coincides with the Data Subject, unless otherwise specified. <span className="font-bold">Data </span>
          Subject The natural person to whom the Personal Data refers. <span className="font-bold">Data Processor</span> The natural or legal person, public authority,
          agency or other body which processes Personal Data on behalf of the Controller. <span className="font-bold">Data Controller (or DPO)</span> The natural or legal
          person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the
          processing of Personal Data, including the security measures concerning this Website. <span className="font-bold">This Website (or this Application)</span>
          The means by which the Personal Data of the User is collected and processed. Service The service provided by this Website
          as described in the terms and conditions available on this site. <span className="font-bold">European Union (or EU)</span> All current member states to the European
          Union and the European Economic Area. <span className="font-bold">Cookie</span> Cookies are small pieces of text sent to the User's web browser by a website the User
          visits. A cookie file is stored in the User's web browser and allows the Services or a third-party to recognize the User and make
          the User's next visit easier and the products more useful to the User. Cookies can be “persistent” or “session” cookies. Persistent
          cookies remain on the User's personal computer or mobile device when the User goes offline, while session cookies are deleted as soon
          as the User closes the web browser. <span className="font-bold">Tracker</span> Tracker indicates any technology - e.g Cookies, unique identifiers, web beacons, embedded
          scripts, e-tags and fingerprinting - that enables the tracking of Users. <span className="font-bold">Legal information</span> This privacy statement has been prepared based
          on multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).
 	      </p>
        <h3 className="font-semibold text-lg pt-6 pb-4"> Introduction</h3>
          The DPO operates <NavLink className="text-cherry-red-400 hover:text-cherry-red-200" to="/">www.ayradb.com </NavLink>
          (the "Services"). This page (<NavLink className="text-cherry-red-400 hover:text-cherry-red-200" to="/">www.ayradb.com/privacy_policy</NavLink>)
          informs the User of our policies regarding the collection, use and disclosure of Personal Data we receive from users of the Services ("User" or "Users").
          We use Personal Data only for providing and improving the Services. By using the Services, the User agrees to the collection and use of Personal Data in
          accordance with this policy.
        <h3 className="font-semibold text-lg pt-6 pb-4"> Data Collection And Use</h3>
        <p>
          While using the Services, we may ask the User to provide certain personally identifiable information that can be used to contact or identify the User.
          Personally identifiable information may include, but is not limited to the User's name ("Personal Data"). Personal Data is collected for the following
          purposes and using the following services:
        </p>
        <ul className="list-disc list-inside">
          <li className="">Analytics: we use the Google Analytics service. Personal Data: cookies; usage data.</li>
          {/* FIXME: Add proper fields here */}
        </ul>
        <h3 className="font-semibold text-lg pt-6 pb-4">Contact Information</h3>
        <p>
          Owner and Data Privacy Officer (DPO) Cherrydata srl Via Abano,
          9 20131 Milano, Italy <span className="cursor-pointer text-cherry-red-400 hover:text-red-200" onClick={() => window.location.assign('mailto:privacy@ayradb.com')}>privacy@ayradb.com</span> {/* FIXME: modify domain when mail is available */}
        </p>
        <h3 className="font-semibold text-lg pt-6 pb-4">Types of Data Collected</h3>
        <p>
          Among the types of Personal Data that this Website collects, by itself or through third parties, there are: email address; password; first name; last name; Cookies; phone number; Usage Data. Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by explanations displayed before collecting the User's data. Personal Data may be freely provided by the User or collected automatically when using this Website. In general, the Data requested by this Website are mandatory and, if the User chooses not to provide this Data, it may be impossible for this Website to provide its Services. In cases some Data are indicated as not mandatory, Users are free not to provide these Data without consequences to the functioning of the Services. Users who are uncertain about which Personal Data is mandatory are welcome to contact the DPO (see contact information above). Any use of Cookies – or of other tracking tools – by this Website or by the owners of third party services used by this Website serves the purpose of providing the Services required by the User, in addition to any other purposes described in the present document. Users are responsible for any third-party Personal Data obtained, published or shared through this Website and confirm that they have the third party's consent to provide the Data to the DPO.
        </p>
        <h3 className="font-semibold text-lg pt-6 pb-4"> How and where Data are processed</h3>
        <div className="pb-4">
          <h4 className="pb-2 font-semibold">Data processing</h4>
          <p>
            The DPO is concerned with safeguarding your Personal Data. We employ a variety of measures designed to protect your Personal Data from unauthorized access and disclosure. We implement systems, applications and procedures to secure your Personal Data, to minimize the risks of unauthorised access, disclosure, modification, or unauthorised destruction of your Personal Data. The Data processing is carried out using tools and following organizational procedures strictly related to the purposes indicated. We take reasonable steps to maintain the security of the Personal Data that we collect, including limiting the number of people who have access to our database servers. However, the complete security of data transmission over the Internet can never be guaranteed. Accordingly, we cannot ensure or warrant the security of any information that you transmit to us and you transmit data to us at your own risk.
        </p>
        </div>
        <div className="pb-4">
          <h4 className="pb-2 font-semibold">Job appliactions</h4>
          <p>
            If a User makes a job application, the User may upload or send the DPO a copy of the CV or other relevant Personal Data. The DPO will use this Data for the purpose of considering the User's application. Unless the User explicitly requests otherwise, the DPO may keep these Data for future reference in accordance with our data retention policy.
          </p>
        </div>
        <div className="pb-4">
          <h4 className="pb-2 font-semibold">Legal basis of processing</h4>
          <p>
            The DPO may process Personal Data if one of the following applies:
          </p>
          <ul className="list-disc list-inside py-3">
            <li>Users have given their consent for specific purposes. Note: Although under some legislations the DPO may be allowed to process Personal Data until the User objects to such processing (“opt-out”), Whenever the processing of Personal Data is subject to European data protection law we rely on consent ("opt-in").</li>
            <li>Data processing is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations;</li>
            <li>Processing is necessary for compliance with a legal obligation of the DPO;</li>
            <li>Processing is carried out in the public interest or in the exercise of official authority vested in the DPO;</li>
            <li>processing is necessary for the legitimate interests pursued by the DPO or by a third party.</li>
          </ul>
          <p className="pt-2">
            In any case, the DPO can be contacted to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a contractual requirement.
          </p>
        </div>
        <div className="pb-4">
          <h4 className="pb-2 font-semibold">Where</h4>
          <p>
            Part of the DPO's Services is Web based. The Data is processed at the DPO's operating offices and in any other places where the parties involved in the processing are located. From time to time, we will make operational decisions which will have an impact on the websites in which we maintain Personal Data. Depending on the User's location, data transfers may involve transferring the User's Data to a country or jurisdiction other than their own. To know more about where we process such transferred Data, Users can check the section providing details about the processing of Personal Data. If a User is a resident in a jurisdiction where transfer of your Personal Data to another jurisdiction requires your consent, then you provide us your express and unambiguous consent to such transfer. Users can learn about the legal basis of Data transfers to a country outside the European Union and about the security measures taken by the DPO to safeguard their Data. If any such transfer takes place, Users can find out more by reading the relevant sections of this privacy policy or contact the DPO using the information provided in the contact section.
          </p>
        </div>
        <div className="pb-4">
          <h4 className="pb-2 font-semibold">Legal basis of processing</h4>
          <p>
            The DPO retains data to provide the Services and for legitimate and lawful purposes. The DPO retains different types of Personal Data for different periods, depending on the purposes for processing the information, the DPO's legitimate business purposes as well as pursuant to legal requirements under the applicable law. Therefore:
          </p>
          <ul className="list-disc list-inside py-3">
            <li>Personal Data collected for purposes related to the performance of a contract between the DPO and the User will be retained until such contract has been fully performed.</li>
            <li>Personal Data collected for the purposes of the DPO’s legitimate interests will be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the DPO within the relevant sections of this document or by contacting the DPO.</li>
          </ul>
          <p className="pt-2">
            The DPO may be allowed to retain Personal Data for a longer period whenever the User has given consent, as long as consent is not withdrawn by the User. Furthermore, the DPO may have to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority. Once the retention period expires, Personal Data will be deleted. Therefore, the right to access, the right to delete and the right to update Data cannot be enforced after expiration of the retention period.
          </p>
        </div>
        <h3 className="font-semibold text-lg pt-6 pb-4">The purpose of data processing</h3>
        <p>
          The Data concerning the User are collected to allow the DPO to provide the Services, administer the use of the Services, administer Users accounts, personalize user experience, comply with the DPO's legal obligations, to comply with court orders and warrants, and assist law enforcement agencies, to collect debts, prevent fraud, misappropriation, infringements, identity thefts and any other misuse of the Service, and to take any action in any legal dispute or proceeding, protect the DPO's rights and interests (or those of its Users or third parties), detect any malicious or fraudulent activity, as well as the following: Registration, authentication, User data management, hosting, infrastructure, payments, contacts and messages, analytics and support. To know more about the Personal Data used for each purpose, the User may refer to the next section “Detailed information on Personal Data management”.
        </p>
        <h3 className="font-semibold text-lg pt-6 pb-4">Contact Information</h3>
        <p className="pb-4">
          Personal Data is collected for the following purposes and using the following services:
        </p>
        <div className="pb-4">
          <h4 className="pb-2 font-semibold">Where</h4>
          <p>
            The Analytics allow the DPO to monitor and analyze web traffic and can be used to keep track of User behavior and usage of the Services. Google Analytics: Google Analytics is a web analysis service provided by Google LLC or by Google Ireland Limited, depending on the location this Website is accessed from, (“Google”). Google uses the Data collected to track and report on the use of this Website, to prepare charts s on the User activities and share them with other Google services. Google may use the Data collected to personalize the ads of its own advertising network. Personal Data processed: Cookies; Usage Data. Place of processing:
            United States – <a className="text-cherry-red-400 hover:text-cherry-red-200" href="https://policies.google.com/privacy">Privacy Policy</a> – <a className="text-cherry-red-400 hover:text-cherry-red-200" href="https://tools.google.com/dlpage/gaoptout?hl=en">Opt out</a>; Ireland – <a className="text-cherry-red-400 hover:text-cherry-red-200" href="https://policies.google.com/privacy">Privacy Policy</a> – <a className="text-cherry-red-400 hover:text-cherry-red-200" href="https://tools.google.com/dlpage/gaoptout?hl=en">Opt out</a>.
          </p>
        </div>
        <div className="pb-4">
          <h4 className="pb-2 font-semibold">Hosting and infrastructure</h4>
          <p>
            This service has the purpose of hosting Data that enable this Website to run, to run specific features of this Website and to provide the Services. Some services among those listed below may work through geographically distributed servers, making it difficult to determine the actual location where the Personal Data are stored. <br />
          </p>
          <p>
            Aruba Cloud<br />
            Aruba Cloud is a hosting service provided by Aruba S.p.A. <br />
            Personal Data processed: various types of Data as specified in the privacy policy of the service. <br />
            Place of processing: Italy – <a className="text-cherry-red-400 hover:text-cherry-red-200" href="https://hosting.aruba.it/documents/tc-files/en/7_privacypolicyhostingcart.pdf">Privacy Policy</a>.
          </p>
          <p className="pt-2">
            Hetzner Cloud <br />
            Hetzner Cloud is a hosting service provided by Hetzner Online GmbH. <br />
            Personal Data processed: various types of Data as specified in the privacy policy of the service. <br />
            Place of processing: Germany – <a className="text-cherry-red-400 hover:text-cherry-red-200" href="https://www.hetzner.com/rechtliches/datenschutz/">Privacy Policy</a>.
          </p>
          {/* TODO ADD OTHER CLOUD PROVIDERS */}
        </div>
        {/* TODO ADD PAYMENTS */}
        {/* TODO ADD CONTACTS AND MESSAGING */}
        {/* TODO ADD SUPPORT AND CONTACT */}
        {/* TODO ADD REGISTRATION AND AUTHENTICATION */}
        <div className="pb-4">
          <h4 className="pb-2 font-semibold">Rights of Users</h4>
          <p>
            Users have certain rights regarding their Data processed by the DPO. In particular, Users have the right to:
          </p>
          <ul className="py-3 list-disc list-inside">
            <li>Consent withdrawal: Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data, at any time. Users can also Object to processing of their Data if processing is carried out on a legal basis other than consent (see the dedicated section below).</li>
            <li>Data access: Users have the right to know which Data is processed by the DPO and obtain a copy of the Data that is processed.</li>
            <li>Data modification: Users have the right to verify the accuracy of their Data and ask for it to rectified.</li>
            <li>Data deletion: Users have the right to obtain the erasure of their Data from the DPO, subject to certain exceptions.</li>
            <li>Processing restrictions: Users have the right to restrict the processing of their Data, subject to certain exceptions. In this case, the DPO will process their Data only for storing them.</li>
            <li>Data transfer: Users have the right to receive and transfer to another controller their Data in a commonly used and machine readable format, if technically feasible.</li>
          </ul>
          <div className="pb-4">
            <h4 className="pb-2 font-semibold">Note on processing restrictions</h4>
            <p>
              Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the DPO or for the purposes of the legitimate interests pursued by the DPO, Users may object by justifying their objection, unless their Personal Data be processed for direct marketing purposes, in which case they can object to that processing without providing any justification.
            </p>
          </div>
          <div className="pb-4">
            <h4 className="pb-2 font-semibold">Excercize rights</h4>
            <p>
              Users can address equests to exercise their rights to the DPO (see contact section).
            </p>
          </div>
        </div>
        <h3 className="font-semibold text-lg pt-6 pb-4">Cookie policy</h3>
        <p>
          Cookies are small pieces of text sent to the User's web browser by a website the User visits (see the Definitions section above for more information on cookies). Some of the purposes for which Cookies are used may require the User's consent.
          Where the installation of Cookies is based on consent, such consent can be freely revoked by the User as explained in this privacy policy document.
          This Website uses Cookies to perform activities that are strictly necessary for the operation or delivery of the Service.
          This Website uses Cookies to enable basic functionalities facilitating the use of the Services, the communication with the DPO and to measure traffic and analyze User behavior with the goal of improving the Service.
          {/* TODO FIX WITHDRAW AND CONSENT: ACTUALLY WE ONLY HAVE DON'T HAVE COOKIES */}
        </p>
        <h3 className="font-semibold text-lg pt-6 pb-4">Additional information</h3>
        <div className="pb-4">
          <h4 className="pb-2 font-semibold">Legal action</h4>
          <p>
            The User's Personal Data may be used for legal purposes by the DPO in Court or in the stages leading to possible legal action arising from improper use of this Website or the related Services. The User declares to be aware that the DPO may be required to reveal personal data upon request of public authorities.
          </p>
        </div>
        <div className="pb-4">
          <h4 className="pb-2 font-semibold">Information not contained in this policy</h4>
          <p>
            Users may contact the DPO at any time to request details concerning the collection or processing of Personal Data using the contact information at the beginning of this document.
          </p>
        </div>
        <div className="pb-4">
          <h4 className="pb-2 font-semibold">Changes to this privacy policy</h4>
          <p>
            The DPO will update this Policy from time to time. If we make any changes to this Policy, we will notify Users on this page <NavLink to="/privacy_policy">(https://www.ayradb.com/privacy_policy)</NavLink> and/or - as far as technically and legally feasible - sending a notice to Users via any contact information available to the DPO. It is strongly recommended to check this page <NavLink to="/privacy_policy">(https://www.ayradb.com/privacy_policy)</NavLink> often, referring to the date of the last modification listed at the top. Substantial changes will be effective 30 days after we initially post the notice. Until the new Policy takes effect, the User can choose not to accept it and terminate the use of the Services. Continuing to use the Services after the new Policy takes effect means that the User agrees to the new Policy.
          </p>
        </div>
        <div className="pt-4">
          <AnimatedButton
            onClick={() => history.goBack()}
            className="bg-cherry-red-400 py-2 px-4 rounded text-white mt-8 font-semibold"
          >
            Back
          </AnimatedButton>
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicyPage;